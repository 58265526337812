import React from "react";

const SVG = ({ className = "", viewBox = "0 0 24 24" }) => (
  <svg
    width="24"
    height="24"
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <linearGradient id="prefix__b" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#C90000" />
        <stop offset="100%" stopColor="#FF4C55" />
      </linearGradient>
      <linearGradient id="prefix__g" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset="0%" stopColor="#C90000" />
        <stop offset="100%" stopColor="#FF4C55" />
      </linearGradient>
      <linearGradient
        id="prefix__h"
        x1="50%"
        x2="50%"
        y1="11.913%"
        y2="61.299%"
      >
        <stop offset="0%" stopColor="#FFF" stopOpacity=".526" />
        <stop offset="100%" stopColor="#FFF" />
      </linearGradient>
      <linearGradient id="prefix__i" x1="50%" x2="50%" y1=".037%" y2="71.909%">
        <stop offset=".018%" stopColor="#FFE600" stopOpacity=".209" />
        <stop offset="100%" stopColor="#FFE600" />
      </linearGradient>
      <linearGradient id="prefix__l" x1="0%" x2="98.042%" y1="50%" y2="50%">
        <stop offset="0%" stopColor="#FFE2A7" />
        <stop offset="100%" stopColor="#FFBA34" />
      </linearGradient>
      <linearGradient id="prefix__n" x1="0%" x2="98.042%" y1="50%" y2="50%">
        <stop offset="0%" stopColor="#FFE2A7" />
        <stop offset="100%" stopColor="#FB6200" />
      </linearGradient>
      <linearGradient
        id="prefix__q"
        x1="76.393%"
        x2="35.054%"
        y1="9.541%"
        y2="95.999%"
      >
        <stop offset="0%" stopColor="#FFE861" />
        <stop offset="100%" stopColor="#FF7E00" />
      </linearGradient>
      <linearGradient id="prefix__v" x1="100%" x2="0%" y1="50%" y2="50%">
        <stop offset="0%" stopColor="#FFD847" />
        <stop offset="100%" stopColor="#FF8500" />
      </linearGradient>
      <linearGradient id="prefix__A" x1="50%" x2="50%" y1="0%" y2="100%">
        <stop offset=".143%" stopColor="#FFA932" stopOpacity=".311" />
        <stop offset="98.981%" stopColor="#FF8500" stopOpacity=".665" />
      </linearGradient>
      <linearGradient id="prefix__B" x1="98.042%" x2="0%" y1="50%" y2="50%">
        <stop offset="0%" stopColor="#FFE2A7" />
        <stop offset="100%" stopColor="#FFEEC8" />
      </linearGradient>
      <linearGradient id="prefix__F" x1="98.042%" x2="0%" y1="50%" y2="50%">
        <stop offset="0%" stopColor="#FFDB96" />
        <stop offset="100%" stopColor="#FFE5AF" />
      </linearGradient>
      <linearGradient id="prefix__I" x1="100%" x2="0%" y1="50%" y2="50%">
        <stop offset="0%" stopColor="#EE9600" />
        <stop offset="100%" stopColor="#F98E17" />
      </linearGradient>
      <linearGradient id="prefix__L" x1="100%" x2="0%" y1="50%" y2="50%">
        <stop offset="0%" stopColor="#FBC068" />
        <stop offset="100%" stopColor="#FFB158" />
      </linearGradient>
      <linearGradient id="prefix__O" x1="50%" x2="50%" y1="100%" y2="0%">
        <stop offset="0%" stopColor="#F29320" />
        <stop offset="100%" stopColor="#ED8000" />
      </linearGradient>
      <linearGradient id="prefix__R" x1="50%" x2="50%" y1="100%" y2="0%">
        <stop offset="0%" stopColor="#FFA627" />
        <stop offset="100%" stopColor="#FB9C1F" />
      </linearGradient>
      <linearGradient id="prefix__U" x1="100%" x2="0%" y1="50%" y2="50%">
        <stop offset="0%" stopColor="#FFE7B5" />
        <stop offset="100%" stopColor="#FFCF6F" />
      </linearGradient>
      <linearGradient id="prefix__X" x1="100%" x2="0%" y1="50%" y2="50%">
        <stop offset="0%" stopColor="#FFB058" />
        <stop offset="100%" stopColor="#EEB55E" />
      </linearGradient>
      <filter
        id="prefix__c"
        width="163%"
        height="250.6%"
        x="-31.5%"
        y="-76.5%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation=".816" />
      </filter>
      <filter
        id="prefix__f"
        width="188.7%"
        height="313.1%"
        x="-44.4%"
        y="-107.8%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur
          in="SourceAlpha"
          result="shadowBlurInner1"
          stdDeviation=".5"
        />
        <feOffset dy="1" in="shadowBlurInner1" result="shadowOffsetInner1" />
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          in="shadowInnerInner1"
          values="0 0 0 0 0.75147192 0 0 0 0 0.022824814 0 0 0 0 0.0380049621 0 0 0 1 0"
        />
      </filter>
      <filter
        id="prefix__k"
        width="100.8%"
        height="99.4%"
        x="-.4%"
        y="-1%"
        filterUnits="objectBoundingBox"
      >
        <feMorphology
          in="SourceAlpha"
          operator="dilate"
          radius=".25"
          result="shadowSpreadOuter1"
        />
        <feOffset in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feComposite
          in="shadowOffsetOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowOffsetOuter1"
        />
        <feColorMatrix
          in="shadowOffsetOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
        />
        <feMorphology
          in="SourceAlpha"
          operator="dilate"
          radius=".25"
          result="shadowSpreadOuter2"
        />
        <feOffset in="shadowSpreadOuter2" result="shadowOffsetOuter2" />
        <feComposite
          in="shadowOffsetOuter2"
          in2="SourceAlpha"
          operator="out"
          result="shadowOffsetOuter2"
        />
        <feColorMatrix
          in="shadowOffsetOuter2"
          result="shadowMatrixOuter2"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="shadowMatrixOuter2" />
        </feMerge>
      </filter>
      <filter
        id="prefix__o"
        width="133.7%"
        height="133.7%"
        x="-16.9%"
        y="-8.4%"
        filterUnits="objectBoundingBox"
      >
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feColorMatrix
          in="shadowOffsetOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
        />
        <feOffset in="SourceAlpha" result="shadowOffsetOuter2" />
        <feColorMatrix
          in="shadowOffsetOuter2"
          result="shadowMatrixOuter2"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
        />
        <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter3" />
        <feGaussianBlur
          in="shadowOffsetOuter3"
          result="shadowBlurOuter3"
          stdDeviation=".5"
        />
        <feColorMatrix
          in="shadowBlurOuter3"
          result="shadowMatrixOuter3"
          values="0 0 0 0 0.984313725 0 0 0 0 0.338626723 0 0 0 0 0 0 0 0 1 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="shadowMatrixOuter2" />
          <feMergeNode in="shadowMatrixOuter3" />
        </feMerge>
      </filter>
      <filter
        id="prefix__r"
        width="100%"
        height="100%"
        x="0%"
        y="0%"
        filterUnits="objectBoundingBox"
      >
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feColorMatrix
          in="shadowOffsetOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 1 0 0 0 0 0.614091852 0 0 0 0 0.0549019608 0 0 0 1 0"
        />
        <feOffset in="SourceAlpha" result="shadowOffsetOuter2" />
        <feColorMatrix
          in="shadowOffsetOuter2"
          result="shadowMatrixOuter2"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.44722465 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="shadowMatrixOuter2" />
        </feMerge>
      </filter>
      <filter
        id="prefix__t"
        width="100%"
        height="100%"
        x="0%"
        y="0%"
        filterUnits="objectBoundingBox"
      >
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feColorMatrix
          in="shadowOffsetOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
        />
        <feOffset in="SourceAlpha" result="shadowOffsetOuter2" />
        <feColorMatrix
          in="shadowOffsetOuter2"
          result="shadowMatrixOuter2"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.43520542 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="shadowMatrixOuter2" />
        </feMerge>
      </filter>
      <filter
        id="prefix__x"
        width="131.4%"
        height="131.4%"
        x="-15.7%"
        y="-15.7%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur
          in="SourceAlpha"
          result="shadowBlurInner1"
          stdDeviation="1.5"
        />
        <feOffset in="shadowBlurInner1" result="shadowOffsetInner1" />
        <feComposite
          in="shadowOffsetInner1"
          in2="SourceAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
          result="shadowInnerInner1"
        />
        <feColorMatrix
          in="shadowInnerInner1"
          result="shadowMatrixInner1"
          values="0 0 0 0 1 0 0 0 0 0.466666667 0 0 0 0 0 0 0 0 1 0"
        />
        <feGaussianBlur
          in="SourceAlpha"
          result="shadowBlurInner2"
          stdDeviation=".5"
        />
        <feOffset in="shadowBlurInner2" result="shadowOffsetInner2" />
        <feComposite
          in="shadowOffsetInner2"
          in2="SourceAlpha"
          k2="-1"
          k3="1"
          operator="arithmetic"
          result="shadowInnerInner2"
        />
        <feColorMatrix
          in="shadowInnerInner2"
          result="shadowMatrixInner2"
          values="0 0 0 0 0.917647059 0 0 0 0 0 0 0 0 0 0 0 0 0 0.445968094 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixInner1" />
          <feMergeNode in="shadowMatrixInner2" />
        </feMerge>
      </filter>
      <filter
        id="prefix__y"
        width="177.9%"
        height="177.9%"
        x="-38.9%"
        y="-38.9%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" stdDeviation=".847" />
      </filter>
      <filter
        id="prefix__D"
        width="100%"
        height="100%"
        x="0%"
        y="0%"
        filterUnits="objectBoundingBox"
      >
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feColorMatrix
          in="shadowOffsetOuter1"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
        />
      </filter>
      <filter
        id="prefix__G"
        width="100%"
        height="100%"
        x="0%"
        y="0%"
        filterUnits="objectBoundingBox"
      >
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feColorMatrix
          in="shadowOffsetOuter1"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.907233392 0"
        />
      </filter>
      <filter
        id="prefix__J"
        width="100%"
        height="100%"
        x="0%"
        y="0%"
        filterUnits="objectBoundingBox"
      >
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feColorMatrix
          in="shadowOffsetOuter1"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.768739073 0"
        />
      </filter>
      <filter
        id="prefix__M"
        width="100%"
        height="100%"
        x="0%"
        y="0%"
        filterUnits="objectBoundingBox"
      >
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feColorMatrix
          in="shadowOffsetOuter1"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.254479895 0"
        />
      </filter>
      <filter
        id="prefix__P"
        width="100%"
        height="100%"
        x="0%"
        y="0%"
        filterUnits="objectBoundingBox"
      >
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feColorMatrix
          in="shadowOffsetOuter1"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.410292832 0"
        />
      </filter>
      <filter
        id="prefix__S"
        width="100%"
        height="100%"
        x="0%"
        y="0%"
        filterUnits="objectBoundingBox"
      >
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feColorMatrix
          in="shadowOffsetOuter1"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.751830201 0"
        />
      </filter>
      <filter
        id="prefix__V"
        width="100%"
        height="100%"
        x="0%"
        y="0%"
        filterUnits="objectBoundingBox"
      >
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feColorMatrix
          in="shadowOffsetOuter1"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.738964161 0"
        />
      </filter>
      <filter
        id="prefix__Y"
        width="100%"
        height="100%"
        x="0%"
        y="0%"
        filterUnits="objectBoundingBox"
      >
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feColorMatrix
          in="shadowOffsetOuter1"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
        />
      </filter>
      <filter
        id="prefix__aa"
        width="100%"
        height="100%"
        x="0%"
        y="0%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" />
      </filter>
      <path
        id="prefix__a"
        d="M0 0h7.773v6.43c0 .134-.108.242-.242.242-.044 0-.087-.012-.125-.035l-3.52-2.125L.368 6.637c-.114.07-.263.033-.332-.082C.012 6.517 0 6.475 0 6.43V0z"
      />
      <path
        id="prefix__e"
        d="M0 0h7.773v2.881c0 .134-.108.242-.242.242-.022 0-.044-.003-.065-.009l-3.58-1.009-3.578 1.01c-.13.035-.263-.04-.299-.168C.003 2.926 0 2.903 0 2.88V0z"
      />
      <path
        id="prefix__j"
        d="M7.773 14.136l-2.423.859c-.137.048-.288-.007-.362-.132l-1.304-2.216-2.409-.9c-.136-.05-.216-.19-.192-.333l.424-2.536L.241 6.641c-.071-.126-.043-.285.067-.38l1.955-1.669.468-2.528c.027-.143.15-.246.295-.248l2.571-.022L7.581.16c.112-.092.273-.092.385 0L9.95 1.794l2.57.022c.145.002.27.105.295.248l.469 2.528 1.955 1.67c.11.094.138.253.067.379l-1.267 2.237.425 2.536c.024.143-.057.283-.192.333l-2.409.9-1.304 2.216c-.074.125-.226.18-.362.132l-2.424-.859z"
      />
      <path
        id="prefix__z"
        d="M3.887 6.023l-1.904.84c-.123.054-.265-.001-.32-.123-.016-.039-.023-.08-.019-.123l.211-2.07L.467 2.996c-.089-.1-.08-.253.02-.342.03-.028.069-.047.11-.056l2.034-.44L3.678.36c.067-.115.215-.154.33-.087.037.021.067.051.088.087l1.046 1.8 2.035.439c.13.028.213.157.185.287-.009.041-.028.08-.056.11L5.918 4.548l.211 2.07c.014.133-.083.252-.216.266-.042.004-.084-.003-.122-.02l-1.904-.84z"
      />
      <path id="prefix__E" d="M3.887 3.917L7.583 2.686 5.142 2.158z" />
      <path id="prefix__H" d="M3.871 3.917L5.918 4.547 7.583 2.686z" />
      <path id="prefix__K" d="M3.871 3.917L5.918 4.547 6.171 7.031z" />
      <path id="prefix__N" d="M3.871 3.917L3.887 6.023 6.171 7.031z" />
      <path id="prefix__Q" d="M3.871 3.917L1.602 7.031 3.887 6.023z" />
      <path id="prefix__T" d="M3.887 3.902L1.855 4.547 1.602 7.031z" />
      <path id="prefix__W" d="M3.887 3.902L0.19 2.686 1.855 4.547z" />
      <path id="prefix__Z" d="M3.887 3.917L2.631 2.158 0.19 2.686z" />
      <circle id="prefix__p" cx="7.789" cy="7.865" r="5.929" />
      <circle id="prefix__s" cx="7.789" cy="7.789" r="6.158" />
      <circle id="prefix__u" cx="7.789" cy="7.789" r="5.304" />
      <circle id="prefix__w" cx="7.789" cy="7.789" r="4.771" />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(0 1)">
      <circle
        cx="12.34"
        cy="11.34"
        r="9.145"
        stroke="#E4EAF5"
        strokeWidth=".5"
      />
      <circle
        cx="12.34"
        cy="11.34"
        r="11.34"
        stroke="#E4EAF5"
        strokeWidth=".5"
      />
      <g transform="translate(4.567 1.463)">
        <g transform="translate(3.902 12.895)">
          <mask id="prefix__d" fill="#fff">
            <use xlinkHref="#prefix__a" />
          </mask>
          <use fill="url(#prefix__b)" xlinkHref="#prefix__a" />
          <g filter="url(#prefix__c)" mask="url(#prefix__d)">
            <use fill="#8C0508" xlinkHref="#prefix__e" />
            <use fill="#000" filter="url(#prefix__f)" xlinkHref="#prefix__e" />
          </g>
          <path
            fill="url(#prefix__g)"
            d="M0 6.402l3.887-2.317 3.886 2.317v.028c0 .134-.108.242-.242.242-.044 0-.087-.012-.125-.035l-3.52-2.125L.368 6.637c-.114.07-.263.033-.332-.082C.012 6.517 0 6.475 0 6.43v-.028z"
            mask="url(#prefix__d)"
            opacity=".43"
          />
          <path
            fill="url(#prefix__h)"
            d="M0.854 0.945H1.854V6.554H0.854z"
            mask="url(#prefix__d)"
          />
          <path
            fill="url(#prefix__i)"
            d="M1.189 0.945H2.189V6.554H1.189z"
            mask="url(#prefix__d)"
          />
          <path
            fill="url(#prefix__h)"
            d="M6.585 0.945H7.585V6.554H6.585z"
            mask="url(#prefix__d)"
          />
          <path
            fill="url(#prefix__i)"
            d="M5.792 0.945H6.792V6.554H5.792z"
            mask="url(#prefix__d)"
          />
        </g>
        <mask id="prefix__m" fill="#fff">
          <use xlinkHref="#prefix__j" />
        </mask>
        <use fill="#000" filter="url(#prefix__k)" xlinkHref="#prefix__j" />
        <use
          fill="#FFF1BA"
          stroke="#FFEA9A"
          strokeWidth=".5"
          xlinkHref="#prefix__j"
        />
        <path
          fill="url(#prefix__l)"
          d="M7.773 0H9.937V2.256H7.773z"
          mask="url(#prefix__m)"
        />
        <path
          fill="url(#prefix__l)"
          d="M11.889 2.103L14.053 2.103 14.053 4.359 11.889 4.359z"
          mask="url(#prefix__m)"
          transform="rotate(40 12.97 3.231)"
        />
        <path
          fill="url(#prefix__l)"
          d="M13.535 6.524L15.699 6.524 15.699 8.779 13.535 8.779z"
          mask="url(#prefix__m)"
          transform="rotate(80 14.617 7.651)"
        />
        <path
          fill="url(#prefix__n)"
          d="M11.919 10.883L14.084 10.883 14.084 13.139 11.919 13.139z"
          mask="url(#prefix__m)"
          transform="rotate(120 13.001 12.01)"
        />
        <path
          fill="url(#prefix__n)"
          d="M7.926 13.23L10.09 13.23 10.09 15.486 7.926 15.486z"
          mask="url(#prefix__m)"
          transform="rotate(160 9.008 14.358)"
        />
        <path
          fill="url(#prefix__n)"
          d="M3.445 12.529L5.609 12.529 5.609 14.785 3.445 14.785z"
          mask="url(#prefix__m)"
          transform="rotate(-160 4.527 13.657)"
        />
        <path
          fill="url(#prefix__n)"
          d="M0.427 9.023L2.591 9.023 2.591 11.279 0.427 11.279z"
          mask="url(#prefix__m)"
          opacity=".6"
          transform="rotate(-120 1.509 10.151)"
        />
        <path
          fill="url(#prefix__l)"
          d="M0.213 4.39L2.378 4.39 2.378 6.645 0.213 6.645z"
          mask="url(#prefix__m)"
          transform="rotate(-80 1.296 5.518)"
        />
        <path
          fill="url(#prefix__l)"
          d="M3.079 0.701L5.243 0.701 5.243 2.957 3.079 2.957z"
          mask="url(#prefix__m)"
          transform="rotate(-40 4.161 1.829)"
        />
        <g mask="url(#prefix__m)">
          <use fill="#000" filter="url(#prefix__o)" xlinkHref="#prefix__p" />
          <use fill="url(#prefix__q)" xlinkHref="#prefix__p" />
        </g>
        <g>
          <use fill="#000" filter="url(#prefix__r)" xlinkHref="#prefix__s" />
          <use fill="url(#prefix__q)" xlinkHref="#prefix__s" />
        </g>
        <g>
          <use fill="#000" filter="url(#prefix__t)" xlinkHref="#prefix__u" />
          <use fill="url(#prefix__v)" xlinkHref="#prefix__u" />
        </g>
        <g>
          <use fill="#FFBD42" xlinkHref="#prefix__w" />
          <use fill="#000" filter="url(#prefix__x)" xlinkHref="#prefix__w" />
        </g>
        <circle
          cx="7.56"
          cy="7.773"
          r="3.262"
          fill="#FFE97C"
          filter="url(#prefix__y)"
        />
        <g transform="translate(3.902 3.871)">
          <mask id="prefix__C" fill="#fff">
            <use xlinkHref="#prefix__z" />
          </mask>
          <path
            stroke="url(#prefix__A)"
            strokeWidth=".5"
            d="M3.643-.079L2.46 1.957.04 2.893l1.57 1.755.143 2.59 2.154-.951 2.507.665-.238-2.343 1.407-2.18-2.302-.496L3.643-.08z"
          />
          <path
            fill="url(#prefix__B)"
            d="M3.887 0L3.887 3.917 5.142 2.158z"
            mask="url(#prefix__C)"
          />
          <g mask="url(#prefix__C)">
            <use fill="#000" filter="url(#prefix__D)" xlinkHref="#prefix__E" />
            <use fill="url(#prefix__F)" xlinkHref="#prefix__E" />
          </g>
          <g mask="url(#prefix__C)">
            <use fill="#000" filter="url(#prefix__G)" xlinkHref="#prefix__H" />
            <use fill="url(#prefix__I)" xlinkHref="#prefix__H" />
          </g>
          <g mask="url(#prefix__C)">
            <use fill="#000" filter="url(#prefix__J)" xlinkHref="#prefix__K" />
            <use fill="url(#prefix__L)" xlinkHref="#prefix__K" />
          </g>
          <g mask="url(#prefix__C)">
            <use fill="#000" filter="url(#prefix__M)" xlinkHref="#prefix__N" />
            <use fill="url(#prefix__O)" xlinkHref="#prefix__N" />
          </g>
          <g mask="url(#prefix__C)">
            <use fill="#000" filter="url(#prefix__P)" xlinkHref="#prefix__Q" />
            <use fill="url(#prefix__R)" xlinkHref="#prefix__Q" />
          </g>
          <g mask="url(#prefix__C)">
            <use fill="#000" filter="url(#prefix__S)" xlinkHref="#prefix__T" />
            <use fill="url(#prefix__U)" xlinkHref="#prefix__T" />
          </g>
          <g mask="url(#prefix__C)">
            <use fill="#000" filter="url(#prefix__V)" xlinkHref="#prefix__W" />
            <use fill="url(#prefix__X)" xlinkHref="#prefix__W" />
          </g>
          <g mask="url(#prefix__C)">
            <use fill="#000" filter="url(#prefix__Y)" xlinkHref="#prefix__Z" />
            <use fill="url(#prefix__F)" xlinkHref="#prefix__Z" />
          </g>
          <path
            fill="#FFF"
            d="M3.887 3.917L2.631 2.158 3.887 0z"
            mask="url(#prefix__C)"
          />
        </g>
      </g>
      <path
        fill="#FF9919"
        d="M1.64 6.926L0.64 7.46 1.174 6.46 0.64 5.46 1.64 5.994 2.64 5.46 2.106 6.46 2.64 7.46z"
        transform="rotate(45 1.64 6.46)"
      />
      <path
        fill="#32C5FF"
        d="M18.64 17.926L17.64 18.46 18.174 17.46 17.64 16.46 18.64 16.994 19.64 16.46 19.106 17.46 19.64 18.46z"
        transform="rotate(70 18.64 17.46)"
      />
      <circle
        cx="15.7"
        cy="2.1"
        r="1"
        fill="#FFE15A"
        filter="url(#prefix__aa)"
      />
    </g>
  </svg>
);

export default SVG;
