import React, { useContext } from "react";

import { IconHeading, EmptyImport } from "../shared";
import { SOLUTION_FEATURE } from "../../constants/pro_constants";
import databaseDetailStore from "../../stores/databaseDetailStore";

const SolutionFeatureIntro = () => {
  const {
    data: {
      tabs: { solutionFeature },
    },
    isSolutionFeatureTabContentEmpty,
  } = useContext(databaseDetailStore);

  return (
    <>
      <div
        className="database-detail__intro database-detail__block"
        id={SOLUTION_FEATURE}
      >
        <IconHeading icon="pro-solution-feature" title="功能简介" />
        {isSolutionFeatureTabContentEmpty ? (
          <div className="database-detail__block__content">
            <EmptyImport />
          </div>
        ) : (
          <div
            className="database-detail__block__content"
            dangerouslySetInnerHTML={{ __html: `${solutionFeature}` }}
          />
        )}
      </div>
      <div className="database-detail__intro--bottom" />
    </>
  );
};

export default SolutionFeatureIntro;
