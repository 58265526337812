import React, { useContext, useState } from "react";
import { observer } from "mobx-react";
import { Tooltip } from "antd";

import { Icon } from "../shared";
import authStore from "../../stores/authStore";
import researchStore from "../../stores/researchStore";
import { trackResearchEvent } from "../../utils/ga_events";
import { ResearchCategory } from "../../constants/research";

interface Props {
  lead: boolean;
  title: string;
  category: string;
  status: string;
  fileUrl: string;
  externalUrl: string;
  id: string;
  researchTypes: string[];
  onClick: () => void;
}

const TableOperation: React.FC<Props> = (props: Props) => {
  //TODO: Move all logic to mobx
  const isShowEyes = props.category === "可视化";
  const isShowDetail = props.category === "报告" && props.status !== "征集中";
  const isShowExternalLink = props.category === "收录";
  const isShowDownload = props.fileUrl && props.category !== "可视化";

  const { isTrialUser } = useContext(authStore);
  const { setIsShowAuthWallModal } = useContext(researchStore);
  const [detailColor, setDetailColor] = useState("#C6CBD4");
  const [downloadColor, setDownloadColor] = useState("#C6CBD4");

  return (
    <div className="research-list-opration">
      {isShowEyes && (
        <Tooltip placement="bottom" title="查看详情">
          <a
            className="research-list-opration__file"
            onClick={props.onClick}
            onMouseEnter={() => {
              setDetailColor("#0062ff");
            }}
            onMouseLeave={() => {
              setDetailColor("#C6CBD4");
            }}
          >
            <Icon name="pro-eyes" color={detailColor} />
          </a>
        </Tooltip>
      )}
      {isShowExternalLink && (
        <Tooltip placement="bottom" title="查看详情">
          <a
            className="research-list-opration__file"
            href={props.externalUrl}
            target="_blank"
            rel="noopener"
            onClick={() => {
              trackResearchEvent(
                "view_info",
                "Research",
                `${props.title}#${props.id}`,
                ResearchCategory[props.category]
              );
            }}
            onMouseEnter={() => {
              setDetailColor("#0062ff");
            }}
            onMouseLeave={() => {
              setDetailColor("#C6CBD4");
            }}
          >
            <Icon name="pro-report" color={detailColor} />
          </a>
        </Tooltip>
      )}
      {isShowDetail && (
        <Tooltip placement="bottom" title="查看详情">
          <a
            className="research-list-opration__file"
            href={`/research/${props.id}`}
            target="_blank"
            rel="noopener"
            onClick={() => {
              trackResearchEvent(
                "view_info",
                "Research",
                `${props.title}#${props.id}`,
                ResearchCategory[props.category]
              );
            }}
            onMouseEnter={() => {
              setDetailColor("#0062ff");
            }}
            onMouseLeave={() => {
              setDetailColor("#C6CBD4");
            }}
          >
            <Icon name="pro-file-detail" color={detailColor} />
          </a>
        </Tooltip>
      )}
      {!props.lead && isShowDownload && (
        <Tooltip placement="bottom" title="下载">
          <>
            {isTrialUser ? (
              <a
                className="research-list-opration__download"
                onClick={() => setIsShowAuthWallModal(true)}
                onMouseEnter={() => {
                  setDownloadColor("#0062ff");
                }}
                onMouseLeave={() => {
                  setDownloadColor("#C6CBD4");
                }}
              >
                <Icon name="pro-file-download" color={downloadColor} />
              </a>
            ) : (
              <a
                className="research-list-opration__download"
                href={props.fileUrl}
                download
                target="_blank"
                rel="noopener"
                onClick={() => {
                  trackResearchEvent(
                    "download",
                    "Research",
                    `${props.title}#${props.id}`,
                    props.category === "可视化" ? "1" : "0"
                  );
                }}
                onMouseEnter={() => {
                  setDownloadColor("#0062ff");
                }}
                onMouseLeave={() => {
                  setDownloadColor("#C6CBD4");
                }}
              >
                <Icon name="pro-file-download" color={downloadColor} />
              </a>
            )}
          </>
        </Tooltip>
      )}
    </div>
  );
};

export default observer(TableOperation);
