import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react";

import "./style.scss";
import { Header, Footer } from "../shared";
import ExchangeCoupon from "./ExchangeCoupon";
import ExchangeSure from "./ExchangeSure";
import ExchangeSuccess from "./ExchangeSuccess";
import MissInfo from "./MissInfo";
import AuthStore from "../../stores/authStore";
import CouponCodeStore from "../../stores/couponCodeStore";

const CouponCode: React.FC = () => {
  const { isCurrentUserEmpty } = useContext(AuthStore);
  const { step } = useContext(CouponCodeStore);

  useEffect(() => {}, [step]);

  const components = [
    <ExchangeCoupon key={0} />,
    <ExchangeSure key={1} />,
    <ExchangeSuccess key={2} />,
  ];

  return (
    <div className="pro-coupon-code-layout">
      <Header />
      <div className="pro-coupon-code-content">
        {isCurrentUserEmpty ? <MissInfo /> : components[step]}
      </div>
      <Footer />
    </div>
  );
};

export default observer(CouponCode);
