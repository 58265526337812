import moment from "moment";

const formatResearchDetail = initData => {
  return {
    header: {
      title: initData.title,
      author: initData.author,
      cover: initData.coverImageUrl,
      industry: initData.industries.join("、"),
      pageSize: initData.pageSize,
      chartSize: initData.charts.length,
      datasetSize: initData.datasets.length,
      uploadedAt: moment(initData.uploadedAt).format("YYYY-MM-DD"),
      lead: initData.lead,
      fileUrl: initData.fileUrl,
      charged: initData.charged,
      paymentUrl: initData.paymentUrl,
      categoryCn: initData.categoryCn,
    },
    tabs: {
      reportIntro: initData.summary,
      reportCatalog: {
        activeIndex: 0,
        images: initData.images.map(item => ({
          preview: item.fileUrl,
          thumbnail: item.fileUrl,
        })),
      },
      charts: initData.charts.map(item => ({
        image: item.fileUrl,
        name: item.title,
        fileUrl: item.fileUrl,
      })),
      dataset: initData.datasets.map(item => ({
        title: item.name,
        category: item.summary,
        size: item.size,
        count: item.amount,
        fileUrl: item.fileUrl,
      })),
    },
    related: {
      institutions: initData.institutions
        .map(item => ({
          avatarURL: item.avatarUrl,
          title: item.name,
          labels: item.roles,
          id: item.uuid,
        }))
        .slice(0, 5),
      solutions: initData.solutions
        .map(item => ({
          title: item.name,
          id: item.uuid,
        }))
        .slice(0, 5),
    },
  };
};

export default formatResearchDetail;
