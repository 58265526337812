const JIN_SHU_JU = {
  snEdCJ: {
    field_1: "验证码 (请填写6个字)",
    field_4: "请罗列企业目前已经获得的经营资质、认证等",
    field_6: "企业2021年总营收规模（人民币）",
    field_7: "企业近三年内平均流动资产规模（人民币）",
    field_9: "请选择贵司 2021年度企业研发费用占销售收入总额百分比",
    field_10: "请选择企业2021年度技术研发投入总额（人民币）",
    field_11: "请选择企业技术研发人员占总人员百分比",
    field_13: "请在下表中选择企业已形成较强技术储备的领域",
    field_15: "请选择企业近3年内人工智能领域专业学术会议及期刊论文发布数量",
    field_16: "请选择企业近3年内人工智能领域专利获得数量",
    field_18: "请具体罗列企业3年内取得国际级奖项或赛事名称",
    field_19: "请具体罗列企业3年内取得国家级奖项或赛事名称",
    field_20: "请具体罗列企业3年内取得省市级奖项或赛事名称",
    field_45:
      "请罗列企业近3年内所获社会性奖项、荣誉及官媒报道（如人民网、地方电视台等）",
    field_47:
      "请罗列企业近3年内所获技术性奖项、荣誉及头部技术媒体报道（如机器之心）",
    field_49: "请罗列企业近3年内所获商业性奖项、荣誉及头部商业媒体报道未命名",
    field_51: "请罗列企业所申报奖项相关领域资深专业人士对企业的口碑评价未命名",
    field_83: "请阐述企业技术储备的领先性（如论文、专利、软著等方面）",
    field_84:
      "请选择贵司 2021年度新一代智能技术研发投入占企业总体研发费用的百分比区间",
    field_85:
      "请提供贵司在近3年间已成功应用于服务或产品中的技术占总体研发产出技术的比例区间",
    field_86: "请提供贵司在近3年间已成功应用于服务或产品中的技术数量范围",
    field_87: "请阐述贵司研发技术成品后或应用后所带来的相关业务收益总额范围",
    field_89:
      "请阐述在近3年间，企业已研发的技术为企业哪些主营业务产生经济收益或社会收益？",
    field_91: "请选择企业技术研发团队规模",
    field_93: "请阐述企业在近3年间技术团队人员流入情况",
    field_94: "请阐述企业在近3年间技术团队人员流入情况",
    field_96: "企业技术研发团队硕士及博士占比",
    field_97: "请罗列企业研发团队的知名的学者、科学家等",
    field_98: "企业取得国家级、省市级重点科研经费规模",
    field_100: "企业是否有系统的技术团队梯队培养机制？如有，请陈述介绍",
  },
  JWHnmP: {
    field_1: "验证码 (请填写6个字)",
    field_4: "请罗列企业目前已经获得的经营资质、认证等",
    field_6: "企业2021年总营收规模（人民币）",
    field_7: "企业近三年内平均流动资产规模（人民币）",
    field_9: "请选择贵司 2021年度企业研发费用占销售收入总额百分比",
    field_10: "请选择企业2021年度技术研发投入总额（人民币）",
    field_11: "请选择企业技术研发人员占总人员百分比",
    field_13: "请在下表中选择企业已形成较强技术储备的领域",
    field_15: "请选择企业近3年内人工智能领域专业学术会议及期刊论文发布数量",
    field_16: "请选择企业近3年内人工智能领域专利获得数量",
    field_18: "请具体罗列企业3年内取得国际级奖项或赛事名称",
    field_19: "请具体罗列企业3年内取得国家级奖项或赛事名称",
    field_20: "请具体罗列企业3年内取得省市级奖项或赛事名称",
    field_22: "请阐述企业主要收入构成及相应占比",
    field_23: "请阐述企业已落地的商业模式、已形成的竞争优势、已建立的壁垒",
    field_25:
      "请阐述企业目前已规模化开展业务的细分市场，如“智慧城市”，“制造型企业ERP系统” 等",
    field_26:
      "请阐述企业目前已规模化开展业务的地理区域，如“中国”，“北上广”，“东南亚”等",
    field_28: "请阐述企业目前战略聚焦市场中各市场占有率",
    field_30: "请选择性罗列企业主营业务长期合作的代表性供应商",
    field_31:
      "请选择性罗列贵司近3年内主营业务方面落地战略合作的代表性技术合作伙伴，如咨询公司、设计院、研究院、方案集成商、销售代理等",
    field_33:
      "请简单罗列贵司在目前在战略聚焦市场的牵头或参与的技术/产业生态，包括但不仅限于技术标准协会、产业联盟、服务平台、联合实验室等",
    field_34:
      "上述生态中，请选择贵司参与度最高的一个生态进行对该生态目前发展情况的简要介绍",
    field_35: "请提供该生态官网链接",
    field_36: "请简要介绍贵司在该生态扮演角色",
    field_37: "请简要介绍该生态代表性输出成果或获得成绩",
    field_45:
      "请罗列企业近3年内所获社会性奖项、荣誉及官媒报道（如人民网、地方电视台等）",
    field_47:
      "请罗列企业近3年内所获技术性奖项、荣誉及头部技术媒体报道（如机器之心）",
    field_49: "请罗列企业近3年内所获商业性奖项、荣誉及头部商业媒体报道未命名",
    field_51: "请罗列企业所申报奖项相关领域资深专业人士对企业的口碑评价未命名",
    field_71:
      "请分别阐述企业目前的巨型、大型、中小型客户数量级及客户类别（政府、企业、学校、消费者等）",
    field_72: "请具体罗列企业3年内服务的知名客户（全球500强、国企、独角兽等）",
    field_74: "企业是否为个人用户提供智能产品或服务？",
    field_75: "企业个人用户总数",
    field_76: "企业个人用户年增长率",
    field_78: "请阐述企业客户或用户的满意度情况",
    field_80: "请选择企业主营业务中利润最高的业务的利润率区间",
    field_82: "请说明企业各个服务场景下用户的忠诚度情况",
  },
  ljXlDD: {
    field_1: "验证码 (请填写6个字)",
    field_4: "请罗列企业目前已经获得的经营资质、认证等",
    field_6: "企业2021年总营收规模（人民币）",
    field_7: "企业近三年内平均流动资产规模（人民币）",
    field_9: "请选择贵司 2021年度企业研发费用占销售收入总额百分比",
    field_10: "请选择企业2021年度技术研发投入总额（人民币）",
    field_11: "请选择企业技术研发人员占总人员百分比",
    field_13: "请在下表中选择企业已形成较强技术储备的领域",
    field_15: "请选择企业近3年内人工智能领域专业学术会议及期刊论文发布数量",
    field_16: "请选择企业近3年内人工智能领域专利获得数量",
    field_18: "请具体罗列企业3年内取得国际级奖项或赛事名称",
    field_19: "请具体罗列企业3年内取得国家级奖项或赛事名称",
    field_20: "请具体罗列企业3年内取得省市级奖项或赛事名称",
    field_22: "请阐述企业主要收入构成及相应占比",
    field_23: "请阐述企业已落地的商业模式、已形成的竞争优势、已建立的壁垒",
    field_25:
      " 请阐述企业目前已规模化开展业务的细分市场，如“智慧城市”，“制造型企业ERP系统” 等",
    field_26:
      "请阐述企业目前已规模化开展业务的地理区域，如“中国”，“北上广”，“东南亚”等",
    field_28: "请阐述企业目前战略聚焦市场中各市场占有率",
    field_30: "请选择性罗列企业主营业务长期合作的代表性供应商",
    field_31:
      "请选择性罗列贵司近3年内主营业务方面落地战略合作的代表性技术合作伙伴，如咨询公司、设计院、研究院、方案集成商、销售代理等",
    field_33:
      "请简单罗列贵司在目前在战略聚焦市场的牵头或参与的技术/产业生态，包括但不仅限于技术标准协会、产业联盟、服务平台、联合实验室等",
    field_34:
      "上述生态中，请选择贵司参与度最高的一个生态进行对该生态目前发展情况的简要介绍",
    field_35: "请提供该生态官网链接",
    field_36: "请简要介绍贵司在该生态扮演角色",
    field_37: "请简要介绍该生态代表性输出成果或获得成绩",
    field_39:
      "请罗列企业目前战略聚焦攻占的具体智能化市场，如“智慧城市”，“制造型企业ERP系统” 等",
    field_40:
      "请罗列企业目前战略聚焦攻占的的业务拓展地理区域，如“中国”，“北上广”，“东南亚”等",
    field_41: "请简要阐述企业目前战略聚焦攻占的智能化市场预期规模（人民币）",
    field_42: "请简要介绍企业在该市场存在的机会与相对竞争优势",
    field_43:
      "请详细阐述企业在该市场的独特性优势（如“团队核心成员在该细分市场平均从业经验超过十年”，“跨代技术储备”等）",
    field_45:
      "请罗列企业近3年内所获社会性奖项、荣誉及官媒报道（如人民网、地方电视台等）",
    field_47:
      "请罗列企业近3年内所获技术性奖项、荣誉及头部技术媒体报道（如机器之心）",
    field_49: "请罗列企业近3年内所获商业性奖项、荣誉及头部商业媒体报道未命名",
    field_51: "请罗列企业所申报奖项相关领域资深专业人士对企业的口碑评价未命名",
  },
  yHnQWx: {
    field_1: "验证码 (请填写6个字)",
    field_2: "该事件发生时间",
    field_3: "该事件名称",
    field_4: "该事件简述",
    field_5: "请提供该事件最为官方的报道链接",
    field_6: "该事件主要相关企业或机构",
    field_8: "该事件涉及资本金额（万元人民币）",
    field_10: "该事件产生影响的领域所涉及具体市场的从业者规模",
    field_12: "请简要论述该事件创新性",
    field_14: "请简要论述该事件颠覆性",
    field_16: "请简要论述该事件引领性",
    field_18: "请尽量全面地提供关于该事件的报道链接",
    field_20: "请提供该事件搜索热度最高的关键词组",
    field_22: "请罗列该事件所影响领域相关资深专业人士、领先机构的评价",
  },
  Xw6sKr: {
    field_1: "验证码 (请填写6个字)",
    field_3: "请罗列企业目前已经获得的经营资质、认证等",
    field_5: "企业2021年总营收规模（人民币）",
    field_6: "企业近三年内平均流动资产规模（人民币）",
    field_8: "请选择贵司 2021年度企业研发费用占销售收入总额百分比",
    field_9: "请选择企业2021年度技术研发投入总额（人民币）",
    field_10: "请选择企业技术研发人员占总人员百分比",
    field_12: "请在下表中选择企业已形成较强技术储备的领域",
    field_14: "请选择企业近3年内人工智能领域专业学术会议及期刊论文发布数量",
    field_15: "请选择企业近3年内人工智能领域专利获得数量",
    field_17: "请具体罗列企业3年内取得国际级奖项或赛事名称",
    field_18: "请具体罗列企业3年内取得国家级奖项或赛事名称",
    field_19: "请具体罗列企业3年内取得省市级奖项或赛事名称",
    field_21:
      "请罗列企业目前战略聚焦攻占的具体智能化市场，如“智慧城市”，“制造型企业ERP系统” 等",
    field_22:
      "请罗列企业目前战略聚焦攻占的的业务拓展地理区域，如“中国”，“北上广”，“东南亚”等",
    field_23: "请简要阐述企业目前战略聚焦攻占的智能化市场预期规模（人民币）",
    field_25: "请简要介绍企业在该市场存在的机会与相对竞争优势",
    field_27:
      "请详细阐述企业在该市场的独特性优势（如“团队核心成员在该细分市场平均从业经验超过十年”，“跨代技术储备”等）",
    field_37:
      "请阐述企业在构建新一代智能汽车技术（相较同业目前使用的主流AI技术方案）方面的战略，并解释该战略的领先性",
    field_39:
      "企业近3年在构建新一代智能汽车技术（相较同业目前使用的主流智能汽车技术方案）方面的项目总数",
    field_40:
      "企业近3年在构建新一代智能汽车技术（相较同业目前使用的主流智能汽车技术方案）方面的项目平均投入研发资金规模（人民币）",
    field_41:
      "企业在构建新一代智能汽车技术（相较同业目前使用的主流智能汽车技术方案）方面的投入占总收入的百分比",
    field_42:
      "企业在构建新一代智能汽车技术（相较同业目前使用的主流智能汽车技术方案）方面的年均文献与专利发布数量",
    field_43:
      "请简要介绍企业在构建新一代智能汽车技术（相较同业目前使用的主流智能汽车技术方案）方面的具体业务及其在2021年度的发展情况",
    field_44:
      "请罗列企业所获在构建新一代智能汽车技术（相较同业目前使用的主流智能汽车技术方案）的过程中所获的社会性奖项、荣誉及官媒报道（如人民网、地方电视台等）",
    field_45:
      "请罗列企业所获在构建新一代智能汽车技术（相较同业目前使用的主流智能汽车技术方案）的过程中所获的技术性奖项、荣誉及头部技术媒体报道（如机器之心）",
    field_46:
      "请罗列企业所获所获在构建新一代智能汽车技术（相较同业目前使用的主流智能汽车技术方案）的过程中所获的商业性奖项、荣誉及头部商业媒体报道",
  },
  ZXvPm3: {
    field_1: "验证码 (请填写6个字)",
    field_3: "请罗列企业目前已经获得的经营资质、认证等",
    field_5: "企业2021年总营收规模（人民币）",
    field_6: "企业近三年内平均流动资产规模（人民币）",
    field_8: "请选择贵司 2021年度企业研发费用占销售收入总额百分比",
    field_9: "请选择企业2021年度技术研发投入总额（人民币）",
    field_10: "请选择企业技术研发人员占总人员百分比",
    field_12: "请在下表中选择企业已形成较强技术储备的领域",
    field_14: "请选择企业近3年内人工智能领域专业学术会议及期刊论文发布数量",
    field_15: "请选择企业近3年内人工智能领域专利获得数量",
    field_17: "请具体罗列企业3年内取得国际级奖项或赛事名称",
    field_18: "请具体罗列企业3年内取得国家级奖项或赛事名称",
    field_19: "请具体罗列企业3年内取得省市级奖项或赛事名称",
    field_21:
      "请罗列企业目前战略聚焦攻占的具体智能化市场，如“智慧城市”，“制造型企业ERP系统” 等",
    field_22:
      "请罗列企业目前战略聚焦攻占的的业务拓展地理区域，如“中国”，“北上广”，“东南亚”等",
    field_23: "请简要阐述企业目前战略聚焦攻占的智能化市场预期规模（人民币）",
    field_25: "请简要介绍企业在该市场存在的机会与相对竞争优势",
    field_27:
      "请详细阐述企业在该市场的独特性优势（如“团队核心成员在该细分市场平均从业经验超过十年”，“跨代技术储备”等）",
    field_37:
      "请阐述企业在构建新一代AI技术（相较同业目前使用的主流AI技术方案）方面的战略，并解释该战略的领先性",
    field_39:
      "企业近3年在构建新一代AI技术（相较同业目前使用的主流AI技术方案）方面的项目总数",
    field_40:
      "企业近3年在构建新一代AI技术（相较同业目前使用的主流AI技术方案）方面的项目平均投入研发资金规模（人民币）",
    field_41:
      "企业在在构建新一代AI技术（相较同业目前使用的主流AI技术方案）方面的投入占总收入的百分比",
    field_42:
      "企业在在构建新一代AI技术（相较同业目前使用的主流AI技术方案）方面的年均文献与专利发布数量",
    field_43:
      "请简要介绍企业在构建新一代AI技术（相较同业目前使用的主流AI技术方案）方面的具体业务及其在2021年度的发展情况",
    field_44:
      "请罗列企业所获在构建新一代AI技术（相较同业目前使用的主流AI技术方案）的过程中所获的社会性奖项、荣誉及官媒报道（如人民网、地方电视台等）",
    field_45:
      "请罗列企业所获在构建新一代AI技术（相较同业目前使用的主流AI技术方案）的过程中所获的技术性奖项、荣誉及头部技术媒体报道（如机器之心）",
    field_46:
      "请罗列企业所获所获在构建新一代AI技术（相较同业目前使用的主流AI技术方案）的过程中所获的商业性奖项、荣誉及头部商业媒体报道",
  },
  AHqah7: {
    field_1: "验证码 (请填写6个字)",
    field_3: "请罗列企业目前已经获得的经营资质、认证等",
    field_5: "企业2021年总营收规模（人民币）",
    field_6: "企业近三年内平均流动资产规模（人民币）",
    field_8: "请选择贵司 2021年度企业研发费用占销售收入总额百分比",
    field_9: "请选择企业2021年度技术研发投入总额（人民币）",
    field_10: "请选择企业技术研发人员占总人员百分比",
    field_12: "请在下表中选择企业已形成较强技术储备的领域",
    field_14: "请选择企业近3年内人工智能领域专业学术会议及期刊论文发布数量",
    field_15: "请选择企业近3年内人工智能领域专利获得数量",
    field_17: "请具体罗列企业3年内取得国际级奖项或赛事名称",
    field_18: "请具体罗列企业3年内取得国家级奖项或赛事名称",
    field_19: "请具体罗列企业3年内取得省市级奖项或赛事名称",
    field_21:
      "请罗列企业目前战略聚焦攻占的具体智能化市场，如“智慧城市”，“制造型企业ERP系统” 等",
    field_22:
      "请罗列企业目前战略聚焦攻占的的业务拓展地理区域，如“中国”，“北上广”，“东南亚”等",
    field_23: "请简要阐述企业目前战略聚焦攻占的智能化市场预期规模（人民币）",
    field_25: "请简要介绍企业在该市场存在的机会与相对竞争优势",
    field_27:
      "请详细阐述企业在该市场的独特性优势（如“团队核心成员在该细分市场平均从业经验超过十年”，“跨代技术储备”等）",
    field_29:
      "企业近3年在构建新一代产业科技（相较同业目前使用的主流技术方案）方面的项目总数",
    field_30:
      "企业近3年在构建新一代产业科技技术（相较同业目前使用的主流技术方案）方面的项目平均投入研发资金规模（人民币）",
    field_31:
      "企业在构建新一代产业科技技术（相较同业目前使用的主流技术方案）方面的投入占总收入的百分比",
    field_32:
      "企业在在构建新一代产业科技技术（相较同业目前使用的主流技术方案）方面的年均文献与专利发布数量",
    field_33:
      "请简要介绍企业在促进新一代产业科技技术（相较同业目前使用的主流技术方案）方面的具体业务及其在2021年度的发展情况",
    field_34:
      "请罗列企业所获在构建新一代产业科技技术（相较同业目前使用的主流技术方案）的过程中所获的社会性奖项、荣誉及官媒报道（如人民网、地方电视台等）",
    field_35:
      "请罗列企业所获在构建新一代产业科技技术（相较同业目前使用的主流技术方案）的过程中所获的技术性奖项、荣誉及头部技术媒体报道（如机器之心）",
    field_36:
      "请罗列企业所获所获在构建新一代产业科技技术（相较同业目前使用的主流技术方案）的过程中所获的商业性奖项、荣誉及头部商业媒体报道",
  },
  kfSidX: {
    field_1: "验证码 (请填写6个字)",
    field_2: "该事件发生时间",
    field_3: "该事件名称",
    field_4: "该事件简述",
    field_5: "请提供该事件最为官方的报道链接",
    field_6: "该事件主要相关企业或机构",
    field_8: "该事件涉及资本金额（万元人民币）",
    field_10: "该事件产生影响的领域所涉及具体市场的从业者规模",
    field_12: "请简要论述该事件创新性",
    field_14: "请简要论述该事件颠覆性",
    field_16: "请简要论述该事件引领性",
    field_18: "请尽量全面地提供关于该事件的报道链接",
    field_20: "请提供该事件搜索热度最高的关键词组",
    field_22: "请罗列该事件所影响领域相关资深专业人士、领先机构的评价",
  },
  jXKeNv: {
    field_1: "验证码 (请填写6个字)",
    field_2: "请提供该技术生态的详细介绍",
    field_3:
      "请提供该技术生态的产业链覆盖度（既该产业中已加入该生态的主体数量）",
    field_4: "请提供该技术生态自身或生态成员项目总计用户数量规模",
    field_5: "请简要介绍该技术生态的开放形式，并罗列具体开放项目",
    field_6:
      "请提供该技术生态下属代表性项目的 Github 或其他开源软件源代码托管服务平台项目页面",
    field_7: "请罗列该生态下由生态运营方或生态成员自主积极运营的项目数量",
    field_8: "请罗列该技术生态下属开发工具名称并注意简要介绍其用途",
    field_9: "请阐述该技术生态下属开发工具链的完善性",
    field_10: "请罗列该技术生态兼容的其他上下游主流生态",
    field_11: "该技术生态社区用户数量（人）",
    field_12:
      "请提供该技术生态项目2021年全年用户贡献情况（Github PR总数；论坛发言量等）",
    field_13: "该技术生态社区用户贡献占总发展贡献比区间",
    field_14: "请描述该技术生态成员的构成及相应占比",
    field_15: "请罗列并简要介绍该技术生态社区2021年度开展的社区活动",
    field_16: "请提供该技术生态社区活动2021年度参与总人次",
    field_17: "请提供该技术生态下属所有开发者用户总数",
    field_18:
      "请提供该技术生态所获社会性奖项、荣誉及官媒报道（如人民网、地方电视台等）",
    field_19: "请罗列该生态相关领域资深专业人士对其的口碑评价",
  },
  l9iPaM: {
    field_1: "验证码 (请填写6个字)",
    field_3: "请罗列企业目前已经获得的经营资质、认证等",
    field_5: "企业2021年总营收规模（人民币）",
    field_6: "企业近三年内平均流动资产规模（人民币）",
    field_8:
      "请分别阐述企业目前的巨型、大型、中小型客户数量级及客户类别（政府、企业、学校、消费者等）",
    field_9: "请具体罗列企业3年内服务的知名客户（全球500强、国企、独角兽等）",
    field_11: "企业是否为个人用户提供智能产品或服务？",
    field_12: "企业个人用户总数",
    field_13: "企业个人用户年增长率",
    field_15: "请阐述企业客户或用户的满意度情况",
    field_16: "请选择企业主营业务中利润最高的业务的利润率区间",
    field_18: "请说明企业各个服务场景下用户的忠诚度情况",
    field_20: "案例客户、场景及痛点详细信息",
    field_21: "请阐述案例客户、场景以及痛点的典型性",
    field_23: "案例落地过程",
    field_24: "请阐述该案例落地过程中项目组获得的数智化技术应用经验",
    field_26: "案例实施效果",
    field_27: "未命名",
    field_28: "请阐述项目组对该案例落地达成的效果预期",
    field_30: "请提供该案例技术采用方对该案例的评价",
    field_32: "请阐述该案例适用场景数智化升级预估市场规模（人民币）",
    field_34: "请阐述案例的标杆性",
    field_36:
      "请阐述该案例的部署流程中已标准化，或可通过进一步标准化降低技术采用门槛的环节",
    field_38: "请罗列本案例涉及的人工智能相关研究或专利",
    field_40: "请罗列本案例所采用的新一代智能技术",
    field_42:
      "请阐述案例为何一定要使用上述技术（可从效能、维护成本等多维度论述）",
    field_44: "请阐述本案例成功提高的技术采用方核心业务指标百分比",
    field_45:
      "请阐述本案例是如何通过使用新一代人工智能技术针对性地获得该业务指标的提高",
    field_47: "请阐述本案例如何通过应用智能技术给企业带来核心竞争力提升",
    field_49: "请阐述本案例如何通过应用智能技术给企业带来资产价值的提升",
    field_51:
      "本案例是否为国家级、省市级标杆公示创新项目？如是，请罗列相应项目",
    field_53: "请阐述本案例的社会性创新价值",
    field_54:
      "请罗列该案例所获社会性创新价值奖项、荣誉及官媒报道（如人民网、地方电视台等）",
    field_56: "请罗列企业所申报奖项相关领域资深专业人士对企业的口碑评价",
    field_57: "相关附件",
  },
  kgvO0O: {
    field_1: "验证码 (请填写6个字)",
    field_3: "请罗列企业目前已经获得的经营资质、认证等",
    field_5: "企业2021年总营收规模（人民币）",
    field_6: "企业近三年内平均流动资产规模（人民币）",
    field_8:
      "请分别阐述企业目前的巨型、大型、中小型客户数量级及客户类别（政府、企业、学校、消费者等）",
    field_9: "请具体罗列企业3年内服务的知名客户（全球500强、国企、独角兽等）",
    field_11: "企业是否为个人用户提供智能产品或服务？",
    field_12: "企业个人用户总数",
    field_13: "企业个人用户年增长率",
    field_15: "请阐述企业客户或用户的满意度情况",
    field_17: "请选择企业主营业务中利润最高的业务的利润率区间",
    field_19: "请说明企业各个服务场景下用户的忠诚度情况",
    field_21: "请阐述该方案在2021年度的营收总额（人民币）",
    field_22: "请阐述该方案近三年的年平均营收增长率（%)",
    field_24: "请阐述该方案近三年的平均客户增长率（%)",
    field_25:
      "请分别阐述企业目前的巨型、大型、中小型客户数量级及客户类别（政府、企业、学校、消费者等）",
    field_27:
      "请阐述企业目前已规模化开展业务的地理区域，如“中国”，“北上广”，“东南亚”等",
    field_28: "请阐述企业目前在已规模化开展业务的各地理区域的市场占有率",
    field_29: "该产品/方案已落地客户服务案例数量（项）",
    field_30: "该产品/方案代表落地案例",
    field_32: "请选择该方案研发投入占贵司研发投入总金额的百分比",
    field_33: "请选择该方案专职研发团队规模（人次）",
    field_35:
      "请阐述方案的核心技术（如机器学习、大数据处理、计算机视觉等）及该方案应用此技术的先进性",
    field_37: "请罗列该方案相关的技术论文与专利",
    field_39: "请罗列该方案获得的国际级或国家级技术性认证",
    field_41: "该方案2021年市场占有率",
    field_43: "请阐述方案近三年所获的的国家级与省市级公示与表彰状况",
    field_45: "请提供该产品/方案用户方对其的使用反馈和口碑评价",
    field_47: "请阐述该方案适用市场的总体规模（人民币）",
    field_49: "请阐述目前方案的主要竞争对手及其存在缺陷",
    field_51: "请阐述方案的独特竞争优势",
  },
  I6iLYE: {
    field_1: "验证码 (请填写6个字)",
    field_70: "请罗列企业目前已经获得的经营资质、认证等",
    field_6: "企业2021年总营收规模（人民币）",
    field_7: "企业近三年内平均流动资产规模（人民币）",
    field_9: "请选择贵司 2021年度企业研发费用占销售收入总额百分比",
    field_10: "请选择企业2021年度技术研发投入总额（人民币）",
    field_11: "请选择企业技术研发人员占总人员百分比",
    field_13: "请在下表中选择企业已形成较强技术储备的领域",
    field_15: "请选择企业近3年内人工智能领域专业学术会议及期刊论文发布数量",
    field_16: "请选择企业近3年内人工智能领域专利获得数量",
    field_18: "请具体罗列企业3年内取得国际级奖项或赛事名称",
    field_19: "请具体罗列企业3年内取得国家级奖项或赛事名称",
    field_20: "请具体罗列企业3年内取得省市级奖项或赛事名称",
    field_22: "请阐述企业主要收入构成及相应占比",
    field_23: "请阐述企业已落地的商业模式、已形成的竞争优势、已建立的壁垒",
    field_25:
      "请阐述企业目前已规模化开展业务的细分市场，如“智慧城市”，“制造型企业ERP系统” 等",
    field_26:
      "请阐述企业目前已规模化开展业务的地理区域，如“中国”，“北上广”，“东南亚”等",
    field_28: "请阐述企业目前战略聚焦市场中各市场占有率",
    field_30: "请选择性罗列企业主营业务长期合作的代表性供应商",
    field_31:
      "请选择性罗列贵司近3年内主营业务方面落地战略合作的代表性技术合作伙伴，如咨询公司、设计院、研究院、方案集成商、销售代理等",
    field_33:
      "请简单罗列贵司在目前在战略聚焦市场的牵头或参与的技术/产业生态，包括但不仅限于技术标准协会、产业联盟、服务平台、联合实验室等",
    field_34:
      "上述生态中，请选择贵司参与度最高的一个生态进行对该生态目前发展情况的简要介绍",
    field_35: "请提供该生态官网链接",
    field_36: "请简要介绍贵司在该生态扮演角色",
    field_37: "请简要介绍该生态代表性输出成果或获得成绩",
    field_39:
      "请罗列企业目前战略聚焦攻占的具体智能化市场，如“智慧城市”，“制造型企业ERP系统” 等",
    field_40:
      "请罗列企业目前战略聚焦攻占的的业务拓展地理区域，如“中国”，“北上广”，“东南亚”等",
    field_41: "请简要阐述企业目前战略聚焦攻占的智能化市场预期规模（人民币）",
    field_42: "请简要介绍企业在该市场存在的机会与相对竞争优势",
    field_43:
      "请详细阐述企业在该市场的独特性优势（如“团队核心成员在该细分市场平均从业经验超过十年”，“跨代技术储备”等）",
    field_45:
      "请罗列企业近3年内所获社会性奖项、荣誉及官媒报道（如人民网、地方电视台等）",
    field_47:
      "请罗列企业近3年内所获技术性奖项、荣誉及头部技术媒体报道（如机器之心）",
    field_49: "请罗列企业近3年内所获商业性奖项、荣誉及头部商业媒体报道未命名",
    field_51: "请罗列企业所申报奖项相关领域资深专业人士对企业的口碑评价未命名",
    field_53:
      "请阐述企业在近3年内提出、执行或事实形成的在促进人工智能基础理论突破或通过人工智能技术推动其他科学领域发展方面的战略，并解释该战略的领先性",
    field_55:
      "企业近3年在促进人工智能基础理论突破或通过人工智能技术推动其他科学领域发展方面的项目总数",
    field_57:
      "企业近3年在促进人工智能基础理论突破或通过人工智能技术推动其他科学领域发展方面的项目平均投入研发资金规模（人民币）",
    field_59:
      "企业在促进人工智能基础理论突破或通过人工智能技术推动其他科学领域发展方面的投入占总收入的百分比",
    field_61:
      "企业在促进人工智能基础理论突破或通过人工智能技术推动其他科学领域发展方面的年均文献与专利发布数量",
    field_63:
      "请简要介绍企业属于在促进人工智能基础理论突破或通过人工智能技术推动其他科学领域发展方面的具体业务及其在2021年度的发展情况",
    field_65:
      "请罗列企业所获在促进人工智能基础理论突破或通过人工智能技术推动其他科学领域发展方面的社会性奖项、荣誉及官媒报道（如人民网、地方电视台等）",
    field_67:
      "请罗列企业所获在促进人工智能基础理论突破或通过人工智能技术推动其他科学领域发展方面的技术性奖项、荣誉及头部技术媒体报道（如机器之心）",
    field_69:
      "请罗列企业所获在促进人工智能基础理论突破或通过人工智能技术推动其他科学领域发展方面的商业性奖项、荣誉及头部商业媒体报道",
  },
};

export { JIN_SHU_JU };
