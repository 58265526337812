import React from "react";
import "./style.scss";

interface Props {
  type?:
    | "primary"
    | "ghost"
    | "transparent"
    | "grey"
    | "white"
    | "text"
    | "link"
    | "second-primary"
    | "yellow";
  size?: string;
  grid?: number;
  shape?: "round";
  disabled?: boolean;
  isFullWidth?: boolean;
  children: React.ReactNode;
  extraClass?: string;
  htmlType?: "submit" | "button";
  onClick?: () => void;
}

const Button: React.FC<Props> = (props: Props) => {
  return (
    <button
      className={`btn btn--${props.type} size--${props.size} ${
        props.isFullWidth ? "btn--full-width" : ""
      } ${props.disabled ? "btn--disabled" : ""} btn-shape--${props.shape} ${
        props.extraClass
      }`}
      style={{
        paddingLeft: 4 * props.grid || 0,
        paddingRight: 4 * props.grid || 0,
      }}
      onClick={props.onClick}
      disabled={props.disabled}
      type={props.htmlType}
    >
      {props.children}
    </button>
  );
};

Button.defaultProps = {
  type: "primary",
  size: "medium",
  isFullWidth: false,
  disabled: false,
  extraClass: "",
  htmlType: "button",
};

export default Button;
