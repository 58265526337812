import React from "react";

const SVG = ({
  fill = "#0062FF",
  width = "100%",
  className = "",
  viewBox = "0 0 16 16",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M0.8 5.6H15.200000000000001V15.2H0.8z" />
      <path
        fill={fill}
        d="M15.2 1.6h-2.4V.8c0-.442-.358-.8-.8-.8-.442 0-.8.358-.8.8v.8H4.8V.8C4.8.358 4.442 0 4 0c-.442 0-.8.358-.8.8v.8H.8c-.442 0-.8.358-.8.8v12.8c0 .442.358.8.8.8h14.4c.442 0 .8-.358.8-.8V2.4c0-.442-.358-.8-.8-.8zM1.6 14.4h12.8v-8H1.6v8zm0-9.6h12.8V3.2H1.6v1.6zM4.8 8c-.442 0-.8.358-.8.8 0 .442.358.8.8.8.442 0 .8-.358.8-.8 0-.442-.358-.8-.8-.8m0 3.2c-.442 0-.8.358-.8.8 0 .442.358.8.8.8.442 0 .8-.358.8-.8 0-.442-.358-.8-.8-.8m3.2 0c-.442 0-.8.358-.8.8 0 .442.358.8.8.8.442 0 .8-.358.8-.8 0-.442-.358-.8-.8-.8M8 8c-.442 0-.8.358-.8.8 0 .442.358.8.8.8.442 0 .8-.358.8-.8 0-.442-.358-.8-.8-.8m3.2 0c-.442 0-.8.358-.8.8 0 .442.358.8.8.8.442 0 .8-.358.8-.8 0-.442-.358-.8-.8-.8"
      />
    </g>
  </svg>
);

export default SVG;
