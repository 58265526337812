import React from "react";

const SVG = ({
  fill = "#0062FF",
  width = "100%",
  className = "",
  viewBox = "0 0 16 16",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd" transform="translate(-240 -1765)">
      <path fill="#F4F4F6" d="M0 0H1440V2012H0z" />
      <rect width="793" height="1574" x="220" y="439" fill="#FFF" rx="4" />
      <path
        fill={fill}
        d="M252.4 1771.93c-.66 0-1.195.536-1.195 1.195 0 .66.536 1.195 1.195 1.195.66 0 1.195-.536 1.195-1.195s-.536-1.195-1.195-1.195zm-9.998 2.67h3.998c.443 0 .8.358.8.8 0 .442-.357.8-.8.8h-3.998c-.443 0-.802.36-.802.802v1.596c0 .442.36.802.802.802h10.397c.442 0 .801-.36.801-.802 0-.441.358-.8.8-.8.443 0 .8.359.8.8 0 1.324-1.077 2.402-2.401 2.402h-10.397c-1.324 0-2.402-1.078-2.402-2.402v-1.596c0-.615.232-1.177.614-1.602-.382-.425-.614-.987-.614-1.602v-1.596c0-.615.232-1.177.614-1.602-.382-.425-.614-.987-.614-1.602v-1.596c0-1.324 1.078-2.402 2.402-2.402h10.396c1.324 0 2.402 1.078 2.402 2.402 0 .441-.358.8-.8.8-.442 0-.8-.359-.8-.8 0-.442-.36-.802-.802-.802h-10.396c-.443 0-.802.36-.802.802v1.596c0 .442.36.802.802.802h3.998c.442 0 .8.358.8.8 0 .442-.358.8-.8.8h-3.998c-.443 0-.802.36-.802.802v1.596c0 .442.36.802.802.802zm7.313-2.251l-.548-.354c-.371-.239-.479-.734-.24-1.105.24-.372.735-.479 1.107-.24l.547.354c.294-.252.64-.444 1.02-.557v-.647c0-.442.357-.8.8-.8.441 0 .8.358.8.8v.647c.379.113.724.305 1.018.557l.547-.354c.372-.238.867-.132 1.107.24.239.37.132.866-.24 1.105l-.547.354c.071.246.11.507.11.776 0 .27-.039.53-.11.777l.548.354c.371.239.478.734.239 1.105-.154.237-.411.367-.674.367-.148 0-.298-.041-.432-.128l-.548-.353c-.294.251-.64.443-1.019.557v.647c0 .441-.358.8-.8.8-.442 0-.8-.359-.8-.8v-.647c-.38-.114-.725-.306-1.019-.557l-.547.353c-.135.086-.284.128-.433.128-.263 0-.52-.13-.674-.367-.239-.37-.132-.866.24-1.105l.548-.354c-.072-.247-.11-.507-.11-.777s.038-.53.11-.776zm-4.915-4.149c0 .442-.358.8-.8.8-.442 0-.8-.358-.8-.8 0-.442.358-.8.8-.8.442 0 .8.358.8.8zm0 4.8c0 .442-.358.8-.8.8-.442 0-.8-.358-.8-.8 0-.442.358-.8.8-.8.442 0 .8.358.8.8zm0 4.8c0 .442-.358.8-.8.8-.442 0-.8-.358-.8-.8 0-.442.358-.8.8-.8.442 0 .8.358.8.8z"
      />
    </g>
  </svg>
);

export default SVG;
