import React from "react";
import _ from "lodash";

const Solution = ({ data }) => {
  return (
    data && (
      <>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">研发机构:</div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.companyName) ? (
              data.companyName
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">名称:</div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.name) ? (
              data.name
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">别名:</div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.aliasName) ? (
              data.aliasName
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">品牌名称:</div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.brandName) ? (
              data.brandName
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">
            所属智能领域:
          </div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.aiFields) ? (
              data.aiFields.join("，")
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">形态:</div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.applyFormats) ? (
              data.applyFormats.join("，")
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">适用行业:</div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.industries) ? (
              data.industries.join("，")
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">适用场景:</div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.scenarios) ? (
              data.scenarios.join("，")
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">
            官方介绍链接:
          </div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.website) ? (
              data.website
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">功能简述:</div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.summary) ? (
              data.summary
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">
            配套产品及设施:
          </div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.related_solution) ? (
              data.related_solution
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">详细描述:</div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.desc) ? (
              <div
                className="selection-rating-modules-form-text--rich"
                dangerouslySetInnerHTML={{ __html: data.desc || "" }}
              />
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">官方 Logo:</div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.logoUrl) ? (
              <div className="selection-rating-modules-form--img">
                <img src={data.logoUrl} alt="image" />
              </div>
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
      </>
    )
  );
};

export default Solution;
