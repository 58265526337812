import { createContext } from "react";
import { observable, action, computed } from "mobx";
import _ from "lodash";

import {
  REPORT_INTRO,
  REPORT_CATALOG,
  CHART,
  DATASET,
} from "../constants/pro_constants";
import { labelToChinese } from "../constants/label_to_chinese";
import formatResearchDetail from "../utils/formatResearchDetail";
import { fetchResearchDetail } from "../api";

class ResearchDetailStore {
  @observable scrolledDomId = "";
  @observable id = "";
  @observable data: any = {};
  @observable isShowAuthWallModal = false;
  @observable authWallModalTitle = "";
  @observable authWallModalDesc = "";
  @observable forbiddenModal = false;
  @observable trialExpiredModal = false;
  @observable vipExpiredModal = false;

  @action
  setForbiddenModal = status => {
    this.forbiddenModal = status;
  };

  @action
  setTrialExpiredModal = status => {
    this.trialExpiredModal = status;
  };

  @action
  setVipExpiredModal = status => {
    this.vipExpiredModal = status;
  };

  @action
  initializeId = id => {
    this.id = id;
  };

  @action
  initializeData = async () => {
    const result = await fetchResearchDetail(this.id);
    this.data = formatResearchDetail(result.data);
  };

  @action
  initializeScrolledDomId = () => {
    this.scrolledDomId = this.researchDetailTabs.find(item => !item.isHide).key;
  };

  @action
  scrollTo = key => {
    this.scrolledDomId = key;
    let dom = document.getElementById(this.scrolledDomId);
    let scrollArea = document.querySelector("#research-detail");

    scrollArea.scrollTo({ top: dom.offsetTop - 130, behavior: "smooth" });
  };

  @action
  updateActiveThumbnail = index => {
    this.data.tabs.reportCatalog.activeIndex = index;
  };

  @action
  setAuthWallModal = (isShow: boolean, title: string, desc: string) => {
    this.setIsShowAuthWallModal(isShow);
    this.setAuthWallModalTitle(title);
    this.setAuthWallModalDesc(desc);
  };

  @action
  setIsShowAuthWallModal = value => {
    this.isShowAuthWallModal = value;
  };

  @action
  setAuthWallModalTitle = value => {
    this.authWallModalTitle = value;
  };

  @action
  setAuthWallModalDesc = value => {
    this.authWallModalDesc = value;
  };

  @computed
  get isReportIntroTabContentEmpty() {
    return _.isEmpty(this.data.tabs.reportIntro);
  }

  @computed
  get isReportCatalogTabContentEmpty() {
    return _.isEmpty(this.data.tabs.reportCatalog.images);
  }

  @computed
  get isChartTabContentEmpty() {
    return _.isEmpty(this.data.tabs.charts);
  }

  @computed
  get isDatasetTabContentEmpty() {
    return _.isEmpty(this.data.tabs.dataset);
  }

  @computed
  get isRelatedInstitutionsEmpty() {
    return !_.isEmpty(this.data) && _.isEmpty(this.data.related.institutions);
  }

  @computed
  get isRelatedSolutionsEmpty() {
    return !_.isEmpty(this.data) && _.isEmpty(this.data.related.solutions);
  }

  @computed
  get isChartEmpty() {
    return this.data.header && this.data.header.chartSize === 0;
  }

  @computed
  get isDatasetEmpty() {
    return this.data.header && this.data.header.datasetSize === 0;
  }

  @computed
  get researchDetailTabs() {
    return [
      {
        key: REPORT_INTRO,
        name: labelToChinese[REPORT_INTRO],
        isHide: false,
      },
      {
        key: REPORT_CATALOG,
        name: labelToChinese[REPORT_CATALOG],
        isHide: this.isReportCatalogTabContentEmpty,
      },
      {
        key: CHART,
        name: labelToChinese[CHART],
        isHide: this.isChartTabContentEmpty,
      },
      {
        key: DATASET,
        name: labelToChinese[DATASET],
        isHide: this.isDatasetTabContentEmpty,
      },
    ];
  }
}

export default createContext(new ResearchDetailStore());
