import moment from "moment";
import {
  RESEARCH_TYPE,
  INDUSTRIES,
  REPORT_SIZES,
} from "../constants/pro_constants";

const formatResearchList = initData => {
  return initData.map(item => {
    return {
      key: item.id,
      id: item.id,
      title: item.title,
      lead: item.lead,
      researchTypes: item.researchTypes.join(),
      category: item.category,
      author: item.author,
      uploadedAt: moment(item.uploadedAt).format("YYYY-MM-DD"),
      externalUrl: item.externalUrl,
      fileUrl: item.fileUrl,
      isNew: item.isNew,
      status: item.status,
    };
  });
};

const initFilter = [{ name: "不限", checked: true }];

const formatedResearchFilters = initData => {
  const filterKeys = Object.keys(initData);

  return filterKeys.map(key => {
    if (key === RESEARCH_TYPE) {
      return {
        type: key,
        fields: initData[key].map(name => ({ name, checked: true })),
        isShow: true,
      };
    }
    if (key === INDUSTRIES) {
      return {
        type: key,
        fields: initFilter.concat(
          initData[key].map(name => ({ name, checked: false }))
        ),
        isShow: true,
      };
    }
    if (key === REPORT_SIZES) {
      return {
        type: key,
        fields: initFilter.concat(
          initData[key].map(name => ({ name, checked: false }))
        ),
        isShow: false,
      };
    }
    return {
      type: key,
      fields: initFilter.concat(
        initData[key].map(name => ({ name, checked: false }))
      ),
      isShow: true,
    };
  });
};

export { formatResearchList, formatedResearchFilters };
