import React, { useEffect, useContext, useState } from "react";
import { observer } from "mobx-react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import "./sign-in.scss";
import { Form, Input, Checkbox, Button } from "antd";
import { PRO } from "../../constants/pro_constants";
import { labelToChinese } from "../../constants/label_to_chinese";
import authStore from "../../stores/authStore";
import { trackLoginEvent } from "../../utils/ga_events";
import { PROTOCOL, WWWHost, TOP_DOMAIN } from "../../api";
import SyncedLogo from "../../styles/images/logo-synced-black.png";
import ProImg from "../../styles/images/pro.svg";
import wxChatIcon from "../../styles/images/wechat-icon.svg";

const SignIn: React.FC = () => {
  const { onLoginSubmit } = useContext(authStore);
  const [accountSpan, setAccountSpan] = useState(false);
  const [passwordSpan, setPasswordSpan] = useState(false);
  const [account, setAccount] = useState("");
  const [password, setPassword] = useState("");
  const id = window.sessionStorage.getItem("id");

  const wxLogin = () => {
    let url = "";
    const mdetect = require("mdetect");
    trackLoginEvent("Wechat");
    const pathname = `/bind-info`;
    const origin = `${location.origin}${pathname}`;
    if (mdetect.isWechat()) {
      url = `${window.location.origin}/auth/wechatservice?origin=${origin}`;
    } else {
      url = `https://open.weixin.qq.com/connect/qrconnect?appid=${window.appid}&scope=snsapi_login&redirect_uri=${PROTOCOL}//${WWWHost}/auth/wechat/callback&state=&login_type=jssdk&self_redirect=default&origin=${origin}`;
    }
    window.location.href = url;
  };

  useEffect(() => {
    // api
  }, []);

  return (
    <div className="member-inbox-signin-layout">
      <Helmet>
        <title>登录 ｜ {labelToChinese[PRO]}</title>
      </Helmet>
      <div className="signin-content-wrapper">
        <div className="signin-header">
          <Link to="/">
            <img src={SyncedLogo} alt="Synced" />
            <img src={ProImg} />
          </Link>
        </div>
        <div className="signin-content">
          <div className="signin-body">
            <div className="signin-body-wx" onClick={wxLogin}>
              <img src={wxChatIcon} alt="wxchat" />
              使用微信快捷登录
            </div>
            <div className="signin-body-line">
              <p>或使用账号密码登录</p>
            </div>
            <Form
              className="signin-form"
              onFinish={values =>
                onLoginSubmit(values, id ? `/inbox/${id}` : `/inbox`, true)
              }
            >
              <div className="signin-form-item">
                <Form.Item
                  rules={[{ required: true, message: "请输入正确的邮箱" }]}
                  name="login_name"
                >
                  <Input
                    placeholder={accountSpan || account ? "" : "电子邮箱地址"}
                    onFocus={() => setAccountSpan(true)}
                    onBlur={() => setAccountSpan(false)}
                    onChange={e => {
                      setAccount(e.target.value);
                    }}
                  />
                </Form.Item>
                {(accountSpan || account) && <span>电子邮箱地址</span>}
              </div>
              <div className="signin-form-item">
                <Form.Item
                  rules={[{ required: true, message: "请输入正确的密码" }]}
                  name="password"
                >
                  <Input.Password
                    placeholder={passwordSpan || password ? "" : "密码"}
                    onFocus={() => setPasswordSpan(true)}
                    onBlur={() => setPasswordSpan(false)}
                    onChange={e => {
                      setPassword(e.target.value);
                    }}
                  />
                </Form.Item>
                {(passwordSpan || password) && <span>密码</span>}
              </div>
              <Form.Item className="signin-form__submit">
                <Button htmlType="submit">登录</Button>
              </Form.Item>
              <div className="signin-form__password-info">
                <Form.Item>
                  <Checkbox>记住密码</Checkbox>
                </Form.Item>
                <div
                  className="signin__forget-password"
                  onClick={() => {
                    window.sessionStorage.setItem("login", "signin");
                    window.sessionStorage.setItem("gangLogin", "signin");
                    window.location.replace(
                      `${PROTOCOL}//pro.${TOP_DOMAIN}/forget-password`
                    );
                  }}
                >
                  忘记密码？
                </div>
              </div>
            </Form>
          </div>
          <div className="signin-footer">
            登录/注册即代表我已阅读并同意
            <a
              href="https://image.jiqizhixin.com/uploads/attachment/file/ca9229c0-1bff-43ef-9e84-1eb7f5939ced/attachment.txt"
              target="_blank"
            >
              《用户协议》
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(SignIn);
