import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { observer } from "mobx-react";

import { IconHeading, AvatarCard } from "../shared";
import researchDetailStore from "../../stores/researchDetailStore";
import researchDetailSubmit from "../../styles/images/research-detail-submit.png";
import authStore from "../../stores/authStore";

const DetailRelated = () => {
  const {
    data: {
      related: { solutions, institutions },
    },
    isRelatedInstitutionsEmpty,
    isRelatedSolutionsEmpty,
    setForbiddenModal,
    setTrialExpiredModal,
    setVipExpiredModal,
  } = useContext(researchDetailStore);
  const {
    isCurrentUserEmpty,
    isAlphaUserWithoutStartTrial,
    isAlphaUser,
    isTrialExpired,
    isExpiredVipUser,
    isExpiredInstitutionUser,
  } = useContext(authStore);
  const isForbidden =
    !isCurrentUserEmpty && (isAlphaUserWithoutStartTrial || !isAlphaUser);
  const isVipExpired = isExpiredVipUser || isExpiredInstitutionUser;
  const history = useHistory();

  return (
    <div className="research-detail-related">
      <a
        className="research-detail-related-submit"
        target="_blank"
        rel="noopener"
        onClick={() => {
          if (isCurrentUserEmpty) {
            history.push("/login");
            return;
          }
          if (isForbidden || isTrialExpired || isVipExpired) {
            setForbiddenModal(isForbidden);
            setTrialExpiredModal(isTrialExpired);
            setVipExpiredModal(isVipExpired);
            return;
          }
          window.open(
            "https://www.jiqizhixin.com/short_urls/46a839bc-a3e5-4dd1-9763-46bc7c0efadc"
          );
        }}
      >
        <img src={researchDetailSubmit} alt="没找到您需要的报告？" />
      </a>
      {!(isRelatedInstitutionsEmpty && isRelatedSolutionsEmpty) && (
        <div className="research-detail-related-info">
          {!isRelatedInstitutionsEmpty && (
            <div>
              <IconHeading icon="pro-dataset" title="涉及机构" />
              {institutions.map((item, i) => {
                return (
                  <div className="research-detail-related-info__card" key={i}>
                    <AvatarCard
                      theme="company"
                      avatarSize={40}
                      data={{ name: item.title, avatar_url: item.avatarURL }}
                      onClick={() =>
                        window.open(`/database/institutions/${item.id}`)
                      }
                    >
                      <div className="research-detail-related-info__card-desc">
                        {item.labels.map((item, i) => {
                          return (
                            <span className="text-limit--one" key={i}>
                              {item}
                            </span>
                          );
                        })}
                      </div>
                    </AvatarCard>
                  </div>
                );
              })}
            </div>
          )}
          {!isRelatedSolutionsEmpty && (
            <div className="research-detail-related-info__solutions">
              <IconHeading icon="pro-package" title="涉及方案" />
              {solutions.map((item, i) => {
                return (
                  <Link
                    to={`/database/solutions/${item.id}`}
                    className="research-detail-related-info__solution-item"
                    key={i}
                    target="_blank"
                    rel="noopener"
                  >
                    {item.title}
                  </Link>
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default observer(DetailRelated);
