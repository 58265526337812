import React from "react";
import { SERVICE } from "../../constants/pro_constants";
import { Button } from "../shared";
import ServiceDataImg from "../../styles/images/sales-service-data.svg";
import ServiceDocumentImg from "../../styles/images/sales-service-document.svg";
import ServiceMessageImg from "../../styles/images/sales-service-message.svg";

const SalesService = () => {
  return (
    <div className="sales-layout__fill-wrapper sales-service" id={SERVICE}>
      <div className="sales-service__cotent">
        <div className="sales-service__title">定制服务</div>
        <div className="sales-service__desc">
          机器之心为团队客户提供贴合客户团队自身工作流程的定制服务，包括数据、研究和产业咨询等多种形式，以助力客户高效实施人工智能战略。
        </div>
        <div className="sales-service__items">
          <div className="sales-service__item">
            <div className="sales-service__avatar">
              <img src={ServiceDataImg} alt="" />
            </div>
            <p>市场数据</p>
          </div>

          <div className="sales-service__item">
            <div className="sales-service__avatar">
              <img src={ServiceDocumentImg} alt="" />
            </div>
            <p>研究智库</p>
          </div>

          <div className="sales-service__item">
            <div className="sales-service__avatar">
              <img src={ServiceMessageImg} alt="" />
            </div>
            <p>专业活动</p>
          </div>
        </div>
        <a
          className="sales-service__review-detail"
          href="https://www.jiqizhixin.com/short_urls/46a839bc-a3e5-4dd1-9763-46bc7c0efadc"
          target="_blank"
        >
          <Button size={"xlarge"} grid={14}>
            了解详情
          </Button>
        </a>
        <a
          className="sales-service__review-detail--mobile"
          href="https://www.jiqizhixin.com/short_urls/46a839bc-a3e5-4dd1-9763-46bc7c0efadc"
          target="_blank"
        >
          <Button size={"large"} grid={11}>
            了解详情
          </Button>
        </a>
      </div>
    </div>
  );
};

export default SalesService;
