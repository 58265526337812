import React, { useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { observer } from "mobx-react";

import "./style.scss";
import { Sidebar } from "../shared";
import SearchPanel from "./SearchPanel";
import researchSubmit from "../../styles/images/research-submit.jpg";
import ResultList from "./ResultList";
import researchStore from "../../stores/researchStore";
import { PRO, RESEARCH } from "../../constants/pro_constants";
import { labelToChinese } from "../../constants/label_to_chinese";

const Research: React.FC = () => {
  const { initializeResearchFilters } = useContext(researchStore);

  useEffect(() => {
    initializeResearchFilters();
  }, []);

  return (
    <div className="research-layout">
      <Helmet>
        <title>
          {labelToChinese[RESEARCH]}｜ {labelToChinese[PRO]}
        </title>
      </Helmet>

      <div className="research-layout__sidebar">
        <Sidebar />
      </div>
      <div className="pro-layout__content-wrapper">
        <div className="research-layout__content">
          <div className="research-layout__content-header">
            <SearchPanel />
            <a
              href="https://www.jiqizhixin.com/short_urls/46a839bc-a3e5-4dd1-9763-46bc7c0efadc"
              target="_blank"
              rel="noopener"
            >
              <img src={researchSubmit} alt="submit requirement" />
            </a>
          </div>
          <ResultList />
        </div>
      </div>
    </div>
  );
};

export default observer(Research);
