import React, { useContext, useRef, useState, useEffect } from "react";
import { observer } from "mobx-react";

import "./style.scss";
import { Input, Modal, Row, Col, Form } from "antd";
import { Icon, Button } from "../shared";
import ForgetPasswordStore from "../../stores/forgetPasswordStore";
import authStore from "../../stores/authStore";
import arrowLeftImg from "../../styles/images/arrow-left-circle.png";

const ValidateCode: React.FC = () => {
  const { updateCaptcha } = useContext(authStore);
  const {
    login_name,
    time,
    codes,
    isError,
    last,
    setCode,
    setFocus,
    setPaste,
    onSend,
    setCountdown,
  } = useContext(ForgetPasswordStore);

  useEffect(() => {
    setCountdown();
  }, []);

  const [visible, setVisible] = useState(false);

  const input0 = useRef();
  const input1 = useRef();
  const input2 = useRef();
  const input3 = useRef();
  const input4 = useRef();
  const input5 = useRef();
  const captchaRef = useRef(null);

  const inputs = [input0, input1, input2, input3, input4, input5];

  return (
    <div className="pro-forget-password-code">
      <div className="pro-forget-password-title--wrapper">
        <div className="pro-forget-password-title">
          <img src={arrowLeftImg} alt="arrow" onClick={last} />
          <p>输入6位验证码</p>
        </div>
        <p>验证码已发送至 {login_name}</p>
      </div>
      <div
        className={`pro-forget-password-code-input ${
          isError ? "ant-input--error" : ""
        }`}
      >
        <Input
          value={codes[0]}
          ref={input0}
          onPaste={e => setPaste(inputs, e)}
          onKeyDown={e => setFocus(0, inputs, e)}
          autoComplete="off"
          maxLength={1}
          onChange={e => setCode(0, e.target.value)}
        />
        <Input
          value={codes[1]}
          ref={input1}
          onPaste={e => setPaste(inputs, e)}
          onKeyDown={e => setFocus(1, inputs, e)}
          autoComplete="off"
          maxLength={1}
          onChange={e => setCode(1, e.target.value)}
        />
        <Input
          value={codes[2]}
          ref={input2}
          onPaste={e => setPaste(inputs, e)}
          onKeyDown={e => setFocus(2, inputs, e)}
          autoComplete="off"
          maxLength={1}
          onChange={e => setCode(2, e.target.value)}
        />
        <Input
          value={codes[3]}
          ref={input3}
          onPaste={e => setPaste(inputs, e)}
          onKeyDown={e => setFocus(3, inputs, e)}
          autoComplete="off"
          maxLength={1}
          onChange={e => setCode(3, e.target.value)}
        />
        <Input
          value={codes[4]}
          ref={input4}
          onPaste={e => setPaste(inputs, e)}
          onKeyDown={e => setFocus(4, inputs, e)}
          autoComplete="off"
          maxLength={1}
          onChange={e => setCode(4, e.target.value)}
        />
        <Input
          value={codes[5]}
          ref={input5}
          onPaste={e => setPaste(inputs, e)}
          onKeyDown={e => setFocus(5, inputs, e)}
          autoComplete="off"
          maxLength={1}
          onChange={e => setCode(5, e.target.value)}
        />
      </div>
      {isError && (
        <p className="pro-forget-password-error-title">
          <Icon name="error-icon" size={16} />
          <span>验证码输入错误</span>
        </p>
      )}
      <div className="pro-forget-password-need">
        {time >= 0 ? (
          <p className="pro-forget-password-desc">{time}秒后重新获取</p>
        ) : (
          <p
            className="pro-forget-password-get"
            onClick={() => setVisible(true)}
          >
            重新获取
          </p>
        )}
      </div>
      <Modal
        visible={visible}
        key={Date.now()}
        width={464}
        footer={null}
        closeIcon={<Icon name="pro-close" size={16} color="#c1c7d0" />}
        className="get-code-modal"
        onCancel={() => setVisible(false)}
      >
        <div className="get-code-modal__content">
          <div className="get-code-modal__title">请输入图形验证码</div>
          <div className="get-code-modal__body">
            <Form onFinish={values => onSend(values, false, setVisible)}>
              <Row gutter={24}>
                <Col span={16}>
                  <Form.Item
                    hasFeedback
                    rules={[
                      { required: true, message: "请输入正确的验证码" },
                      { min: 5, message: "验证码是 5 位" },
                    ]}
                    name="_rucaptcha"
                  >
                    <Input autoComplete="off" placeholder="图形验证码" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <img
                    className="pro-forget-password__captcha"
                    ref={captchaRef}
                    src="/rucaptcha/"
                    alt="图形验证码"
                    onClick={e => updateCaptcha(captchaRef.current, e)}
                  />
                </Col>
              </Row>
              <Form.Item className="pro-forget-password__send">
                <Button isFullWidth size="large" htmlType="submit">
                  确认
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default observer(ValidateCode);
