import React from "react";

const SVG = ({
  fill = "#92929D",
  width = "100%",
  className = "",
  viewBox = "0 0 24 24",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      xmlns="http://www.w3.org/2000/svg"
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Component/icon/ic_Chevron-Up"
        transform="translate(12.000000, 12.000000) scale(1, -1) translate(-12.000000, -12.000000) "
        fill={fill}
      >
        <path
          d="M12,10.4142136 L7.70710678,14.7071068 C7.31658249,15.0976311 6.68341751,15.0976311 6.29289322,14.7071068 C5.90236893,14.3165825 5.90236893,13.6834175 6.29289322,13.2928932 L11.2928932,8.29289322 C11.6834175,7.90236893 12.3165825,7.90236893 12.7071068,8.29289322 L17.7071068,13.2928932 C18.0976311,13.6834175 18.0976311,14.3165825 17.7071068,14.7071068 C17.3165825,15.0976311 16.6834175,15.0976311 16.2928932,14.7071068 L12,10.4142136 Z"
          id="Shape"
        />
      </g>
    </g>
  </svg>
);

export default SVG;
