import React from "react";

const SVG = ({ fill = "#4BAE51", className = "", viewBox = "0 0 16 20" }) => (
  <svg
    width="16"
    height="20"
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M1.16 3.631v7.718c0 1.254 0 4.566 6.555 7.17 6.555-2.604 6.555-5.916 6.555-7.17V3.63c-3.63-.014-5.718-1.306-6.555-1.974-.837.668-2.926 1.96-6.555 1.974zM7.715.016l.427.47.033.032c.26.25 2.08 1.865 5.854 1.948l.302.003c.163 0 .33-.002.499-.009l.6-.02v8.909c0 2.333-.778 5.734-7.506 8.338l-.21.08-.207-.08C.888 17.127.027 13.796 0 11.467V2.44l.6.02C5.278 2.62 7.27.508 7.288.486l.427-.47zm4.86 6.155c-3.184 1.593-6.482 5.007-6.482 5.007L3.706 9.415l-1.193.967 4.492 3.984c1.967-4.213 5.722-7.426 6.008-7.666l.018-.016-.455-.513z"
    />
  </svg>
);

export default SVG;
