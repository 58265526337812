import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import { Table } from "antd";

import { DATASET } from "../../constants/pro_constants";
import { IconHeading, Icon } from "../shared";
import researchDetailStore from "../../stores/researchDetailStore";
import authStore from "../../stores/authStore";

const title = "无法获取数据包";
const description = `您当前用户类型为"试用用户"，不支持数据包下载功能。点击"成为会员"，获得全部数据访问权限，解锁更多高级功能。`;

const Dataset = () => {
  const {
    data: {
      tabs: { dataset },
    },
    setAuthWallModal,
    setForbiddenModal,
    setTrialExpiredModal,
    setVipExpiredModal,
  } = useContext(researchDetailStore);
  const {
    isCurrentUserEmpty,
    isPaidVipUser,
    isPaidInstitutionUser,
    isAlphaUser,
    isAlphaUserWithoutStartTrial,
    isTrialExpired,
    isExpiredVipUser,
    isExpiredInstitutionUser,
  } = useContext(authStore);
  const [downloadColor, setDownloadColor] = useState({});
  const isVipUser = isAlphaUser && (isPaidVipUser || isPaidInstitutionUser);
  const isForbidden =
    !isCurrentUserEmpty && (isAlphaUserWithoutStartTrial || !isAlphaUser);
  const isVipExpired = isExpiredVipUser || isExpiredInstitutionUser;
  const history = useHistory();

  const downloadNode = (text, data) =>
    !isVipUser ? (
      <a
        className="research-list-opration__download"
        onClick={() => {
          if (isCurrentUserEmpty) {
            history.push("/login");
            return;
          }
          if (isForbidden || isTrialExpired || isVipExpired) {
            setForbiddenModal(isForbidden);
            setTrialExpiredModal(isTrialExpired);
            setVipExpiredModal(isVipExpired);
            return;
          }
          setAuthWallModal(true, title, description);
        }}
        onMouseEnter={() => {
          setDownloadColor({ [data.title]: "#0062ff" });
        }}
        onMouseLeave={() => {
          setDownloadColor({ [data.title]: "#C6CBD4" });
        }}
      >
        <Icon name="pro-file-download" color={downloadColor[data.title]} />
      </a>
    ) : (
      <a
        className="research-list-opration__download"
        href={data.fileUrl}
        download
        onMouseEnter={() => {
          setDownloadColor({ [data.title]: "#0062ff" });
        }}
        onMouseLeave={() => {
          setDownloadColor({ [data.title]: "#C6CBD4" });
        }}
      >
        <Icon name="pro-file-download" color={downloadColor[data.title]} />
      </a>
    );

  const datasetColumns = [
    {
      title: "数据包名称",
      dataIndex: "title",
      width: 200,
    },
    {
      title: "数据类型",
      dataIndex: "category",
      width: 150,
    },
    {
      title: "大小",
      dataIndex: "size",
      width: 80,
    },
    {
      title: "数据条目",
      dataIndex: "count",
      width: 100,
    },
    {
      title: "操作",
      dataIndex: "operation",
      fixed: "right",
      width: 72,
      align: "center",
      render: downloadNode,
    },
  ];

  return (
    <>
      <div className="database-detail__intro--bottom" />
      <div className="research-detail__block" id={DATASET}>
        <IconHeading icon="pro-dataset" title="数据包" />
        <div className="research-detail__block__content research-detail__table-container">
          <Table
            columns={datasetColumns}
            dataSource={dataset}
            pagination={false}
            scroll={{ x: 396 }}
          />
        </div>
        <div className="research-detail__block__content research-detail__table-container--mobile">
          {dataset.map((item, index) => {
            return (
              <div className="research-detail__data-zip" key={index}>
                <div className="text-limit--one">{item.title}</div>
                <div className="research-detail__data-zip-content">
                  <div>{item.category}</div>
                  <div>
                    <span>{item.size}</span>
                    {downloadNode(null, item)}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default observer(Dataset);
