import React from "react";
import { Link } from "react-router-dom";
import Button from "../button/Button";
import Icon from "../icon/Icon";

import "./style.scss";

interface Props {
  onClose(): void;
}

const GuestTooltip: React.FC<Props> = (prop: Props) => {
  return (
    <div className="guest-tooltip">
      <span className="guest-tooltip__user">您正在使用 机器之心 Pro</span>
      <span className="guest-tooltip__pro-intro">
        专业的人工智能市场信息终端
      </span>
      <Link to="/" className="guest-tooltip__more-btn">
        <Button size="small" grid={2} type="white">
          了解更多
        </Button>
      </Link>
      <span
        className="guest-tooltip__close"
        onClick={() => {
          const dom = document.querySelector(".guest-tooltip");
          dom.style.display = "none";
          prop.onClose();
        }}
      >
        <Icon name="pro-close" color="#ffffff" />
      </span>
    </div>
  );
};

export default GuestTooltip;
