import React, { useContext, useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";

import "./style.scss";
import { pricingData } from "./TableConstants";
import authStore from "../../stores/authStore";
import { Button } from "../shared";

interface Props {
  setIsShowPaymentModal: (iShow: boolean) => void;
  setIsShowContactModal: (isShow: boolean) => void;
}

const PricingRights: React.FC<Props> = (props: Props) => {
  const [fixed, setFixed] = useState(false);
  const {
    currentUser,
    isPaidVipUser,
    isPaidInstitutionUser,
    isAlphaUser,
  } = useContext(authStore);

  useLayoutEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    const rightsDomY = document
      .querySelector(".pro-pricing-rights")
      .getBoundingClientRect().y;

    if (rightsDomY < 70) {
      setFixed(true);
    } else {
      setFixed(false);
    }
  };

  const pricingColumns = [
    {
      title: (
        <h2
          className="pro-pricing__introduction"
          style={{ textAlign: "center" }}
        >
          权益介绍
        </h2>
      ),
      dataIndex: "introduction",
      key: "introduction",
      width: 170,
      fixed: "left",
    },
    {
      title: (
        <div className="pricing-table-title-trial__container">
          <h2 className="pricing-table-title-trial">试用会员</h2>
          <span className="pricing__title-desc">新注册用户可免费试用7天</span>
        </div>
      ),
      dataIndex: "trialUser",
      key: "trialUser",
      width: 210,
      align: "center",
    },
    {
      title: (
        <>
          <h2>Pro会员</h2>
          {currentUser ? (
            isPaidVipUser ? (
              <Link to={isAlphaUser ? "/home" : `/users/${currentUser.id}`}>
                <Button size="small" grid={6} shape="round">
                  <span className="pricing-button__bold">开始使用</span>
                </Button>
              </Link>
            ) : (
              <Button
                size="small"
                grid={6}
                shape="round"
                disabled={isPaidInstitutionUser}
                onClick={() => props.setIsShowPaymentModal(true)}
              >
                <span className="pricing-button__bold">立即成为Pro会员</span>
              </Button>
            )
          ) : (
            <Link to="/login">
              <Button size="small" grid={6} shape="round">
                <span className="pricing-button__bold">立即成为Pro会员</span>
              </Button>
            </Link>
          )}
          <div className="pricing__title-desc">*机构会员权益参见 Pro 会员</div>
        </>
      ),
      dataIndex: "personalUser",
      key: "personalUser",
      width: 210,
      align: "center",
    },
    // Hidden institution column per requirement change
    // {
    //   title: (
    //     <>
    //       <h2>机构会员</h2>
    //       {currentUser ? (
    //         isPaidInstitutionUser ? (
    //           <Link to="/home">
    //             <Button size="small" grid={6} type="transparent" shape="round">
    //               <span className="pricing-button__bold">开始使用</span>
    //             </Button>
    //           </Link>
    //         ) : (
    //           <Button
    //             size="small"
    //             grid={6}
    //             type="transparent"
    //             shape="round"
    //             onClick={() => props.setIsShowContactModal(true)}
    //           >
    //             <span className="pricing-button__bold">立即咨询</span>
    //           </Button>
    //         )
    //       ) : (
    //         <Link to="/login">
    //           <Button size="small" grid={6} type="transparent" shape="round">
    //             <span className="pricing-button__bold">立即咨询</span>
    //           </Button>
    //         </Link>
    //       )}
    //     </>
    //   ),
    //   dataIndex: "institutionUser",
    //   key: "institutionUser",
    //   width: 220,
    //   align: "center",
    // },
  ];

  return (
    <>
      <div className={`pro-pricing-rights`}>
        <table>
          <thead>
            <tr>
              {pricingColumns.map(item => {
                return (
                  <th key={item.key} className={`${fixed ? "fixed" : ""}`}>
                    {item.title}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {pricingData.map(item => {
              return (
                <tr key={item.key}>
                  <td>{item.introduction}</td>
                  <td>{item.trialUser}</td>
                  <td>{item.personalUser}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PricingRights;
