import React from "react";

const SVG = ({
  fill = "#92929D",
  width = "100%",
  className = "",
  viewBox = "0 0 20 20",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>icon 5</title>
    <desc>Created with Sketch.</desc>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Component/icon/ic_Rss" fill={fill}>
        <g
          id="Polaris-icon/Major/2-Tone/Pin"
          transform="translate(1.666667, 1.666667)"
        >
          <path
            d="M10.2995,9.35995648 C10.1095,9.518278 10.0003333,9.75326048 10.0003333,10.000742 C10.0003333,11.6656179 9.55283333,12.5713837 9.20366667,13.0238499 L3.64366667,7.46593105 C4.102,7.11179079 5.00616667,6.66765724 6.667,6.66765724 C6.9145,6.66765724 7.14866667,6.55766544 7.307,6.36767961 L10.8895,2.0688335 L14.5986667,5.7777236 L10.2995,9.35995648 Z M16.4228333,5.24526331 L11.4228333,0.245636121 C11.257,0.0789818817 11.0295,-0.0151777636 10.7961667,0.00232093153 C10.5611667,0.0123201859 10.3436667,0.120645442 10.1928333,0.30063202 L6.27033333,5.00778101 C2.85116667,5.13693805 1.8595,6.9176386 1.7545,7.12762294 C1.5945,7.44843235 1.657,7.83590346 1.91116667,8.09005117 L4.65533333,10.8340132 L0.2445,15.2445177 C-0.0813333333,15.5694934 -0.0813333333,16.0969541 0.2445,16.4227631 C0.407,16.585251 0.620333333,16.6669116 0.833666667,16.6669116 C1.04616667,16.6669116 1.2595,16.585251 1.42283333,16.4227631 L5.83366667,12.0122587 L8.57783333,14.7562208 C8.73783333,14.9162088 8.95116667,15.0003692 9.167,15.0003692 C9.29366667,15.0003692 9.42033333,14.9712047 9.5395,14.9120425 C9.7495,14.8070503 11.5311667,13.8162908 11.6595,10.3965458 L16.367,6.47433832 C16.547,6.32351623 16.6553333,6.10603245 16.6661667,5.87104997 C16.6761667,5.63773404 16.5886667,5.410251 16.4228333,5.24526331 L16.4228333,5.24526331 Z"
            id="Mask"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
