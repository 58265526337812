import {
  OVERVIEW,
  FUNCTION,
  SERVICE,
  PRICE,
  SUCCEED_CASE,
} from "./pro_constants";
import { labelToChinese } from "./label_to_chinese";

export const SalesMenus = [
  {
    key: OVERVIEW,
    name: labelToChinese[OVERVIEW],
    isHide: false,
  },
  {
    key: FUNCTION,
    name: labelToChinese[FUNCTION],
    isHide: false,
  },
  {
    key: SERVICE,
    name: labelToChinese[SERVICE],
    isHide: false,
  },
  {
    key: PRICE,
    name: labelToChinese[PRICE],
    isHide: false,
  },
  {
    key: SUCCEED_CASE,
    name: labelToChinese[SUCCEED_CASE],
    isHide: false,
  },
];
