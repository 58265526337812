import React, { useContext } from "react";
import { observer } from "mobx-react";

import "./style.scss";
import SelectionRatingModulesItem from "./SelectionRatingModulesItem";
import SelectionRatingDetailStore from "../../stores/selectionRatingDetailStore";

const SelectionRatingModulesInfo: React.FC = () => {
  const {
    ratingInfo: { modulesInfo },
  } = useContext(SelectionRatingDetailStore);

  return (
    modulesInfo && (
      <div className="selection-rating-modules">
        <div className="selection-rating-modules__wrapper">
          {modulesInfo.map((item, index) => (
            <SelectionRatingModulesItem
              key={index}
              title={item.formName}
              forms={item.data}
            />
          ))}
        </div>
      </div>
    )
  );
};

export default observer(SelectionRatingModulesInfo);
