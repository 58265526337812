import { action, computed, observable } from "mobx";
import { createContext } from "react";
import { message } from "antd";

import {
  CHINA,
  ABROAD,
  TRIAL_USER,
  TRIAL_USER2,
  OTHER_USER,
} from "../constants/pro_constants";
import { Cities } from "../constants/identification";
import { identify, onTrialStart, fetchCertificationInfo } from "../api";
import { trackCertificateEvent } from "../utils/ga_events";

class IdentificationStore {
  //TODO refactor method of store value.
  @observable userIdentity = "";
  @observable name = "";
  @observable mobile = "";
  @observable wechat = "";
  @observable email = "";
  @observable country = "";
  @observable city = "";
  @observable graduateSchool = "";
  @observable major = "";
  @observable degree = "";
  @observable graduationYear = "";
  @observable company = "";
  @observable position = "";
  @observable workExperience = "";
  @observable selectedCountry = CHINA;

  @action
  initializeIdentity = async () => {
    const result = await fetchCertificationInfo();
    this.userIdentity = result.category;
  };

  @action
  initializeCareerInformation = async () => {
    const result = await fetchCertificationInfo();
    this.country = result.country;
    this.city = result.city;
    this.graduateSchool = result.school;
    this.major = result.major;
    this.degree = result.degree;
    this.graduationYear = result.graduationYear;
    this.company = result.company;
    this.position = result.position;
    this.workExperience = result.workExperience;
  };

  @action
  initializeBasicInformation = async () => {
    const result = await fetchCertificationInfo();
    this.name = result.fullName;
    this.mobile = result.mobile;
    this.wechat = result.wechat;
    this.email = result.email;
  };

  @action
  updateUserIdentity = role => {
    this.userIdentity = role;
  };

  @action
  updateBasicInformation = async data => {
    this.name = data.name;
    this.mobile = data.mobile;
    this.wechat = data.wechat;
    this.email = data.email;

    const result = await identify(this.identiticationParams);
    const currentUser = window.currentUser;
    if (result.success) {
      if (!currentUser.isAlpha) {
        trackCertificateEvent();
      }
      window.location.href = "/identification/success";
    } else {
      message.info(result.errors);
    }
  };

  @action
  updateCareerInormation = data => {
    this.country = data.country;
    this.city = data.city;
    this.graduateSchool = data.graduateSchool;
    this.major = data.major;
    this.degree = data.degree;
    this.graduationYear = data.graduationYear;
    this.company = data.company;
    this.position = data.position;
    this.workExperience = data.workExperience;
  };

  @action
  setSelectedCountry = value => {
    this.selectedCountry = value;
  };

  @action
  onTrialStart = async () => {
    const result = await onTrialStart();
    if (result.vip === OTHER_USER) {
      window.location.href = "";
      return;
    }
    if (result.vip === TRIAL_USER || result.vip === TRIAL_USER2) {
      window.location.href = "/home";
      return;
    }
  };

  @computed
  get identiticationParams() {
    return {
      category: this.userIdentity,
      country: this.country,
      city: this.city,
      school: this.graduateSchool,
      major: this.major,
      degree: this.degree,
      graduationYear: this.graduationYear,
      position: this.position,
      company: this.company,
      workExperience: this.workExperience,
      fullName: this.name,
      mobile: this.mobile,
      wechat: this.wechat,
      email: this.email,
    };
  }

  @computed
  get regions() {
    return { [CHINA]: Cities, [ABROAD]: null };
  }
}

export default createContext(new IdentificationStore());
