import React from "react";
import InstitutionDefaultSvg from "../../../styles/images/institution-default.svg";

// import Tag from "../tag/Tag";
import Icon from "../icon/Icon";
import "./style.scss";
interface Data {
  name: string;
  avatarUrl: string;
  isLasted: true | false;
}
interface Props {
  theme: "company" | "person";
  shape?: "circle" | "square";
  isShowProfilePopover?: boolean;
  avatarSize?: number;
  children?: React.ReactNode;
  data?: Data;
  autoFit?: boolean;
  onClick?: () => void;
}

// TODO: Add clickable(link to) function for avartar instead of pass from onClick
const AvatarCard: React.FC<Props> = (props: Props) => {
  return (
    <div
      className={`avatar-card ${
        props.isShowProfilePopover ? "avatar-card--active" : ""
      }`}
      onClick={props.onClick}
    >
      <div
        className={`avatar-card__logo ${props.shape} ${props.size} ${
          props.isShowProfilePopover ? "avatar-card__logo--check" : ""
        }${
          props.theme == "company" && !props.data.avatarUrl
            ? "avatar-card__logo--default"
            : ""
        }`}
        style={{ width: props.avatarSize, height: props.avatarSize }}
      >
        {props.theme == "company" ? (
          <img
            className={`${
              props.autoFit ? "avatar-img-size avatar-img-fit--auto" : ""
            }`}
            src={
              props.data.avatarUrl
                ? props.data.avatarUrl
                : InstitutionDefaultSvg
            }
            alt=""
          />
        ) : (
          <img
            className={`${
              props.autoFit ? "avatar-img-size avatar-img-fit--auto" : ""
            }`}
            src={props.data.avatarUrl}
            alt=""
          />
        )}
      </div>
      <div
        className={`avatar-card__brief ${
          props.isShowProfilePopover ? "avatar-card__brief--check" : ""
        }`}
      >
        {props.theme == "company" ? (
          <span className="avatar-card__name">
            <a className="text-limit--two">{props.data.name}</a>
            {props.data.isLasted && <Icon name="pro-new" />}
          </span>
        ) : (
          <p>{props.data.name}</p>
        )}
        {props.children}
      </div>
    </div>
  );
};

AvatarCard.defaultProps = {
  shape: "square",
  theme: "company",
  avatarSize: 44,
  autoFit: false,
};

export default AvatarCard;
