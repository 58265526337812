import React, { useContext, useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import authStore from "../../../stores/authStore";
import { observer } from "mobx-react";
import { PrivilegeModal } from "..";

interface Props {
  pathArray?: any;
  isCheckRole?: boolean;
  children: React.ReactDOM;
}
const RequireAuth = ({ isCheckRole, children }) => {
  const history = useHistory();

  const [forbiddenModal, setForbiddenModal] = useState(false);
  const [trialExpiredModal, setTrialExpiredModal] = useState(false);
  const [vipExpiredModal, setVipExpiredModal] = useState(false);

  const {
    isCurrentUserEmpty,
    isAlphaUserWithoutStartTrial,
    isAlphaUser,
    isTrialExpired,
    isExpiredVipUser,
    isExpiredInstitutionUser,
  } = useContext(authStore);

  const isForbidden =
    !isCurrentUserEmpty && (isAlphaUserWithoutStartTrial || !isAlphaUser);
  const isVipExpired = isExpiredVipUser || isExpiredInstitutionUser;

  const checkRole =
    !isForbidden && !isTrialExpired && !isVipExpired && children;

  useEffect(() => {
    if (isCheckRole && isForbidden) {
      setForbiddenModal(true);
    }
    if (isCheckRole && isTrialExpired) {
      setTrialExpiredModal(true);
    }
    if (isCheckRole && isVipExpired) {
      setVipExpiredModal(true);
    }
    return () => {
      window.sessionStorage.removeItem("path");
    };
  }, [isCheckRole]);

  return (
    <>
      {!isCurrentUserEmpty ? (
        isCheckRole ? (
          checkRole
        ) : (
          children
        )
      ) : (
        <Redirect to="/login" />
      )}
      {isForbidden && (
        <PrivilegeModal
          visible={forbiddenModal}
          title="您没有机器之心 Pro 使用权限"
          userType="forbidden"
          onCancel={() => {
            setForbiddenModal(false);
            if (window.sessionStorage.getItem("path")) {
              history.goBack();
            } else {
              history.push("/");
            }
          }}
        />
      )}
      {isTrialExpired && (
        <PrivilegeModal
          visible={trialExpiredModal}
          title="您的试用已到期"
          userType="trial-expired"
          onCancel={() => {
            setTrialExpiredModal(false);
            if (window.sessionStorage.getItem("path")) {
              history.goBack();
            } else {
              history.push("/");
            }
          }}
        />
      )}
      {isVipExpired && (
        <PrivilegeModal
          visible={vipExpiredModal}
          title="您的 Pro 会员已到期"
          userType="trial-expired"
          onCancel={() => {
            setVipExpiredModal(false);
            if (window.sessionStorage.getItem("path")) {
              history.goBack();
            } else {
              history.push("/");
            }
          }}
        />
      )}
    </>
  );
};

RequireAuth.defaultProps = {
  isCheckRole: false,
  pathArray: [],
};

export default observer(RequireAuth);
