const formatCommunicationGroups = initData => {
  return initData.map(item => ({
    title: item.name,
    description: item.description,
    summary: item.summary,
    image: item.coverImageUrl,
    groupAvatar: item.fileUrl,
  }));
};

export default formatCommunicationGroups;
