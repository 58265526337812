import React from "react";
import { IconHeading, AvatarCard, Tag } from "../shared";
import { COOPERATION_INSTITUTION } from "../../constants/pro_constants";

const CooperationInstitution: React.FC = props => {
  return (
    <>
      {props.cooperatePartners.length > 0 && (
        <>
          <div
            className="database-detail__block client__intro"
            id={COOPERATION_INSTITUTION}
          >
            <IconHeading icon="pro-cooperation-institution" title="合作机构" />
            <div className="database-detail__block__content client__list">
              {props.cooperatePartners.map((ele, i) => (
                <div key={i} className="client__avatar-card__item">
                  <AvatarCard
                    theme="company"
                    key={ele}
                    data={ele}
                    onClick={() =>
                      window.open(`/database/institutions/${ele.id}`)
                    }
                  >
                    <div className="avatar-card__tags">
                      {ele.fields.map((ele, i) => (
                        <Tag key={i}>{ele}</Tag>
                      ))}
                    </div>
                  </AvatarCard>
                </div>
              ))}
            </div>
          </div>
          <div className="database-detail__intro--bottom" />
        </>
      )}
    </>
  );
};

export default CooperationInstitution;
