import React from "react";
import "./style.scss";
import Icon from "../icon/Icon";

interface Props {
  list: string[];
}

const Keywords: React.FC<Props> = (props: Props) => {
  return (
    <div className="pro-keywords">
      <Icon name="pro-keywords-tag" size={12} />
      {props.list.map(item => {
        return (
          <React.Fragment key={item}>
            <span>{item}</span>
            <span className="pro-keywords__divider">·</span>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default Keywords;
