import React, { useContext } from "react";
import { observer } from "mobx-react";
import { Helmet } from "react-helmet";

import { Sidebar } from "../shared";
import SubscribeList from "./SubscribeList";
import SubscribeDrawer from "./SubscribeDrawer";
import SubscribeStore from "../../stores/subscribeStore";
import { PRO, SUBSCRIBE } from "../../constants/pro_constants";
import { labelToChinese } from "../../constants/label_to_chinese";
import "./style.scss";

const Subscribe = () => {
  const { selectSubscribe, isDrawerVisible, handleDrawerClose } = useContext(
    SubscribeStore
  );

  return (
    <div className="subscribe-layout">
      <Helmet>
        <title>
          {labelToChinese[SUBSCRIBE]}｜ {labelToChinese[PRO]}
        </title>
      </Helmet>
      <div className="subscribe-layout__sidebar">
        <Sidebar />
      </div>
      <div className="subscribe-layout__wrapper pro-layout__content-wrapper">
        <div
          className={`subscribe-layout__content ${
            isDrawerVisible ? "subscribe-layout__content--transform" : ""
          }`}
          style={isDrawerVisible ? { maxWidth: "775px" } : {}}
        >
          <SubscribeList
            onItemClick={item => {
              selectSubscribe(item);
            }}
          />
        </div>
      </div>
      {isDrawerVisible && (
        <SubscribeDrawer
          visible={isDrawerVisible}
          onClose={handleDrawerClose}
        />
      )}
    </div>
  );
};

export default observer(Subscribe);
