import React, { useContext } from "react";
import { observer } from "mobx-react";

import BusinessCase from "./BusinessCase";
import MikeInfo from "./MikeInfo";
import SelectionRatingDetailStore from "../../stores/selectionRatingDetailStore";
import Institution from "./Institution";
import InstitutionFinance from "./InstitutionFinance";
import Solution from "./Solution";
import SolutionTech from "./SolutionTech";
import Contact from "./Contact";
import JinShuJu from "./JinShuJu";

interface Props {
  form: any;
}

const SelectionRatingModulesForm: React.FC<Props> = ({ form }) => {
  const { selectModuleId } = useContext(SelectionRatingDetailStore);

  return (
    <div
      className={`selection-rating-modules-form ${
        selectModuleId === form.module_id
          ? "selection-rating-modules-form--focus"
          : ""
      }`}
      id={form.module_id}
    >
      <h3>{form.categoryCn}</h3>
      <div className="selection-rating-modules-form--content">
        {form.category === "unit" && form.atom === "business" && (
          <BusinessCase data={form.data} />
        )}
        {form.category === "unit" && form.atom === "institution" && (
          <Institution data={form.data} />
        )}
        {form.category === "unit" && form.atom === "finance" && (
          <InstitutionFinance data={form.data} />
        )}
        {form.category === "unit" && form.atom === "solution" && (
          <Solution data={form.data} />
        )}
        {form.category === "unit" && form.atom === "extra_solution" && (
          <SolutionTech data={form.data} />
        )}
        {form.category === "unit" && form.atom === "contact" && (
          <Contact data={form.data} />
        )}
        {form.category === "mike" && <MikeInfo data={form.data} />}
        {form.category === "jinshuju" && (
          <JinShuJu form={form} data={form.data} />
        )}
      </div>
    </div>
  );
};

export default observer(SelectionRatingModulesForm);
