import React, { useContext } from "react";
import { Tooltip } from "antd";
import authStore from "../../stores/authStore";
import { LinkWrapper } from "../shared";

interface Props {
  image: string;
  title: string;
  description: string;
  vipRequired: boolean;
  linkUrl: string;
}

const LandingItem: React.FC<Props> = (props: Props) => {
  const { isPaidUser } = useContext(authStore);
  const isNeedToDisable: boolean = props.vipRequired && !isPaidUser;

  return (
    <LinkWrapper linkTo={!isNeedToDisable ? props.linkUrl : "#"}>
      {isNeedToDisable ? (
        <Tooltip placement="top" title="成为付费会员后可用">
          <div className="pro-home__landing-item landing--disabled">
            <img src={props.image} alt={props.title} />
            <h4>{props.title}</h4>
            <div
              className="pro-home__landing-item-description"
              dangerouslySetInnerHTML={{ __html: props.description }}
            />
          </div>
        </Tooltip>
      ) : (
        <div className="pro-home__landing-item">
          <img src={props.image} alt={props.title} />
          <h4>{props.title}</h4>
          <div
            className="pro-home__landing-item-description"
            dangerouslySetInnerHTML={{ __html: props.description }}
          />
        </div>
      )}
    </LinkWrapper>
  );
};

export default LandingItem;
