import React from "react";

const SVG = ({ className = "", viewBox = "0 0 14 14" }) => (
  <svg
    width="14"
    height="14"
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#F4F4F6"
        d="M0 0H1440V760H0z"
        transform="translate(-1122 -42)"
      />
      <g transform="translate(-1122 -42) translate(322 20)">
        <rect width="997" height="740" fill="#FFF" rx="4" />
        <g>
          <path
            fill="#0062FF"
            d="M7 13.125C3.617 13.125.875 10.383.875 7S3.617.875 7 .875 13.125 3.617 13.125 7 10.383 13.125 7 13.125zm0-.875c2.9 0 5.25-2.35 5.25-5.25S9.9 1.75 7 1.75 1.75 4.1 1.75 7 4.1 12.25 7 12.25zm1.409-5.856v.099c.143-.19.3-.329.47-.417.171-.09.367-.133.588-.133.215 0 .408.046.578.14.169.094.295.227.38.398.053.1.088.207.104.323.016.115.024.263.024.442v1.52c0 .163-.038.287-.113.37-.073.084-.18.13-.29.126-.114.004-.223-.043-.297-.128-.076-.086-.114-.209-.114-.368v-1.36c0-.27-.037-.476-.112-.619-.075-.142-.224-.214-.447-.214-.146 0-.278.044-.398.13-.12.087-.207.206-.263.358-.04.122-.06.349-.06.682v1.023c0 .165-.038.29-.115.372-.076.083-.185.128-.297.124-.112.003-.218-.044-.29-.128-.076-.086-.114-.209-.114-.368v-2.36c0-.155.034-.271.102-.348.067-.077.16-.115.277-.115.073 0 .137.017.195.05.06.037.109.09.14.153.034.068.052.15.052.248zm-1.542-.906h-2v1.076h1.842c.136 0 .237.03.304.092s.103.15.1.24c.003.092-.033.181-.099.244s-.168.095-.305.095H4.867V8.48h2.07c.14 0 .244.033.315.098.071.066.11.161.106.258 0 .104-.035.189-.106.253-.07.065-.176.098-.316.098H4.523c-.193 0-.333-.043-.417-.13-.085-.085-.127-.224-.127-.415V5.348c0-.128.018-.232.056-.313.036-.079.1-.141.178-.177.081-.036.184-.055.31-.055h2.345c.141 0 .246.031.315.094s.107.154.103.247c.004.094-.034.186-.103.25-.069.063-.174.094-.315.094z"
            transform="translate(800 22)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
