import React from "react";

const SVG = ({
  fill = "#999",
  width = "100%",
  className = "",
  viewBox = "0 0 12 12",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none">
      <path
        fill={fill}
        d="M11.005.926c-.122-.123-.288-.192-.46-.192H6.575c-.173 0-.339.069-.46.192L.953 6.134c-.122.124-.19.291-.19.466 0 .174.068.341.19.465l3.969 4.005c.127.128.294.192.46.192.168 0 .335-.064.462-.192l5.16-5.208c.123-.124.191-.291.191-.466V1.392c0-.175-.068-.342-.19-.466zM8.154 5.121c-.72 0-1.305-.59-1.305-1.317 0-.726.584-1.316 1.305-1.316.72 0 1.304.59 1.304 1.316 0 .727-.584 1.317-1.304 1.317z"
      />
    </g>
  </svg>
);

export default SVG;
