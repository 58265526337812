import React, { useEffect, useContext, useState } from "react";
import { observer } from "mobx-react";

import "./dialog.scss";
import { Dialog } from "../shared";
import memberInboxStore from "../../stores/memberInboxStore";
import AuthStore from "../../stores/authStore";
import { forceLogout } from "../../utils/tools";
import { updateInfoXyApi } from "../../api/index";

const UserAgreementDialog = () => {
  const { dialog, setDialog, baseXy } = useContext(memberInboxStore);
  const { memberInfo, fetchMemberInfo } = useContext(AuthStore);

  const [loading, setLoading] = useState(false);

  const onCancel = () => {
    setDialog("user-agreement", false);
  };

  useEffect(() => {
    // api();
  }, []);

  return (
    <Dialog
      visible={dialog["user-agreement"]}
      style={{ padding: 0 }}
      onCancel={() => {
        onCancel();
        forceLogout();
      }}
      grayClose
      layerClose={false}
      closeStyle={{ color: "rgba(55,65,81,0.2)", top: "24px", right: "14px" }}
    >
      <div className="user-agreement-dialog">
        <div className="user-agreement-dialog__title">
          《用户服务协议》更新提示
        </div>
        <p>欢迎使用机器之心PRO通讯会员订阅服务。</p>
        <p>
          我们更新了
          <a href={baseXy} target="_blank">
            《用户服务协议》
          </a>
          。请您务必谨慎阅读、充分理解最新
          <a href={baseXy} target="_blank">
            《用户服务协议》
          </a>
          各条款内容。
        </p>
        <p>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          点击"同意"，即表示您已阅读并同意上述协议条款，我们将持续为您提供优质的产品及服务。
        </p>

        <div className="user-agreement-dialog__group">
          <div
            className="user-agreement-dialog__btn-cancel"
            onClick={() => {
              onCancel();
              forceLogout();
            }}
          >
            不同意并退出
          </div>
          <div
            className="user-agreement-dialog__btn-ok"
            onClick={async () => {
              if (!loading) {
                setLoading(true);
                await updateInfoXyApi(memberInfo.systemProMemberLicenseVersion);
                fetchMemberInfo();
                onCancel();
                dialog.params.successCb && dialog.params.successCb();
                setLoading(false);
              }
            }}
          >
            同意
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default observer(UserAgreementDialog);
