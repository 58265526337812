import React, { useState } from "react";
import Tab from "./Tab";

interface Children {
  props?: {
    label: string;
    children: React.ReactNode;
  };
  children?: React.ReactNode;
}

interface Props {
  activeTab: number;
  hasDivider?: boolean;
  hasBottomLine?: boolean;
  children: Children[];
}

interface TabsModule extends React.FC<Props> {
  Tab?: React.FC<Children["props"]>;
}

const Tabs: TabsModule = (props: Props) => {
  const [activeTab, setActiveTab] = useState(props.activeTab);

  return (
    <div className="tabs">
      <div className="tabs__navs">
        {props.children.map((child, index) => {
          return (
            <Tab
              key={index}
              isActive={activeTab === index}
              label={child.props.label}
              onClick={() => setActiveTab(index)}
              hasDivider={props.hasDivider}
            />
          );
        })}
      </div>
      {props.hasBottomLine && <div className="tabs__navs__bottom-line" />}
      <div className="tabs__content">
        {
          props.children.find((element, index) => index === activeTab).props
            .children
        }
      </div>
    </div>
  );
};

Tabs.defaultProps = {
  hasDivider: false,
  hasBottomLine: false,
  activeTab: 0,
};

export default Tabs;
