import React from "react";
import { IconHeading, AvatarCard, Tag } from "../shared";
import { CLIENTS } from "../../constants/pro_constants";

const ClientIntro: React.FC = props => {
  return (
    <>
      {props.clients.length > 0 && (
        <>
          <div className="database-detail__block client__intro" id={CLIENTS}>
            <IconHeading icon="pro-client" title="客户" />
            <div className="database-detail__block__content client__list">
              {props.clients.map((ele, i) => (
                <div key={i} className="client__avatar-card__item">
                  <AvatarCard
                    theme="company"
                    data={ele}
                    onClick={() =>
                      window.open(`/database/institutions/${ele.id}`)
                    }
                  >
                    <div className="avatar-card__tags">
                      {ele.fields.map((ele, i) => (
                        <Tag key={i}>{ele}</Tag>
                      ))}
                    </div>
                  </AvatarCard>
                </div>
              ))}
            </div>
          </div>
          <div className="database-detail__intro--bottom" />
        </>
      )}
    </>
  );
};

export default ClientIntro;
