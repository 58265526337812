import React from "react";

const SVG = ({
  fill = "#0062FF",
  width = "100%",
  className = "",
  viewBox = "0 0 16 16",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FFF"
        d="M5.6 10.4c3.2 0 4.8 2.4 4.8 2.4l-.8 2.4h-8l-.8-2.4s1.6-2.4 4.8-2.4"
      />
      <path
        fill={fill}
        d="M5.6 9.6C2.013 9.6.209 12.244.134 12.356c-.136.206-.17.462-.093.697l.8 2.4c.109.327.414.547.759.547h8c.344 0 .65-.22.758-.547l.8-2.4c.079-.235.044-.491-.092-.697C10.99 12.244 9.186 9.6 5.6 9.6zm0-8C6.923 1.6 8 2.677 8 4S6.923 6.4 5.6 6.4C4.276 6.4 3.2 5.323 3.2 4s1.076-2.4 2.4-2.4m0 6.4c2.205 0 4-1.794 4-4s-1.795-4-4-4c-2.206 0-4 1.794-4 4s1.794 4 4 4m3.423 6.4H2.176l-.48-1.443c.497-.563 1.8-1.757 3.904-1.757 2.11 0 3.407 1.191 3.905 1.755L9.023 14.4zM15.2 7.2h-1.6V5.6c0-.442-.358-.8-.8-.8-.443 0-.8.358-.8.8v1.6h-1.6c-.443 0-.8.358-.8.8 0 .442.357.8.8.8H12v1.6c0 .442.357.8.8.8.442 0 .8-.358.8-.8V8.8h1.6c.442 0 .8-.358.8-.8 0-.442-.358-.8-.8-.8"
      />
    </g>
  </svg>
);

export default SVG;
