import { observer } from "mobx-react";
import { useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import authStore from "../../../stores/authStore";
import { trackUserStatusEvent } from "../../../utils/ga_events";

const ScrollToTop = () => {
  const { pathname } = useLocation();
  const {
    isCurrentUserEmpty,
    isInsideUser,
    isPaidVipUser,
    isPaidInstitutionUser,
    isExpired,
    isAlphaUser,
    isTrialUser,
    isTrialExpired,
    userTags,
  } = useContext(authStore);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!isCurrentUserEmpty) {
      if (isInsideUser) {
        trackUserStatusEvent("inside_user");
      } else {
        if (isAlphaUser && (isPaidInstitutionUser || isPaidVipUser)) {
          trackUserStatusEvent(
            isExpired ? "expired_vip_user" : "vip_user",
            userTags.join("|")
          );
        } else if (isAlphaUser && isTrialUser && !isExpired) {
          trackUserStatusEvent("trial_user");
        } else if (isTrialExpired) {
          trackUserStatusEvent("expired_trial_user");
        } else {
          trackUserStatusEvent("other_user");
        }
      }
    }
  }, [pathname]);

  return null;
};

export default observer(ScrollToTop);
