import React, { useContext } from "react";

import { IconHeading, EmptyImport } from "../shared";
import { SOLUTION_DESC } from "../../constants/pro_constants";
import databaseDetailStore from "../../stores/databaseDetailStore";

const SolutionIntro = () => {
  const {
    data: {
      tabs: { solutionIntro },
    },
    isSolutionDescTabContentEmpty,
  } = useContext(databaseDetailStore);

  return (
    <>
      <div
        className="database-detail__block database-detail__intro--sp"
        id={SOLUTION_DESC}
      >
        <IconHeading icon="pro-institution-intro" title="方案概述" />
        {isSolutionDescTabContentEmpty ? (
          <div className="database-detail__block__content">
            <EmptyImport />
          </div>
        ) : (
          <div
            className="database-detail__block__content"
            dangerouslySetInnerHTML={{ __html: `${solutionIntro}` }}
          />
        )}
      </div>
      <div className="database-detail__intro--bottom" />
    </>
  );
};

export default SolutionIntro;
