import React from "react";

const SVG = ({
  fill = "#333",
  width = "100%",
  className = "",
  viewBox = "0 0 20 20",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd" opacity=".9">
      <path d="M0 0h20v20H0z" />
      <path
        d="M11.667 2.5v3.333c0 .46.373.834.833.834h3.333"
        stroke={fill}
        strokeWidth="1.667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.167 17.5H5.833c-.92 0-1.666-.746-1.666-1.667V4.167c0-.92.746-1.667 1.666-1.667h5.834l4.166 4.167v9.166c0 .92-.746 1.667-1.666 1.667zM7.5 7.5h.833M7.5 10.833h5M7.5 14.167h5"
        stroke={fill}
        strokeWidth="1.667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SVG;
