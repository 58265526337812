import React, { useContext } from "react";
import { Link } from "react-router-dom";

import "./style.scss";
import { Button, Icon } from "../shared";
import subscribeStore from "../../stores/subscribeStore";
import { trackHitTheWallEvent } from "../../utils/ga_events";

const DrawerFooter = () => {
  const {
    isSubscribed,
    email,
    hasExtraSubscribe,
    isCantSubscribe,
    subscribeDetail,
    handleSubscribe,
  } = useContext(subscribeStore);
  const { subscribeColumnCount, leftCount } = subscribeDetail;

  return (
    <div className="subscribe-drawer__footer">
      <div className="subscribe-drawer__footer--left">
        <Icon
          name={`${isSubscribed ? "pro-tick" : "pro-subscribed"}`}
          size={isSubscribed ? 20 : 30}
        />
        <div className="subscribe-drawer__footer-info">
          <h4>{isSubscribed ? "订阅中" : "开始订阅"}</h4>
          {isCantSubscribe ? (
            <div
              className="subscribe-drawer__footer-message"
              onClick={() => {
                trackHitTheWallEvent("become_vip", "Subscribe");
              }}
            >
              您当前为试用期用户，无法订阅该内容。{" "}
              <Link to="/pricing">成为会员</Link>
              后即可享受内容订阅服务。
            </div>
          ) : (
            <>
              {hasExtraSubscribe && !isSubscribed && (
                <div className="subscribe-drawer__footer-message">
                  {`根据您的权益，您还可以同时订阅 ${leftCount} 个内容产品。点击开始订阅，接收最新更新。`}
                </div>
              )}
              {!hasExtraSubscribe && !isSubscribed && (
                <div className="subscribe-drawer__footer-message">
                  根据您的权益，您已同时订阅 {subscribeColumnCount} 个内容
                  产品，请取消订阅部分内容后在尝试订阅
                </div>
              )}
              {isSubscribed && (
                <>
                  <div className="subscribe-drawer__footer-message">
                    新发布内容将自动发送至您的邮箱
                  </div>
                  <div className="subscribe-drawer__footer-mail">{email}</div>
                </>
              )}
            </>
          )}
        </div>
      </div>
      {isSubscribed ? (
        <Button
          onClick={handleSubscribe}
          size="small"
          grid={4}
          type="transparent"
        >
          取消订阅
        </Button>
      ) : (
        <Button
          onClick={handleSubscribe}
          size="small"
          grid={4}
          disabled={!hasExtraSubscribe}
        >
          开始订阅
        </Button>
      )}
    </div>
  );
};

export default DrawerFooter;
