import React from "react";
import ProHome from "./ProHome";
import ProDatabase from "./ProDatabase";
import ProResearch from "./ProResearch";
import ProTrend from "./ProTrend";
import ProZhizhou from "./ProZhizhou";
import ProSubscribe from "./ProSubscribe";
import ProCommunication from "./ProCommunication";
import ProClose from "./ProClose";
import ProMenu from "./ProMenu";
import ProComingSoon from "./ProComingSoon";
import ProSearch from "./ProSearch";
import ProProfile from "./ProProfile";
import ProLogout from "./ProLogout";
import Beta from "./Beta";
import ProArrowUp from "./ProArrowUp";
import ProArrowDown from "./ProArrowDown";
import ProFileDownload from "./ProFileDownload";
import ProStar from "./ProStar";
import ProDelete from "./ProDelete";
import ProSafe from "./ProSafe";
import ProAward from "./ProAward";
import ProInstitutionIntro from "./ProInstitutionIntro";
import ProPackage from "./ProPackage";
import ProClient from "./ProClient";
import ProMember from "./ProMember";
import ProCooperationInstitution from "./ProCooperationInstitution";
import ProBehavior from "./ProBehavior";
import ProNewsflash from "./ProNewsflash";
import ProSolutionFeature from "./ProSolutionFeature";
import ProSolutionTech from "./ProSolutionTech";
import ProMaterial from "./ProMaterial";
import ProBusinessCase from "./ProBusinessCase";
import ProCaseIntro from "./ProCaseIntro";
import ProCaseBgIntro from "./ProCaseBgIntro";
import ProEnglish from "./ProEnglish";
import ProTick from "./ProTick";
import ProSubscribed from "./ProSubscribed";
import ProEyes from "./ProEyes";
import ProReport from "./ProReport";
import ProFileDetail from "./ProFileDetail";
import ProChart from "./ProChart";
import ProCatalog from "./ProCatalog";
import ProDataset from "./ProDataset";
import ProfileIcon from "./ProfileIcon";
import IdentifyIcon from "./IdentifyIcon";
import VipIcon from "./VipIcon";
import BindWeboIcon from "./BindWeboIcon";
import BindWechatIcon from "./BindWechatIcon";
import BindGithubIcon from "./BindGithubIcon";
import PhoneIcon from "./PhoneIcon";
import TickGreenBg from "./TickGreenBg";
import PricingYes from "./PricingYes";
import FooterWechat from "./FooterWechat";
import FooterWeibo from "./FooterWeibo";
import FooterRss from "./FooterRss";
import ProKeywordsTag from "./ProKeywordsTag";
import ArrowLeftWithCircle from "./ArrowLeftWithCircle";
import ArrowRightWithCircle from "./ArrowRightWithCircle";
import Information from "./Information";
import ProGuest from "./ProGuest";
import ProBuy from "./ProBuy";
import NotifySuccess from "./NotifySuccess";
import NotifyError from "./NotifyError";
import NotifyWarn from "./NotifyWarn";
import ProNew from "./ProNew";
import ErrorIcon from "./ErrorIcon";
import ProSelections from "./ProSelections";
import ProInstitutionHonour from "./ProInstitutionHonour";
import ProIcon from "./ProIcon";
import EditIcon from "./EditIcon";
import InboxBook from "./InboxBook";
import InboxBattery from "./InboxBattery";

interface Props {
  name: string;
  className?: string;
  color?: string;
  size?: number;
}

const Icon: React.FC<Props> = ({ name, color, size, className }: Props) => {
  const svgIcons = {
    "pro-home": <ProHome width={`${size}`} fill={color} />,
    "pro-database": <ProDatabase width={`${size}`} fill={color} />,
    "pro-research": <ProResearch width={`${size}`} fill={color} />,
    "pro-trend": <ProTrend width={`${size}`} fill={color} />,
    "pro-zhizhou": <ProZhizhou width={`${size}`} fill={color} />,
    "pro-subscribe": <ProSubscribe width={`${size}`} fill={color} />,
    "pro-communication": <ProCommunication width={`${size}`} fill={color} />,
    "pro-close": <ProClose width={`${size}`} fill={color} />,
    "pro-menu": <ProMenu width={`${size}`} fill={color} />,
    "pro-coming-soon": <ProComingSoon />,
    "pro-search": <ProSearch width={`${size}`} fill={color} />,
    "pro-profile": <ProProfile width={`${size}`} fill={color} />,
    "pro-logout": <ProLogout width={`${size}`} fill={color} />,
    beta: <Beta className={className} />,
    "pro-arrow-up": <ProArrowUp width={`${size}`} fill={color} />,
    "pro-arrow-down": <ProArrowDown width={`${size}`} fill={color} />,
    "pro-file-download": <ProFileDownload width={`${size}`} fill={color} />,
    "pro-star": <ProStar fill={color} />,
    "pro-delete": <ProDelete width={`${size}`} fill={color} />,
    "pro-safe": <ProSafe />,
    "pro-award": <ProAward />,
    "pro-institution-intro": <ProInstitutionIntro width={`${size}`} />,
    "pro-package": <ProPackage width={`${size}`} />,
    "pro-client": <ProClient width={`${size}`} />,
    "pro-member": <ProMember width={`${size}`} />,
    "pro-behavior": <ProBehavior width={`${size}`} />,
    "pro-cooperation-institution": (
      <ProCooperationInstitution width={`${size}`} />
    ),
    "pro-newsflash": <ProNewsflash width={`${size}`} />,
    "pro-solution-feature": <ProSolutionFeature width={`${size}`} />,
    "pro-solution-tech": <ProSolutionTech width={`${size}`} />,
    "pro-material": <ProMaterial width={`${size}`} />,
    "pro-business-case": <ProBusinessCase width={`${size}`} />,
    "pro-text-yes": <ProCaseIntro width={`${size}`} />,
    "pro-first-order": <ProCaseBgIntro width={`${size}`} />,
    "pro-english": <ProEnglish />,
    "pro-tick": <ProTick width={`${size}`} />,
    "pro-subscribed": <ProSubscribed width={`${size}`} />,
    "pro-eyes": <ProEyes width={`${size}`} fill={color} />,
    "pro-report": <ProReport width={`${size}`} fill={color} />,
    "pro-file-detail": <ProFileDetail width={`${size}`} fill={color} />,
    "pro-chart": <ProChart width={`${size}`} fill={color} />,
    "pro-catalog": <ProCatalog width={`${size}`} fill={color} />,
    "pro-dataset": <ProDataset width={`${size}`} fill={color} />,
    "profile-icon": <ProfileIcon width={`${size}`} />,
    "identify-icon": <IdentifyIcon width={`${size}`} />,
    "vip-icon": <VipIcon className={className} width={`${size}`} />,
    "bind-webo-icon": <BindWeboIcon width={`${size}`} fill={color} />,
    "bind-wechat-icon": <BindWechatIcon width={`${size}`} fill={color} />,
    "bind-github-icon": <BindGithubIcon width={`${size}`} fill={color} />,
    "phone-icon": <PhoneIcon width={`${size}`} fill={color} />,
    "tick-green-bg": <TickGreenBg width={`${size}`} />,
    "pricing-yes": <PricingYes width={`${size}`} />,
    "pro-footer-wechat": <FooterWechat width={`${size}`} />,
    "pro-footer-weibo": <FooterWeibo width={`${size}`} />,
    "pro-footer-rss": <FooterRss width={`${size}`} />,
    information: <Information width={`${size}`} fill={color} />,
    "pro-guest": <ProGuest width={`${size}`} />,
    "pro-keywords-tag": <ProKeywordsTag width={`${size}`} />,
    "arrow-left-with-circle": (
      <ArrowLeftWithCircle width={`${size}`} fill={color} />
    ),
    "arrow-right-with-circle": (
      <ArrowRightWithCircle width={`${size}`} fill={color} />
    ),
    "pro-buy": <ProBuy width={`${size}`} />,
    "notify-success": <NotifySuccess width={`${size}`} />,
    "notify-error": <NotifyError width={`${size}`} />,
    "notify-warn": <NotifyWarn width={`${size}`} />,
    "pro-new": <ProNew />,
    "error-icon": <ErrorIcon width={`${size}`} />,
    "pro-selections": <ProSelections width={`${size}`} fill={color} />,
    "pro-institution-honour": <ProInstitutionHonour width={`${size}`} />,
    "pro-icon": <ProIcon width={`${size}`} fill={color} />,
    "edit-icon": <EditIcon width={`${size}`} fill={color} />,
    "inbox-book": <InboxBook width={`${size}`} fill={color} />,
    "inbox-battery": <InboxBattery width={`${size}`} fill={color} />,
  };
  return <>{svgIcons[name]}</>;
};

Icon.defaultProps = {
  size: 16,
};

export default Icon;
