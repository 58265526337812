import React from "react";

const SVG = ({
  fill = "#C6CBD4",
  width = "100%",
  className = "",
  viewBox = "0 0 16 16",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>down -n</title>
    <desc>Created with Sketch.</desc>
    <g id="研究" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Polaris-icon/Major/2-Tone/Folder-Up" fill={fill}>
        <path
          d="M8,7.2 C8.44237235,7.2 8.79995,7.55758883 8.79995,7.999975 L8.79995,10.8686854 L9.83428536,9.83431768 C9.99027561,9.67832255 10.1950628,9.599925 10.39985,9.599925 C10.6046372,9.599925 10.8094244,9.67832255 10.9654147,9.83431768 C11.2781951,10.1471079 11.2781951,10.6526921 10.9654147,10.9654823 L8.56556465,13.3654073 C8.2527842,13.6781976 7.7472158,13.6781976 7.43443535,13.3654073 L5.03458534,10.9654823 C4.72180489,10.6526921 4.72180489,10.1471079 5.03458534,9.83431768 C5.34736579,9.52152745 5.85293419,9.52152745 6.16571464,9.83431768 L7.20005,10.8686854 L7.20005,7.999975 C7.20005,7.55758883 7.55762765,7.2 8,7.2 Z M8.04972898,1.60027885 C10.4484788,1.62357914 12.5932224,2.94548249 13.4160597,4.9089206 C14.9365201,5.38735331 15.9632781,6.58654178 15.9990536,7.89135824 C16.0419843,9.46956483 14.6199066,10.93593 12.6307867,11.2 L12.6307867,11.2 L12.2855527,11.2 L12.2855527,11.1984466 L12.2712425,11.1984466 C11.8508799,11.1984466 11.5110123,10.9033096 11.5110123,10.5382716 C11.5110123,10.1732337 11.8508799,9.87809665 12.2712425,9.87809665 L12.2712425,9.87809665 L12.3893018,9.87809665 C13.5859936,9.81440918 14.5340453,8.95385165 14.5340453,7.89912501 C14.5340453,6.80867125 13.5198088,5.92481343 12.2658762,5.91860001 C11.8473024,4.20680508 10.0960898,2.9221822 7.99964323,2.9221822 C5.90319667,2.9221822 4.15198406,4.20680508 3.73341026,5.91860001 C2.47947764,5.92481343 1.46524113,6.80867125 1.46524113,7.89912501 C1.46524113,8.93676477 2.38646124,9.78955553 3.55811013,9.87188324 L3.55811013,9.87188324 L3.55811013,9.87809665 L3.70657861,9.87809665 C4.12694119,9.87809665 4.4668088,10.1732337 4.4668088,10.5382716 C4.4668088,10.9033096 4.12694119,11.1984466 3.70657861,11.1984466 L3.70657861,11.1984466 L3.69763473,11.1984466 L3.69763473,11.2 L3.46867128,11.2 C1.4437758,10.9436968 -0.0212324967,9.46179806 0.000232826374,7.84475766 C0.0181205956,6.49489395 1.06634387,5.24599819 2.6297349,4.74581855 C3.52054581,2.83053437 5.67959956,1.57853191 8.04972898,1.60027885 Z"
          id="Combined-Shape"
        />
      </g>
    </g>
  </svg>
);

export default SVG;
