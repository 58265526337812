export const eventTrack = (...args) => {
  if (!window.gtag) {
    return;
  }

  // eslint-disable-next-line no-undef
  gtag(...args);
};

export const trackLoginEvent = method => {
  eventTrack("event", "login", {
    event_category: "engagement",
    method: method,
  });
};

export const trackSignupEvent = method => {
  eventTrack("event", "sign_up", {
    event_category: "engagement",
    method: method,
  });
};

export const trackCertificateEvent = () => {
  eventTrack("event", "certificate", {
    event_category: "engagement",
  });
};

export const trackShowPaymentModalEvent = () => {
  eventTrack("event", "begin_checkout", {
    event_category: "ecommerce",
  });
};

export const trackPurchaseEvent = (action, productName, price) => {
  eventTrack("event", action, {
    event_category: "ecommerce",
    event_label: productName,
    value: price,
  });
};

export const trackContactSuccessEvent = () => {
  eventTrack("event", "leave_message", {
    event_category: "ecommerce",
  });
};

export const trackDatabaseEvent = (action, type, condition, value = "") => {
  eventTrack("event", action, {
    event_category: type,
    event_label: condition,
    value: value,
  });
};

export const trackResearchEvent = (action, type, condition, value = "") => {
  eventTrack("event", action, {
    event_category: type,
    event_label: condition,
    value: value,
  });
};

export const trackNewsEvent = (action, label, value) => {
  eventTrack("event", action, {
    event_category: "News",
    event_label: label,
    value: value,
  });
};

export const trackInsightEvent = (action, label, value) => {
  eventTrack("event", action, {
    event_category: "Insight",
    event_label: label,
    value: value,
  });
};

export const trackSubscribeEvent = (action, label, value) => {
  eventTrack("event", action, {
    event_category: "Subscribe",
    event_label: label,
    value: value,
  });
};

export const trackCommunicationEvent = (action, label, value) => {
  eventTrack("event", action, {
    event_category: "Communication",
    event_label: label,
    value: value,
  });
};

export const trackHitTheWallEvent = (action, category) => {
  eventTrack("event", action, {
    event_category: category,
  });
};

export const trackUserStatusEvent = (category, label = "") => {
  eventTrack("event", "get_user_status", {
    event_category: category,
    event_label: label,
  });
};

export const readInboxArticleEvent = (obj: any) => {
  const {
    user_id,
    user,
    article_id,
    article_type,
    article,
    article_purchased,
  } = obj;

  eventTrack("event", "read_article", {
    user_id,
    user,
    article_id,
    article_type,
    article,
    article_purchased,
  });
};

export const buyInboxMemberEvent = (obj: any) => {
  const {
    user_id,
    user,
    transaction_id,
    value,
    item_id,
    item_name,
    price,
    purchase_source,
  } = obj;
  eventTrack("event", "purchase", {
    transaction_id,
    value,
    currency: "CNY",
    items: [
      {
        item_id,
        item_name,
        affiliation: "Pro 会员服务",
        item_brand: "机器之心 Pro 会员服务",
        item_category: "会员订阅",
        price,
        quantity: 1,
      },
    ],
    purchase_source,
    user_id,
    user,
  });
};

export const buyInboxIntegralEvent = (obj: any) => {
  const {
    user_id,
    user,
    transaction_id,
    value,
    item_id,
    item_name,
    price,
    purchase_source,
  } = obj;
  eventTrack("event", "purchase", {
    transaction_id,
    value,
    currency: "CNY",
    items: [
      {
        item_id,
        item_name,
        affiliation: "Pro 会员服务",
        item_brand: "机器之心 Pro 会员服务",
        item_category: "积分充值",
        price,
        quantity: 1,
      },
    ],
    purchase_source,
    user_id,
    user,
  });
};
