import React, { useEffect, useContext } from "react";
import { observer } from "mobx-react";

import "./dialog.scss";
import { Dialog } from "../shared";
import { trackLoginEvent } from "../../utils/ga_events";
import { PROTOCOL, WWWHost } from "../../api";
import codeIcon from "../../styles/images/guide-code.png";
import wxChatIcon from "../../styles/images/wechat-icon.svg";

import memberInboxStore from "../../stores/memberInboxStore";

const GuideSigninDialog = () => {
  const { dialog, setDialog } = useContext(memberInboxStore);
  const onCancel = () => {
    setDialog("guide-signin", false);
    window.sessionStorage.setItem("endCode", "end");
  };

  const wxLogin = () => {
    let url = "";
    const mdetect = require("mdetect");
    trackLoginEvent("Wechat");
    const code = window.sessionStorage.getItem("code") || "";
    const pathname = `/inbox?code=${code}`;
    const origin = `${location.origin}${pathname}`;
    if (mdetect.isWechat()) {
      setDialog("guide-signin", false);
      url = `${window.location.origin}/auth/wechatservice?origin=${origin}`;
    } else {
      url = `https://open.weixin.qq.com/connect/qrconnect?appid=${window.appid}&scope=snsapi_login&redirect_uri=${PROTOCOL}//${WWWHost}/auth/wechat/callback&state=&login_type=jssdk&self_redirect=default`;
    }
    window.location.href = url;
  };

  useEffect(() => {
    // api();
  }, []);

  return (
    <Dialog
      visible={dialog["guide-signin"]}
      style={{ padding: "40px 20px", borderRadius: "10px" }}
      onCancel={onCancel}
      layerClose={false}
      grayClose
      closeStyle={{ color: "#bec2d7", top: "16px", right: "18px" }}
    >
      <div className="guide-signin-dialog">
        <img src={codeIcon} alt="codeIcon" />
        <p>立即登录，获取试读积分</p>
        <div onClick={wxLogin}>
          <img src={wxChatIcon} alt="wxchat" />
          使用微信快捷登录
        </div>
      </div>
    </Dialog>
  );
};

export default observer(GuideSigninDialog);
