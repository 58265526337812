import React, { useEffect, useContext } from "react";
import { observer } from "mobx-react";

import "./dialog.scss";
import { Dialog } from "../shared";
import successIcon from "../../styles/images/inbox-pay-success.png";
import proCodeImg from "../../styles/images/pro-code.png";
import memberInboxStore from "../../stores/memberInboxStore";

const PaySuccessDialog = () => {
  const { dialog, setDialog } = useContext(memberInboxStore);
  const onCancel = () => setDialog("pay-success", false);

  useEffect(() => {
    // api();
  }, []);

  return (
    <Dialog
      visible={dialog["pay-success"]}
      style={{ padding: 0 }}
      onCancel={onCancel}
      layerClose={false}
      closeStyle={{ color: "rgba(55,65,81,0.2)", top: "24px", right: "14px" }}
    >
      <div className="pay-success-dialog">
        <img src={successIcon} alt="successIcon" />
        <p>订阅成功</p>
        {/* <div className="pay-success-dialog__desc">
          <p>已为您自动解锁权益期内的会员通讯内容</p>
          <p>积分已到账，可用于解锁其他会员权益</p>
          <p>访问微信服务号「机器之心PRO会员」查看每周更新</p>
        </div>
        <div className="pay-success-dialog__btn" onClick={() => onCancel()}>
          开始阅读
        </div> */}
        <div className="pay-success-dialog__qr">
          <img src={proCodeImg} alt="code" />
        </div>
        <div className="pay-success-dialog__bottom">
          <p>关注「机器之心PRO会员」服务号，</p>
          <p>访问左下角「收件箱」，每周获取最新会员内容</p>
        </div>
      </div>
    </Dialog>
  );
};

export default observer(PaySuccessDialog);
