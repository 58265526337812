import React from "react";

import { Table } from "antd";

const columns = [
  {
    title: "订单编号",
    dataIndex: "id",
  },
  {
    title: "付款时间",
    dataIndex: "payTime",
  },
  {
    title: "产品名称",
    dataIndex: "productName",
    render: data => (
      <>
        {data.productName && <div>{data.productName}</div>}
        {data.couponCode && <div>{data.couponCode}</div>}
      </>
    ),
  },
  {
    title: "价格",
    dataIndex: "price",
    render: data => (data ? `￥${data}` : "-"),
  },
  {
    title: "支付方式",
    dataIndex: "payWay",
    render: data => (data ? data : "-"),
  },
  {
    title: "支付状态",
    dataIndex: "payStatus",
  },
];

interface Props {
  data: [];
}

const OrderTablePanel: React.FC<Props> = (props: Props) => {
  return <Table columns={columns} dataSource={props.data} pagination={false} />;
};

export default OrderTablePanel;
