import React from "react";

const SVG = ({
  fill = "#92929D",
  width = "100%",
  className = "",
  viewBox = "0 0 24 24",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill={fill}
        d="M12 1.501c1.418 0 2.793.278 4.086.825 1.25.528 2.373 1.286 3.338 2.25.964.965 1.722 2.088 2.25 3.338.547 1.293.825 2.668.825 4.086 0 1.418-.278 2.793-.825 4.086-.528 1.25-1.286 2.373-2.25 3.338-.965.964-2.088 1.721-3.338 2.25-1.293.547-2.668.825-4.086.825-1.418 0-2.793-.278-4.086-.825-1.25-.529-2.373-1.286-3.338-2.25-.964-.965-1.722-2.088-2.25-3.338C1.779 14.793 1.5 13.418 1.5 12c0-1.418.278-2.793.825-4.086.528-1.25 1.286-2.373 2.25-3.338.965-.964 2.088-1.722 3.338-2.25C9.207 1.779 10.582 1.5 12 1.5m0-1.199C5.54.302.302 5.539.302 12c0 6.46 5.237 11.698 11.698 11.698 6.46 0 11.698-5.237 11.698-11.698C23.698 5.54 18.461.302 12 .302z"
      />
      <path
        fill={fill}
        d="M14.8 10.026c.15 0 .297.01.443.025C14.845 8.301 12.863 7 10.601 7 8.07 7 6 8.628 6 10.695c0 1.194.69 2.174 1.841 2.933l-.46 1.308 1.608-.762c.576.108 1.037.219 1.612.219.144 0 .288-.007.43-.017-.093-.296-.141-.603-.142-.911 0-1.899 1.725-3.44 3.911-3.44m-2.474-1.177c.346 0 .576.215.576.542 0 .325-.23.544-.576.544-.345 0-.691-.219-.691-.544 0-.327.346-.542.69-.542M9.107 9.934c-.345 0-.693-.219-.693-.544 0-.327.348-.542.693-.542s.575.215.575.542c0 .325-.23.544-.575.544M19 13.413c0-1.738-1.841-3.154-3.909-3.154-2.189 0-3.913 1.417-3.913 3.154 0 1.74 1.724 3.152 3.913 3.152.458 0 .92-.108 1.381-.217l1.261.652-.345-1.086C18.312 15.26 19 14.392 19 13.413m-5.177-.544c-.23 0-.46-.215-.46-.435 0-.217.23-.434.46-.434.348 0 .576.218.576.434 0 .22-.228.435-.576.435m2.53 0c-.228 0-.456-.215-.456-.435 0-.217.229-.434.456-.434.344 0 .577.218.577.434 0 .22-.233.435-.577.435"
      />
    </g>
  </svg>
);

export default SVG;
