import React, { useState, useEffect } from "react";
import Icon from "../icon/Icon";

import "./style.scss";

interface Props {
  openedText: string;
  closedText: string;
  size?: string;
  isOpen?: boolean;
  onChange: () => void;
}
const Collapses: React.FC<Props> = (props: Props) => {
  const [isOpen, setIsOpen] = useState(props.isOpen);
  const iconName = isOpen ? "pro-arrow-up" : "pro-arrow-down";

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  const handleClick = () => {
    setIsOpen(!isOpen);
    props.onChange();
  };

  return (
    <div className="pro-collapse" onClick={handleClick}>
      <span className={`pro-collapse__text size--${props.size}`}>
        {isOpen ? props.openedText : props.closedText}
      </span>
      <Icon name={iconName} color="#6b8aba" />
    </div>
  );
};

export default Collapses;
