import React from "react";

const SVG = ({
  fill = "#92929D",
  width = "100%",
  className = "",
  viewBox = "0 0 16 16",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FFF"
        d="M8 12c-2.032 0-3.752.874-4.8 1.567C4.507 14.587 6.177 15.2 8 15.2c1.822 0 3.492-.614 4.8-1.633C11.75 12.874 10.03 12 8 12"
      />
      <path
        fill={fill}
        d="M8 0C3.589 0 0 3.59 0 8c0 4.41 3.589 8 8 8s8-3.59 8-8c0-4.411-3.589-8-8-8m4.482 12.56C11.36 11.873 9.806 11.2 8 11.2c-1.805 0-3.36.674-4.483 1.36C2.335 11.4 1.6 9.784 1.6 8c0-3.529 2.871-6.4 6.4-6.4 3.529 0 6.4 2.871 6.4 6.4 0 1.784-.735 3.399-1.918 4.56M4.92 13.607C5.774 13.166 6.83 12.8 8 12.8s2.226.366 3.08.807c-.914.504-1.964.793-3.08.793-1.117 0-2.166-.29-3.08-.793M8 8c-.882 0-1.6-.718-1.6-1.6 0-.882.718-1.6 1.6-1.6.882 0 1.6.718 1.6 1.6C9.6 7.282 8.882 8 8 8m0-4.8c-1.765 0-3.2 1.435-3.2 3.2 0 1.765 1.435 3.2 3.2 3.2 1.765 0 3.2-1.435 3.2-3.2 0-1.765-1.435-3.2-3.2-3.2"
      />
    </g>
  </svg>
);

export default SVG;
