import React from "react";

const MikeInfo = ({ data }) => {
  return (
    <>
      {data.map((item, index) => {
        return (
          <div
            className="selection-rating-modules-form--block mike-block"
            key={index}
          >
            <div className="selection-rating-modules-form--field">
              {item[0]}:
            </div>
            <div className="selection-rating-modules-form--value">
              {item[1]}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default MikeInfo;
