import React, { useContext } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";

import "./style.scss";
import { Button } from "../shared";
import couponCodeStore from "../../stores/couponCodeStore";
import SyncedLogo from "../../styles/images/vip-logo.png";

const ExchangeSure: React.FC = () => {
  const { code, last, sureCode } = useContext(couponCodeStore);
  return (
    <div className="pro-coupon-code-sure">
      <h2>{code.category === "common" ? "确认兑换" : "价格优惠"}</h2>
      <p className="pro-coupon-code-description">
        您输入的优惠码包含以下权益：
      </p>
      <div className="pro-coupon-code-card">
        <img
          src={SyncedLogo}
          alt="logo"
          className="pro-coupon-code-card-logo"
        />
        <div className="pro-coupon-code-card-content">
          {code.category === "common" ? (
            <>
              <p dangerouslySetInnerHTML={{ __html: code.desc }} />
            </>
          ) : (
            <p dangerouslySetInnerHTML={{ __html: code.desc }} />
          )}
        </div>
      </div>
      <div className="pro-coupon-code-handle">
        {code.category === "common" ? (
          <Button size="large" grid={10} onClick={sureCode}>
            立即兑换
          </Button>
        ) : (
          <Link to={`/pricing?couponCode=${code.value}&isShowMoal=1`}>
            <Button size="large" grid={10}>
              前往购买
            </Button>
          </Link>
        )}
        <p className="pro-coupon-code-prev" onClick={last}>
          重新输入
        </p>
      </div>
    </div>
  );
};

export default observer(ExchangeSure);
