import React, { useEffect, useContext, useState } from "react";
import { observer } from "mobx-react";

import "./dialog.scss";
import { Dialog } from "../shared";
import memberInboxStore from "../../stores/memberInboxStore";
import { Form, Input, message } from "antd";
import { editInfoApi } from "../../api/index";

interface Props {
  // 回显用
  name?: string;
  company?: string;
  position?: string;
  // 提交后操作回调
  onSubmit?(values?: any): any;
}

const EditInfoDialog = (props: Props) => {
  const { name = "", company = "", position = "", onSubmit = () => {} } = props;
  const { dialog, setDialog } = useContext(memberInboxStore);
  const [form] = Form.useForm();
  const [isComplete, setIsComplete] = useState(false);

  const onCancel = () => setDialog("edit-info", false);

  const onFieldsChange = () => {
    const values: any = form.getFieldsValue();
    const { name } = values;
    setIsComplete(!!name);
  };

  const onFinish = async values => {
    if (isComplete) {
      try {
        await editInfoApi({ user: values });
        message.open({
          type: "success",
          duration: 3,
          className: "ant-toast",
          content: "更改成功！",
        });
        onCancel();
        onSubmit(values);
      } catch (err) {
        if (err.error.message && err.error.message.length > 0) {
          const errMessage = err.error.message.toString();
          message.error(errMessage);
        }
      }
    }
  };

  useEffect(() => {
    if (dialog["edit-info"]) {
      setIsComplete(!!name);
    }
  }, [dialog["edit-info"]]);

  return (
    <Dialog
      visible={dialog["edit-info"]}
      style={{ padding: "14px", borderRadius: "8px" }}
      onCancel={onCancel}
      grayClose
      closeStyle={{ color: "rgba(55,65,81,0.2)", top: "22px", right: "14px" }}
    >
      <div className="edit-info-dialog">
        <p className="edit-info-dialog__title">编辑个人身份</p>
        {dialog["edit-info"] && (
          <Form
            className="edit-info__form"
            initialValues={{
              name,
              company,
              position,
            }}
            autoComplete="off"
            form={form}
            onFieldsChange={onFieldsChange}
            onFinish={onFinish}
          >
            <Form.Item label="昵称" name="name">
              <Input placeholder="请输入您的昵称" />
            </Form.Item>

            <Form.Item label="公司" name="company">
              <Input placeholder="请输入您所在的公司" />
            </Form.Item>

            <Form.Item label="职务" name="position">
              <Input placeholder="请输入您在公司所担任的职务" />
            </Form.Item>

            <div
              className={`bind-info__form-btn ${!isComplete &&
                "bind-info__form-btn--opacity"}`}
              onClick={() => form.submit()}
            >
              提交
            </div>
          </Form>
        )}
      </div>
    </Dialog>
  );
};

export default observer(EditInfoDialog);
