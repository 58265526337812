import React from "react";
import { Route } from "react-router-dom";
import { RequireAuth } from "../components/shared/index";

const renderRouter = (routers: any) => {
  const normalRouter = routers.filter(
    item => !item.isCheckRole && !item.isHide
  );
  const checkRoleRouter = routers.filter(
    item => item.isCheckRole && !item.isHide
  );
  const pathArray = checkRoleRouter.map(item => item.path);

  const _normalRouter = normalRouter.map(
    ({ auth, exact, strict, path, params = {}, Component }, index) =>
      auth ? (
        <Route
          key={index}
          exact={exact}
          strict={strict}
          path={path}
          render={props => (
            <RequireAuth>
              <Component
                type={params.type}
                header={params.header}
                icon={params.icon}
                id={props.match.params.id}
              />
            </RequireAuth>
          )}
        />
      ) : (
        <Route
          key={index}
          exact={exact}
          strict={strict}
          path={path}
          render={props => (
            <Component
              type={params.type}
              header={params.header}
              icon={params.icon}
              id={props.match.params.id}
            />
          )}
        />
      )
  );

  const _checkRoleRouter = (
    <>
      {pathArray.includes(location.pathname) && (
        <RequireAuth isCheckRole pathArray={pathArray}>
          {checkRoleRouter.map(
            ({ exact, strict, path, params = {}, Component }, index) => (
              <Route
                key={index}
                exact={exact}
                strict={strict}
                path={path}
                render={props => (
                  <Component
                    type={params.type}
                    header={params.header}
                    icon={params.icon}
                    id={props.match.params.id}
                  />
                )}
              />
            )
          )}
        </RequireAuth>
      )}
    </>
  );

  return (
    <>
      {_normalRouter}
      {_checkRoleRouter}
    </>
  );
};

export default renderRouter;
