import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { observer } from "mobx-react";
import { Modal } from "antd";
import InfiniteScroll from "react-infinite-scroller";

import communicationStore from "../../stores/communicationStore";
import GroupCard from "./GroupCard";
import { PRO, COMUNICATION } from "../../constants/pro_constants";
import { labelToChinese } from "../../constants/label_to_chinese";

import "./style.scss";
import { Sidebar, Icon } from "../shared";

const Communication = () => {
  const {
    groups,
    isShowModal,
    updateIsShowModal,
    currentGroupName,
    currentGroupAvatar,
    currentGroupSummary,
    initializeGroups,
    fetchMoreData,
    loading,
    hasMore,
    pagination,
  } = useContext(communicationStore);

  useEffect(() => {
    initializeGroups();
  }, []);

  return (
    <div className="communication-layout">
      <Helmet>
        <title>
          {labelToChinese[COMUNICATION]}｜{labelToChinese[PRO]}
        </title>
      </Helmet>

      <div className="communication-layout__sidebar">
        <Sidebar />
      </div>
      <div className="pro-layout__content-wrapper">
        <div className="communication-layout__content">
          <h2>职业交流</h2>
          <p>请根据您的兴趣选择加入交流群</p>
          <InfiniteScroll
            className="communication__groups"
            initialLoad={false}
            pageStart={pagination.current}
            loadMore={fetchMoreData}
            hasMore={!loading && hasMore}
            loader={
              <div className="loader" key={0}>
                Loading ...
              </div>
            }
            useWindow={false}
          >
            {groups.map((item, i) => {
              return <GroupCard key={i} {...item} />;
            })}
          </InfiniteScroll>
          <Modal
            className="communication-modal"
            visible={isShowModal}
            destroyOnClose
            onCancel={() => updateIsShowModal(false)}
            width={406}
            centered
            footer={null}
            closeIcon={<Icon name="pro-close" size={16} color="#c1c7d0" />}
            title={
              <h3 className="communication-modal__header">
                {currentGroupName}
              </h3>
            }
          >
            <img src={currentGroupAvatar} alt={currentGroupName} />
            <span className="communication-modal__desc">
              {currentGroupSummary}
            </span>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default observer(Communication);
