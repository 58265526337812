import {
  Database,
  DatabaseDetail,
  Research,
  ResearchDetail,
  Insight,
  News,
  Communication,
  Home,
  Sales,
  User,
  Identity,
  BasicInformation,
  IdentificationSuccess,
  Subscribe,
  Refer,
  ReferDetail,
  Message,
  MessageDetail,
  CareerInformation,
  Auth,
  AuthSuccess,
  Pricing,
  PaymentSuccess,
  Profile,
  Membership,
  ForgetPassword,
  CouponCode,
  SelectionList,
  AwardList,
  SelectionRatingDetail,
  BasePage,
  MemberInbox,
  MemberInboxDetail,
  MemberInboxPc,
  SignIn,
  BindInfo,
  Authentication,
  MemberInboxReport,
  MemberInboxCenter,
  MemberInboxSearch,
  MemberInboxInterpretation,
} from "../components";
import { INSTITUTION, SOLUTION, DATASET } from "../constants/pro_constants";
import identificationSuccess from "../styles/images/identification-success.svg";
import identificationStart from "../styles/images/identification-start.svg";

export interface Props {
  path: string;
  auth?: boolean;
  exact?: boolean;
  strict?: boolean;
  params?: any;
  isHide?: boolean;
  Component: React.ReactNode;
  isCheckRole?: boolean;
}

const config: Array<Props> = [
  {
    path: "/",
    exact: true,
    Component: Sales,
  },
  {
    path: "/pricing",
    exact: true,
    strict: true,
    Component: Pricing,
  },
  {
    path: "/pricing/success",
    auth: true,
    exact: true,
    strict: true,
    Component: PaymentSuccess,
  },
  {
    path: "/login",
    exact: true,
    strict: true,
    Component: Auth,
    params: {
      type: "login",
    },
  },
  {
    path: "/login/success",
    auth: true,
    exact: true,
    Component: AuthSuccess,
  },
  {
    path: "/register",
    exact: true,
    strict: true,
    Component: Auth,
    params: {
      type: "register",
    },
  },
  {
    path: "/authentication",
    exact: true,
    strict: true,
    Component: Auth,
    params: {
      type: "authentication",
    },
  },
  {
    path: "/register/success",
    auth: true,
    exact: true,
    Component: AuthSuccess,
  },
  {
    path: "/users/:id",
    exact: true,
    Component: User,
  },
  {
    path: "/identification/step1",
    exact: true,
    strict: true,
    auth: true,
    Component: Identity,
  },
  {
    path: "/identification/step2",
    exact: true,
    strict: true,
    auth: true,
    Component: CareerInformation,
  },
  {
    path: "/identification/step3",
    exact: true,
    strict: true,
    auth: true,
    Component: BasicInformation,
  },
  {
    path: "/identification/success",
    exact: true,
    strict: true,
    auth: true,
    Component: IdentificationSuccess,
    params: {
      header: "恭喜你，职业认证成功",
      icon: identificationSuccess,
    },
  },
  {
    path: "/identification/start",
    exact: true,
    strict: true,
    auth: true,
    Component: IdentificationSuccess,
    params: {
      header: "开始试用「机器之心Pro」",
      icon: identificationStart,
    },
  },
  {
    path: "/database/institutions/:id",
    exact: true,
    Component: DatabaseDetail,
    params: {
      type: "institution",
    },
  },
  {
    path: "/database/solutions/:id",
    exact: true,
    Component: DatabaseDetail,
    params: {
      type: "solution",
    },
  },
  {
    path: "/database/business-cases/:id",
    exact: true,
    Component: DatabaseDetail,
    params: {
      type: "businessCase",
    },
  },
  {
    path: "/research/:id",
    exact: true,
    strict: true,
    Component: ResearchDetail,
  },
  {
    path: "/membership",
    exact: true,
    strict: true,
    Component: Membership,
  },
  {
    path: "/forget-password",
    exact: true,
    strict: true,
    Component: ForgetPassword,
  },
  {
    path: "/coupon-code",
    exact: true,
    strict: true,
    Component: CouponCode,
  },
  {
    path: "/selections",
    exact: true,
    strict: true,
    Component: SelectionList,
  },
  {
    path: "/awards",
    exact: true,
    strict: true,
    Component: AwardList,
  },
  {
    path: "/selection-rating/:id",
    exact: true,
    strict: true,
    Component: SelectionRatingDetail,
  },
  {
    path: "/check-auth",
    exact: true,
    strict: true,
    Component: BasePage,
  },
  {
    path: "/inbox",
    exact: true,
    strict: true,
    Component: MemberInbox,
  },
  {
    path: "/inbox/:id",
    exact: true,
    strict: true,
    Component: MemberInboxDetail,
  },
  {
    path: "/inbox-pc",
    exact: true,
    strict: true,
    Component: MemberInboxPc,
  },
  {
    path: "/inbox-report/:id",
    exact: true,
    strict: true,
    Component: MemberInboxReport,
  },
  {
    path: "/inbox-interpretation/:id",
    exact: true,
    strict: true,
    Component: MemberInboxInterpretation,
  },
  {
    path: "/inbox-center",
    exact: true,
    strict: true,
    Component: MemberInboxCenter,
  },
  {
    path: "/inbox-search",
    exact: true,
    strict: true,
    Component: MemberInboxSearch,
  },
  {
    path: "/sign-in",
    exact: true,
    strict: true,
    Component: SignIn,
  },
  {
    path: "/bind-info",
    exact: true,
    strict: true,
    Component: BindInfo,
  },
  {
    path: "/auth",
    exact: true,
    strict: true,
    Component: Authentication,
  },
  {
    path: "/trend/refer/:id",
    exact: true,
    strict: true,
    auth: true,
    Component: ReferDetail,
  },
  {
    path: "/message/:id",
    exact: true,
    strict: true,
    auth: true,
    Component: MessageDetail,
  },
  /* -------------------- isCheckRole分割 -------------------- */
  {
    path: "/research",
    exact: true,
    strict: true,
    isCheckRole: true,
    Component: Research,
  },
  {
    path: "/home",
    exact: true,
    strict: true,
    isCheckRole: true,
    Component: Home,
  },
  {
    path: "/database/institutions",
    exact: true,
    strict: true,
    isCheckRole: true,
    Component: Database,
    params: {
      type: INSTITUTION,
    },
  },
  {
    path: "/database/solutions",
    exact: true,
    strict: true,
    isCheckRole: true,
    Component: Database,
    params: {
      type: SOLUTION,
    },
  },
  {
    path: "/database/datasets",
    exact: true,
    strict: true,
    isCheckRole: true,
    Component: Database,
    params: {
      type: DATASET,
    },
  },
  {
    path: "/trend/insight",
    exact: true,
    isCheckRole: true,
    Component: Insight,
  },
  {
    path: "/trend/refer",
    exact: true,
    isCheckRole: true,
    Component: Refer,
  },
  {
    path: "/trend/news",
    exact: true,
    isCheckRole: true,
    Component: News,
  },
  {
    path: "/communication",
    exact: true,
    isCheckRole: true,
    Component: Communication,
  },
  {
    path: "/subscribe",
    exact: true,
    isCheckRole: true,
    Component: Subscribe,
  },
  {
    path: "/message",
    exact: true,
    isCheckRole: true,
    Component: Message,
  },
  {
    path: "/profile",
    exact: true,
    isCheckRole: true,
    Component: Profile,
  },
];

export default config;
