import React, { useEffect, useContext, useState } from "react";
import { Breadcrumb } from "antd";
import { observer } from "mobx-react";
import { Helmet } from "react-helmet";
import { useParams, useLocation } from "react-router-dom";

import "./refer-detail.scss";
import { Sidebar } from "../shared";
import AuthStore from "../../stores/authStore";
import messageStore from "../../stores/messageStore";
import { PRO, REFER } from "../../constants/pro_constants";
import { labelToChinese } from "../../constants/label_to_chinese";
import PrivateArea from "../message/PrivateArea";
import descImg from "../../styles/images/member-inbox-desc.png";
import Loading from "../shared/loading/Loading";
import MyDirectory from "../message/MyDirectory";
import "viewerjs/dist/viewer.css";
import Viewer from "viewerjs";

const ReferDetail: React.FC = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const { id } = useParams();
  const { fetchMemberInfo } = useContext(AuthStore);
  const {
    fetchDetail,
    detail,
    detailLoading,
    scrollChoose,
    activeIndex,
  } = useContext(messageStore);

  const [isPreview, setIsPreview] = useState(false);
  const [topTip, setTopTip] = useState("");
  const [breadList, setBreadList] = useState<any>([]);
  const [isDirectory, setIsDirectory] = useState(false);

  const onScrollEvent = () => {
    const dom = document.getElementsByClassName(
      "refer-detail-layout__content-wrapper"
    )[0];
    setIsDirectory(!detailLoading && dom.scrollTop > dom.clientHeight);
    scrollChoose("refer");
  };

  useEffect(() => {
    //!todo: 因为后端接口并发会导致卡死,等后端修复后去掉定时器;
    setTimeout(() => {
      fetchMemberInfo();
    }, 500);
    fetchDetail(id);
    // fetchMemberInfo();
    // if (!(currentUser && !currentUser.mobile_verified)) {
    //   fetchDetail(id);
    //   window.sessionStorage.removeItem("login");
    // }
  }, []);

  useEffect(() => {
    if (!detailLoading) {
      const view = new Viewer(document.querySelector(".refer-detail-body"), {
        button: false,
        toolbar: false,
        title: false,
        navbar: false,
        initialCoverage: 1,
        zIndex: 10000,
        transition: false,
        viewed(e: any) {
          const {
            target: { viewer },
          } = e;
          // console.log(viewer);
          const now = viewer.index + 1;
          const total = viewer.length;
          setTopTip(`${now}/${total}`);
        },
        show() {
          setIsPreview(true);
        },
        hide() {
          setIsPreview(false);
          setTopTip("");
        },
        ready() {
          document.querySelector(".viewer-container").addEventListener(
            "click",
            (e: any) => {
              e.stopPropagation();
              view.hide();
            },
            false
          );
        },
      });
      const bread = [
        {
          path: "/trend/refer",
          title: labelToChinese[REFER],
        },
        {
          path: currentPath,
          title: detail.title,
        },
      ];
      setBreadList(bread);
    }
  }, [detailLoading]);

  return (
    <div className="refer-detail-layout">
      <Helmet>
        <title>
          {detailLoading ? "" : detail.title}｜ {labelToChinese[PRO]}
        </title>
      </Helmet>
      <div className="refer-detail-layout__sidebar">
        <Sidebar />
      </div>
      <div
        className="refer-detail-layout__content-wrapper"
        id="refer-detail-content"
        onScrollCapture={onScrollEvent}
      >
        {!detailLoading && detail.fullContent && (
          <MyDirectory
            type={"refer"}
            active={activeIndex}
            style={{ opacity: isDirectory ? 1 : 0 }}
          />
        )}
        <div className="refer-detail-layout__content">
          {detailLoading ? (
            <Loading />
          ) : (
            <>
              <div className="refer-detail__header">
                <Breadcrumb separator=">">
                  {breadList &&
                    breadList.map((item, index) => {
                      return index + 1 !== breadList.length ? (
                        <Breadcrumb.Item href={item.path} key={item.path}>
                          {item.title}
                        </Breadcrumb.Item>
                      ) : (
                        <Breadcrumb.Item key={item.path}>
                          {item.title}
                        </Breadcrumb.Item>
                      );
                    })}
                </Breadcrumb>
              </div>
              <div className="refer-detail__content">
                {isPreview && (
                  <p className="member-inbox-preview-top">{topTip}</p>
                )}
                <div className="refer-detail-header">
                  {detail.title && (
                    <p className="detail-title">{detail.title}</p>
                  )}
                  {detail.date && <p className="detail-date">{detail.date}</p>}
                  {detail.summary && (
                    <p className="detail-summary">
                      <img src={descImg} alt="desc" />
                      {detail.summary}
                    </p>
                  )}
                </div>
                <div
                  className="refer-detail-body"
                  dangerouslySetInnerHTML={{
                    __html: `${detail.fullContent || detail.previewContent}`,
                  }}
                />
                <PrivateArea />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(ReferDetail);
