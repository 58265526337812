const formatSelectionRatingData = initData => ({
  header: {
    selection: initData.ranking,
    ranking: initData.rankingForm,
    institution: {
      id: initData.account.id,
      name: initData.account.companyName,
    },
    ratedForms: initData.finisheds,
    ratedFormsCount: initData.finishedCount,
    ratedFormsTotalCount: initData.totalCount,
    nextNotRateFormId: initData.writingId,
  },
  questionnaire: initData.marking,
  modulesInfo: initData.requiredData.concat(initData.formData),
});

export { formatSelectionRatingData };
