import React from "react";

const SVG = ({
  fill = "#92929D",
  width = "100%",
  className = "",
  viewBox = "0 0 20 20",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M0 0H20V20H0z" />
      <path
        fill={fill}
        d="M17 0H3c-.552 0-1 .447-1 1v18c0 .553.448 1 1 1h14c.552 0 1-.447 1-1V1c0-.553-.448-1-1-1zM4 18h12V2H4v16zM9 6h2c.552 0 1-.447 1-1 0-.553-.448-1-1-1H9c-.552 0-1 .447-1 1 0 .553.448 1 1 1m1 8c-.552 0-1 .447-1 1 0 .553.448 1 1 1s1-.447 1-1c0-.553-.448-1-1-1"
      />
    </g>
  </svg>
);

export default SVG;
