import React, { useEffect, useContext } from "react";
import { observer } from "mobx-react";

import "./dialog.scss";
import { Dialog } from "../shared";
import codeIcon from "../../styles/images/error-code.png";
import errorIcon from "../../styles/images/inbox-pay-error.png";

import memberInboxStore from "../../stores/memberInboxStore";

const ExchangeErrorDialog = () => {
  const { dialog, setDialog } = useContext(memberInboxStore);
  const onCancel = () => {
    setDialog("exchange-error", false);
    window.sessionStorage.setItem("endCode", "end");
  };

  useEffect(() => {
    // api();
  }, []);

  return (
    <Dialog
      visible={dialog["exchange-error"]}
      style={{ padding: "40px 20px", borderRadius: "10px" }}
      onCancel={onCancel}
      layerClose={false}
      grayClose
      closeStyle={{ color: "#bec2d7", top: "16px", right: "18px" }}
    >
      <div className="exchange-error-dialog">
        <img src={codeIcon} alt="codeIcon" />
        <div>
          <img src={errorIcon} alt="errorIcon" />
          兑换失败！每月只能兑换一次哦~
        </div>
        <p>下个月再来试试吧~</p>
      </div>
    </Dialog>
  );
};

export default observer(ExchangeErrorDialog);
