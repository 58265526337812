import React from "react";

const SVG = ({ width = "100%", className = "" }) => (
  <svg
    width={width}
    height={width}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    viewBox="0 0 16 16"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#EB2835" fillRule="nonzero">
        <g>
          <path
            d="M97 219c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8m2.831 9.983c.216.237.207.603-.02.83-.226.226-.592.235-.829.019l-1.932-1.933-1.933 1.933c-.112.113-.265.176-.424.176-.16 0-.312-.063-.424-.176-.113-.113-.176-.265-.176-.424 0-.16.063-.312.176-.425l1.932-1.933-1.932-1.932c-.154-.151-.215-.373-.16-.581.056-.209.218-.371.426-.427.209-.055.43.005.582.159l1.933 1.933 1.932-1.933c.237-.216.603-.207.83.02.226.226.235.592.019.829l-1.933 1.932 1.933 1.933"
            transform="translate(-1399 -325) translate(1310 106)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
