import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
// import homePro from "../../styles/images/home-pro.svg";
import authStore from "../../stores/authStore";
import { Button, Icon } from "../shared";

const Profile: React.FC = () => {
  const { currentUser, isPaidUser, trialDays } = useContext(authStore);

  return (
    <div className="pro-home-profile">
      <div className="pro-home-profile__info">
        <img
          className="pro-home-profile__avatar avatar-img-fit--auto"
          src={currentUser.avatar_url}
          alt={currentUser.name}
        />
        <div className="pro-home-profile__header">
          <h5 className="text-limit--one">{currentUser.name}</h5>
          {isPaidUser && <Icon name="pro-icon" size={33} color="#0062ff" />}
        </div>
        {isPaidUser ? (
          <div className="pro-home-profile__description">
            <span>{currentUser.pubinfo}</span>
          </div>
        ) : (
          <div className="pro-home-profile__trial-description">
            <p>
              试用期还剩
              <span> {trialDays} </span>天
            </p>
            <Link to={"/pricing"}>
              <Button size="medium" grid={6} shape="round">
                成为会员
              </Button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(Profile);
