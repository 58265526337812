import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";

import Icon from "../icon/Icon";
import { labelToChinese } from "../../../constants/label_to_chinese";
import { ZHIZHOU } from "../../../constants/pro_constants";

const Menu = props => {
  const [isMenuActive, setIsMenuActive] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setIsMenuActive(props.link && location.pathname.indexOf(props.link) > -1);
  }, [location]);

  return props.label === ZHIZHOU ? (
    <NavLink
      className="pro-sidebar__item zhizhou"
      activeClassName="actived"
      to={props.link}
      target="_blank"
    >
      <Icon
        name={props.icon}
        size={20}
        color={isMenuActive ? "#0062ff" : "#92929D"}
      />
      <span>{labelToChinese[props.label]}</span>
      <Icon className="beta-icon" name="beta" />
    </NavLink>
  ) : (
    <NavLink
      className="pro-sidebar__item"
      activeClassName="actived"
      to={props.link}
    >
      <Icon
        name={props.icon}
        size={20}
        color={isMenuActive ? "#0062ff" : "#92929D"}
      />
      <span>{labelToChinese[props.label]}</span>
    </NavLink>
  );
};

export default Menu;
