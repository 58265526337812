import React from "react";

const SVG = ({ className = "", viewBox = "0 0 23 10" }) => (
  <svg
    width="23"
    height="10"
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <linearGradient id="proNew" x1="50%" x2="50%" y1="1.074%" y2="100%">
        <stop offset="0%" stopColor="#FF8C00" />
        <stop offset="100%" stopColor="#FF3000" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <path
          fill="#FFF"
          d="M0 0H1440V1019H0z"
          transform="translate(-312 -783)"
        />
        <path
          fill="#F4F4F6"
          d="M200 0H1440V1135H200z"
          transform="translate(-312 -783)"
        />
        <g transform="translate(-312 -783) translate(220 583)">
          <rect width="1200" height="436" fill="#FFF" rx="4" />
          <g>
            <g>
              <g>
                <g>
                  <path
                    fill="#FFF"
                    d="M0 0H1200V72H0z"
                    transform="translate(0 128) translate(0 51)"
                  />
                  <text
                    fill="#333"
                    fontFamily="SourceHanSansSC-Regular, Source Han Sans SC"
                    fontSize="14"
                    transform="translate(0 128) translate(0 51)"
                  >
                    <tspan x="60" y="31">
                      九牧
                    </tspan>
                  </text>
                </g>
              </g>
              <g
                fillRule="nonzero"
                transform="translate(0 128) translate(92 72)"
              >
                <rect width="23" height="10" fill="url(#proNew)" rx="2" />
                <path
                  fill="#FFF"
                  stroke="#FFF"
                  strokeWidth=".4"
                  d="M8.786 7.468h-.708L5.762 3.892c-.06-.096-.12-.204-.156-.3h-.012c.012.108.024.312.024.648v3.228H5.03V2.872h.744L8.03 6.388c.108.168.168.276.192.312h.012c-.024-.132-.024-.36-.024-.672V2.872h.588v4.596h-.012zm3.66 0H9.974V2.872h2.376V3.4h-1.788v1.476h1.644v.528h-1.644v1.548h1.884v.516zm6.48-4.596l-1.284 4.596h-.696l-.924-3.312c-.036-.156-.072-.312-.072-.48h-.012c-.012.168-.036.324-.084.468l-.936 3.324h-.684L12.89 2.872h.66l.936 3.468c.036.156.072.312.072.468h.012c.012-.12.048-.276.096-.468l.996-3.468h.588l.948 3.504c.036.12.06.264.072.444h.012c.012-.132.036-.276.084-.456l.9-3.492h.66z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
