import React from "react";

const SVG = ({
  fill = "#92929D",
  width = "100%",
  className = "",
  viewBox = "0 0 20 20",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>icon 7</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <path
        d="M17.5,1.66666667 L2.5,1.66666667 C2.03916667,1.66666667 1.66666667,2.03916667 1.66666667,2.5 C1.66666667,2.96083333 2.03916667,3.33333333 2.5,3.33333333 L2.5,14.1666667 C2.5,14.6275 2.8725,15 3.33333333,15 L7.26666667,15 L6.69166667,17.2983333 C6.58,17.7441667 6.85166667,18.1966667 7.29833333,18.3083333 C7.36583333,18.325 7.43416667,18.3333333 7.50083333,18.3333333 C7.87416667,18.3333333 8.21333333,18.0808333 8.30833333,17.7016667 L8.98333333,15 L11.0166667,15 L11.6916667,17.7016667 C11.7866667,18.0808333 12.1258333,18.3333333 12.4991667,18.3333333 C12.5658333,18.3333333 12.6341667,18.325 12.7016667,18.3083333 C13.1483333,18.1966667 13.42,17.7441667 13.3083333,17.2983333 L12.7333333,15 L16.6666667,15 C17.1275,15 17.5,14.6275 17.5,14.1666667 L17.5,3.33333333 C17.9608333,3.33333333 18.3333333,2.96083333 18.3333333,2.5 C18.3333333,2.03916667 17.9608333,1.66666667 17.5,1.66666667 L17.5,1.66666667 Z M4.16666667,13.3333333 L15.8333333,13.3333333 L15.8333333,3.33333333 L4.16666667,3.33333333 L4.16666667,13.3333333 Z M6.66666667,11.6666667 C7.1275,11.6666667 7.5,11.2941667 7.5,10.8333333 L7.5,9.16666667 C7.5,8.70583333 7.1275,8.33333333 6.66666667,8.33333333 C6.20583333,8.33333333 5.83333333,8.70583333 5.83333333,9.16666667 L5.83333333,10.8333333 C5.83333333,11.2941667 6.20583333,11.6666667 6.66666667,11.6666667 M10,11.6666667 C10.4608333,11.6666667 10.8333333,11.2941667 10.8333333,10.8333333 L10.8333333,7.5 C10.8333333,7.03916667 10.4608333,6.66666667 10,6.66666667 C9.53916667,6.66666667 9.16666667,7.03916667 9.16666667,7.5 L9.16666667,10.8333333 C9.16666667,11.2941667 9.53916667,11.6666667 10,11.6666667 M13.3333333,11.6666667 C13.7941667,11.6666667 14.1666667,11.2941667 14.1666667,10.8333333 L14.1666667,5.83333333 C14.1666667,5.3725 13.7941667,5 13.3333333,5 C12.8725,5 12.5,5.3725 12.5,5.83333333 L12.5,10.8333333 C12.5,11.2941667 12.8725,11.6666667 13.3333333,11.6666667"
        id="ProResearch"
      />
    </defs>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Component/icon/ic_Projects">
        <mask id="ProResearch2" fill="white">
          <use xlinkHref="#ProResearch" />
        </mask>
        <use
          id="Icon"
          fill={fill}
          fillRule="evenodd"
          xlinkHref="#ProResearch"
        />
      </g>
    </g>
  </svg>
);

export default SVG;
