import React, { useContext, useEffect } from "react";
import InstitutionTabs from "./InstitutionTabs";
import databaseDetailStore from "../../stores/databaseDetailStore";
import { observer } from "mobx-react";
import BusinessCaseTabs from "./BusinessCaseTabs";
import SolutionTabs from "./SolutionTabs";

const DetailTabs: React.FC = () => {
  const { type, initializeScrolledDomId, setRichImgStyle } = useContext(
    databaseDetailStore
  );

  useEffect(() => {
    initializeScrolledDomId();
  }, [type]);

  useEffect(() => {
    setRichImgStyle();
  }, [{}]);

  return <div className="detail-layout__info-tabs">{Tabs[type]}</div>;
};

const Tabs = {
  institution: (
    <>
      <InstitutionTabs />
    </>
  ),
  solution: <SolutionTabs />,
  businessCase: (
    <>
      <BusinessCaseTabs />
    </>
  ),
};

export default observer(DetailTabs);
