import React from "react";

const SVG = ({
  fill = "#0062FF",
  width = "100%",
  className = "",
  viewBox = "0 0 16 16",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M7.2 7.2L10.4 15.2 12 12 15.2 10.4z" />
      <path
        fill={fill}
        d="M10.4 16h-.034c-.313-.014-.592-.21-.71-.503l-3.2-8c-.118-.297-.048-.636.178-.863.228-.226.568-.295.863-.177l8 3.2c.293.117.49.396.502.71.014.315-.16.608-.441.749l-2.962 1.48-1.48 2.962c-.136.272-.414.442-.716.442zM8.636 8.636l1.846 4.613.803-1.607c.077-.155.202-.28.357-.357l1.607-.803-4.613-1.846zM7.2 4c-.442 0-.8-.358-.8-.8V.8c0-.442.358-.8.8-.8.442 0 .8.358.8.8v2.4c0 .442-.358.8-.8.8m-4 4H.8C.358 8 0 7.642 0 7.2c0-.442.358-.8.8-.8h2.4c.442 0 .8.358.8.8 0 .442-.358.8-.8.8m1.172-2.828c-.205 0-.41-.079-.566-.235L2.11 3.24c-.313-.313-.313-.819 0-1.131.312-.313.818-.313 1.13 0l1.698 1.697c.313.313.313.819 0 1.131-.156.156-.36.235-.565.235m5.656 0c-.205 0-.41-.079-.565-.235-.313-.312-.313-.818 0-1.13l1.697-1.698c.313-.313.819-.313 1.131 0 .313.312.313.818 0 1.13l-1.697 1.698c-.156.156-.361.235-.566.235m-7.354 7.354c-.205 0-.41-.079-.565-.235-.313-.312-.313-.818 0-1.13l1.697-1.698c.313-.313.819-.313 1.131 0 .313.312.313.818 0 1.13L3.24 12.292c-.156.156-.361.235-.566.235"
      />
    </g>
  </svg>
);

export default SVG;
