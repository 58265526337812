import React from "react";

const SVG = ({
  fill = "#0062FF",
  width = "100%",
  className = "",
  viewBox = "0 0 16 16",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M0.8 4H15.200000000000001V15.2H0.8z" />
      <path
        fill={fill}
        d="M15.92 3.665c-.002-.007 0-.015-.004-.023l-1.6-3.2C14.18.172 13.903 0 13.6 0H2.4c-.303 0-.58.171-.716.442l-1.6 3.2c-.003.008-.002.016-.005.023C.031 3.767 0 3.879 0 4v11.2c0 .442.358.8.8.8h14.4c.442 0 .8-.358.8-.8V4c0-.12-.031-.233-.08-.335zM1.6 14.4V4.8h5.6v.8c0 .442.358.8.8.8.442 0 .8-.358.8-.8v-.8h5.6v9.6H1.6zM2.894 1.6H7.2v1.6H2.094l.8-1.6zm11.012 1.6H8.8V1.6h4.306l.8 1.6zm-6.706 8H4c-.442 0-.8.358-.8.8 0 .442.358.8.8.8h3.2c.442 0 .8-.358.8-.8 0-.442-.358-.8-.8-.8M4 9.6h1.6c.442 0 .8-.358.8-.8 0-.442-.358-.8-.8-.8H4c-.442 0-.8.358-.8.8 0 .442.358.8.8.8"
      />
    </g>
  </svg>
);

export default SVG;
