import React from "react";
import { Icon } from "../shared";

export const pricingData = [
  {
    introduction: <span className="table-body--bold">定制研究</span>,
    trialUser: "",
    personalUser: "",
    // institutionUser: "",
    key: "1",
  },
  {
    introduction: "服务享优惠",
    trialUser: "-",
    personalUser: <Icon name="pricing-yes" size={20} />,
    // institutionUser: "",
    key: "2",
  },
  {
    introduction: (
      <span className="table-body--bold">大范围、高质量的AI产业数据库</span>
    ),
    trialUser: "",
    personalUser: "",
    // institutionUser: "",
    key: "3",
  },
  {
    introduction: "机构/方案/数据包筛选",
    trialUser: "特殊筛选维度不可用",
    personalUser: "无限制",
    // institutionUser: "无限制",
    key: "4",
  },
  {
    introduction: "筛选后的数据结果",
    trialUser: "仅展示第一页结果",
    personalUser: "无限制",
    // institutionUser: "无限制",
    key: "5",
  },
  {
    introduction: "机构/方案/数据包列表可查看数据条目",
    trialUser: "仅展示前三页数据条目",
    personalUser: "无限制",
    // institutionUser: "无限制",
    key: "6",
  },
  {
    introduction: "机构/方案/数据包排序",
    trialUser: <Icon name="pricing-yes" size={20} />,
    personalUser: <Icon name="pricing-yes" size={20} />,
    // institutionUser: <Icon name="pricing-yes" size={20} />,
    key: "7",
  },
  {
    introduction: "获取数据包",
    trialUser: "-",
    personalUser: "无限制",
    // institutionUser: "可直接下载使用",
    key: "8",
  },
  {
    introduction: "查看机构/方案/案例详情页",
    trialUser: "部分信息不可查看",
    personalUser: "无限制",
    // institutionUser: "无限制",
    key: "9",
  },
  {
    introduction: "机构对接服务",
    trialUser: "-",
    personalUser: <Icon name="pricing-yes" size={20} />,
    // institutionUser: <Icon name="pricing-yes" size={20} />,
    key: "10",
  },
  {
    introduction: "案例相关材料获取",
    trialUser: "-",
    personalUser: <Icon name="pricing-yes" size={20} />,
    // institutionUser: <Icon name="pricing-yes" size={20} />,
    key: "11",
  },
  {
    introduction: <span className="table-body--bold">高效获取研究内容</span>,
    trialUser: "",
    personalUser: "",
    // institutionUser: "",
    key: "12",
  },
  {
    introduction: "研究内容列表可查看内容",
    trialUser: "仅展示前三页数据条目",
    personalUser: "无限制",
    // institutionUser: "无限制",
    key: "13",
  },
  {
    introduction: "研究内容筛选",
    trialUser: "特殊维度不可用",
    personalUser: "无限制",
    // institutionUser: "无限制",
    key: "14",
  },
  {
    introduction: "研究内容排序",
    trialUser: <Icon name="pricing-yes" size={20} />,
    personalUser: <Icon name="pricing-yes" size={20} />,
    // institutionUser: <Icon name="pricing-yes" size={20} />,
    key: "15",
  },
  {
    introduction: "研究需求提交",
    trialUser: <Icon name="pricing-yes" size={20} />,
    personalUser: <Icon name="pricing-yes" size={20} />,
    // institutionUser: <Icon name="pricing-yes" size={20} />,
    key: "16",
  },
  {
    introduction: "自产内容详情页",
    trialUser: "仅可查看部分信息",
    personalUser: "无限制",
    // institutionUser: "无限制",
    key: "17",
  },
  {
    introduction: "研究内容获取",
    trialUser: "仅可获取部分免费内容",
    personalUser: "可获取所有研究内容",
    // institutionUser: "可获取所有研究内容",
    key: "18",
  },
  {
    introduction: "研究相关图表/数据报获取",
    trialUser: "-",
    personalUser: <Icon name="pricing-yes" size={20} />,
    // institutionUser: <Icon name="pricing-yes" size={20} />,
    key: "19",
  },
  {
    introduction: <span className="table-body--bold">透视行业趋势</span>,
    trialUser: "",
    personalUser: "",
    // institutionUser: <Icon name="pricing-yes" size={20} />,
    key: "20",
  },
  {
    introduction: "分类查看动态",
    trialUser: "-",
    personalUser: "无限制",
    // institutionUser: <Icon name="pricing-yes" size={20} />,
    key: "21",
  },
  {
    introduction: "可查看动态数量",
    trialUser: "无限制",
    personalUser: "无限制",
    // institutionUser: <Icon name="pricing-yes" size={20} />,
    key: "23",
  },
  {
    introduction: "可阅读洞察参考",
    trialUser: "仅可阅读试读部分内容",
    personalUser: "无限制",
    // institutionUser: <Icon name="pricing-yes" size={20} />,
    key: "24",
  },
  {
    introduction: <span className="table-body--bold">获取会员通讯</span>,
    trialUser: "",
    personalUser: "",
    // institutionUser: <Icon name="pricing-yes" size={20} />,
    key: "25",
  },
  {
    introduction: "阅读会员通讯",
    trialUser: "仅可阅读试读部分内容",
    personalUser: "无限制",
    // institutionUser: <Icon name="pricing-yes" size={20} />,
    key: "26",
  },
];
