import React from "react";

import "./style.scss";

interface Props {
  isActive: boolean;
  label: string;
  hasDivider: boolean;
  onClick: () => void;
}

const Tab: React.FC<Props> = (props: Props) => {
  return (
    <>
      <button
        onClick={props.onClick}
        className={`tabs-nav__tab ${props.isActive ? "active" : ""}`}
      >
        {props.label}
      </button>
      {props.hasDivider && <div className="tabs-nav__divider">|</div>}
    </>
  );
};

Tab.defaultProps = {
  hasDivider: false,
};

export default Tab;
