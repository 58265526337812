import { createContext } from "react";
import { action, observable, computed } from "mobx";
import _ from "lodash";
import dayjs from "dayjs";
import { message } from "antd";
import {
  contact,
  fetchProductsInfo,
  orderProduct,
  fetchPurchaseQrcode,
  fetchPurchaseStatus,
  fetchReducedPrice,
} from "../api";
import {
  formatOrderData,
  formatPersonProductData,
} from "../utils/formatPaymentData";
import {
  trackShowPaymentModalEvent,
  trackPurchaseEvent,
  trackContactSuccessEvent,
} from "../utils/ga_events";

class PaymentStore {
  @observable communicateTime = "";
  @observable contactSuccess = false;
  @observable productId = "";
  @observable personProduct = {};
  @observable orderData = {};
  @observable purchaseQrcode = "";
  @observable purchaseStatus = false;
  @observable purchaseTimer = null;
  @observable isCouponCodeValid = true;
  @observable reducedPrice = 0;
  @observable isDiscount = false;

  @action
  initContactSuccess = () => {
    this.contactSuccess = false;
  };

  @action
  initPurchaseStatus = () => {
    this.purchaseStatus = false;
  };

  @action
  initPurchaseQrcode = () => {
    this.purchaseQrcode = "";
  };

  @action
  setIsDiscount = status => {
    this.isDiscount = status;
  };

  @action
  resetPurchaseTimer = () => {
    clearInterval(this.purchaseTimer);
  };

  @action
  handleOnContact = async values => {
    const communicateTime = values["communicate"]
      ? `${values["communicate"].format("YYYY-MM-DD HH:mm")}`
      : "";
    this.communicateTime = communicateTime;
    const params = {
      ...values,
      communicate: communicateTime,
    };

    const result = await contact(params);
    this.contactSuccess = result.success;
    if (this.contactSuccess) {
      trackContactSuccessEvent();
    }
  };

  @action
  fetchProductsInfo = async couponCode => {
    const result = await fetchProductsInfo(couponCode);
    this.personProduct = formatPersonProductData(result[0]);
  };

  @action
  orderProduct = async () => {
    this.productId = this.personProduct.id;
    const result = await orderProduct(this.productId);
    trackShowPaymentModalEvent();
    this.orderData = formatOrderData(result.data);
  };

  @action
  fetchPurchaseQrcode = async couponCode => {
    const result = await fetchPurchaseQrcode(
      this.orderData.orderId,
      couponCode
    );
    if (result.status === "success") {
      this.purchaseQrcode = result.message;
      trackPurchaseEvent(
        "checkout_progress",
        this.personProduct.name,
        this.personProduct.price
      );
    }
  };

  @action
  fetchPurchaseStatus = async () => {
    this.purchaseTimer = setInterval(async () => {
      const result = await fetchPurchaseStatus(this.orderData.orderId);
      if (result.status === 1) {
        if (this.purchaseTimer) {
          trackPurchaseEvent(
            "purchase",
            this.personProduct.name,
            this.personProduct.price
          );
          clearInterval(this.purchaseTimer);
          this.purchaseTimer = null;
          this.purchaseStatus = true;
        }
      }
    }, 1000);
  };

  @action
  fetchReducedPrice = async couponCode => {
    const result = await fetchReducedPrice(couponCode, this.productId);
    this.setIsDiscount(true);
    if (result.status === "success") {
      this.reducedPrice = result.finalPrice / 100;
      this.isCouponCodeValid = true;
    } else {
      // this.setIsDiscount(false);
      this.isCouponCodeValid = false;
      message.error(result.message);
    }
  };

  @computed
  get isHasPurchaseQrcode() {
    return _.isEmpty(this.purchaseQrcode);
  }

  @computed
  get showUpTips() {
    return +new Date() < +dayjs("2022-05-09 23:55:00");
  }
}

export default createContext(new PaymentStore());
