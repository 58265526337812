import React from "react";

const SVG = ({
  fill = "#0062FF",
  width = "100%",
  className = "",
  viewBox = "0 0 16 16",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd" transform="translate(-34 -577)">
      <path fill="#F4F4F6" d="M0 0H768V2120H0z" />
      <rect width="488" height="1663" x="14" y="331" fill="#FFF" rx="4" />
      <g>
        <path
          fill="#FFF"
          d="M8 15.2c0-.883.717-1.6 1.6-1.6h5.6V.8H9.6C8.717.8 8 1.517 8 2.4 8 1.517 7.283.8 6.4.8H.8v12.8h5.6c.883 0 1.6.717 1.6 1.6"
          transform="translate(34 577)"
        />
        <path
          fill={fill}
          d="M15.2 0H9.6C8.986 0 8.425.232 8 .613 7.575.232 7.014 0 6.4 0H.8C.358 0 0 .358 0 .8v12.8c0 .442.358.8.8.8h5.6c.442 0 .8.358.8.8 0 .442.358.8.8.8.442 0 .8-.358.8-.8 0-.442.358-.8.8-.8h5.6c.442 0 .8-.358.8-.8V.8c0-.442-.358-.8-.8-.8zm-.8 12.8H9.6c-.282 0-.55.054-.8.143V2.4c0-.442.358-.8.8-.8h4.8v11.2zm-8 0H1.6V1.6h4.8c.442 0 .8.358.8.8v10.543c-.25-.09-.518-.143-.8-.143zM4.8 3.2H4c-.442 0-.8.358-.8.8 0 .442.358.8.8.8h.8c.442 0 .8-.358.8-.8 0-.442-.358-.8-.8-.8m0 3.2H4c-.442 0-.8.358-.8.8 0 .442.358.8.8.8h.8c.442 0 .8-.358.8-.8 0-.442-.358-.8-.8-.8m6.4-1.6h.8c.442 0 .8-.358.8-.8 0-.442-.358-.8-.8-.8h-.8c-.442 0-.8.358-.8.8 0 .442.358.8.8.8m0 3.2h.8c.442 0 .8-.358.8-.8 0-.442-.358-.8-.8-.8h-.8c-.442 0-.8.358-.8.8 0 .442.358.8.8.8M4.8 9.6H4c-.442 0-.8.358-.8.8 0 .442.358.8.8.8h.8c.442 0 .8-.358.8-.8 0-.442-.358-.8-.8-.8"
          transform="translate(34 577)"
        />
      </g>
    </g>
  </svg>
);

export default SVG;
