import React, { useEffect, useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { Col, Row } from "antd";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import _ from "lodash";

import "./style.scss";
import DetailHeader from "./DetailHeader";
import DetailTabs from "./DetailTabs";
import DetailRelated from "./DetailRelated";
import {
  Sidebar,
  Breadcrumbs,
  AuthWallModal,
  PrivilegeModal,
  GuestTooltip,
} from "../shared";
import researchDetailStore from "../../stores/researchDetailStore";
import BreadcrumbStore from "../../stores/breadcrumbStore";
import AuthStore from "../../stores/authStore";
import { RESEARCH } from "../../constants/pro_constants";
import { labelToChinese } from "../../constants/label_to_chinese";
import researchDetailSubmit from "../../styles/images/research-detail-submit.png";

interface Props {
  id: string;
}

const ResearchDetail: React.FC<Props> = (props: Props) => {
  const currentPath = location.pathname;
  const {
    data,
    isRelatedInstitutionsEmpty,
    isRelatedSolutionsEmpty,
    initializeId,
    initializeData,
    authWallModalTitle,
    authWallModalDesc,
    isShowAuthWallModal,
    setIsShowAuthWallModal,
    forbiddenModal,
    trialExpiredModal,
    vipExpiredModal,
    setForbiddenModal,
    setTrialExpiredModal,
    setVipExpiredModal,
  } = useContext(researchDetailStore);

  const {
    isCurrentUserEmpty,
    isAlphaUserWithoutStartTrial,
    isAlphaUser,
    isTrialExpired,
    isExpiredVipUser,
    isExpiredInstitutionUser,
  } = useContext(AuthStore);

  const isForbidden =
    !isCurrentUserEmpty && (isAlphaUserWithoutStartTrial || !isAlphaUser);
  const isVipExpired = isExpiredVipUser || isExpiredInstitutionUser;
  const { breadcrumbMap, initializeBreadcrumbs } = useContext(BreadcrumbStore);
  const [guestTooltipShow, setGuestTooltipShow] = useState(true);
  const history = useHistory();

  useEffect(() => {
    initializeId(props.id);
    initializeData();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      initializeBreadcrumbs(currentPath, data.header.title);
    }
    window.sessionStorage.setItem("path", `/research/${props.id}`);
  }, [data]);

  return (
    <>
      {isCurrentUserEmpty && guestTooltipShow && (
        <GuestTooltip onClose={() => setGuestTooltipShow(false)} />
      )}
      <div
        className={`research-detail-layout ${
          isCurrentUserEmpty && guestTooltipShow
            ? "detail-layout__tooltip--show"
            : ""
        }`}
      >
        {!_.isEmpty(data) && (
          <Helmet>
            <title>
              {data.header.title}｜{labelToChinese[RESEARCH]}
            </title>
          </Helmet>
        )}

        <div
          className={`research-detail-layout__sidebar ${
            isCurrentUserEmpty && guestTooltipShow
              ? "detail-layout__sidebar__tooltip--show"
              : ""
          }`}
        >
          <Sidebar />
        </div>
        <div
          className={`pro-layout__content-wrapper ${
            isCurrentUserEmpty && guestTooltipShow
              ? "pro-layout__content-wrapper__tooltip--show"
              : ""
          }`}
        >
          <div
            className="research-detail-layout__container"
            id="research-detail"
          >
            <Breadcrumbs items={breadcrumbMap} />
            <Row className="research-detail-layout__content">
              <Col span={16}>
                <DetailHeader />
                {!_.isEmpty(data) && <DetailTabs />}
              </Col>
              <Col span={8}>{!_.isEmpty(data) && <DetailRelated />}</Col>
            </Row>
            <div className="research-detail-layout__content--mobile">
              <DetailHeader />
              {!_.isEmpty(data) && (
                <div className="research-detail-related--mobile">
                  <a
                    className="research-detail-related-submit--mobile"
                    target="_blank"
                    rel="noopener"
                    onClick={() => {
                      if (isCurrentUserEmpty) {
                        history.push("/login");
                        return;
                      }
                      if (isForbidden || isTrialExpired || isVipExpired) {
                        setForbiddenModal(isForbidden);
                        setTrialExpiredModal(isTrialExpired);
                        setVipExpiredModal(isVipExpired);
                        return;
                      }
                      window.open(
                        "https://www.jiqizhixin.com/short_urls/46a839bc-a3e5-4dd1-9763-46bc7c0efadc"
                      );
                    }}
                  >
                    <img
                      src={researchDetailSubmit}
                      alt="没找到您需要的报告？"
                    />
                  </a>
                </div>
              )}
              {!_.isEmpty(data) && <DetailTabs />}
              {!_.isEmpty(data) &&
                (!isRelatedInstitutionsEmpty || !isRelatedSolutionsEmpty) && (
                  <DetailRelated />
                )}
            </div>
            <AuthWallModal
              title={authWallModalTitle}
              description={authWallModalDesc}
              visible={isShowAuthWallModal}
              label="research"
              onCancel={() => setIsShowAuthWallModal(false)}
            />
            {forbiddenModal && (
              <PrivilegeModal
                visible={forbiddenModal}
                title="您没有机器之心 Pro 使用权限"
                userType="forbidden"
                onCancel={() => {
                  setForbiddenModal(false);
                }}
              />
            )}
            {trialExpiredModal && (
              <PrivilegeModal
                visible={trialExpiredModal}
                title="您的试用已到期"
                userType="trial-expired"
                onCancel={() => {
                  setTrialExpiredModal(false);
                }}
              />
            )}
            {vipExpiredModal && (
              <PrivilegeModal
                visible={vipExpiredModal}
                title="您的 Pro 会员已到期"
                userType="trial-expired"
                onCancel={() => {
                  setVipExpiredModal(false);
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(ResearchDetail);
