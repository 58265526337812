import React, { useContext, useState } from "react";
import { observer } from "mobx-react";
import { Table, Skeleton } from "antd";
import databaseStore from "../../stores/databaseStore";
import { Icon, AuthWallModal, AuthWallPanel } from "../shared";
import {
  TRIGGER_DESC,
  TRIGGER_ASC,
  CACCEL_SORT,
  UPLOADED_AT,
  DATA_COUNT,
  DATA_AMOUNT,
} from "../../constants/pro_constants";
import { labelToChinese } from "../../constants/label_to_chinese";
import authStore from "../../stores/authStore";
import { trackDatabaseEvent } from "../../utils/ga_events";

const QualityObj = { 高: 3, 中: 2, 低: 1 };

const QualityShow = {
  高: (
    <>
      {[1, 2, 3].map(element => (
        <Icon name="pro-star" color="#FF8D00" key={element} />
      ))}
    </>
  ),
  中: (
    <>
      {[1, 2].map(element => (
        <Icon name="pro-star" color="#FF8D00" key={element} />
      ))}
      <Icon name="pro-star" color="#E2E2EB" />
    </>
  ),
  低: (
    <>
      {[1, 2, 3].map(element => (
        <Icon name="pro-star" color="#E2E2EB" key={element} />
      ))}
    </>
  ),
};

const customSkeleton = (
  <Skeleton paragraph={{ rows: 1, width: "80%" }} active />
);

const DatasetResultPanel = () => {
  const { isTrialUser } = useContext(authStore);
  const [isShowAuthWallModal, setIsShowAuthWallModal] = useState(false);
  const [downloadColor, setDownloadColor] = useState({});
  const isShowModal = isTrialUser && isShowAuthWallModal;

  const {
    type,
    resultList: { data, count },
    pagination,
    sortField,
    sortInfo,
    isLoading,
    fetchDatabaseList,
    setSortField,
    setSortOrderInfo,
  } = useContext(databaseStore);

  const columns = [
    {
      title: "数据包名称",
      dataIndex: "name",
      ellipsis: true,
      sorter: true,
      sortOrder: sortInfo.field == "name" && sortInfo.order,
      width: "30%",
      className: "database-list-panel__solution__name",
      render: data =>
        !isLoading
          ? (
              <span>
                {data.isLasted && <Icon name="pro-new" />}
                {data.name}
              </span>
            ) || "-"
          : customSkeleton,
    },
    {
      title: "数据来源",
      dataIndex: "source",
      ellipsis: true,
      sorter: true,
      sortOrder: sortInfo.field == "source" && sortInfo.order,
      width: "15%",
      render: data => (!isLoading ? data || "-" : customSkeleton),
    },
    {
      title: "大小",
      dataIndex: "size",
      ellipsis: true,
      sorter: true,
      sortOrder: sortInfo.field == "size" && sortInfo.order,
      width: "10%",
      render: data => (!isLoading ? data || "-" : customSkeleton),
    },
    {
      title: "数据内容",
      dataIndex: "summary",
      ellipsis: true,
      sorter: true,
      sortOrder: sortInfo.field == "summary" && sortInfo.order,
      width: "23%",
      render: data => (!isLoading ? data || "-" : customSkeleton),
    },
    {
      title: "数据质量",
      dataIndex: "quantity",
      ellipsis: true,
      sorter: true,
      sortOrder: sortInfo.field == "quantity" && sortInfo.order,
      width: "10%",
      className: "database-list-panel__dataset__quality",
      render: data => (!isLoading ? QualityShow[data] || "-" : customSkeleton),
    },
    {
      title: "操作",
      key: "action",
      render: record => {
        return isTrialUser ? (
          <a
            onClick={() => {
              setIsShowAuthWallModal(true);
            }}
            onMouseEnter={() => {
              setDownloadColor({ [record.key]: "#0062ff" });
            }}
            onMouseLeave={() => {
              setDownloadColor({ [record.key]: "#C6CBD4" });
            }}
            className="database-list-panel__dataset__action"
          >
            <Icon
              name="pro-file-download"
              size={16}
              color={downloadColor[record.key]}
            />
          </a>
        ) : (
          <a
            download
            href={record.fileUrl}
            className="database-list-panel__dataset__action"
            onClick={e => {
              e.stopPropagation();
              trackDatabaseEvent(
                "download",
                "Dataset",
                record.key,
                QualityObj[record.quantity]
              );
            }}
            onMouseEnter={() => {
              setDownloadColor({ [record.key]: "#0062ff" });
            }}
            onMouseLeave={() => {
              setDownloadColor({ [record.key]: "#C6CBD4" });
            }}
          >
            <Icon
              name="pro-file-download"
              size={16}
              color={downloadColor[record.key]}
            />
          </a>
        );
      },
    },
  ];

  return (
    <div className="database-list-panel">
      <Table
        columns={columns}
        locale={{
          triggerDesc: labelToChinese[TRIGGER_DESC],
          triggerAsc: labelToChinese[TRIGGER_ASC],
          cancelSort: labelToChinese[CACCEL_SORT],
        }}
        expandable={{
          expandRowByClick: true,
          // expandIcon: () => null,
          expandedRowRender: record => (
            <div className="dataset-detail">
              <div className="dataset-detail__firstline">
                <div className="dataset-detail__item">
                  <span className="dataset-detail__label">时间范围：</span>
                  <span className="dataset-detail__value">
                    {record.startTime}至{record.endTime}
                  </span>
                </div>
                <div className="dataset-detail__item">
                  <span className="dataset-detail__label">文件格式：</span>
                  <span className="dataset-detail__value">
                    {record.fileType}
                  </span>
                </div>
                <div className="dataset-detail__item">
                  <span className="dataset-detail__label">数据条目：</span>
                  <span className="dataset-detail__value">{record.amount}</span>
                </div>
              </div>
              <p className="dataset-detail__item">
                <span className="dataset-detail__label">内容简介：</span>
                <span className="dataset-detail__value">{record.content}</span>
              </p>
            </div>
          ),
        }}
        dataSource={data}
        title={() => (
          <div className="database-list-panel__header">
            <div className="database-list-panel__title">
              找到 {count} 条结果
            </div>
            <div className="database-list-panel__action">
              <span>排序方式：</span>
              <a
                className={`${sortField == UPLOADED_AT ? "active" : ""}`}
                onClick={() => setSortField(UPLOADED_AT)}
              >
                {labelToChinese[UPLOADED_AT]}
              </a>
              <a
                className={`${sortField == DATA_AMOUNT ? "active" : ""}`}
                onClick={() => setSortField(DATA_AMOUNT)}
              >
                {labelToChinese[DATA_COUNT]}
              </a>
            </div>
          </div>
        )}
        pagination={pagination}
        onChange={(pagination, filters, sorter) => {
          setSortOrderInfo(sorter);
          fetchDatabaseList(pagination, sorter);
        }}
      />
      {isShowModal && (
        <AuthWallModal
          visible={isShowModal}
          title="无法获取数据包"
          description={`您当前的用户类型为"试用用户"，不支持数据包下载功能。点击"成为会员"，获得全部数据访问权限，解锁更多高级功能。`}
          label={type}
          onCancel={() => {
            setIsShowAuthWallModal(false);
          }}
        />
      )}
      {isTrialUser && <AuthWallPanel label={type} />}
    </div>
  );
};

export default observer(DatasetResultPanel);
