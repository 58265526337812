import { createContext } from "react";
import { observable, action, computed } from "mobx";
import _ from "lodash";
import { fetchDatabaseDetail, fetchAssetsData } from "../api";
import {
  formatDetailData,
  formatAssetsData,
} from "../utils/formatDatabaseDetailData";
import {
  CLIENTS,
  CORE_MEMBER,
  COOPERATION_INSTITUTION,
  ASSETS_EVENT,
  CASE_DETAIL,
  RELATED_FILE,
  CORE_INSTITUTION,
  PROJECT_BACKGROUND,
  INSTITUTION_INTRO,
  PRODUCT_SOLUTIONS,
  SOLUTION_DESC,
  SOLUTION_FEATURE,
  SOLUTION_TECHNOLOGY,
  SOLUTION_MATERIAL,
  SOLUTION_BUSINESS_CASE,
  INSTITUTION,
  BUSINESS_CASE,
  SOLUTION,
} from "../constants/pro_constants";
import { labelToChinese } from "../constants/label_to_chinese";
import { trackDatabaseEvent } from "../utils/ga_events";

class DatabseDetailStore {
  @observable id = "";
  @observable type = "";
  @observable data = null;
  @observable assetsData = [];
  @observable scrolledDomId = "";
  @observable isShowAll = false;

  @action
  initializeType = type => {
    this.type = type;
  };

  @action
  initializeId = id => {
    this.id = id;
  };

  upperCapitalLetter = str => {
    return str
      .toLowerCase()
      .replace(/( |^)[a-z]/g, element => element.toUpperCase());
  };

  @action
  initializeData = async () => {
    this.data = null;
    const result = await fetchDatabaseDetail(this.id);
    this.data = formatDetailData(this.type, result);
    trackDatabaseEvent(
      "view_info",
      this.upperCapitalLetter(this.type),
      `${this.data.header.name}#${this.id}`,
      this.data.level
    );
    // this.isShowAll = this.data.level === "level3";
    this.isShowAll = true;
  };

  @action
  initializeAssetsData = async () => {
    const result = await fetchAssetsData(this.id);
    this.assetsData = formatAssetsData(result.data);
  };

  @action
  initializeScrolledDomId = () => {
    this.scrolledDomId = this.currentTabs.find(item => !item.isHide).key;
  };

  @action
  scrollTo = key => {
    this.scrolledDomId = key;
    let dom = document.getElementById(this.scrolledDomId);
    let scrollArea = document.querySelector("#database-detail");

    scrollArea.scrollTo({ top: dom.offsetTop - 130, behavior: "smooth" });
  };

  @action
  setRichImgStyle = () => {
    const intro = document.getElementsByClassName(
      "detail-layout__info-tabs"
    )[0];
    const width = intro ? (intro.offsetWidth * 2) / 3 : 0;
    const content = document.getElementsByClassName(
      "database-detail__block__content"
    );
    const img =
      content.length > 0
        ? [...content].reduce(
            (total, current) =>
              total.concat([...current.getElementsByTagName("img")]),
            []
          )
        : null;
    if (width && img) {
      img.forEach(item => {
        item.style.maxWidth = `${width}px`;
        item.style.height = "auto";
      });
    }
  };

  @computed
  get currentTabs() {
    let tabs = [];

    if (this.type === INSTITUTION) {
      tabs = this.institutionTabs;
    }

    if (this.type === BUSINESS_CASE) {
      tabs = this.businessCaseTabs;
    }

    if (this.type === SOLUTION) {
      tabs = this.solutionTabs;
    }

    return tabs;
  }

  @computed
  get institutionTabs() {
    return [
      {
        key: INSTITUTION_INTRO,
        name: labelToChinese[INSTITUTION_INTRO],
        isHide: false,
      },
      {
        key: PRODUCT_SOLUTIONS,
        name: labelToChinese[PRODUCT_SOLUTIONS],
        isHide: false,
      },
      {
        key: CLIENTS,
        name: labelToChinese[CLIENTS],
        isHide: this.isClientsTabContentEmpty,
      },
      {
        key: CORE_MEMBER,
        name: labelToChinese[CORE_MEMBER],
        isHide: this.isCoreMemberTabContentEmpty,
      },
      {
        key: COOPERATION_INSTITUTION,
        name: labelToChinese[COOPERATION_INSTITUTION],
        isHide: this.isCooperationInstitutionTabContentEmpty,
      },
      {
        key: ASSETS_EVENT,
        name: labelToChinese[ASSETS_EVENT],
        isHide: this.isAssetsEventTabContentEmpty,
      },
    ];
  }

  @computed
  get isInstitutionIntroTabContentEmpty() {
    return _.isEmpty(this.data.tabs.institutionIntro);
  }

  @computed
  get isProductSolutionsTabContentEmpty() {
    return _.isEmpty(this.data.tabs.productSolutions);
  }

  @computed
  get isClientsTabContentEmpty() {
    return _.isEmpty(this.data.tabs.clients);
  }

  @computed
  get isCoreMemberTabContentEmpty() {
    return _.isEmpty(this.data.tabs.coreMembers);
  }

  @computed
  get isCooperationInstitutionTabContentEmpty() {
    return _.isEmpty(this.data.tabs.cooperatePartners);
  }

  @computed
  get isAssetsEventTabContentEmpty() {
    return _.isEmpty(this.assetsData);
  }

  @computed
  get solutionTabs() {
    return [
      {
        key: SOLUTION_DESC,
        name: labelToChinese[SOLUTION_DESC],
        isHide: false,
      },
      {
        key: SOLUTION_FEATURE,
        name: labelToChinese[SOLUTION_FEATURE],
        isHide: false,
      },
      {
        key: SOLUTION_TECHNOLOGY,
        name: labelToChinese[SOLUTION_TECHNOLOGY],
        isHide: this.isSolutionTechnologyTabContentEmpty,
      },
      {
        key: SOLUTION_MATERIAL,
        name: labelToChinese[SOLUTION_MATERIAL],
        isHide: this.isSolutionMaterialTabContentEmpty,
      },
      {
        key: SOLUTION_BUSINESS_CASE,
        name: labelToChinese[SOLUTION_BUSINESS_CASE],
        isHide: this.isSolutionBusinessCaseTabContentEmpty,
      },
    ];
  }

  @computed
  get isSolutionDescTabContentEmpty() {
    return _.isEmpty(this.data.tabs.solutionIntro);
  }

  @computed
  get isSolutionFeatureTabContentEmpty() {
    return _.isEmpty(this.data.tabs.solutionFeature);
  }

  @computed
  get isSolutionTechnologyTabContentEmpty() {
    return _.isEmpty(this.data.tabs.techArchitecture);
  }

  @computed
  get isSolutionMaterialTabContentEmpty() {
    return _.isEmpty(
      this.data.tabs.relatedFile && this.data.tabs.relatedFile.name
    );
  }

  @computed
  get isSolutionBusinessCaseTabContentEmpty() {
    return _.isEmpty(this.data.tabs.businessCases);
  }

  @computed
  get businessCaseTabs() {
    return [
      {
        key: CASE_DETAIL,
        name: labelToChinese[CASE_DETAIL],
        isHide: this.isCaseDetailTabContentEmpty,
      },
      {
        key: PROJECT_BACKGROUND,
        name: labelToChinese[PROJECT_BACKGROUND],
        isHide: false,
      },
      {
        key: CORE_INSTITUTION,
        name: labelToChinese[CORE_INSTITUTION],
        isHide: this.isCoreInstitutionTabContentEmpty,
      },
      {
        key: RELATED_FILE,
        name: labelToChinese[RELATED_FILE],
        isHide: this.isRelatedFileTabContentEmpty,
      },
    ];
  }

  @computed
  get isCaseDetailTabContentEmpty() {
    return _.isEmpty(this.data.tabs.caseDetailIntro);
  }

  @computed
  get isProjectBackgroundTabContentEmpty() {
    return _.isEmpty(this.data.tabs.projectBackground);
  }

  @computed
  get isCoreInstitutionTabContentEmpty() {
    return _.isEmpty(this.data.tabs.coreInstitution);
  }

  @computed
  get isRelatedFileTabContentEmpty() {
    return _.isEmpty(
      this.data.tabs.relatedFile && this.data.tabs.relatedFile.name
    );
  }

  @computed
  get isNewsflashContentEmpty() {
    return _.isEmpty(this.data.newsflash);
  }
}

export default createContext(new DatabseDetailStore());
