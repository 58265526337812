import homeData from "../styles/images/home-data.svg";
import homeResearch from "../styles/images/home-research.svg";
import homeCustomize from "../styles/images/home-customize.svg";
import homeNews from "../styles/images/home-news.svg";
import homeCommunication from "../styles/images/home-communication.svg";

export const LandingItems = [
  {
    image: homeData,
    title: "数据检索",
    description: "<span>覆盖万条高质量数据</span>",
    vipRequired: false,
    linkUrl: "database/institutions",
  },
  {
    image: homeResearch,
    title: "查找报告",
    description:
      "<span>收录超过<span style='color: #0062ff; font-weight: 500;'> 800 </span>份研究内容</span>",
    vipRequired: false,
    linkUrl: "/research",
  },
  {
    image: homeNews,
    title: "趋势洞察",
    description: "<span>实时更新的行业资讯及专业精选深度内容</span>",
    vipRequired: false,
    linkUrl: "/trend/insight",
  },
  {
    image: homeCustomize,
    title: "定制研究",
    description: "<span>专业分析师为您提供定制研究服务</span>",
    vipRequired: true,
    linkUrl:
      "https://www.jiqizhixin.com/short_urls/46a839bc-a3e5-4dd1-9763-46bc7c0efadc",
  },
  {
    image: homeCommunication,
    title: "职业交流",
    description:
      "<span>链接超过<span style='color: #0062ff; font-weight: 500;'> 6万 </span>名资深AI从业者</span>",
    vipRequired: false,
    linkUrl: "/communication",
  },
];
