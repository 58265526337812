import React from "react";
import { JIN_SHU_JU } from "../../constants/jinshuju";

const JinShuJu = ({ data, form }) => {
  let fields = [];

  if (data) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (/^field_/.test(key)) {
          fields.push({
            key,
            field: JIN_SHU_JU[form.jinshujuId]
              ? JIN_SHU_JU[form.jinshujuId][key]
              : key,
            value: data[key],
          });
        }
      }
    }
  }

  return (
    <>
      {fields.map(item => (
        <div
          className="selection-rating-modules-form--block mike-block"
          key={item.key}
        >
          <div className="selection-rating-modules-form--field">
            {item.field}:
          </div>
          <div className="selection-rating-modules-form--value">
            {Array.isArray(item.value) ? item.value.join("、") : item.value}
          </div>
        </div>
      ))}
    </>
  );
};

export default JinShuJu;
