import React, { useContext } from "react";
import { observer } from "mobx-react";
import { Input } from "antd";
import { Icon } from "../shared";
import researchStore from "../../stores/researchStore";

const SearchPanel = () => {
  const { updateResearchListByKeywords } = useContext(researchStore);

  return (
    <div className="research-filter-panel">
      <Input
        placeholder={"请输入关键词查找相关研究内容，例如“物流”"}
        type="text"
        onPressEnter={e => updateResearchListByKeywords(e.target.value)}
        prefix={<Icon name="pro-search" size={18} />}
      />
    </div>
  );
};

export default observer(SearchPanel);
