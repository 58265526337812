import React from "react";
import { Modal } from "antd";

import "./style.scss";
import closeIcon from "../../../styles/images/assistant-close.svg";
import qrCode from "../../../styles/images/member-qr-code.png";

interface Props {
  visible: boolean;
  onCancel: () => void;
  desc?: string;
  imgUrl?: string;
}

const AssistantCodeModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel = () => {}, desc = "", imgUrl = qrCode } = props;

  return (
    <Modal
      className="assistant-code-modal"
      style={{ top: "25%", padding: 0 }}
      width={335}
      visible={visible}
      footer={null}
      closeIcon={
        <img src={closeIcon} style={{ width: "12px", height: "12px" }} />
      }
      onCancel={onCancel}
    >
      <div className="assistant-code-modal__image">
        <img src={imgUrl} alt="code" />
      </div>
      {desc && <div className="assistant-code-modal__content">{desc}</div>}
    </Modal>
  );
};

export default AssistantCodeModal;
