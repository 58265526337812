import { createContext } from "react";
import { observable, computed, action } from "mobx";
import _ from "lodash";
import {
  formatSubscribeList,
  formatSubscribeDetail,
} from "../utils/formatSubscribeData";
import {
  fetchSubscribeDetail,
  fetchSubscribeList,
  userSubscribe,
} from "../api";
import { trackSubscribeEvent } from "../utils/ga_events";
class SubscribeStore {
  @observable list = [];
  @observable subscribeDetail = null;
  @observable isDrawerVisible = false;
  @observable selectedId = "";
  @observable isSubscribed = false;
  @observable email = "";
  @observable subscribedCount = 0;
  @observable currentSubscribeTitle = "";

  @action
  fetchSubscribe = async () => {
    const result = await fetchSubscribeList();
    this.list = formatSubscribeList(result.data);
    trackSubscribeEvent("view_list", "", "");
  };

  @action
  initialSubscribeDetail = async () => {
    const result = await fetchSubscribeDetail(this.selectedId);
    this.subscribeDetail = formatSubscribeDetail(result.column);
    this.isSubscribed = result.column.isSubscribe;
    this.email = result.column.email;
    this.subscribedCount = result.column.subscribedCount;
    this.currentSubscribeTitle = this.subscribeDetail.meta.title;
    trackSubscribeEvent(
      "view_item",
      `${this.currentSubscribeTitle}#${this.selectedId}`,
      ""
    );
  };

  selectSubscribe = item => {
    this.isDrawerVisible = true;
    this.selectedId = item.id;
  };

  @action
  handleDrawerClose = () => {
    this.isDrawerVisible = false;
  };

  @action
  updateSubscribeList = (isSubscribe: boolean) => {
    const updatedSubscribedList = this.list.map(item => {
      if (item.id === this.selectedId) {
        item.isSubscribe = isSubscribe;
      }
      return item;
    });

    this.list = updatedSubscribedList;
  };

  @action
  handleSubscribe = async () => {
    await userSubscribe(this.selectedId, response => {
      if (response.body.data.message === "订阅成功") {
        this.isSubscribed = true;
        this.email = response.body.data.email;
        this.updateSubscribeList(true);
        trackSubscribeEvent(
          "subscribe",
          `${this.currentSubscribeTitle}#${this.selectedId}`,
          ""
        );
        return;
      }
      if (response.body.data.message === "已取消订阅") {
        this.isSubscribed = false;
        this.updateSubscribeList(false);
        trackSubscribeEvent(
          "unSubscribe",
          `${this.currentSubscribeTitle}#${this.selectedId}`,
          ""
        );
        return;
      }
    });
  };

  @computed
  get subscribedListCount() {
    return this.list.filter(item => item.isSubscribe).length;
  }

  @computed
  get subscribedList() {
    return this.list.filter(item => item.isSubscribe);
  }

  @computed
  get unSubscribedList() {
    return this.list.filter(item => !item.isSubscribe);
  }

  @computed
  get isSubscribedListEmpty() {
    return _.isEmpty(this.list.filter(item => item.isSubscribe));
  }

  @computed
  get isUnsubscribedListEmpty() {
    return _.isEmpty(this.list.filter(item => !item.isSubscribe));
  }

  @computed
  get meta() {
    return this.subscribeDetail.meta;
  }

  @computed
  get receiveList() {
    return this.subscribeDetail.receiveList;
  }

  @computed
  get archieveList() {
    return this.subscribeDetail.archieveList;
  }

  @computed
  get trialList() {
    return this.subscribeDetail.trialList;
  }

  @computed
  get hasExtraSubscribe() {
    return this.subscribeDetail && this.subscribeDetail.leftCount > 0;
  }

  @computed
  get isCantSubscribe() {
    return this.subscribeDetail && this.subscribeDetail.subscribeColumnSize > 0;
  }
}

export default createContext(new SubscribeStore());
