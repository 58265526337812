import moment from "moment";

const formatSubscribeList = initData => {
  return initData.map(item => ({
    id: item.id,
    title: item.title,
    type: item.columnType,
    count: item.currentCount,
    technologies: item.fields,
    keywords: item.keywords,
    isSubscribe: item.isSubscribe,
  }));
};

const formatSubscribeDetail = initData => {
  return {
    meta: {
      title: initData.title,
      totalCount: initData.totalCount,
      count: initData.currentCount,
      latestUpdateDate: moment(initData.updatedAt).fromNow(),
      technologies: initData.fields,
      keywords: initData.keywords,
    },
    subscribeColumnCount: initData.subscribeColumnSize,
    subscribedCount: initData.subscribedCount,
    leftCount: initData.leftCount,
    allFileUrl: initData.downAllFile,
    trialList: initData.readings
      .sort((pre, next) => compareDate(pre, next))
      .map(item => {
        return {
          name: item.title,
          volume: item.issue,
          date: moment(item.updatedAt).fromNow(),
          fileUrl: item.fileUrl,
        };
      }),
    receiveList: initData.subscriptions
      .sort((pre, next) => compareDate(pre, next))
      .map(item => {
        return {
          name: item.title,
          volume: item.issue,
          date: moment(item.updatedAt).fromNow(),
          fileUrl: item.fileUrl,
        };
      }),
    archieveList: initData.archives
      .sort((pre, next) => compareDate(pre, next))
      .map(item => {
        return {
          name: item.title,
          volume: item.issue,
          date: moment(item.updatedAt).fromNow(),
          fileUrl: item.fileUrl,
        };
      }),
  };
};

const compareDate = (pre, next) => {
  if (next.updatedAt > pre.updatedAt) {
    return 1;
  }
  if (next.updatedAt < pre.updatedAt) {
    return -1;
  }
  return 0;
};

export { formatSubscribeList, formatSubscribeDetail };
