import React from "react";

const SVG = ({
  fill = "#0062FF",
  width = "100%",
  className = "",
  viewBox = "0 0 16 16",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd">
      <g fill={fill}>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <path
                    stroke={fill}
                    strokeWidth=".5"
                    d="M1.39 12.205c.038.014.07.027.103.038.664.207 1.327.416 1.992.618.207.062.336.182.4.39.2.66.406 1.317.611 1.976.015.046.037.09.055.136l-.024-.047 2.11-2.096c-1.554-.357-2.816-1.202-3.776-2.495l-1.47 1.48m10.077 3.134c.008-.022.026-.065.04-.11.202-.652.407-1.303.605-1.957.062-.205.176-.342.385-.405.58-.174 1.16-.354 1.739-.533.127-.04.254-.085.36-.121-.49-.491-.976-.977-1.47-1.469-.949 1.281-2.213 2.122-3.776 2.48l2.117 2.115m-8.84-8.29c-.014 2.931 2.384 5.355 5.316 5.373 3.001.018 5.422-2.372 5.435-5.365.012-2.95-2.403-5.377-5.364-5.39-2.958-.013-5.372 2.399-5.387 5.382M8.012.732c3.064.003 5.698 2.23 6.213 5.245.223 1.307.05 2.558-.51 3.756-.054.114-.036.175.049.259.677.67 1.349 1.345 2.023 2.017.137.136.244.283.205.487-.04.206-.17.328-.372.39-.83.254-1.657.516-2.488.768-.122.037-.185.094-.223.218-.249.825-.507 1.647-.762 2.47-.134.435-.53.536-.852.214-1.02-1.017-2.037-2.037-3.056-3.056-.193-.194-.28-.193-.474 0l-3.03 3.032c-.357.356-.737.26-.887-.225-.252-.812-.507-1.622-.754-2.436-.038-.124-.103-.18-.224-.218-.824-.25-1.647-.508-2.47-.765-.428-.133-.532-.52-.217-.839.679-.685 1.361-1.367 2.048-2.045.095-.094.11-.163.051-.287-1.142-2.38-.609-5.33 1.289-7.161C4.81 1.36 6.343.73 8.01.732"
                    transform="translate(-250 -409) translate(220 109) translate(30 296) translate(0 3) translate(0 .268)"
                  />
                  <path
                    d="M9.643 6.855c-.813-.062-1.323-.497-1.648-1.244-.307.776-.846 1.169-1.643 1.245.61.55.79 1.211.604 2.005.381-.126.709-.319 1.037-.32.333 0 .666.189 1.055.313-.2-.8-.002-1.45.595-1.999zM5.828 8.991V8.97c.148-.612-.065-1.096-.477-1.52-.149-.154-.267-.339-.396-.511-.294-.394-.156-.856.306-1 .388-.121.779-.234 1.172-.337.172-.045.294-.127.39-.288.214-.36.443-.71.67-1.06.28-.429.735-.428 1.015.003.22.338.441.675.647 1.022.101.172.226.275.416.323.373.094.741.207 1.11.318.544.165.676.612.322 1.073-.258.337-.518.672-.766 1.017-.059.082-.1.202-.098.303.007.438.033.876.052 1.314.024.545-.339.833-.833.651-.403-.148-.8-.314-1.205-.456-.092-.033-.214-.032-.307.001-.404.143-.802.307-1.205.455-.495.182-.85-.101-.834-.651.007-.211.002-.423.002-.635h.02z"
                    transform="translate(-250 -409) translate(220 109) translate(30 296) translate(0 3) translate(0 .268)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
