import React, { useContext, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";

import "./style.scss";
import { Header, Footer, Button } from "../shared";
import membershipStore from "../../stores/membershipStore";
import AuthStore from "../../stores/authStore";
import { observer } from "mobx-react";

const Membership: React.FC = () => {
  const history = useHistory();
  const { currentUser } = useContext(AuthStore);
  const {
    loading,
    available,
    src,
    title,
    description,
    descriptionEnd,
    getAlphaInfo,
    getMembership,
  } = useContext(membershipStore);

  if (!currentUser) {
    history.push({
      pathname: "/login",
      params: {
        url: "membership",
      },
    });
    return;
  }

  useEffect(() => {
    getAlphaInfo();
  }, []);

  return (
    <div className="header-footer--verticality-layout">
      <Header />
      {loading ? (
        ""
      ) : (
        <div className="pro-membership-content">
          <img className="pro-membership-crown" src={src} />
          <p className="pro-membership-title">{title}</p>
          <p className="pro-membership-description">
            <p>{description}</p>
            <p>{descriptionEnd}</p>
          </p>
          {available ? (
            <Link to="/home">
              <Button size="large" grid={16}>
                开始使用
              </Button>
            </Link>
          ) : (
            <Button onClick={getMembership} size="large" grid={16}>
              立即领取
            </Button>
          )}
        </div>
      )}
      <Footer />
    </div>
  );
};

export default observer(Membership);
