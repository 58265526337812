import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";

import databaseDetailStore from "../../stores/databaseDetailStore";
import { Button, Tag, Icon, Collapse, IconHeading } from "../shared";
import globalAward from "../../styles/images/global-award.svg";
import { PROTOCOL, TOP_DOMAIN } from "../../api";
import rank2020 from "../../styles/images/rank-2020.png";
import rank2021 from "../../styles/images/rank-2021.png";
import rank2022 from "../../styles/images/rank-2022.png";

const SolutionHeader = () => {
  const {
    data: { header },
  } = useContext(databaseDetailStore);

  const hasTechnologies = header.technologies.length > 0;

  return (
    <div className="solution-header">
      <div className="solution-header__title">
        <div>
          <div className="solution-header__title__name">{header.name}</div>
          <div className="solution-header__title__tags">
            {header.fields.map((field, i) => (
              <Tag key={i}>{field}</Tag>
            ))}
          </div>
        </div>
        <Button
          size="small"
          grid={4}
          type="ghost"
          onClick={() => {
            window.open(
              `${PROTOCOL}//jigou.${TOP_DOMAIN}?iid=${header.producer.id}`
            );
          }}
        >
          寻求合作
        </Button>
      </div>
      <Collapse line="three" height={70} className="solution-header__summary">
        {header.summary}
      </Collapse>

      {hasTechnologies && (
        <div className="solution-header__keywords">
          核心技术：
          {header.technologies.map(item => {
            return <span key={item.id}>{item.name}</span>;
          })}
        </div>
      )}

      {header.producer && (
        <div className="solution-header__producer">
          <span className="solution-header__producer__title">提供方</span>
          <div className="solution-header__producer-content">
            <Link
              to={`/database/institutions/${header.producer.id}`}
              className="solution-header__producer__avtar"
              target="_blank"
            >
              <img src={header.producer.avatar_url} alt="" />
            </Link>
            <div className="solution-header__producer__brief">
              <Link
                to={`/database/institutions/${header.producer.id}`}
                target="_blank"
              >
                {header.producer.name}
              </Link>
              {header.producer.assigned && <Icon name="pro-safe" />}
              {header.producer.awards2019 && <Icon name="pro-award" />}
              {header.producer.fields.slice(0, 5).map((field, i) => (
                <Tag key={i}>{field}</Tag>
              ))}
              <Collapse
                line="two"
                height={50}
                className="solution-header__producer__summary"
              >
                {header.producer.summary}
              </Collapse>
            </div>
          </div>
        </div>
      )}
      {((header.award2019List && header.award2019List.length > 0) ||
        (header.award2020List && header.award2020List.length > 0) ||
        (header.award2021List && header.award2021List.length > 0) ||
        (header.award2022List && header.award2022List.length > 0) ||
        (header.award2023List && header.award2023List.length > 0) ||
        (header.award2024List && header.award2024List.length > 0)) && (
        <div className="institution-header__awards">
          <IconHeading icon="pro-institution-honour" title="相关荣誉" />
          <div className="institution-header__awards__list">
            {header.award2022List &&
              header.award2022List.map(item => {
                return (
                  <div key={item} className="institution-header__awards__item">
                    <img src={rank2022} alt="awards" />
                    <span className="institution-header__awards--2022">
                      {item}
                    </span>
                  </div>
                );
              })}
            {header.award2021List &&
              header.award2021List.map(item => {
                return (
                  <div key={item} className="institution-header__awards__item">
                    <img src={rank2021} alt="awards" />
                    <span className="institution-header__awards--2021">
                      {item}
                    </span>
                  </div>
                );
              })}
            {header.award2020List &&
              header.award2020List.map(item => {
                return (
                  <div key={item} className="institution-header__awards__item">
                    <img src={rank2020} alt="awards" />
                    <span className="institution-header__awards--one">
                      {item}
                    </span>
                  </div>
                );
              })}
            {header.award2019List &&
              header.award2019List.map(item => {
                return (
                  <div key={item} className="institution-header__awards__item">
                    <img src={globalAward} alt="awards" />
                    <span className="institution-header__awards--two">
                      {item}
                    </span>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(SolutionHeader);
