import React from "react";
import { SUCCEED_CASE } from "../../constants/pro_constants";
import UseInstitutionsImg from "../../styles/images/use-institutions.png";
import UseInstitutionsImgMobile from "../../styles/images/use-institutions--mobile.png";
const SalesCase = () => {
  return (
    <div className="sales-case" id={SUCCEED_CASE}>
      <div className="sales-case__title">他们都在用</div>
      <div className="sales-case__images">
        <img src={UseInstitutionsImg} alt="" />
      </div>
      <div className="sales-case__images--mobile">
        <img src={UseInstitutionsImgMobile} alt="" />
      </div>
    </div>
  );
};

export default SalesCase;
