import React from "react";

const SVG = ({ width = "100%", className = "" }) => (
  <svg
    width={width}
    height={width}
    viewBox="0 0 30 30"
    version="1.1"
    className={`svg-icon ${className || ""}`}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>icon/pop success</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <path
        d="M15,6 C19.9705627,6 24,10.0294373 24,15 C24,19.9705627 19.9705627,24 15,24 C10.0294373,24 6,19.9705627 6,15 C6,10.0294373 10.0294373,6 15,6 Z M17.2928932,12.2928932 L14,15.5857864 L12.7071068,14.2928932 C12.3165825,13.9023689 11.6834175,13.9023689 11.2928932,14.2928932 C10.9023689,14.6834175 10.9023689,15.3165825 11.2928932,15.7071068 L13.2928932,17.7071068 C13.6834175,18.0976311 14.3165825,18.0976311 14.7071068,17.7071068 L18.7071068,13.7071068 C19.0976311,13.3165825 19.0976311,12.6834175 18.7071068,12.2928932 C18.3165825,11.9023689 17.6834175,11.9023689 17.2928932,12.2928932 Z"
        id="NotifySuccess"
      />
    </defs>
    <g
      id="icon/pop-success"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="icon">
        <rect id="Rectangle" x="0" y="0" width="30" height="30" />
        <mask id="NotifySuccess2" fill="white">
          <use xlinkHref="#NotifySuccess" />
        </mask>
        <use id="Combined-Shape" fill="#42526E" xlinkHref="#NotifySuccess" />
        <rect
          id="color"
          fill="#00B67A"
          mask="url(#NotifySuccess2)"
          x="0"
          y="0"
          width="30"
          height="30"
        />
      </g>
    </g>
  </svg>
);

export default SVG;
