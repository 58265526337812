import React from "react";

const SVG = ({
  fill = "#92929D",
  width = "100%",
  className = "",
  viewBox = "0 0 20 20",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill={fill}
        d="M17.5 10.833h-15c-.46 0-.833-.372-.833-.833 0-.46.373-.833.833-.833h15c.46 0 .833.372.833.833 0 .46-.373.833-.833.833zM17.5 5h-15c-.46 0-.833-.372-.833-.833 0-.461.373-.834.833-.834h15c.46 0 .833.373.833.834 0 .46-.373.833-.833.833zm0 11.667h-15c-.46 0-.833-.373-.833-.834 0-.46.373-.833.833-.833h15c.46 0 .833.373.833.833 0 .461-.373.834-.833.834z"
      />
    </g>
  </svg>
);

export default SVG;
