import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { Helmet } from "react-helmet";
import { Tabs } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";

import { Sidebar, Icon } from "../shared";
import NewsStore from "../../stores/newsStore";
import { PRO, NEWS } from "../../constants/pro_constants";
import { labelToChinese } from "../../constants/label_to_chinese";
import EmptyDataImg from "../../styles/images/empty-data.svg";
import { trackNewsEvent } from "../../utils/ga_events";
import "./style.scss";

const { TabPane } = Tabs;

const News: React.FC = () => {
  const {
    tabs,
    isTabsEmpty,
    newsList,
    hasMore,
    loading,
    isNewslistEmpty,
    initializeTabsData,
    fetchNewsList,
    fetchMoreData,
    setSelectedTab,
  } = useContext(NewsStore);

  useEffect(() => {
    initializeTabsData();
    fetchNewsList();
  }, []);

  return (
    <div className="news-layout">
      <Helmet>
        <title>
          {labelToChinese[NEWS]}｜ {labelToChinese[PRO]}
        </title>
      </Helmet>
      <div className="news-layout__sidebar">
        <Sidebar />
      </div>
      <div className="news-layout__content-wrapper" id="news-content">
        <div className="news-layout__content">
          <div className="news-layout__tabs">
            {!isTabsEmpty && (
              <Tabs
                defaultActiveKey="0"
                onChange={index => {
                  setSelectedTab(tabs[index]);
                  fetchNewsList();
                }}
              >
                {tabs.map((tab, i) => {
                  return i === tabs.length - 1 ? (
                    <TabPane
                      tab={
                        <span>
                          {tab} <Icon name="pro-english" />
                        </span>
                      }
                      key={i}
                    />
                  ) : (
                    <TabPane tab={tab} key={i} />
                  );
                })}
              </Tabs>
            )}
          </div>
          <InfiniteScroll
            className="news-list"
            dataLength={newsList.length}
            next={fetchMoreData}
            scrollThreshold="200px"
            hasMore={hasMore}
            scrollableTarget="news-content"
            loader={
              loading && (
                <div className="loader" key={0}>
                  Loading
                </div>
              )
            }
          >
            {!loading && isNewslistEmpty ? (
              <div className="news-layout__no-data">
                <img src={EmptyDataImg} alt="暂无数据" />
                <p>暂无数据</p>
              </div>
            ) : (
              newsList.map(newsData => {
                return <NewsItem data={newsData} key={newsData.id} />;
              })
            )}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

const NewsItem = ({ data }) => {
  const { selectedTab } = useContext(NewsStore);
  const isShowLabel = selectedTab === "全部" && data.types.length > 0;
  const isShowDivider = data.institutions.length > 0 && data.types.length > 0;

  return (
    <div className="news-item">
      <div className="news-item__brief">
        <div className="news-item__left-info">
          <a
            className="news-item__title text-limit--one"
            href={data.sourceUrl}
            target="_blank"
            onClick={() =>
              trackNewsEvent("view_item", `${data.title}#${data.id}`, "")
            }
          >
            {data.title}
          </a>
          {isShowLabel && (
            <span className="news-item__type">
              {data.types[0]}
              {isShowDivider && <span className="news--divider" />}
            </span>
          )}
        </div>
        {data.institutions.map((institution, i) => {
          return (
            <Link
              className="news-item__institution"
              target="_blank"
              to={`/database/institutions/${institution.id}`}
              key={i}
            >
              <span className="news-item__institution__avatar">
                <img src={institution.avatarUrl} alt="" />
              </span>
              <span className="news-item__institution__name">
                {institution.name}
              </span>
            </Link>
          );
        })}
      </div>

      <span className="news-item__time">{data.time}</span>
    </div>
  );
};

export default observer(News);
