import { action, observable } from "mobx";
import { createContext } from "react";
import { breadcrumbs } from "../constants/breadcrumb";

class BreadcrumbStore {
  @observable breadcrumbMap = [];

  @action
  initializeBreadcrumbs = (currentPath: string, title: string) => {
    const filteredBreadcrumbs = breadcrumbs.filter(item =>
      currentPath.includes(item.path)
    );

    this.breadcrumbMap = filteredBreadcrumbs.concat([{ title, path: "" }]);
  };
}

export default createContext(new BreadcrumbStore());
