declare const wx: any;
declare const WeixinJSBridge: any;

import { fetchPayInfoApi, createPayOrderApi } from "../api/index";

const wxDecorator = (
  realFn,
  whiteList = ["http://pro.lvh.me:3000", "https://pro.almosthuman.cn"]
) => {
  if (whiteList.includes(window.location.origin)) {
    // eslint-disable-next-line no-console
    console.warn("当前域名为调试wx处在白名单中,故可能存在一些环境问题!");
    return true;
  } else {
    return realFn();
  }
};

export const isWeiXin = () =>
  wxDecorator(() => {
    const useragent = navigator.userAgent.toLowerCase();
    return /MicroMessenger/i.test(useragent);
  });

/**
 * 提取崔欣写的方法
 * 本地默认是微信登录,方便调试
 * @returns boolean
 */
export const isWxLogin = () =>
  wxDecorator(() => {
    return window.localStorage.getItem("login") &&
      window.localStorage.getItem("login") === "wxLogin"
      ? true
      : false;
  });

export const randomStr = () => {};
export const initSign = () => {};

const fetchInfo = async (orderId?: string) => {
  const res = await fetchPayInfoApi(orderId);
  return res.r;
};

// wx-sdk模式
export const wxInit = async () => {
  if (!(window as any).wx) {
    console.error("wx is not defined!");
    return false;
  }
  const { appId, timeStamp, nonceStr, signature } = await fetchInfo();
  wx.config({
    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    appId, // 必填，公众号的唯一标识
    timestamp: timeStamp, // 必填，生成签名的时间戳
    nonceStr, // 必填，生成签名的随机串
    signature, // 必填，签名
    jsApiList: ["chooseWXPay"], // 必填，需要使用的JS接口列表
  });

  wx.ready(function() {
    console.log("wx初始化成功!");
  });

  wx.error(function(error) {
    console.error(error);
  });
};
// wx-sdk模式
export const wxPay = async (id: string) => {
  if (!(window as any).wx) {
    console.error("wx is not defined!");
    return Promise.reject("wx is not defined!");
  }

  const res: any = await createPayOrderApi(id);
  const params = await fetchInfo(res.data.orderId);
  const data = res.data;

  return new Promise((resolve, reject) => {
    wx.chooseWXPay({
      ...params,
      timestamp: params.timeStamp,
      success: function() {
        resolve(data);
      },
      error: function(error) {
        reject(error);
      },
    });
  });
};

export const debugFn = (err: any, open?: boolean) => {
  if (open) {
    alert(JSON.stringify(err));
  } else {
    console.error(err);
  }
};
// wx-js模式
export const wxJsPay = async (id: string) => {
  const res: any = await createPayOrderApi(id);
  const params = await fetchInfo(res.data.orderId);
  const data = res.data;

  return new Promise((resolve, reject) => {
    WeixinJSBridge.invoke("getBrandWCPayRequest", params, (res: any) => {
      if (res.err_msg == "get_brand_wcpay_request:ok") {
        resolve(data);
      }
      if (res.err_msg == "get_brand_wcpay_request:fail") {
        reject(res);
      }
    });
  });
};
