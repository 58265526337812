import React, { useContext } from "react";
import { IconHeading, EmptyImport } from "../shared";
import databaseDetailStore from "../../stores/databaseDetailStore";
import { observer } from "mobx-react";
import { INSTITUTION_INTRO } from "../../constants/pro_constants";

const InstitutionIntro = observer(() => {
  const {
    data: {
      tabs: { institutionIntro },
    },
    isInstitutionIntroTabContentEmpty,
  } = useContext(databaseDetailStore);

  return (
    <>
      <div
        className="database-detail__intro database-detail__block database-detail__intro--sp"
        id={INSTITUTION_INTRO}
      >
        <IconHeading icon="pro-institution-intro" title="机构介绍" />
        {isInstitutionIntroTabContentEmpty ? (
          <div className="database-detail__block__content">
            <EmptyImport />
          </div>
        ) : (
          <div
            className="database-detail__block__content"
            dangerouslySetInnerHTML={{ __html: `${institutionIntro}` }}
          />
        )}
      </div>
      <div className="database-detail__intro--bottom" />
    </>
  );
});

export default InstitutionIntro;
