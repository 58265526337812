import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";

import SyncedLogo from "../../styles/images/vip-logo.png";
import OrderTablePanel from "./OrderTablePanel";
import { Button, Icon } from "../shared";
import ProfileStore from "../../stores/ProfileStore";

const VipInformation = () => {
  const [nextIconColor, setNextIconColor] = useState("#C6CBD4");
  const { vipInfo, isPaidUser, isVipInfoEmpty, fetchVipInfo } = useContext(
    ProfileStore
  );

  useEffect(() => {
    fetchVipInfo();
  }, []);

  return (
    !isVipInfoEmpty && (
      <div className="vip-info">
        <div className={`vip-info__card ${isPaidUser ? "" : "trial"}`}>
          <img src={SyncedLogo} alt="" className="vip-info__card__synced" />
          <span className="vip-info__card__product-name">
            机器之心Pro-{vipInfo.identity}
          </span>
          <span className="vip-info__card__username">{vipInfo.name}</span>
          {isPaidUser
            ? vipInfo.identity === "pro会员" && (
                <span className="vip-info__card__expire-time">
                  权益到期时间：{vipInfo.expiredAt}
                </span>
              )
            : vipInfo.identity === "pro会员" && (
                <span className="vip-info__card__trial-day">
                  试用期剩余 <span>{vipInfo.trialDays}</span> 天
                </span>
              )}
        </div>
        {!isPaidUser && (
          <Link to={"/pricing"} className="vip-info__become-vip">
            <Button shape="round" grid={8} size="xlarge">
              立即成为Pro会员
            </Button>
          </Link>
        )}
        <div className="vip-info__order-list">
          <div className="vip-info__order-list__title">
            <span>订单记录</span>
            <Link
              to="/coupon-code"
              onMouseEnter={() => setNextIconColor("#0062ff")}
              onMouseLeave={() => setNextIconColor("#C6CBD4")}
            >
              兑换优惠码
              <Icon name="arrow-right-with-circle" color={nextIconColor} />
            </Link>
          </div>
          <OrderTablePanel data={vipInfo.orders} />
        </div>
        <div className="vip-info__bill">
          如需发票请联系「机器之心Pro小助手X」（微信：syncedai）
        </div>
      </div>
    )
  );
};

export default observer(VipInformation);
