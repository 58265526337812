import React, { useContext } from "react";

import { IconHeading } from "../shared";
import { SOLUTION_TECHNOLOGY } from "../../constants/pro_constants";
import databaseDetailStore from "../../stores/databaseDetailStore";

const TechArchitecture = () => {
  const {
    data: {
      tabs: { techArchitecture },
    },
  } = useContext(databaseDetailStore);

  return (
    <>
      <div
        className="database-detail__block database-detail__intro"
        id={SOLUTION_TECHNOLOGY}
      >
        <IconHeading icon="pro-solution-tech" title="技术架构" />
        <div
          className="database-detail__block__content"
          dangerouslySetInnerHTML={{ __html: `${techArchitecture}` }}
        />
      </div>
      <div className="database-detail__intro--bottom" />
    </>
  );
};

export default TechArchitecture;
