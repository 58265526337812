import React, { useContext } from "react";
import { observer } from "mobx-react";
import { Table, Skeleton, Popover } from "antd";

import { AvatarCard, AuthWallPanel, Icon } from "../shared";
import databaseStore from "../../stores/databaseStore";

import {
  TRIGGER_DESC,
  TRIGGER_ASC,
  CACCEL_SORT,
  APPLY_FORMATS,
} from "../../constants/pro_constants";
import { labelToChinese } from "../../constants/label_to_chinese";
import authStore from "../../stores/authStore";

const customSkeleton = (
  <Skeleton paragraph={{ rows: 1, width: "80%" }} active />
);

const SolutionResultPanel = () => {
  const {
    resultList: { data, count },
    pagination,
    fetchDatabaseList,
    isLoading,
    type,
  } = useContext(databaseStore);
  const { isTrialUser } = useContext(authStore);

  const columns = [
    {
      title: "方案名称",
      dataIndex: "name",
      ellipsis: true,
      sorter: true,
      width: "290px",
      className: "database-list-panel__solution__name",
      render: data =>
        !isLoading
          ? (
              <span>
                {data.isLasted && <Icon name="pro-new" />}
                {data.name}
              </span>
            ) || "-"
          : customSkeleton,
    },
    {
      title: "简介",
      dataIndex: "summary",
      ellipsis: {
        showTitle: false,
      },
      className: "database-list-panel__solution__summary",
      render: data =>
        !isLoading
          ? (
              <Popover
                overlayStyle={{ width: 450 }}
                placement="topLeft"
                trigger="hover"
                content={data}
              >
                {data}
              </Popover>
            ) || "-"
          : customSkeleton,
    },
    {
      title: labelToChinese[APPLY_FORMATS],
      dataIndex: "applyFormats",
      ellipsis: true,
      width: "14%",
      render: data =>
        !isLoading ? (data && data.join(",")) || "-" : customSkeleton,
    },
    {
      title: "供应方",
      dataIndex: "producer",
      ellipsis: true,
      width: "14%",
      render: data =>
        !isLoading
          ? (data && (
              <AvatarCard
                theme="company"
                shape="square"
                data={data}
                avatarSize={32}
              />
            )) ||
            "-"
          : customSkeleton,
    },
    {
      title: "所在区域",
      dataIndex: "region",
      sorter: true,
      ellipsis: true,
      width: "8%",
      render: data => (!isLoading ? data || "-" : customSkeleton),
    },
  ];

  return (
    <div className="database-list-panel">
      <Table
        columns={columns}
        dataSource={data}
        locale={{
          triggerDesc: labelToChinese[TRIGGER_DESC],
          triggerAsc: labelToChinese[TRIGGER_ASC],
          cancelSort: labelToChinese[CACCEL_SORT],
        }}
        tableLayout="fixed"
        pagination={pagination}
        onChange={(pagination, filters, sorter) =>
          fetchDatabaseList(pagination, sorter)
        }
        title={() => (
          <div className="database-list-panel__title">找到 {count} 条结果</div>
        )}
        onRow={record => {
          return {
            onClick: () => {
              window.open(`/database/solutions/${record.key}`);
            },
          };
        }}
      />
      {isTrialUser && <AuthWallPanel label={type} />}
    </div>
  );
};

export default observer(SolutionResultPanel);
