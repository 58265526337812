export const breadcrumbs = [
  {
    title: "数据",
    path: "/database",
  },
  {
    title: "机构",
    path: "/database/institutions",
  },
  {
    title: "方案",
    path: "/database/solutions",
  },
  {
    title: "研究",
    path: "/research",
  },
];
