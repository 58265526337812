import React, { useContext } from "react";
import { AvatarCard } from "../shared";
import DatabaseStore from "../../stores/databaseStore";
import { observer } from "mobx-react";
import { INSTITUTION } from "../../constants/pro_constants";
import EmptyDataImg from "../../styles/images/empty-data.svg";

const KeywordResultPanel = () => {
  const {
    keyword,
    isGetData,
    keywordResultInstitutionData,
    keywordResultRelatedData,
    isKeywordResultInstitutionDataEmpty,
    isKeywordResultRelatedDataEmpty,
    hideSearchPanel,
    setSelectedKeywordResult,
  } = useContext(DatabaseStore);

  return !isKeywordResultInstitutionDataEmpty ||
    !isKeywordResultRelatedDataEmpty ? (
    <>
      <div className="keyword-result-panel">
        {!isKeywordResultInstitutionDataEmpty && isGetData && (
          <>
            <span className="keyword-result-panel__title">
              「{keyword}」相关公司
            </span>
            {keywordResultInstitutionData.map((ele, i) => {
              return (
                <div
                  className="keyword-result-panel__item"
                  key={i}
                  onClick={() => {
                    hideSearchPanel();
                    setSelectedKeywordResult(INSTITUTION, ele.name);
                  }}
                >
                  <AvatarCard
                    theme="company"
                    shape="square"
                    avatarSize={36}
                    data={ele}
                  />
                </div>
              );
            })}
          </>
        )}

        {!isKeywordResultRelatedDataEmpty && isGetData && (
          <>
            <span className="keyword-result-panel__title">
              「{keyword}」相关领域
            </span>
            {keywordResultRelatedData.map((ele, i) => {
              return (
                <div
                  className="keyword-result-panel__item"
                  key={i}
                  onClick={() => {
                    hideSearchPanel();
                    setSelectedKeywordResult("field", ele);
                  }}
                >
                  {ele}
                </div>
              );
            })}
          </>
        )}
      </div>
      <div
        className="keyword-result-panel__layer"
        onClick={() => hideSearchPanel()}
      />
    </>
  ) : (
    isGetData && keyword && (
      <>
        <div className="keyword-result-panel">
          <div className="keyword-result-empty">
            <div>
              <img src={EmptyDataImg} alt="暂无数据" />
              <p>暂无数据</p>
            </div>
          </div>
        </div>
        <div
          className="keyword-result-panel__layer"
          onClick={() => hideSearchPanel()}
        />
      </>
    )
  );
};

export default observer(KeywordResultPanel);
