import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Helmet } from "react-helmet";

import "./member-inbox-pc.scss";
import titleImg from "../../styles/images/member-inbox-title.png";
import servicCode from "../../styles/images/pro-service-code.png";

const MemberInboxPc: React.FC = () => {
  useEffect(() => {}, []);

  return (
    <div className="member-inbox-pc">
      <Helmet>
        <title>会员收件箱 | 机器之心PRO</title>
      </Helmet>
      <>
        <div className="member-inbox-pc__header">
          <img src={titleImg} alt="title" />
        </div>
        <div className="member-inbox-pc__code">
          <p>
            使用微信扫码关注「机器之心PRO服务」点击菜单底部「收件箱」获取每周会员内容
          </p>
          <div>
            <img src={servicCode} alt="code" />
          </div>
        </div>
      </>
    </div>
  );
};

export default observer(MemberInboxPc);
