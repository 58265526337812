import React, { useEffect, useContext, useState } from "react";
import { observer } from "mobx-react";

import "./dialog.scss";
import { Dialog } from "../shared";
// import integralIcon from "../../styles/images/inbox-pay-integral.png";
import agreeIcon from "../../styles/images/inbox-agree.png";
import disagreeIcon from "../../styles/images/inbox-disagree.png";
import memberInboxStore from "../../stores/memberInboxStore";
import { wxJsPay, debugFn, isWxLogin } from "../../utils/wx_tools";
import { message } from "antd";
import AuthStore from "../../stores/authStore";
import { fetchIntegralInfoApi } from "../../api/index";
import { buyInboxIntegralEvent } from "../../utils/ga_events";
import checkOrigin from "./checkOrigin";

interface Props {
  onSuccess?(): any;
}

const BuyIntegralDialog: React.FC<Props> = (props: Props) => {
  const { onSuccess = () => {} } = props;
  const { memberInfo, fetchMemberInfo, isCurrentUserEmpty } = useContext(
    AuthStore
  );
  const {
    dialog,
    setDialog,
    xy,
    xyText,
    wxLogin,
    jfId,
    setPurchase,
  } = useContext(memberInboxStore);
  const onCancel = () => {
    window.localStorage.removeItem("gangLogin");
    setDialog("integral", false);
  };
  const [info, setInfo] = useState<any>({});
  const [agree, setAgree] = useState<any>(true);
  const purchase_source = checkOrigin();

  useEffect(() => {
    if (dialog.integral) {
      // api();
      fetchIntegralInfoApi().then((res: any) => setInfo(res));
    }
  }, [dialog.integral]);

  return (
    <Dialog
      visible={dialog.integral}
      style={{ padding: "0", borderRadius: "10px" }}
      onCancel={onCancel}
      layerClose={false}
      closeStyle={{ color: "rgba(55,65,81,0.2)", top: "24px", right: "14px" }}
      needAuthCb={
        memberInfo && isWxLogin()
          ? null
          : () => {
              wxLogin();
            }
      }
    >
      <div className="buy-integral-dialog">
        <p>购买积分</p>
        <div className="integral-num">
          当前剩余 <span>{memberInfo ? memberInfo.points : 0}</span> 积分
        </div>
        <div className="integral-info">
          <img src={info.show_image_url} alt="" />
        </div>
        {info.final_price && (
          <div
            className="integral-btn"
            onClick={() => {
              if (isCurrentUserEmpty && !memberInfo) {
                onCancel();
                wxLogin();
              } else {
                if (agree) {
                  onCancel();
                  wxJsPay(jfId)
                    .then((data: any) => {
                      // 标记是刚购买过的会员
                      setPurchase("integral");
                      fetchMemberInfo();
                      message.open({
                        type: "success",
                        duration: 3,
                        className: "ant-toast",
                        content: `${info.integral}积分已到账！`,
                      });
                      onSuccess();
                      buyInboxIntegralEvent({
                        transaction_id: data.shortId,
                        value: data.final_price / 100,
                        item_id: data.proProductId,
                        item_name: data.proProductName,
                        price: data.final_price / 100,
                        purchase_source,
                        user_id: memberInfo.id,
                        user: memberInfo.username,
                      });
                    })
                    .catch((err: any) => {
                      message.open({
                        type: "success",
                        duration: 3,
                        className: "ant-toast",
                        content: "支付失败，请重新尝试！",
                      });
                      // 很简单的意思 就是重新吊起购买
                      setDialog("integral", true);
                      debugFn(err);
                    });
                } else {
                  message.open({
                    type: "error",
                    duration: 3,
                    className: "ant-toast",
                    content: xyText,
                  });
                }
              }
            }}
          >
            立即充值
          </div>
        )}
        <div className="integral-tips">
          <img
            src={agree ? agreeIcon : disagreeIcon}
            alt="agreeIcon"
            onClick={() => setAgree(!agree)}
          />
          我已阅读并同意{" "}
          <a href={xy} target="_blank">
            《用户协议》
          </a>
        </div>
      </div>
    </Dialog>
  );
};

export default observer(BuyIntegralDialog);
