import React from "react";

const SVG = ({
  fill = "#0062FF",
  width = "100%",
  className = "",
  viewBox = "0 0 16 16",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      fillRule="evenodd"
      d="M15.2 0H.8C.358 0 0 .358 0 .8v14.4c0 .442.358.8.8.8h6.4c.442 0 .8-.358.8-.8 0-.442-.358-.8-.8-.8H1.6V1.6h12.8v7.2c0 .442.358.8.8.8.442 0 .8-.358.8-.8v-8c0-.442-.358-.8-.8-.8m-.566 11.434L12 14.07l-1.034-1.035c-.313-.312-.819-.312-1.132 0-.312.313-.312.819 0 1.132l1.6 1.6c.156.156.361.234.566.234.205 0 .41-.078.566-.234l3.2-3.2c.312-.313.312-.819 0-1.132-.313-.312-.819-.312-1.132 0M4.8 8.8h6.4c.442 0 .8-.358.8-.8 0-.442-.358-.8-.8-.8H4.8c-.442 0-.8.358-.8.8 0 .442.358.8.8.8m0 3.2h2.4c.442 0 .8-.358.8-.8 0-.442-.358-.8-.8-.8H4.8c-.442 0-.8.358-.8.8 0 .442.358.8.8.8m0-6.4h6.4c.442 0 .8-.358.8-.8 0-.442-.358-.8-.8-.8H4.8c-.442 0-.8.358-.8.8 0 .442.358.8.8.8"
    />
  </svg>
);

export default SVG;
