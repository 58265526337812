import React from "react";

const SVG = ({
  fill = "#00B67A",
  width = "100%",
  className = "",
  viewBox = "0 0 16 16",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Shape</title>
    <desc>Created with Sketch.</desc>
    <g
      id="landing-page"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M8,0 C3.58214286,0 0,3.58214286 0,8 C0,12.4178571 3.58214286,16 8,16 C12.4178571,16 16,12.4178571 16,8 C16,3.58214286 12.4178571,0 8,0 Z M11.4553571,5.3875 L7.23214286,10.9513193 L4.54464286,7.51607143 C4.47678571,7.42142857 4.54464286,7.28928571 4.66071429,7.28928571 L5.49821429,7.28928571 C5.68035714,7.28928571 5.85357143,7.37678571 5.96071429,7.52678571 L7.23214286,9.29107143 L10.0392857,5.39821429 C10.1464286,5.25 10.3178571,5.16071429 10.5017857,5.16071429 L11.3392857,5.16071429 C11.4553571,5.16071429 11.5232143,5.29285714 11.4553571,5.3875 Z"
        id="Shape"
        fill={fill}
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default SVG;
