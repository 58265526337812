import React, { useContext } from "react";
import { observer } from "mobx-react";

import "./style.scss";
import DrawerListItem from "./DrawerListItem";
import subscribeStore from "../../stores/subscribeStore";
import { trackSubscribeEvent } from "../../utils/ga_events";

const DrawerList = () => {
  const { receiveList, archieveList, trialList, selectedId } = useContext(
    subscribeStore
  );

  return (
    <div className="subscribe-drawer__list">
      {trialList.length > 0 && (
        <>
          <div className="subscribe-drawer__list-title">试读</div>
          <div className="subscribe-drawer__list-items">
            {trialList.map((item, i) => (
              <DrawerListItem
                {...item}
                hasDivider
                key={i}
                onClick={() => trackSubscribeEvent("preview", selectedId, "")}
              />
            ))}
          </div>
        </>
      )}
      {receiveList.length > 0 && (
        <>
          <div className="subscribe-drawer__receive-header">
            <div className="subscribe-drawer__list-title">已接收内容</div>
            {/* TODO: Hidden temporary for prov2.0.1 release */}
            {/* <a href="">
              <Icon name="pro-file-download-active" />
              下载全部已接受的内容
            </a> */}
          </div>
          <div className="subscribe-drawer__list-items">
            {receiveList.map((item, i) => (
              <DrawerListItem
                {...item}
                hasDivider
                key={i}
                onClick={() => trackSubscribeEvent("download", selectedId, i)}
              />
            ))}
          </div>
        </>
      )}
      {archieveList.length > 0 && (
        <>
          <div className="subscribe-drawer__list-title">往期内容归档</div>
          <div className="subscribe-drawer__list-items">
            {archieveList.map((item, i) => (
              <DrawerListItem
                {...item}
                hasDivider
                key={i}
                onClick={() =>
                  trackSubscribeEvent("download_archive", selectedId, "")
                }
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default observer(DrawerList);
