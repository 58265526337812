import React from "react";

const SVG = ({ fill = "#FF8D00", className = "", viewBox = "0 0 14 14" }) => (
  <svg
    width="14"
    height="14"
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      fillRule="evenodd"
      d="M2.8 14c-.145 0-.29-.045-.411-.133-.21-.153-.32-.409-.282-.666l.65-4.553L.205 6.095c-.186-.185-.251-.458-.172-.708.08-.25.293-.434.551-.477l3.849-.642 1.94-3.88c.12-.24.382-.397.63-.388.267.002.51.154.626.394l1.858 3.817 3.935.7c.257.046.467.23.546.48.078.248.012.52-.173.704l-2.553 2.553.65 4.553c.037.259-.072.516-.285.668-.212.153-.492.173-.724.055L7.05 11.983l-3.94 1.944c-.097.05-.204.073-.31.073z"
    />
  </svg>
);

export default SVG;
