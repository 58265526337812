import React, { useContext } from "react";
import { observer } from "mobx-react";

import { Menu } from "../shared";
import CoreInstitutionIntro from "./CoreInstitutionIntro";
import CaseDetailIntro from "./CaseDetailIntro";
import ProjectBackgroundIntro from "./ProjectBackgroundIntro";
import RelatedFilesIntro from "./RelatedFilesIntro";
import databaseDetailStore from "../../stores/databaseDetailStore";

const BusinessCaseTabs = () => {
  const {
    isCoreInstitutionTabContentEmpty,
    isRelatedFileTabContentEmpty,
    businessCaseTabs,
    scrolledDomId,
    scrollTo,
  } = useContext(databaseDetailStore);

  return (
    <div>
      <Menu
        items={businessCaseTabs}
        selectedKey={scrolledDomId}
        onClick={item => scrollTo(item.key)}
      />
      <CaseDetailIntro />
      <ProjectBackgroundIntro />
      {!isCoreInstitutionTabContentEmpty && <CoreInstitutionIntro />}
      {!isRelatedFileTabContentEmpty && <RelatedFilesIntro />}
    </div>
  );
};

export default observer(BusinessCaseTabs);
