import React, { useState, useContext, useEffect } from "react";
import { Modal, Input, Form, message } from "antd";
import { observer } from "mobx-react";

import { Icon, Button } from "../shared";
import ProfileStore from "../../stores/ProfileStore";
import { verifyIdentidy } from "../../api";
import encryptMobile from "../../utils/encryptMobile";

const CheckWayList = [
  {
    name: "phone",
    title: "手机验证",
  },
  {
    name: "email",
    title: "邮箱验证",
  },
];
interface Props {
  visible: boolean;
  onHide: () => void;
  isUpdate: boolean;
  type: "email" | "phone";
}

const UpdateEmailAndPhoneModal: React.FC<any> = (props: Props) => {
  const {
    personalInfo,
    checkMethod,
    isChooseVerifyWay,
    isUpdatePhone,
    isCheckMethodCountEqual2,
  } = useContext(ProfileStore);

  const [checkWay, setCheckWay] = useState(checkMethod);
  const [isIdentidyVerify, setIsIdentidyVerify] = useState(isChooseVerifyWay);
  const [isUpdate, setIsUpdate] = useState(isUpdatePhone);
  const [isChooseWay, setIsChooseWay] = useState(isChooseVerifyWay);

  useEffect(() => {
    setIsChooseWay(isChooseVerifyWay && isCheckMethodCountEqual2);
    setCheckWay(checkMethod);
    setIsUpdate(isUpdatePhone);
    setIsIdentidyVerify(isChooseVerifyWay);
  }, [checkMethod, isUpdatePhone, isCheckMethodCountEqual2, isChooseVerifyWay]);

  return (
    <Modal
      visible={props.visible}
      width={464}
      footer={null}
      closeIcon={<Icon name="pro-close" size={16} color="#c1c7d0" />}
      className="update-EP-moal"
      onCancel={() => props.onHide()}
    >
      {isChooseWay && (
        <div className="update-EP-modal__content">
          <div className="update-EP-modal__title">请选择验证方式</div>
          <div className="update-EP-modal__body">
            <div className="update-EP-modal__check-ways">
              {CheckWayList.map(item => {
                return (
                  <div
                    className={`update-EP-modal__check-ways__item ${
                      checkWay === item.name ? "active" : ""
                    }`}
                    key={item.name}
                    onClick={() => setCheckWay(item.name)}
                  >
                    <Icon
                      name={`${item.name}-icon`}
                      color={checkWay === item.name ? `#0062FF` : `#92929D`}
                      size={20}
                    />
                    <span>{item.title}</span>
                  </div>
                );
              })}
            </div>
            <div className="update-EP-modal__action">
              <Button
                size="large"
                grid={33}
                onClick={() => {
                  setIsIdentidyVerify(true);
                  setIsChooseWay(false);
                }}
              >
                确定
              </Button>
            </div>
          </div>
        </div>
      )}
      {isIdentidyVerify && !isChooseWay && (
        <IdentidyVerify
          way={checkWay}
          value={
            checkWay === "phone"
              ? encryptMobile(personalInfo.mobile)
              : personalInfo.email
          }
          onFinish={code => {
            verifyIdentidy(code, response => {
              if (response.statusCode === 200) {
                setIsUpdate(true);
                setIsIdentidyVerify(false);
              } else {
                message.error(response.body.errors.join("，"));
              }
            });
          }}
        />
      )}
      {isUpdate && props.type === "phone" && <UpdatePhone />}
      {isUpdate && props.type === "email" && <UpdateEmail />}
    </Modal>
  );
};

const IdentidyVerify = props => {
  const placeholder = props.way === "phone" ? "请输入手机号码" : "请输入邮箱";
  const [mobile, setMobile] = useState(props.value);
  const { getVerifyIdentidyCode } = useContext(ProfileStore);

  return (
    <div className="update-EP-modal__content">
      <div className="update-EP-modal__title">验证身份</div>
      <Form
        onFinish={values => {
          props.onFinish(values.code);
        }}
      >
        <Form.Item
          rules={[{ required: true, message: placeholder }]}
          name="mobile"
          initialValue={mobile}
        >
          <Input
            placeholder={placeholder}
            className="update-EP-modal__form__input"
            value={mobile}
            onChange={event => setMobile(event.target.value)}
            disabled
          />
        </Form.Item>

        <Form.Item>
          <Form.Item
            rules={[{ required: true, message: "请输入验证码" }]}
            name="code"
            style={{
              width: "calc(55% - 6px)",
              display: "inline-block",
              margin: "0 6px 0 0",
            }}
          >
            <Input
              placeholder="验证码"
              className="update-EP-modal__form__check-code"
            />
          </Form.Item>
          <Form.Item
            style={{
              width: "calc(45% - 6px)",
              display: "inline-block",
              margin: "0 0 0 6px",
            }}
          >
            <Button
              size="small"
              grid={7}
              onClick={() => getVerifyIdentidyCode(mobile, props.way)}
            >
              获取验证码
            </Button>
          </Form.Item>
        </Form.Item>
        <Form.Item className="update-EP-modal__action">
          <Button size="large" grid={33} htmlType="submit">
            确定
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

const UpdatePhone = observer(() => {
  const [mobile, setMobile] = useState("");
  const { getPhoneVerifyCode, onUpdatePhone } = useContext(ProfileStore);

  return (
    <div className="update-EP-modal__content">
      <div className="update-EP-modal__title">更改手机号码</div>

      <div className="update-EP-modal__body">
        <Form
          onFinish={values => {
            onUpdatePhone(values);
          }}
        >
          <Form.Item
            rules={[
              { required: true, message: "请输入新的手机号码" },
              {
                pattern: /^1[3456789]\d{9}$/,
                message: "请输入正确的手机号码",
              },
            ]}
            name="mobile"
          >
            <Input
              placeholder="请输入新的手机号码"
              className="update-EP-modal__form__input"
              value={mobile}
              onChange={event => setMobile(event.target.value)}
            />
          </Form.Item>

          <Form.Item>
            <Form.Item
              rules={[{ required: true, message: "请输入验证码" }]}
              name="code"
              style={{
                width: "calc(55% - 6px)",
                display: "inline-block",
                margin: "0 6px 0 0",
              }}
            >
              <Input
                placeholder="验证码"
                className="update-EP-modal__form__check-code"
              />
            </Form.Item>
            <Form.Item
              style={{
                width: "calc(45% - 6px)",
                display: "inline-block",
                margin: "0 0 0 6px",
              }}
            >
              <Button
                size="small"
                grid={7}
                onClick={() => getPhoneVerifyCode(mobile)}
              >
                获取验证码
              </Button>
            </Form.Item>
          </Form.Item>
          <Form.Item className="update-EP-modal__action">
            <Button size="large" grid={33} htmlType="submit">
              确定
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
});

const UpdateEmail = () => {
  const { onUpdateEmail } = useContext(ProfileStore);

  return (
    <div className="update-EP-modal__content">
      <div className="update-EP-modal__title">更改邮箱</div>
      <div className="update-EP-modal__body">
        <Form onFinish={values => onUpdateEmail(values.email)}>
          <Form.Item
            rules={[{ type: "email", message: "请输入正确的邮箱" }]}
            name="email"
          >
            <Input
              placeholder="请输入新的邮箱地址"
              className="update-EP-modal__form__input"
            />
          </Form.Item>
          <Form.Item className="update-EP-modal__action">
            <Button size="large" grid={33} htmlType="submit">
              保存
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default observer(UpdateEmailAndPhoneModal);
