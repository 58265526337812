import React, { useContext } from "react";
import { Modal } from "antd";
import { observer } from "mobx-react";

import { Icon, Button } from "../shared";
import bluePanel from "../../styles/images/auth-modal-blue.png";
import crownIcon from "../../styles/images/pro-crown.svg";
import paymentStore from "../../stores/paymentStore";
import successImage from "../../styles/images/payment-success.svg";
import ladyIcon from "../../styles/images/lady.svg";
import gentleIcon from "../../styles/images/gentle.svg";

const CONTACTS = [
  {
    avatar: gentleIcon,
    name: "Mr.Zhao",
    telepahone: "187-0168-8690",
  },
  {
    avatar: ladyIcon,
    name: "Ms.Zhou",
    telepahone: "159-2143-3729",
  },
];

interface Props {
  visible: boolean;
  onCancel: () => void;
}

const ContactSuccessModal: React.FC<Props> = (props: Props) => {
  const { communicateTime } = useContext(paymentStore);

  return (
    <Modal
      className="contact-modal contact-success-modal"
      visible={props.visible}
      footer={null}
      width={400}
      closeIcon={<Icon name="pro-close" size={16} color="#c1c7d0" />}
      onCancel={props.onCancel}
    >
      <img className="contact-modal__bg--blue" src={bluePanel} alt="" />
      <div className="contact-modal__header">
        <img src={crownIcon} alt="" />
        <h2>机构会员</h2>
      </div>
      <div className="contact-success-modal__content">
        <img src={successImage} alt="提交成功" />
        <h3>提交成功，稍后我们将与您联系</h3>
        {communicateTime && <p>方便的联系时间：{communicateTime}</p>}

        <div className="contact-success-modal__contacts">
          <div className="contact-success-modal__contacts__title">
            联系我们客户经理
          </div>
          <div className="contact-success-modal__contacts__list">
            {CONTACTS.map((contact, index) => {
              return (
                <div
                  className="contact-success-modal__contacts__item"
                  key={index}
                >
                  <img src={contact.avatar} alt={contact.name} />
                  <span className="contact-success-modal__contacts__name">
                    {contact.name}
                  </span>
                  <span className="contact-success-modal__contacts__telephone">
                    {contact.telepahone}
                  </span>
                </div>
              );
            })}
          </div>
        </div>

        <div className="contact-success-modal__footer">
          <Button size="large" grid={20} onClick={props.onCancel}>
            完成
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default observer(ContactSuccessModal);
