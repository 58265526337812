import React, { useContext, useEffect, useState } from "react";
import Modal from "antd/lib/modal/Modal";
import { Icon, Button } from "../shared";
import bluePanel from "../../styles/images/auth-modal-blue.png";
import crownIcon from "../../styles/images/pro-crown.svg";
import paymentWechatActiveImage from "../../styles/images/payment-wechat-active.png";
import PaymentStore from "../../stores/paymentStore";
import { observer } from "mobx-react";
import QRCode from "qrcode";
import { Input } from "antd";
import { Link } from "react-router-dom";
import { useQuery } from "../../utils/useQuery";
import { isWeiXin } from "../../utils/wx_tools";

interface Props {
  visible: boolean;
  onCancel: () => void;
}

const PaymentModal: React.FC<Props> = (props: Props) => {
  const [couponCode, setCouponCode] = useState(useQuery().get("couponCode"));
  // const [isDiscount, setIsDiscount] = useState(false);

  const {
    orderData,
    purchaseQrcode,
    purchaseStatus,
    isHasPurchaseQrcode,
    isCouponCodeValid,
    reducedPrice,
    isDiscount,
    orderProduct,
    fetchPurchaseQrcode,
    fetchPurchaseStatus,
    initPurchaseQrcode,
    resetPurchaseTimer,
    fetchReducedPrice,
    setIsDiscount,
  } = useContext(PaymentStore);

  useEffect(() => {
    orderProduct();
    initPurchaseQrcode();
    if (couponCode) {
      fetchReducedPrice(couponCode);
    }
  }, []);

  useEffect(() => {
    if (purchaseQrcode) {
      QRCode.toDataURL(purchaseQrcode)
        .then(url => {
          const qrcodeDom = document.getElementById("purchase-qrcode");
          qrcodeDom.setAttribute("src", url);
        })
        .catch(err => {
          console.error(err);
        });
    }
  }, [purchaseQrcode]);

  return (
    <>
      <Modal
        className="payment-modal"
        visible={props.visible}
        footer={null}
        width={520}
        closeIcon={<Icon name="pro-close" size={16} color="#c1c7d0" />}
        onCancel={() => {
          props.onCancel();
          resetPurchaseTimer();
        }}
      >
        <img className="payment-modal__bg--blue" src={bluePanel} alt="" />
        <div className="payment-modal__header">
          <img src={crownIcon} alt="" />
          <h2>成为PRO会员</h2>
        </div>
        <div className="payment-modal__content">
          <h3>
            您正在为账户 <span>{orderData.username} </span> 购买 1 年期机器之心
            Pro 会员权益
          </h3>
          {isHasPurchaseQrcode ? (
            <>
              <div>
                <div className="payment-modal__infomation">
                  <div className="payment-modal__infomation--item">
                    <span>开通账号</span>
                    <span>
                      {orderData.username}({orderData.userEmail})
                    </span>
                  </div>
                  <div className="payment-modal__infomation--item">
                    <span>开通权益</span>
                    <span>{orderData.productName}</span>
                  </div>
                  <div className="payment-modal__infomation--item">
                    <span>支付数额</span>
                    <span>￥{orderData.price}</span>
                  </div>
                  <div className="payment-modal__infomation--item">
                    <span>权益期限</span>
                    <span>{orderData.expiredAt}</span>
                  </div>
                </div>
                <div className="payment-modal__paid-mode">
                  <span>支付方式</span>
                  <span className="payment-modal__paid-mode--wechat">
                    <img src={paymentWechatActiveImage} alt="" />
                  </span>
                </div>
                <div className="payment-modal__paid-price">
                  <span className="payment-modal__paid-price--key">
                    实际付款
                  </span>
                  <span
                    className={`payment-modal__paid-price--value ${
                      isDiscount && isCouponCodeValid ? "through" : ""
                    }`}
                  >
                    ￥{orderData.price}
                  </span>
                  {isDiscount && isCouponCodeValid && (
                    <div className="payment-modal__paid-price--discount">
                      <span>优惠价</span> ￥{reducedPrice}
                    </div>
                  )}
                  <div className="payment-modal__paid-price--bill">
                    支付后可开发票
                  </div>
                </div>
                <div className="payment-modal__discount">
                  <span className="payment-modal__discount__key">优惠码</span>
                  <div className="payment-modal__discount__value">
                    <div
                      className={`payment-modal__discount__value--input ${
                        isDiscount && isCouponCodeValid
                          ? "coupon-code-valid"
                          : ""
                      }`}
                    >
                      <Input
                        placeholder="如有优惠码，请在此键入"
                        value={couponCode}
                        onChange={event => setCouponCode(event.target.value)}
                      />
                    </div>
                    {!isDiscount && (
                      <span
                        onClick={() => {
                          fetchReducedPrice(couponCode);
                        }}
                        className={`payment-modal__discount__value--discount ${
                          couponCode ? "active" : ""
                        }`}
                      >
                        使用优惠码
                      </span>
                    )}
                    {isDiscount && (
                      <span
                        onClick={() => {
                          setCouponCode("");
                          setIsDiscount(false);
                        }}
                        className="payment-modal__discount__value--cancel"
                      >
                        取消使用
                      </span>
                    )}
                    {isDiscount && !isCouponCodeValid && (
                      <div className="payment-modal__discount__error">
                        此优惠码不可用于减价，请
                        <Link to="/coupon-code"> 点击此处 </Link> 进行兑换
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="payment-modal__footer">
                <Button
                  grid={20}
                  size="large"
                  onClick={() => {
                    fetchPurchaseQrcode(couponCode);
                    fetchPurchaseStatus();
                  }}
                >
                  前往支付
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className="payment-modal__qrcode">
                {purchaseQrcode && <img id="purchase-qrcode" />}
                <span>
                  支付金额{" "}
                  <span className="payment-modal__qrcode__price">
                    {couponCode && isDiscount && isCouponCodeValid
                      ? reducedPrice
                      : orderData.price}
                  </span>
                </span>
                {isWeiXin() && (
                  <span className="payment-modal__qrcode__hint">
                    请长按识别二维码
                  </span>
                )}
              </div>
              {purchaseStatus && (window.location.href = "/pricing/success")}
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default observer(PaymentModal);
