import React, { useEffect, useContext, useState, useRef } from "react";
import { observer } from "mobx-react";
import { Helmet } from "react-helmet";
// import { Link } from "react-router-dom";

import "./_authentication.scss";
import { Form, Input, message } from "antd";
import { PRO } from "../../constants/pro_constants";
import { labelToChinese } from "../../constants/label_to_chinese";
import authStore from "../../stores/authStore";
import memberInboxStore from "../../stores/memberInboxStore";
import { mobileVerified } from "../../api/index";
import SyncedLogo from "../../styles/images/logo-synced-black.png";
import ProImg from "../../styles/images/pro.svg";

const Authentication: React.FC = () => {
  const [form] = Form.useForm();
  const { memberInfo, fetchMemberInfo, updateCaptcha } = useContext(authStore);
  const { onSendCode, time } = useContext(memberInboxStore);
  const [isComplete, setIsComplete] = useState(false);
  const id = window.sessionStorage.getItem("id");

  const captchaRef = useRef(null);

  const onFieldsChange = () => {
    const values: any = form.getFieldsValue();
    const { mobile, _rucaptcha, phone_verify_code } = values;
    setIsComplete(
      !!mobile &&
        !!_rucaptcha &&
        !!phone_verify_code &&
        form.getFieldsError().filter(({ errors }) => errors.length).length === 0
    );
  };

  const onFinish = async values => {
    if (isComplete) {
      setIsComplete(false);
      const params = {
        mobile_verify: {
          mobile: values.mobile,
          phone_verify_code: values.phone_verify_code,
        },
        _rucaptcha: values._rucaptcha,
      };
      await mobileVerified(params, response => {
        if (response.statusCode === 200) {
          message.success("实名认证成功");
          setIsComplete(true);
          const code = window.sessionStorage.getItem("code") || "";
          window.location.replace(
            id ? `/inbox/${id}` : code ? `/inbox?code=${code}` : `/inbox`
          );
        } else {
          message.error(response.body.errors);
        }
      });
    }
  };

  useEffect(() => {
    fetchMemberInfo();
  }, []);

  return (
    <div className="member-inbox-authentication-layout">
      <Helmet>
        <title>绑定信息 ｜ {labelToChinese[PRO]}</title>
      </Helmet>
      <div className="authentication__header">
        {/* <Link to="/"> */}
        <img src={SyncedLogo} alt="Synced" />
        <img src={ProImg} />
        {/* </Link> */}
      </div>
      <div className="authentication__content">
        <p className="authentication__content__title">实名认证</p>
        <div className="authentication__content__desc">
          根据国家法律法规要求，绑定手机号获得更高级的安全保护。
        </div>
        {memberInfo && (
          <Form
            className="authentication__form"
            initialValues={memberInfo}
            autoComplete="off"
            form={form}
            onFieldsChange={onFieldsChange}
            onFinish={values => onFinish(values)}
          >
            <Form.Item
              rules={[
                { required: true, message: "请输入手机号" },
                {
                  pattern: /^1[3456789]\d{9}$/,
                  message: "请输入正确的手机号",
                },
              ]}
              label="手机号"
              name="mobile"
            >
              <Input placeholder="请输入手机号" />
            </Form.Item>
            <div className="authentication__form-code">
              <Form.Item
                hasFeedback
                rules={[
                  { required: true, message: "请输入正确的验证码" },
                  { min: 5, message: "验证码是 5 位" },
                ]}
                name="_rucaptcha"
              >
                <Input autoComplete="off" placeholder="图形验证码" />
              </Form.Item>
              <img
                className="authentication__captcha"
                ref={captchaRef}
                src="/rucaptcha/"
                alt="图形验证码"
                onClick={e => updateCaptcha(captchaRef.current, e)}
              />
            </div>
            <div className="authentication__form-code">
              <Form.Item
                hasFeedback
                rules={[{ required: true, message: "请输入正确的验证码" }]}
                name="phone_verify_code"
              >
                <Input autoComplete="off" placeholder="验证码" />
              </Form.Item>
              <div
                className={`authentication__form-send-code ${time > 0 &&
                  "authentication__form-send-code--time"}`}
                onClick={() => {
                  if (time <= 0) {
                    const values: any = form.getFieldsValue();
                    const { mobile, _rucaptcha } = values;
                    onSendCode({ mobile, _rucaptcha });
                  }
                }}
              >
                {time > 0 ? `${time}s 后重新发送` : "发送验证码"}
              </div>
            </div>
            <div
              className={`authentication__form-btn ${!isComplete &&
                "authentication__form-btn--opacity"}`}
              onClick={() => form.submit()}
            >
              提交
            </div>
          </Form>
        )}
      </div>
    </div>
  );
};

export default observer(Authentication);
