export default function(
  isNewMember,
  windowExpire,
  listVip,
  setDialog,
  setDiscount
) {
  if (isNewMember) {
    setDialog("new-buy", true);
  } else if (listVip) {
    //会员期内
    setDiscount(true); // 折扣购买
    setDialog("cost", true);
  } else {
    //会员过期30天内折扣购买，会员过期超30天正价购买
    setDiscount(windowExpire);
    setDialog("cost", true);
  }
}
