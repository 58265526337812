import React, { useContext } from "react";
import { IconHeading, EmptyImport } from "../shared";
import databaseDetailStore from "../../stores/databaseDetailStore";
import { observer } from "mobx-react";
import { CASE_DETAIL } from "../../constants/pro_constants";

const CaseDetailIntro = () => {
  const {
    data: {
      tabs: { caseDetailIntro },
    },
    isCaseDetailTabContentEmpty,
  } = useContext(databaseDetailStore);

  return (
    <>
      <div
        className="database-detail__block database-detail__intro--sp"
        id={CASE_DETAIL}
      >
        <IconHeading icon="pro-text-yes" title="案例详解" />
        {isCaseDetailTabContentEmpty ? (
          <div className="database-detail__block__content">
            <EmptyImport />
          </div>
        ) : (
          <div
            className="database-detail__block__content"
            dangerouslySetInnerHTML={{ __html: `${caseDetailIntro}` }}
          />
        )}
      </div>
      <div className="database-detail__intro--bottom" />
    </>
  );
};

export default observer(CaseDetailIntro);
