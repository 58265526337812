import React, { useEffect, useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { observer } from "mobx-react";
import { useLocation } from "react-router-dom";

import { Sidebar, Newsflash, Breadcrumbs, GuestTooltip } from "../shared";
import DetailHeader from "./DetailHeader";
import DetailTabs from "./DetailTabs";
import DataCollecting from "./DataCollecting";
import "./style.scss";
import DatabseDetailStore from "../../stores/databaseDetailStore";
import BreadcrumbStore from "../../stores/breadcrumbStore";
import AuthStore from "../../stores/authStore";
import { labelToChinese } from "../../constants/label_to_chinese";

interface Props {
  type: "institution" | "solution" | "businessCase";
  id?: string;
  DatabseDetailStore?: {
    initializeData: (arg) => void;
  };
}

const DatabaseDetail: React.FC<Props> = (props: Props) => {
  const location = useLocation();
  const currentPath = location.pathname;

  const {
    data,
    initializeType,
    initializeId,
    initializeData,
    initializeAssetsData,
    isShowAll,
  } = useContext(DatabseDetailStore);

  const { breadcrumbMap, initializeBreadcrumbs } = useContext(BreadcrumbStore);
  const { isCurrentUserEmpty } = useContext(AuthStore);
  const [guestTooltipShow, setGuestTooltipShow] = useState(true);

  useEffect(() => {
    initializeType(props.type);
    initializeId(props.id);
    initializeData();
    initializeAssetsData();
    window.sessionStorage.setItem(
      "path",
      `/database/${props.type}s/${props.id}`
    );
  }, [props.type, props.id]);

  useEffect(() => {
    if (data) {
      initializeBreadcrumbs(currentPath, data.header.name);
    }
  }, [data]);

  return (
    <>
      {isCurrentUserEmpty && guestTooltipShow && (
        <GuestTooltip onClose={() => setGuestTooltipShow(false)} />
      )}
      <div
        className={`detail-layout ${
          isCurrentUserEmpty && guestTooltipShow
            ? "detail-layout__tooltip--show"
            : ""
        }`}
      >
        {data && (
          <Helmet>
            <title>
              {data.header.name} ｜ {labelToChinese[props.type]}
            </title>
          </Helmet>
        )}
        <div
          className={`detail-layout__sidebar ${
            isCurrentUserEmpty && guestTooltipShow
              ? "detail-layout__sidebar__tooltip--show"
              : ""
          }`}
        >
          <Sidebar />
        </div>
        <div
          className={`pro-layout__content-wrapper ${
            isCurrentUserEmpty && guestTooltipShow
              ? "pro-layout__content-wrapper__tooltip--show"
              : ""
          }`}
          id="database-detail"
        >
          <div className="detail-layout__content">
            <Breadcrumbs items={breadcrumbMap} />
            <DetailHeader />

            {data && (
              <div
                className={`detail-layout__info ${
                  !isShowAll ? "collecting" : ""
                }`}
              >
                {isShowAll ? (
                  <>
                    <DetailTabs />
                    <div className="detail-layout__info-news">
                      <Newsflash data={data.newsflash} />
                    </div>
                  </>
                ) : (
                  <DataCollecting label="机构" />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(DatabaseDetail);
