import React from "react";

import "./style.scss";

interface Props {
  header: string;
  headerImage: string;
  children: React.ReactNode;
}

const PricingCard: React.FC<Props> = (props: Props) => {
  return (
    <div className="pro-pricing-card">
      <img
        className="pro-pricing-card__header-image"
        src={props.headerImage}
        alt=""
      />
      <h2>{props.header}</h2>
      <div className="pro-pricing-card__content">{props.children}</div>
    </div>
  );
};

export default PricingCard;
