import React, { useState, useEffect } from "react";

import "./style.scss";
import closeImg from "../../../styles/images/inbox-close.png";
import grayCloseImg from "../../../styles/images/gray-close.png";

interface Props {
  visible: boolean;
  style?: React.CSSProperties;
  layerStyle?: React.CSSProperties;
  closeStyle?: React.CSSProperties;
  rootStyle?: React.CSSProperties;
  children?: React.ReactNode;
  onCancel: () => void;
  keepAlive?: boolean;
  layerClose?: boolean;
  closeable?: boolean;
  closeIcon?: string;
  grayClose?: boolean;
  needAuthCb?: null | (() => void);
}

const Dialog: React.FC<Props> = (props: Props) => {
  const {
    visible,
    style,
    layerStyle,
    closeStyle,
    rootStyle,
    onCancel = () => {},
    children,
    keepAlive = false,
    layerClose = true,
    closeable = true,
    closeIcon = "",
    grayClose = false,
    needAuthCb = null,
  } = props;
  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(visible);
    });
  }, [visible]);

  const originProps = {
    style: rootStyle,
  };

  if (needAuthCb) {
    Object.assign(originProps, {
      onClickCapture: (e: any) => {
        needAuthCb();
        e.stopPropagation();
      },
    });
  }

  return keepAlive || visible ? (
    <div className="dialog-root" {...originProps}>
      <div className={`dialog ${show ? "dialog--show" : ""}`} style={style}>
        {closeable && (
          <img
            style={closeStyle}
            className="close-icon"
            onClick={() => {
              closeable && onCancel();
            }}
            src={grayClose ? grayCloseImg : closeIcon || closeImg}
            alt="close"
          />
        )}
        {children}
      </div>
      <div
        style={layerStyle}
        className={`dialog-layer ${show ? "dialog-layer--show" : ""}`}
        onClick={() => {
          layerClose && onCancel();
        }}
      />
    </div>
  ) : (
    <></>
  );
};

export default Dialog;
