import React, { useEffect, useContext } from "react";
import { observer } from "mobx-react";

import "./dialog.scss";
import { Dialog } from "../shared";
import memberInboxStore from "../../stores/memberInboxStore";

const LoginDialog = () => {
  const { dialog, setDialog, wxLogin } = useContext(memberInboxStore);
  const onCancel = () => setDialog("login", false);

  useEffect(() => {
    // api();
  }, []);

  return (
    <Dialog
      visible={dialog.login}
      style={{ padding: "33px 22px", borderRadius: "10px" }}
      onCancel={onCancel}
      layerClose={false}
      closeable={false}
      closeStyle={{ color: "rgba(55,65,81,0.2)", top: "24px", right: "14px" }}
    >
      <div className="inbox-login-dialog">
        <p>您正在阅读机器之心 PRO 会员专属内容</p>
        <div
          onClick={() => {
            wxLogin();
          }}
        >
          立即登录
        </div>
      </div>
    </Dialog>
  );
};

export default observer(LoginDialog);
