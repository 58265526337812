import React from "react";

const SVG = ({
  fill = "#92929D",
  width = "100%",
  className = "",
  viewBox = "0 0 20 20",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <path
              d="M0 0L20 0 20 20 0 20z"
              transform="translate(-22 -654) translate(22 654)"
            />
            <path
              fill={fill}
              d="M10 1.667c-4.595 0-8.333 3.738-8.333 8.333 0 4.594 3.738 8.333 8.333 8.333 4.595 0 8.333-3.739 8.333-8.333 0-4.595-3.738-8.333-8.333-8.333m4.67 13.083c-1.17-.716-2.79-1.417-4.67-1.417s-3.5.702-4.67 1.417C4.1 13.54 3.333 11.858 3.333 10c0-3.676 2.991-6.667 6.667-6.667 3.676 0 6.667 2.991 6.667 6.667 0 1.858-.766 3.54-1.998 4.75m-7.878 1.09C7.68 15.38 8.78 15 10 15c1.22 0 2.318.38 3.21.84-.954.525-2.048.827-3.21.827-1.163 0-2.257-.302-3.21-.826M10 10c-.92 0-1.667-.748-1.667-1.667S9.081 6.666 10 6.666c.92 0 1.667.748 1.667 1.667 0 .92-.748 1.667-1.667 1.667m0-5C8.162 5 6.667 6.495 6.667 8.333S8.162 11.666 10 11.666s3.333-1.495 3.333-3.333S11.838 5 10 5"
              transform="translate(-22 -654) translate(22 654)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
