import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react";
import { Helmet } from "react-helmet";

import "./style.scss";
import { Sidebar } from "../shared";
import Card from "./Card";
import selectionRatingStore from "../../stores/selectionRatingStore";

const SelectionList: React.FC = () => {
  const { rankings, getRankings } = useContext(selectionRatingStore);

  useEffect(() => {
    getRankings();
  }, []);

  return (
    <div className="pro-layout-selections">
      <Helmet>
        <title>奖项评分</title>
      </Helmet>
      <div className="pro-layout-sidebar">
        <Sidebar />
      </div>
      <div className="pro-layout-wrapper">
        <div className="pro-layout-content">
          <h2>奖项评分</h2>
          <div className="pro-selection-content">
            {rankings.map((item, index) => (
              <Card data={item} key={index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(SelectionList);
