import React from "react";

import Icon from "../icon/Icon";
import "./style.scss";

interface Props {
  type: string;
  name: string;
}

const FileCard: React.FC<Props> = (props: Props) => {
  return (
    <div className="file-card">
      <Icon name={`pro-${props.type}`} />
      <span>{props.name}</span>
    </div>
  );
};

export default FileCard;
