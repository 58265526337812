import React from "react";

const SVG = ({
  fill = "#FFFFFF",
  width = "100%",
  className = "",
  viewBox = "0 0 14 14",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd">
      <g fill={fill}>
        <g>
          <g>
            <g>
              <path
                d="M2.1 0c.292 0 .552.183.655.45l.031.103L4.165 7h7.231l1.239-3.724c.126-.364.518-.56.889-.44.33.114.523.448.466.787l-.025.101-1.4 4.2c-.087.25-.307.432-.566.47L11.9 8.4H4.466l.301 1.4H11.9c1.155 0 2.1.945 2.1 2.1 0 1.155-.945 2.1-2.1 2.1-1.155 0-2.1-.945-2.1-2.1 0-.184.024-.363.07-.533l.056-.167H6.174c.084.217.126.455.126.7 0 1.155-.945 2.1-2.1 2.1-1.155 0-2.1-.945-2.1-2.1 0-.803.457-1.503 1.122-1.855l.145-.07L1.533 1.4H.7c-.385 0-.7-.315-.7-.7C0 .347.265.053.605.007L.7 0h1.4zm9.8 11.2c-.385 0-.7.315-.7.7 0 .385.315.7.7.7.385 0 .7-.315.7-.7 0-.385-.315-.7-.7-.7zm-7.7 0c-.385 0-.7.315-.7.7 0 .385.315.7.7.7.385 0 .7-.315.7-.7 0-.385-.315-.7-.7-.7zm5.77-7c.387 0 .7.313.7.7 0 .387-.313.7-.7.7H5.43c-.193 0-.368-.078-.495-.205S4.73 5.093 4.73 4.9c0-.387.313-.7.7-.7h4.54zm0-2.8c.387 0 .7.313.7.7 0 .387-.313.7-.7.7H5.43c-.193 0-.368-.078-.495-.205-.126-.127-.205-.302-.205-.495 0-.387.313-.7.7-.7h4.54z"
                transform="translate(-709 -374) translate(220 62) translate(462 301) translate(27 11)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
