import React, { useContext } from "react";
import { observer } from "mobx-react";

import "./style.scss";
import subscribeStore from "../../stores/subscribeStore";

const DrawerMeta = () => {
  const { meta } = useContext(subscribeStore);

  return (
    <div className="subscribe-drawer__meta">
      <h3>{meta.title}</h3>
      <div className="subscribe-drawer__summary">
        <span>
          {`更新到 ${meta.totalCount} 期`}
          {meta.count !== 0 ? (
            <>
              ，已收到 <label>{meta.count}</label> 期
            </>
          ) : null}
        </span>
        <span>
          最后更新:{" "}
          <span className="subscribe-drawer__summary-date">{`${meta.latestUpdateDate}`}</span>
        </span>
      </div>
      {meta.technologies.length > 0 && (
        <div className="subscribe-drawer__description">
          <label>核心技术:</label>
          <div>
            {meta.technologies.map((item, i) => (
              <span key={i}>{item}</span>
            ))}
          </div>
        </div>
      )}
      {meta.keywords.length > 0 && (
        <div className="subscribe-drawer__description">
          <label>关键字:</label>
          <div>
            {meta.keywords.map((item, i) => (
              <span key={i}>{item}</span>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(DrawerMeta);
