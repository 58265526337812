import React, { useContext } from "react";
import { observer } from "mobx-react";
import moment from "moment";
import { Table, Skeleton } from "antd";
import { AvatarCard, AuthWallPanel } from "../shared";
import databaseStore from "../../stores/databaseStore";
import { labelToChinese } from "../../constants/label_to_chinese";
import {
  INSTITUION_BRIEF,
  INDUSTRY_ROLES,
  DEVELOP_STAGE,
  COMPANY_SIZE,
  REGION,
  FOUNDEDAT,
  TRIGGER_DESC,
  TRIGGER_ASC,
  CACCEL_SORT,
} from "./../../constants/pro_constants";
import authStore from "../../stores/authStore";
const customSkeleton = (
  <Skeleton paragraph={{ rows: 1, width: "80%" }} active />
);

const InstitutionResultPanel = () => {
  const {
    resultList: { data, count },
    pagination,
    isLoading,
    type,
    fetchDatabaseList,
  } = useContext(databaseStore);
  const { isTrialUser } = useContext(authStore);

  const columns = [
    {
      title: labelToChinese[INSTITUION_BRIEF],
      dataIndex: "name",
      ellipsis: true,
      sorter: true,
      width: "25%",
      className: "database-list-panel__institution__brief",
      render: data =>
        !isLoading ? (
          <AvatarCard theme="company" data={data} avatarSize={32}>
            <div className="avatar-card__desc text-limit--one">
              {data.summary && data.summary}
            </div>
          </AvatarCard>
        ) : (
          customSkeleton
        ),
    },
    {
      title: labelToChinese[INDUSTRY_ROLES],
      ellipsis: true,
      width: "20%",
      dataIndex: "roles",
      render: data =>
        !isLoading ? (data && data.join(",")) || "-" : customSkeleton,
    },
    {
      title: labelToChinese[DEVELOP_STAGE],
      ellipsis: true,
      width: "15%",
      dataIndex: DEVELOP_STAGE,
      render: data => {
        return !isLoading ? data || "-" : customSkeleton;
      },
    },
    {
      title: labelToChinese[COMPANY_SIZE],
      ellipsis: true,
      sorter: true,
      width: "8%",
      dataIndex: COMPANY_SIZE,
      render: data => (!isLoading ? data || "-" : customSkeleton),
    },
    {
      title: labelToChinese[REGION],
      ellipsis: true,
      sorter: true,
      width: "8%",
      dataIndex: REGION,
      render: data => (!isLoading ? data || "-" : customSkeleton),
    },
    {
      title: labelToChinese[FOUNDEDAT],
      ellipsis: true,
      sorter: true,
      width: "10%",
      dataIndex: FOUNDEDAT,
      className: "database-list-panel__institution__founded-at",
      render: data =>
        !isLoading
          ? data.toString().indexOf("-") > -1
            ? "-"
            : moment
                .unix(data)
                .utcOffset(480)
                .format("YYYY-MM") || "-"
          : customSkeleton,
    },
  ];
  return (
    <div className="database-list-panel">
      <Table
        columns={columns}
        dataSource={data}
        locale={{
          triggerDesc: labelToChinese[TRIGGER_DESC],
          triggerAsc: labelToChinese[TRIGGER_ASC],
          cancelSort: labelToChinese[CACCEL_SORT],
        }}
        tableLayout="fixed"
        pagination={pagination}
        onChange={(pagination, filters, sorter) =>
          fetchDatabaseList(pagination, sorter)
        }
        title={() => (
          <div className="database-list-panel__title">找到 {count} 条结果</div>
        )}
        onRow={record => {
          return {
            onClick: () => {
              window.open(`/database/institutions/${record.key}`);
            },
          };
        }}
      />
      {isTrialUser && <AuthWallPanel label={type} />}
    </div>
  );
};

export default observer(InstitutionResultPanel);
