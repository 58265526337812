import React, { useContext } from "react";
import { Modal, Form, Input, DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/zh_CN";
import { observer } from "mobx-react";

import { Icon, Button } from "../shared";
import bluePanel from "../../styles/images/auth-modal-blue.png";
import crownIcon from "../../styles/images/pro-crown.svg";
import paymentStore from "../../stores/paymentStore";

interface Props {
  visible: boolean;
  onCancel: () => void;
}

const ContactModal: React.FC<Props> = (props: Props) => {
  const { handleOnContact } = useContext(paymentStore);

  return (
    <Modal
      className="contact-modal"
      visible={props.visible}
      footer={null}
      width={400}
      closeIcon={<Icon name="pro-close" size={16} color="#c1c7d0" />}
      onCancel={props.onCancel}
    >
      <img className="contact-modal__bg--blue" src={bluePanel} alt="" />
      <div className="contact-modal__header">
        <img src={crownIcon} alt="" />
        <h2>机构会员</h2>
      </div>
      <div className="contact-modal__content">
        <h3>请输入您的联系方式</h3>
        <Form
          className="contact-modal__form"
          layout="vertical"
          onFinish={values => {
            handleOnContact(values);
            props.onCancel();
          }}
        >
          <Form.Item
            rules={[{ required: true, message: "请输入真实姓名" }]}
            label="姓名"
            name="name"
          >
            <Input autocomplete="off" placeholder="请输入您的真实姓名" />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: "请输入电话" }]}
            label="电话"
            name="phone"
          >
            <Input autocomplete="off" placeholder="请输入您的常用电话" />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: "请输入邮箱" }]}
            label="邮箱"
            name="email"
          >
            <Input autocomplete="off" placeholder="请输入邮箱" />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: "请输入机构" }]}
            label="机构"
            name="company"
          >
            <Input autocomplete="off" placeholder="请输入机构全称" />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: "请输入职务" }]}
            label="职务"
            name="position"
          >
            <Input autocomplete="off" placeholder="请输入职务" />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: "请输入微信 ID" }]}
            label="微信 ID"
            name="wechat"
          >
            <Input autocomplete="off" placeholder="请输入微信 ID" />
          </Form.Item>
          <Form.Item label="方便的联系时间" name="communicate">
            <DatePicker
              locale={locale}
              showTime={{ format: "HH:mm" }}
              format="YYYY-MM-DD HH:mm"
            />
          </Form.Item>
          <Form.Item
            wrapperCol={{ offset: 6 }}
            className="contact-modal__submit"
          >
            <Button size="large" grid={18} htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default observer(ContactModal);
