import {
  INSTITUTION,
  INDUSTRIES,
  INDUSTRY_ROLES,
  COMPANY_SIZE,
  YEARS,
  REGION,
  TECHNOLOGIES,
  SOLUTION,
  AREAS,
  APPLY_FORMATS,
  DATASET,
  SOURCES,
  UPLOADED_AT,
  DATA_COUNT,
  DATA_TYPES,
  COLLECTIONS,
} from "../constants/pro_constants";
import moment from "moment";

const initFilter = [{ name: "不限", active: true }];

const formatDatabaseData = (type, initData) => {
  if (type === INSTITUTION) {
    return {
      filters: {
        [INDUSTRIES]: initFilter.concat(
          initData[INDUSTRIES].map(item => ({ name: item, active: false }))
        ),
        [YEARS]: initFilter.concat(
          initData[YEARS].map(item => ({ name: item, active: false }))
        ),
        [INDUSTRY_ROLES]: initFilter.concat(
          initData[INDUSTRY_ROLES].map(item => ({ name: item, active: false }))
        ),
        // Hide DEVELOP_STAGE per business requirement
        // [DEVELOP_STAGE]: initFilter.concat(
        //   Object.keys(initData[DEVELOP_STAGE]).map(name => {
        //     return {
        //       name,
        //       tips: initData[DEVELOP_STAGE][name],
        //       active: false,
        //     };
        //   })
        // ),
        [COMPANY_SIZE]: initFilter.concat(
          Object.keys(initData[COMPANY_SIZE]).map(name => {
            return {
              name,
              tips: initData[COMPANY_SIZE][name],
              active: false,
            };
          })
        ),
        // Hide collections per business requirement
        [COLLECTIONS]: initFilter.concat(
          initData[COLLECTIONS].map(item => ({ name: item, active: false }))
        ),
        [REGION]: initFilter.concat(
          initData[REGION].map(item => ({ name: item, active: false }))
        ),
        [TECHNOLOGIES]: initFilter.concat(
          initData[TECHNOLOGIES].map(item => ({ name: item, active: false }))
        ),
      },
    };
  }
  if (type === SOLUTION) {
    return {
      filters: {
        [INDUSTRIES]: initFilter.concat(
          initData[INDUSTRIES].map(item => ({ name: item, active: false }))
        ),
        // Hide collections per business requirement
        [COLLECTIONS]: initFilter.concat(
          initData[COLLECTIONS].map(item => ({ name: item, active: false }))
        ),
        [APPLY_FORMATS]: initFilter.concat(
          initData[APPLY_FORMATS].map(item => ({ name: item, active: false }))
        ),
        [TECHNOLOGIES]: initFilter.concat(
          initData[TECHNOLOGIES].map(item => ({ name: item, active: false }))
        ),
        [REGION]: initFilter.concat(
          initData[AREAS].map(item => ({ name: item, active: false }))
        ),
      },
    };
  }
  if (type === DATASET) {
    return {
      filters: {
        [SOURCES]: initFilter.concat(
          initData[SOURCES].map(item => ({ name: item, active: false }))
        ),
        [UPLOADED_AT]: initFilter.concat(
          initData[UPLOADED_AT].map(item => ({ name: item, active: false }))
        ),
        [DATA_COUNT]: initFilter.concat(
          initData[DATA_COUNT].map(item => ({ name: item, active: false }))
        ),
        [DATA_TYPES]: initFilter.concat(
          initData[DATA_TYPES].map(item => ({ name: item, active: false }))
        ),
        // [COLLECTIONS]: initFilter.concat(
        //   initData[COLLECTIONS].map(item => ({ name: item, active: false }))
        // ),
      },
    };
  }
};

const formatDataBaseListData = (type, initData) => {
  if (type === INSTITUTION) {
    return {
      data: initData.data.map(data => ({
        name: {
          name: data.name,
          avatarUrl: data.avatarUrl,
          summary: data.summary,
          isLasted: data.isLasted,
        },
        key: data.id,
        roles: data.roles,
        foundedAt: data.foundedAt,
        region: data.region,
        companySize: data.companySize,
        developStage: data.developStage,
      })),
      count: initData.totalCount,
      currentTotalCount: initData.currentTotalCount,
      pageSize: initData.pageSize,
      current: initData.current,
    };
  }

  if (type === SOLUTION) {
    return {
      data: initData.data.map(data => ({
        key: data.id,
        name: {
          name: data.name,
          isLasted: data.isLasted,
        },
        summary: data.summary,
        applyFormats: data.applyFormats,
        producer: data.producer,
        region: data.region,
      })),
      count: initData.totalCount,
      currentTotalCount: initData.currentTotalCount,
      pageSize: initData.pageSize,
      current: initData.current,
    };
  }

  if (type === DATASET) {
    return {
      data: initData.data.map(data => ({
        key: data.id,
        name: {
          name: data.name,
          isLasted: data.isLasted,
        },
        source: data.source,
        size: data.size,
        content: data.content,
        summary: data.summary,
        quantity: data.quantity,
        numerical: data.numericalValue,
        startTime: moment(data.startedAt).format("YYYY-MM-DD"),
        endTime: moment(data.finishedAt).format("YYYY-MM-DD"),
        fileType: data.contentType,
        fileUrl: data.fileUrl,
        amount: data.amount,
      })),
      count: initData.totalCount,
      currentTotalCount: initData.currentTotalCount,
      pageSize: initData.pageSize,
      current: initData.current,
    };
  }
};

export { formatDatabaseData, formatDataBaseListData };
