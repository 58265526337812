import React from "react";

const SVG = ({
  fill = "#0062FF",
  width = "100%",
  className = "",
  viewBox = "0 0 16 16",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill={fill} stroke="#0062FF" strokeWidth=".5">
      <path
        d="M8.015 15.2c-.37 0-.723-.162-.967-.444L.31 6.97C-.04 6.566-.1 5.98.16 5.513c0 0 0 0 0 0L2.88.643C3.1.248 3.518 0 3.969 0h8.054c.452 0 .87.247 1.091.645l2.725 4.892c.26.467.2 1.051-.147 1.455l-6.71 7.764c-.244.282-.597.444-.967.444zm-.023-1.278h.047l6.71-7.763-2.726-4.892H3.97L1.252 6.135l6.739 7.787h0z"
        transform="translate(1 1)"
      />
      <path
        d="M11.2 5.6H4.8v-.96c0-.353.239-.64.533-.64h5.334c.294 0 .533.287.533.64v.96h0z"
        transform="translate(1 1)"
      />
    </g>
  </svg>
);

export default SVG;
