import React from "react";

const SVG = ({
  fill = "#92929D",
  width = "100%",
  className = "",
  viewBox = "0 0 30 30",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd">
      <path d="M0 0H30V30H0z" />
      <path
        fill={fill}
        d="M17.36 16.232c-.229.19-.36.472-.36.769 0 1.997-.537 3.084-.956 3.627l-6.672-6.67c.55-.424 1.635-.957 3.628-.957.297 0 .578-.132.768-.36l4.3-5.159 4.45 4.451-5.159 4.299zm7.347-4.938l-6-6c-.199-.2-.472-.313-.752-.292-.282.012-.543.142-.724.358l-4.707 5.649c-4.103.155-5.293 2.292-5.419 2.544-.192.385-.117.85.188 1.155L10.586 18l-5.293 5.292c-.39.39-.39 1.023 0 1.414.195.195.451.293.707.293.255 0 .511-.098.707-.293L12 19.414l3.293 3.293c.192.192.448.293.707.293.152 0 .304-.035.447-.106.252-.126 2.39-1.315 2.544-5.418l5.65-4.707c.215-.181.345-.442.358-.724.012-.28-.093-.553-.292-.751z"
      />
    </g>
  </svg>
);

export default SVG;
