import React from "react";
import { Helmet } from "react-helmet";

import "./style.scss";
import { forceLogout } from "../../utils/tools";
import { LOGIN, REGISTER, AUTHENTICATION } from "../../constants/pro_constants";
import { Login, Register, Authentication, Header, Footer } from "../shared";
import { PRO } from "../../constants/pro_constants";
import { labelToChinese } from "../../constants/label_to_chinese";

interface Props {
  type: "login" | "register" | "authentication";
}

const Auth: React.FC<Props> = (props: Props) => {
  return (
    <div
      className="pro-auth-layout"
      onClick={() => {
        window.currentUser &&
          !window.currentUser.mobile_verified &&
          forceLogout(props.type === AUTHENTICATION);
      }}
    >
      <Helmet>
        <title>
          {props.type === LOGIN
            ? "登录"
            : props.type === REGISTER
            ? "注册"
            : "实名认证"}{" "}
          ｜ {labelToChinese[PRO]}
        </title>
      </Helmet>

      <Header />
      <div className="pro-auth">
        {props.type === LOGIN && <Login />}
        {props.type === REGISTER && <Register />}
        {props.type === AUTHENTICATION && <Authentication />}
      </div>
      <Footer />
    </div>
  );
};

export default Auth;
