/*
 * @Author: your name
 * @Date: 2020-10-12 13:57:08
 * @LastEditTime: 2020-10-19 16:45:12
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /Synced/frontend/sites/pro/stores/couponCodeStore.ts
 */
import { observable, action, computed } from "mobx";
import { createContext } from "react";
import { checkCode, exchangeCode } from "../api";
import { Notify } from "../components/shared";

interface codeProps {
  right: boolean;
  desc: string;
  value: string;
  category: string;
}

interface StoreProps {
  step: number;
  code: codeProps;
  next(): void;
  last(): void;
  changeCode(v: string): void;
  validateCode(): void;
  sureCode(): void;
}

class CouponCodeStore implements StoreProps {
  @observable public step = 0;
  @observable public code = { value: "", category: "", desc: "", right: false };

  @computed
  get disabled() {
    return this.code.value.length === 0;
  }

  @action public next = () => {
    this.step += 1;
  };

  @action public last = () => {
    this.step -= 1;
  };

  @action public changeCode = v => {
    this.code.value = v;
  };

  @action public validateCode = async () => {
    this.code.right = false;
    const result = await checkCode(this.code.value);
    if (result.status === "success") {
      this.code.right = true;
      result.data.desc = result.data.desc || "";
      Object.assign(this.code, result.data);
      this.next();
    } else {
      Notify.error({
        message: "系统通知",
        description: result.message,
      });
    }
  };

  @action public sureCode = async () => {
    const result = await exchangeCode(this.code.value);
    if (result.status === "success") {
      this.next();
    } else {
      Notify.error({
        message: "系统通知",
        description: result.message,
      });
    }
  };
}

export default createContext(new CouponCodeStore());
