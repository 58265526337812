import React from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";

import "./style.scss";
import { Button } from "../shared";

const MissInfo: React.FC = () => {
  const history = useHistory();

  const create = () =>
    history.push({
      pathname: "/register",
    });

  const login = () =>
    history.push({
      pathname: "/login",
      params: {
        url: "coupon-code",
      },
    });

  return (
    <div className="pro-coupon-code-miss">
      <h2>
        请先登录后，
        <br />
        再进行优惠码兑换。
      </h2>
      <div className="pro-coupon-code-area">
        <Button size="large" grid={10} onClick={login}>
          立即登录
        </Button>
        <p className="pro-coupon-code-title">尚无账号?立即</p>
        <p className="pro-coupon-code-create" onClick={create}>
          创建用户
        </p>
      </div>
    </div>
  );
};

export default observer(MissInfo);
