import { createContext } from "react";
import { observable, action, computed } from "mobx";
import _ from "lodash";

import { formatNewsTabs, formatNewsList } from "../utils/formatNewsData";
import { fetchNewsList, fetchCategoriesInNews } from "../api";
import { trackNewsEvent } from "../utils/ga_events";

class NewsStore {
  @observable tabs = [];
  @observable selectedTab = "全部";
  @observable newsList = [];
  @observable loading = true;
  @observable hasMore = true;
  @observable totalCount = 0;
  @observable pagination = {
    current: 1,
    pageSize: 50,
  };

  @action
  setSelectedTab = tab => {
    this.selectedTab = tab;
    this.pagination.current = 1;
  };

  @action
  initializeTabsData = async () => {
    const result = await fetchCategoriesInNews();
    this.tabs = formatNewsTabs(result.categories);
  };

  @action
  fetchNewsList = async () => {
    this.loading = true;
    const result = await fetchNewsList(this.selectedTab, this.getUrlParams);
    this.totalCount = result.totalCount;
    this.newsList = formatNewsList(result.data);
    trackNewsEvent("view_list", this.selectedTab, result.current);
    this.pagination.current = this.pagination.current + 1;
    this.loading = false;
  };

  @action
  fetchMoreData = async () => {
    this.loading = true;

    const result = await fetchNewsList(this.selectedTab, this.getUrlParams);
    trackNewsEvent("view_list", this.selectedTab, result.current);
    this.newsList = this.newsList.concat(formatNewsList(result.data));
    this.loading = false;

    if (this.totalCount !== 0 && this.isLoadedAll) {
      this.hasMore = false;
      this.loading = false;
    } else {
      this.pagination.current = this.pagination.current + 1;
    }
  };

  @computed
  get isLoadedAll() {
    return this.pagination.pageSize * this.pagination.current > this.totalCount;
  }

  @computed
  get getUrlParams() {
    let params = this.pagination.current
      ? `size=${this.pagination.pageSize}&page=${this.pagination.current}&`
      : "";

    return params;
  }

  @computed
  get isTabsEmpty() {
    return _.isEmpty(this.tabs);
  }

  @computed
  get isNewslistEmpty() {
    return _.isEmpty(this.newsList);
  }
}

export default createContext(new NewsStore());
