import React from "react";

const SVG = ({
  fill = "#0062FF",
  width = "100%",
  className = "",
  viewBox = "0 0 16 16",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" d="M1.6 6.4H14.4V15.200000000000001H1.6z" />
      <path
        fill={fill}
        d="M15.2 14.4V7.2c.442 0 .8-.358.8-.8 0-.443-.358-.8-.8-.8H.8c-.442 0-.8.357-.8.8 0 .442.358.8.8.8v7.2c-.442 0-.8.357-.8.8 0 .442.358.8.8.8h14.4c.442 0 .8-.358.8-.8 0-.443-.358-.8-.8-.8zM2.715 3.935c-.406.177-.877-.014-1.05-.42-.175-.406.014-.876.42-1.05l5.6-2.4c.201-.086.429-.086.63 0l5.6 2.4c.406.174.595.644.42 1.05-.13.303-.425.485-.736.485-.105 0-.212-.02-.314-.065L8 1.67 2.715 3.935zM2.4 14.4h11.2V7.2H2.4v7.2zM8 8.8c-.442 0-.8.357-.8.8V12c0 .442.358.8.8.8.442 0 .8-.358.8-.8V9.6c0-.443-.358-.8-.8-.8m3.2 0c-.442 0-.8.357-.8.8V12c0 .442.358.8.8.8.442 0 .8-.358.8-.8V9.6c0-.443-.358-.8-.8-.8m-6.4 0c-.442 0-.8.357-.8.8V12c0 .442.358.8.8.8.442 0 .8-.358.8-.8V9.6c0-.443-.358-.8-.8-.8"
      />
    </g>
  </svg>
);

export default SVG;
