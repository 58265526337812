import {
  DATABASE,
  HOME,
  TREND,
  SOLUTION,
  INVEST,
  FINANCING,
  CASE_DETAIL,
  PROJECT_BACKGROUND,
  CORE_INSTITUTION,
  RELATED_FILE,
  INSTITUTION_INTRO,
  PRODUCT_SOLUTIONS,
  CLIENTS,
  CORE_MEMBER,
  COOPERATION_INSTITUTION,
  ASSETS_EVENT,
  SOLUTION_DESC,
  SOLUTION_FEATURE,
  SOLUTION_TECHNOLOGY,
  SOLUTION_MATERIAL,
  SOLUTION_BUSINESS_CASE,
  INDUSTRIES,
  INDUSTRY_ROLES,
  DEVELOP_STAGE,
  COMPANY_SIZE,
  YEARS,
  REGION,
  TECHNOLOGIES,
  COLLECTIONS,
  INSTITUTION,
  APPLY_FORMATS,
  SOURCES,
  UPLOADED_AT,
  DATA_COUNT,
  DATA_TYPES,
  INSTITUION_BRIEF,
  ROLES,
  FOUNDEDAT,
  TRIGGER_DESC,
  TRIGGER_ASC,
  CACCEL_SORT,
  DATASET,
  OTHERS,
  FILE_TYPE,
  REPORT_SIZES,
  RESEARCH_TYPE,
  REPORT_INTRO,
  REPORT_CATALOG,
  CHART,
  REFER,
  NEWS,
  INSIGHT,
  RESEARCH,
  COMUNICATION,
  SUBSCRIBE,
  MESSAGE,
  EVENT,
  ZHIZHOU,
  SELECTIONS,
  OVERVIEW,
  FUNCTION,
  SERVICE,
  PRICE,
  SUCCEED_CASE,
  PRO,
  BUSINESS_CASE,
  CHINA,
  ABROAD,
  PROFILE,
  IDENTITY,
  CITY,
  SCHOOL,
  MAJOR,
  DEGREE,
  GRADUATE,
  COMPANY,
  POSITION,
  WORKEXPERIENCE,
  FULLNAME,
  MOBILE,
  WECHAT,
  EMAIL,
  KEYWORDS,
} from "./pro_constants";

export const labelToChinese = {
  [HOME]: "首页",
  [DATABASE]: "市场数据",
  [TREND]: "行业趋势",
  [REFER]: "洞察参考",
  [NEWS]: "动态",
  [INSIGHT]: "洞察",
  [SOLUTION]: "方案库",
  [RESEARCH]: "研究报告",
  [COMUNICATION]: "职业交流",
  [SUBSCRIBE]: "订阅中心",
  [MESSAGE]: "会员通讯",
  [EVENT]: "事件库",
  [ZHIZHOU]: "智周分析",
  [SELECTIONS]: "奖项评分",
  [INVEST]: "投资",
  [FINANCING]: "融资",
  [CASE_DETAIL]: "案例详情",
  [PROJECT_BACKGROUND]: "项目背景与客户痛点",
  [CORE_INSTITUTION]: "核心机构",
  [RELATED_FILE]: "相关材料",
  [INSTITUTION_INTRO]: "机构介绍",
  [PRODUCT_SOLUTIONS]: "产品与服务",
  [CLIENTS]: "客户",
  [CORE_MEMBER]: "核心成员",
  [COOPERATION_INSTITUTION]: "合作机构",
  [ASSETS_EVENT]: "资本事件",
  [SOLUTION_DESC]: "方案概述",
  [SOLUTION_FEATURE]: "功能简介",
  [SOLUTION_TECHNOLOGY]: "技术架构",
  [SOLUTION_MATERIAL]: "相关材料",
  [SOLUTION_BUSINESS_CASE]: "应用案例",
  [COLLECTIONS]: "热门场景",
  [INDUSTRIES]: "服务行业",
  [INDUSTRY_ROLES]: "产业链角色",
  [DEVELOP_STAGE]: "发展阶段",
  [COMPANY_SIZE]: "机构规模",
  [YEARS]: "成立时间",
  [REGION]: "所在区域",
  [TECHNOLOGIES]: "热门技术",
  [INSTITUTION]: "机构库",
  [APPLY_FORMATS]: "产品类型",
  [SOURCES]: "数据来源",
  [UPLOADED_AT]: "上传时间",
  [DATA_COUNT]: "数据条目",
  [DATA_TYPES]: "数据类别",
  [INSTITUION_BRIEF]: "机构名称",
  [ROLES]: "供应链角色",
  [FOUNDEDAT]: "成立时间",
  [TRIGGER_DESC]: "点击降序排列",
  [TRIGGER_ASC]: "点击升序排列",
  [CACCEL_SORT]: "取消排序",
  [DATASET]: "数据包",
  [OTHERS]: "其他",
  [FILE_TYPE]: "文件类型",
  [REPORT_SIZES]: "报告页数",
  [RESEARCH_TYPE]: "研究类型",
  [REPORT_INTRO]: "报告简介",
  [REPORT_CATALOG]: "报告目录",
  [CHART]: "图表",
  [OVERVIEW]: "概览",
  [FUNCTION]: "功能",
  [SERVICE]: "服务",
  [PRICE]: "定价",
  [SUCCEED_CASE]: "成功案例",
  [PRO]: "机器之心 Pro",
  [BUSINESS_CASE]: "应用案例",
  [CHINA]: "中国",
  [ABROAD]: "海外",
  [PROFILE]: "个人中心",
  [IDENTITY]: "身份",
  [CITY]: "所在地",
  [SCHOOL]: "毕业院校",
  [MAJOR]: "专业",
  [DEGREE]: "学位",
  [GRADUATE]: "毕业年份",
  [COMPANY]: "公司",
  [POSITION]: "职务",
  [WORKEXPERIENCE]: "工作年限",
  [FULLNAME]: "姓名",
  [MOBILE]: "手机号码",
  [WECHAT]: "微信 ID",
  [EMAIL]: "邮箱",
  [KEYWORDS]: "关键词",
};
