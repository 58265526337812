import React, { useContext } from "react";
import { observer } from "mobx-react";

import { Menu } from "../shared";
import databaseDetailStore from "../../stores/databaseDetailStore";
import SolutionIntro from "./SolutionIntro";
import SolutionFeatureIntro from "./SolutionFeatureIntro";
import TechArchitecture from "./TechArchitecture";
import SolutionMateria from "./SolutionMateria";
import SolutionBusinessCases from "./SolutionBusinessCases";

const SolutionTabs = () => {
  const {
    solutionTabs,
    scrolledDomId,
    scrollTo,
    isSolutionTechnologyTabContentEmpty,
    isSolutionMaterialTabContentEmpty,
    isSolutionBusinessCaseTabContentEmpty,
  } = useContext(databaseDetailStore);
  return (
    <div>
      <Menu
        items={solutionTabs}
        selectedKey={scrolledDomId}
        onClick={item => scrollTo(item.key)}
      />
      <SolutionIntro />
      <SolutionFeatureIntro />
      {!isSolutionTechnologyTabContentEmpty && <TechArchitecture />}
      {!isSolutionMaterialTabContentEmpty && <SolutionMateria />}
      {!isSolutionBusinessCaseTabContentEmpty && <SolutionBusinessCases />}
    </div>
  );
};

export default observer(SolutionTabs);
