import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, PricingCard, Icon } from "../shared";
import blueLevel2 from "../../styles/images/blue-bg-level2.png";
import blueLevel3 from "../../styles/images/blue-bg-level3.png";
import salesElementBg from "../../styles/images/sales-element-bg.svg";
import paymentStore from "../../stores/paymentStore";
import { observer } from "mobx-react";

const SalesPrice = () => {
  const { personProduct, fetchProductsInfo, showUpTips } = useContext(
    paymentStore
  );

  useEffect(() => {
    fetchProductsInfo();
  }, []);

  return (
    <div className="sales-price">
      <div className="sales-price__title">定价</div>
      <div className="sales-price__description">
        根据您的需求，可选择不同的权益
      </div>
      <img
        src={salesElementBg}
        alt=""
        className="sales-layout__element-bg-image"
      />
      <div className="sales-price__cards">
        <PricingCard header="PRO会员" headerImage={blueLevel2}>
          <div className="sales-price__person-vip">
            <div className="sales-price__person-vip__price">
              ￥{personProduct.price}
              <span>元 /年</span>
            </div>
            <ul className="sales-price__person-vip__services">
              <li>
                <Icon name="notify-success" size={26} />
                <span>专业市场数据</span>
              </li>
              <li>
                <Icon name="notify-success" size={26} />
                <span>千余研究报告</span>
              </li>
            </ul>
            {showUpTips && (
              <p className="up-tips-top">于2022年5月9日晚11点55分涨价至</p>
            )}
            {showUpTips && (
              <p className="up-tips-bottom">
                <span>￥1,999</span>
                <span>元 /年</span>
              </p>
            )}
          </div>
        </PricingCard>
        <PricingCard header="机构会员" headerImage={blueLevel3}>
          <div className="sales-price__institution-vip">
            <div className="sales-price__institution-vip__title">了解更多</div>
            <div className="sales-price__institution-vip__desc">
              深度定制，系统服务
            </div>
          </div>
        </PricingCard>
      </div>
      <Link to={"/pricing"} className="sales-price__review-detail">
        <Button grid={14} size="xlarge" shape="round">
          了解详情
        </Button>
      </Link>
    </div>
  );
};

export default observer(SalesPrice);
