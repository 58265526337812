import React, { useContext } from "react";
import { Checkbox } from "antd";
import researchUpdate from "../../styles/images/research-update.svg";
import researchStore from "../../stores/researchStore";
import { observer } from "mobx-react";

const ListHeader = () => {
  const {
    researchTypes,
    researchTypeDisabled,
    handleSelectReport,
    updateResearchList,
    newChartCount,
  } = useContext(researchStore);

  return (
    <div className="research-list-header">
      <div className="research-list-header__selections">
        <span className="research-list-header__type">内容类型：</span>
        <div className="research-list-header__checkbox">
          {researchTypes.map((item, i) => {
            return (
              <Checkbox
                checked={item.checked}
                disabled={researchTypeDisabled}
                key={i}
                onChange={e =>
                  updateResearchList(
                    e.target.checked,
                    "researchTypes",
                    item.name
                  )
                }
              >
                {item.name}
              </Checkbox>
            );
          })}
        </div>
        <div className="research-divider" />
        <div className="research-list-header__report">
          <Checkbox onChange={e => handleSelectReport(e.target.checked)}>
            只看报告
          </Checkbox>
        </div>
      </div>
      <div className="research-list__chart-image">
        <img src={researchUpdate} alt="今日新增" />
        <span>发现 {newChartCount} 个新报告</span>
      </div>
    </div>
  );
};

export default observer(ListHeader);
