import React, { useState, useEffect, useContext } from "react";
import { NavLink, Link } from "react-router-dom";
import { observer } from "mobx-react";
import { Tooltip } from "antd";
import SubMenu from "./SubMenu";
import { sidebarList } from "../../../constants/sidebar";
import { labelToChinese } from "../../../constants/label_to_chinese";
import Menu from "./Menu";
import SyncedLogo from "../../../styles/images/logo-synced-black.png";
import AvatarCard from "../avatar_card/AvatarCard";
import Icon from "../icon/Icon";
import AuthStore from "../../../stores/authStore";
import { SELECTIONS } from "../../../constants/pro_constants";
import ProImg from "../../../styles/images/pro.svg";
import "./style.scss";

const Sidebar: React.FC = () => {
  const windowInnerwidth = window.innerWidth;
  const [isSidebarShow, setIsSidebarShow] = useState(false);
  const [isShowProfilePopover, setIsShowProfilePopover] = useState(false);
  const {
    isCurrentUserEmpty,
    currentUser,
    trialDays,
    isTrialUser,
    onLogout,
  } = useContext(AuthStore);

  const [profileColor, setProfileColor] = useState("#92929D");
  const [logoutColor, setLogoutColor] = useState("#92929D");
  const autoFit = true;

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleResize = () => {
    setIsSidebarShow(false);
  };

  const setClassName = (type: string, className: string): void => {
    const map = {
      logout: setLogoutColor,
      profile: setProfileColor,
    };
    setTimeout(() => {
      map[type](className);
    }, 100);
  };

  const isInDetail =
    location.pathname.indexOf("/database/institutions/") !== -1 ||
    location.pathname.indexOf("/database/solutions/") !== -1 ||
    location.pathname.indexOf("/database/business-cases/") !== -1 ||
    location.pathname.indexOf("/research/") !== -1;

  let proSidebarList = sidebarList;

  if (currentUser && !currentUser.specialGuest) {
    proSidebarList = sidebarList.filter(item => item.label !== SELECTIONS);
  }

  return (
    <div className={`pro-sidebar ${isInDetail ? "pro-sidebar--detail" : ""}`}>
      <div className="pro-sidebar__action">
        {isSidebarShow ? (
          <span onClick={() => setIsSidebarShow(false)}>
            <Icon name="pro-close" size={20} />
          </span>
        ) : (
          <span onClick={() => setIsSidebarShow(true)}>
            <Icon name="pro-menu" size={20} />
          </span>
        )}
      </div>

      <Link className="pro-sidebar__logo" to={"/"}>
        <img src={SyncedLogo} alt="机器之心" />
        <img src={ProImg} />
      </Link>

      <div className={`pro-sidebar__menus ${isSidebarShow && "show"}`}>
        {proSidebarList.map(item => {
          return item.subMenu ? (
            <SubMenu
              key={item.label}
              menuLabel={item.label}
              menuIcon={item.icon}
            >
              <div className="pro-sidebar__sub-menu-content">
                {item.subMenu.map(subItem => {
                  if (!subItem.isEnable) {
                    return (
                      <div
                        className="pro-sidebar__sub-menu--ban"
                        key={subItem.label}
                      >
                        <span>{labelToChinese[subItem.label]}</span>
                        <Tooltip placement="bottom" title={"敬请期待"}>
                          <span>
                            <Icon name="pro-coming-soon" />
                          </span>
                        </Tooltip>
                      </div>
                    );
                  }
                  return (
                    <NavLink
                      activeClassName="actived"
                      key={subItem.label}
                      to={subItem.link}
                    >
                      {labelToChinese[subItem.label]}
                    </NavLink>
                  );
                })}
              </div>
            </SubMenu>
          ) : (
            <Menu {...item} key={item.label} />
          );
        })}
      </div>

      <div className="pro-sidebar__footer">
        <div className="pro-sidebar__search">
          <Icon name="pro-search" size={21} color="#92929D" />
          <span>搜索</span>
          <Tooltip placement="bottom" title={"敬请期待"}>
            <span>
              <Icon name="pro-coming-soon" />
            </span>
          </Tooltip>
        </div>
        {!isCurrentUserEmpty ? (
          <AvatarCard
            theme="person"
            avatarSize={windowInnerwidth > 1024 ? 32 : 20}
            isShowProfilePopover={isShowProfilePopover}
            shape="circle"
            autoFit={autoFit}
            data={{ name: currentUser.name, avatarUrl: currentUser.avatar_url }}
            onClick={() => setIsShowProfilePopover(!isShowProfilePopover)}
          />
        ) : (
          <Link className="pro-sidebar__guest-user" to="/login">
            <div className="pro-sidebar__guest-user__avatar">
              <Icon name="pro-guest" size={20} />
            </div>
            <span className="pro-sidebar__guest-user__text">
              游客你好，请登录
            </span>
          </Link>
        )}
        {isShowProfilePopover && (
          <ul className="pro-sidebar__user-center">
            <li
              className="pro-sidebar__profile"
              onMouseEnter={() => setClassName("profile", "#0062ff")}
              onMouseLeave={() => setClassName("profile", "#92929D")}
              onClick={() => {
                setClassName("profile", "#92929D");
                setIsShowProfilePopover(false);
              }}
            >
              <Link to="/profile">
                <Icon name="pro-profile" color={profileColor} />
                <span>个人中心</span>
              </Link>
            </li>
            <li
              className="pro-sidebar__logout"
              onMouseEnter={() => setClassName("logout", "#eb2835")}
              onMouseLeave={() => setClassName("logout", "#92929D")}
              onClick={() => {
                setClassName("logout", "#92929D");
                setIsShowProfilePopover(false);
                onLogout();
              }}
            >
              <Icon name="pro-logout" color={logoutColor} />
              <a>
                <span>退出登录</span>
              </a>
            </li>
          </ul>
        )}
        {isTrialUser && (
          <div className="pro-sidebar__trial">
            <Link to={"/pricing"} className="pro-sidebar__trial--hint">
              试用期还剩
              <span>{trialDays}</span>天
            </Link>

            <Link to={"/pricing"} className="pro-sidebar__trial--vip">
              成为会员
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(Sidebar);
