/*
 * @Author: your name
 * @Date: 2020-10-12 13:49:34
 * @LastEditTime: 2021-01-21 11:17:31
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /Synced/frontend/sites/pro/components/researchDetail/ReportCatalog.tsx
 */
import React, { useContext, useRef, useState } from "react";
import { observer } from "mobx-react";

import { REPORT_CATALOG } from "../../constants/pro_constants";
import { IconHeading, Icon } from "../shared";
import researchDetailStore from "../../stores/researchDetailStore";

const ReportCatalog = () => {
  const [arrowLeftColor, setArrowLeftColor] = useState("#C6CBD4");
  const [arrowRightColor, setArrowRightColor] = useState("#C6CBD4");
  const {
    updateActiveThumbnail,
    data: {
      tabs: { reportCatalog },
    },
  } = useContext(researchDetailStore);
  const thumbnailsRef = useRef(null);

  const handleLeftClick = () => {
    if (!(reportCatalog.activeIndex === 0)) {
      updateActiveThumbnail(reportCatalog.activeIndex - 1);
      if (reportCatalog.activeIndex < reportCatalog.images.length - 2) {
        thumbnailsRef.current.scrollTo({ right: 330 });
      }
    }
  };

  const handleRightClick = () => {
    if (!(reportCatalog.activeIndex === reportCatalog.images.length - 1)) {
      updateActiveThumbnail(reportCatalog.activeIndex + 1);
      if (reportCatalog.activeIndex > 2) {
        thumbnailsRef.current.scrollTo({ left: 330 });
      }
    }
  };

  return (
    <>
      <div className="database-detail__intro--bottom" />
      <div className="research-detail__block" id={REPORT_CATALOG}>
        <IconHeading icon="pro-catalog" title="报告目录" />
        <div className="report-catalog research-detail__block__content">
          <img
            className="report-catalog__main-image"
            src={reportCatalog.images[reportCatalog.activeIndex].preview}
            alt="报告目录"
          />
          <div className="report-catalog__thumbnails">
            <div
              onClick={handleLeftClick}
              onMouseEnter={() => {
                setArrowLeftColor("#0062ff");
              }}
              onMouseLeave={() => {
                setArrowLeftColor("#C6CBD4");
              }}
            >
              <Icon
                name="arrow-left-with-circle"
                size={20}
                color={arrowLeftColor}
              />
            </div>
            <div
              className="report-catalog__thumbnails-images"
              ref={thumbnailsRef}
            >
              {reportCatalog.images.map((item, i) => {
                const activeClass =
                  reportCatalog.activeIndex === i ? "thumbnail--active" : "";

                return (
                  <img
                    className={activeClass}
                    tabIndex={0}
                    key={i}
                    src={item.thumbnail}
                    alt="报告目录缩略图"
                    onClick={() => updateActiveThumbnail(i)}
                  />
                );
              })}
            </div>
            <div
              onClick={handleRightClick}
              onMouseEnter={() => {
                setArrowRightColor("#0062ff");
              }}
              onMouseLeave={() => {
                setArrowRightColor("#C6CBD4");
              }}
            >
              <Icon
                name="arrow-right-with-circle"
                size={20}
                color={arrowRightColor}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(ReportCatalog);
