import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";

import "./style.scss";
import lockIcon from "../../styles/images/lock-icon.png";
import pdfImg from "../../styles/images/inbox-pdf.svg";

interface Props {
  model: any;
  hasTags?: boolean;
}

const MessageCard: React.FC<Props> = (props: Props) => {
  const { model = {}, hasTags = false } = props;
  const history = useHistory();

  useEffect(() => {
    // api
  }, []);

  return (
    <div
      className="message-card"
      onClick={() => history.push(`/message/${model.id}`)}
    >
      <div className="message-card__info">
        <div className="message-card__title">
          <p dangerouslySetInnerHTML={{ __html: model.title }} />
          {!model.unlocked && <img src={lockIcon} alt="lock" />}
        </div>
        <p className="message-card__time">{model.publishedTime}</p>
      </div>
      {model.summary && (
        <p>
          {model.category && model.category === "report" && (
            <img src={pdfImg} alt="pdf" />
          )}
          {model.summary}
        </p>
      )}
      {hasTags && model.hotTags && model.hotTags.length > 0 && (
        <div className="message-card__tags">
          {model.hotTags.map((item: any, index: number) => (
            <div key={index}>{item}</div>
          ))}
        </div>
      )}
    </div>
  );
};

export default observer(MessageCard);
