import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Tabs } from "antd";

import { PRO, PROFILE } from "../../constants/pro_constants";
import { labelToChinese } from "../../constants/label_to_chinese";
import { Sidebar, Icon } from "../shared";
import PersonalInformation from "./PersonalInfomation";
import IdentifyInformation from "./IdentifyInformation";
import VipInformation from "./VipInformation";

import "./style.scss";
import identificationStore from "../../stores/identificationStore";

const { TabPane } = Tabs;
const TabPaneContent = [
  { title: "个人资料", icon: "profile-icon", content: <PersonalInformation /> },
  {
    title: "专业认证",
    icon: "identify-icon",
    content: <IdentifyInformation />,
  },
  {
    title: "会员身份",
    icon: "vip-icon",
    size: 18,
    content: <VipInformation />,
  },
];

const Profile = () => {
  const { initializeIdentity } = useContext(identificationStore);

  useEffect(() => {
    initializeIdentity();
  }, []);

  return (
    <div className="profile-layout">
      <Helmet>
        <title>
          {labelToChinese[PROFILE]}｜ {labelToChinese[PRO]}
        </title>
      </Helmet>

      <div className="profile-layout__sidebar">
        <Sidebar />
      </div>
      <div className="pro-layout__content-wrapper">
        <div className="profile-layout__content">
          <div className="profile-layout__content__title">个人中心</div>
          <div className="profile-layout__content__body">
            <Tabs
              defaultActiveKey="1"
              tabBarGutter={40}
              tabBarStyle={{ paddingLeft: 40 }}
            >
              {TabPaneContent.map((tabPanelItem, index) => {
                return (
                  <TabPane
                    tab={
                      <span className="profile-layout__content__tab">
                        <Icon
                          name={tabPanelItem.icon}
                          className={tabPanelItem.size ? "svg__vip-icon" : ""}
                        />
                        {tabPanelItem.title}
                      </span>
                    }
                    key={index + 1}
                  >
                    {tabPanelItem.content}
                  </TabPane>
                );
              })}
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
