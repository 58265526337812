import React, { useState, useEffect } from "react";
import Icon from "../icon/Icon";

import "./style.scss";

interface Props {
  line: string;
  height: number;
  className?: string | "";
  children: React.ReactNode;
}
const Collapse: React.FC<Props> = (props: Props) => {
  const [isCollapse, setIsCollapse] = useState(true);
  useEffect(() => {
    if (
      document.querySelector(`.${props.className}`).offsetHeight > props.height
    ) {
      setIsCollapse(true);
      document
        .querySelector(`.${props.className}`)
        .classList.add(`text-limit--${props.line}`);
    } else {
      setIsCollapse(false);
    }
  }, [props.height]);
  return (
    <div className="collapse-container">
      <div
        className={`${props.className} ${
          !isCollapse ? "collapse-container__content-expand" : ""
        } expand-content`}
      >
        {props.children}
      </div>
      {isCollapse && (
        <span
          className="collapse-container__action"
          onClick={() => setIsCollapse(false)}
        >
          <span>显示更多</span>
          <Icon name="pro-arrow-down" color="#6b8aba" />
        </span>
      )}
    </div>
  );
};

export default Collapse;
