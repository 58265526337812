import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";

import ProfileStore from "../../stores/ProfileStore";
import { labelToChinese } from "../../constants/label_to_chinese";
import { Button } from "../shared";
import { observer } from "mobx-react";

const IdentifyInformation = () => {
  const {
    certificationMeta,
    certificationUserMeta,
    fetchCertificationInfo,
  } = useContext(ProfileStore);

  const IDENTITY = {
    studying: "在读",
    working: "在职",
  };

  useEffect(() => {
    fetchCertificationInfo();
  }, []);

  return (
    <div className="pro-cetification">
      <div className="pro-cetification__title">认证信息</div>
      {certificationMeta.map((item, i) => {
        return (
          <div
            className={
              i === certificationMeta.length - 1
                ? "pro-cetification__item--last"
                : "pro-cetification__item"
            }
            key={i}
          >
            <label>{labelToChinese[item.name]}</label>
            <span>
              {item.name === "category" ? IDENTITY[item.value] : item.value}
            </span>
          </div>
        );
      })}
      <div className="pro-cetification__title">个人资料</div>
      {certificationUserMeta.map((item, i) => {
        return (
          <div
            className={
              i === certificationUserMeta.length - 1
                ? "pro-cetification__item--last"
                : "pro-cetification__item"
            }
            key={i}
          >
            <label>{labelToChinese[item.name]}</label>
            <span>{item.value}</span>
          </div>
        );
      })}
      <Link to="/identification/step1">
        <Button size="large" grid={8}>
          <span style={{ fontWeight: 500 }}>修改认证资料</span>
        </Button>
      </Link>
    </div>
  );
};

export default observer(IdentifyInformation);
