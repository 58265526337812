import React, { useContext, useRef } from "react";
import { Form, Input } from "antd";
import { observer } from "mobx-react";

import "./style.scss";
import { useHistory } from "react-router-dom";
import { Button } from "..";
import authStore from "../../../stores/authStore";
interface Props {
  test?: any;
}

const Authentication: React.FC<Props> = () => {
  const [form] = Form.useForm();
  const { params } = useHistory().location;
  const { url } = params ? params : { url: "" };
  const { onAuthSubmit, updateCaptcha, onAuthSendCode, time } = useContext(
    authStore
  );
  const captchaRef = useRef(null);

  return (
    <div
      className="pro-authentication"
      onClick={event => event.stopPropagation()}
    >
      <Form form={form} onFinish={values => onAuthSubmit(values, url)}>
        <h2>实名认证</h2>
        <h3>根据国家法律法规要求，绑定手机号获得更高级的安全保护。</h3>
        <Form.Item
          hasFeedback
          rules={[
            { required: true, message: "请输入正确的手机号码" },
            {
              pattern: /^1[3456789]\d{9}$/,
              message: "请输入正确的手机号码",
            },
          ]}
          name="mobile"
        >
          <Input placeholder="手机号码" />
        </Form.Item>
        <div className="pro-authentication__code">
          <Form.Item
            hasFeedback
            rules={[
              { required: true, message: "请输入正确的验证码" },
              { min: 5, message: "验证码是 5 位" },
            ]}
            name="_rucaptcha"
          >
            <Input autoComplete="off" placeholder="图形验证码" />
          </Form.Item>
          <img
            className="pro-authentication__captcha"
            ref={captchaRef}
            src="/rucaptcha/"
            alt="图形验证码"
            onClick={e => updateCaptcha(captchaRef.current, e)}
          />
        </div>
        <div className="pro-authentication__code">
          <Form.Item
            hasFeedback
            rules={[{ required: true, message: "请输入正确的短信验证码" }]}
            name="phone_verify_code"
          >
            <Input autoComplete="off" placeholder="验证码" />
          </Form.Item>
          <div
            className={`send-code ${time > 0 && "send-code--time"}`}
            onClick={() => {
              if (time <= 0) {
                const values: any = form.getFieldsValue();
                const { mobile, _rucaptcha } = values;
                onAuthSendCode({ mobile, _rucaptcha });
              }
            }}
          >
            {time > 0 ? `${time}s 后重新发送` : "发送验证码"}
          </div>
        </div>
        <Form.Item className="pro-authentication__submit">
          <Button isFullWidth size="large" htmlType="submit">
            提交
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default observer(Authentication);
