import React from "react";
import { FUNCTION } from "../../constants/pro_constants";
import SalesMarketImg from "../../styles/images/sales-market.png";
import SalesDocumentImg from "../../styles/images/sales-document.png";
import SalesTrendImg from "../../styles/images/sales-trend.png";

const functionItems = [
  {
    title: "市场信息",
    description:
      "查找人工智能公司及解决方案，了解AI应用现状，寻找潜在合作伙伴。",
    imageUrl: SalesMarketImg,
  },
  {
    title: "研究报告",
    description: "阅读报告，获取专业洞见，辅助人工智能战略决策。",
    imageUrl: SalesDocumentImg,
  },
  {
    title: "行业趋势",
    description: "了解实时资讯，阅读深度文章把握人工智能发展趋势。",
    imageUrl: SalesTrendImg,
  },
];
const SalesFunction = () => {
  return (
    <div className="sales-function" id={FUNCTION}>
      {functionItems.map(item => {
        return (
          <div className="sales-function__item" key={item.title}>
            <div className="sales-function__item__info">
              <div className="sales-function__item__title">{item.title}</div>
              <div className="sales-function__item__desc">
                {item.description}
              </div>
            </div>
            <div className="sales-function__item__image">
              <img src={item.imageUrl} alt={item.title} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SalesFunction;
