import React, { useState } from "react";

import "./style.scss";
import { Icon } from "../shared";

interface Props {
  name: string;
  volume?: number;
  date?: string;
  fileUrl?: string;
  hasDivider?: boolean;
  onClick?: () => {};
}

const DrawerListItem: React.FC<Props> = (props: Props) => {
  const [downloadColor, setDownloadColor] = useState({});

  return (
    <div
      className={`subscribe-drawer__list-item ${
        props.hasDivider ? "list-item--divider" : ""
      }`}
    >
      <div className="subscribe-drawer__list-item-meta">
        <span className="subscribe-drawer__list-item-name text-limit--one">
          <span className="subscribe-drawer__list-item-volume">
            Vol.{props.volume}
          </span>
          {props.name}
        </span>
        <div className="subscribe-drawer__list-item-date">
          <span>{props.date}</span>
        </div>
      </div>
      <a
        href={props.fileUrl}
        className="subscribe-drawer__list-item-download"
        onClick={() => props.onClick()}
        onMouseEnter={() => {
          setDownloadColor({ [props.name]: "#0062ff" });
        }}
        onMouseLeave={() => {
          setDownloadColor({ [props.name]: "#C6CBD4" });
        }}
      >
        <Icon name="pro-file-download" color={downloadColor[props.name]} />
      </a>
    </div>
  );
};

DrawerListItem.defaultProps = {
  hasDivider: false,
};

export default DrawerListItem;
