import React, { useContext } from "react";
import moment from "moment";

import { Icon, Button, Tag, IconHeading, Collapse } from "../shared";
import globalAward from "../../styles/images/global-award.svg";
import rank2020 from "../../styles/images/rank-2020.png";
import DatabseDetailStore from "../../stores/databaseDetailStore";
import { observer } from "mobx-react";
import { PROTOCOL, TOP_DOMAIN } from "../../api";
import { REGION } from "../../constants/pro_constants";
import { labelToChinese } from "../../constants/label_to_chinese";
import { Tooltip } from "antd";
import rank2021 from "../../styles/images/rank-2021.png";
import rank2022 from "../../styles/images/rank-2022.png";

const getUrl = url => {
  if (
    url.substr(0, 7).toLowerCase() == "http://" ||
    url.substr(0, 8).toLowerCase() == "https://"
  ) {
    return url;
  } else {
    return "http://" + url;
  }
};

const InstitutionHeader = () => {
  const {
    data: { header },
    id,
  } = useContext(DatabseDetailStore);

  const hasAward = header.award2019List.length > 0;
  const hasTechnologies = header.technologies.length > 0;
  const hasInfomation =
    header.registerName ||
    header.region ||
    header.officialWebsite ||
    header.foundedAt;

  return (
    <div className="institution-header">
      <div className="institution-header__title">
        <div className="institution-header__title-left">
          <a>
            <img src={header.avatar} alt={header.name} />
          </a>
          <div className="institution-header__title-text">
            <div className="institution-header__title-text__synced">
              <h4>{header.name}</h4>
              {header.contactable && <Icon name="pro-safe" />}
              {hasAward && <Icon name="pro-award" />}
              <span>{header.aliasName}</span>
              <span>|</span>
              <span className="institution-header__avatar-desc text-limit--one">
                <Tooltip
                  overlayStyle={{ width: 450 }}
                  placement="topLeft"
                  trigger="hover"
                  title={header.avatarDesc}
                >
                  {header.avatarDesc}
                </Tooltip>
              </span>
            </div>
            <div className="institution-header__title__tags">
              {header.fields &&
                header.fields.map(item => {
                  return <Tag key={item}>{item}</Tag>;
                })}
            </div>
          </div>
          <div className="mobile-institution-header__title-text">
            <div className="mobile-institution-header__title-text__synced">
              <h3>{header.name}</h3>
              <span>{header.aliasName}</span>
            </div>
            <span className="mobile-institution-header__avatar-desc">
              {header.avatarDesc}
            </span>
          </div>
        </div>
        {header.contactable && (
          <Button
            size="small"
            grid={4}
            type="ghost"
            onClick={() =>
              window.open(`${PROTOCOL}//jigou.${TOP_DOMAIN}?iid=${id}`)
            }
          >
            立即联系
          </Button>
        )}
      </div>
      <div className="mobile-institution-header__title__tags">
        {header.fields &&
          header.fields.map((item, index) => {
            return <Tag key={index}>{item}</Tag>;
          })}
      </div>
      <Collapse
        line="three"
        height={70}
        className="institution-header__summary"
      >
        {header.summary}
      </Collapse>
      {hasTechnologies && (
        <div className="institution-header__keywords">
          核心技术：
          {header.technologies.map(item => {
            return <span key={item.id}>{item.name}</span>;
          })}
        </div>
      )}
      {hasInfomation && (
        <div className="institution-header__information">
          <div className="institution-header__block">
            <div className="institution-header__information-item">
              <label htmlFor="">注册名称：</label>
              <span>{header.registerName}</span>
            </div>
            <div className="institution-header__information-item">
              <label htmlFor="">{labelToChinese[REGION]}：</label>
              <span>{header.region}</span>
            </div>
          </div>
          <div className="institution-header__block">
            <div className="institution-header__information-item">
              <label htmlFor="">官网：</label>
              <a href={getUrl(header.officialWebsite)} target="_blank">
                {header.officialWebsite}
              </a>
            </div>
            <div className="institution-header__information-item">
              <label htmlFor="">成立时间：</label>
              <span>
                {header.foundedAt &&
                header.foundedAt.toString().indexOf("-") > -1
                  ? "-"
                  : moment
                      .unix(header.foundedAt)
                      .utcOffset(480)
                      .format("YYYY-MM")}
              </span>
            </div>
          </div>
        </div>
      )}
      {((header.award2019List && header.award2019List.length > 0) ||
        (header.award2020List && header.award2020List.length > 0) ||
        (header.award2021List && header.award2021List.length > 0) ||
        (header.award2022List && header.award2022List.length > 0) ||
        (header.award2023List && header.award2023List.length > 0) ||
        (header.award2024List && header.award2024List.length > 0)) && (
        <div className="institution-header__awards">
          <IconHeading icon="pro-institution-honour" title="相关荣誉" />
          <div className="institution-header__awards__list">
            {header.award2022List &&
              header.award2022List.map(item => {
                return (
                  <div key={item} className="institution-header__awards__item">
                    <img src={rank2022} alt="awards" />
                    <span className="institution-header__awards--2022">
                      {item}
                    </span>
                  </div>
                );
              })}
            {header.award2021List &&
              header.award2021List.map(item => {
                return (
                  <div key={item} className="institution-header__awards__item">
                    <img src={rank2021} alt="awards" />
                    <span className="institution-header__awards--2021">
                      {item}
                    </span>
                  </div>
                );
              })}
            {header.award2020List &&
              header.award2020List.map(item => {
                return (
                  <div key={item} className="institution-header__awards__item">
                    <img src={rank2020} alt="awards" />
                    <span className="institution-header__awards--one">
                      {item}
                    </span>
                  </div>
                );
              })}
            {header.award2019List &&
              header.award2019List.map(item => {
                return (
                  <div key={item} className="institution-header__awards__item">
                    <img src={globalAward} alt="awards" />
                    <span className="institution-header__awards--two">
                      {item}
                    </span>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(InstitutionHeader);
