import React from "react";

import "./style.scss";

interface Props {
  text?: string;
}
const EmptyImport: React.FC<Props> = (props: Props) => {
  return (
    <div className="empty-import">
      <span>{props.text}</span>
      <a href="" className="empty-import__link">
        请告诉我们，让我们做得更好
      </a>
    </div>
  );
};

EmptyImport.defaultProps = {
  text: "数据收集中",
};

export default EmptyImport;
