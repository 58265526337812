import React from "react";

const SVG = ({
  fill = "#C6CBD4",
  width = "100%",
  className = "",
  viewBox = "0 0 16 16",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        id="pro-file-detail"
        d="M14.166 3.434l-3.2-3.2C10.816.084 10.612 0 10.4 0h-8c-.442 0-.8.358-.8.8v14.4c0 .442.358.8.8.8h11.2c.442 0 .8-.358.8-.8V4c0-.212-.084-.416-.234-.566zM12.8 14.4H3.2V1.6h6.869L12.8 4.331V14.4zm-2.4-4H5.6c-.442 0-.8.358-.8.8 0 .442.358.8.8.8h4.8c.442 0 .8-.358.8-.8 0-.442-.358-.8-.8-.8M4.8 8c0 .442.358.8.8.8h4.8c.442 0 .8-.358.8-.8 0-.442-.358-.8-.8-.8H5.6c-.442 0-.8.358-.8.8m.8-2.4h.8c.442 0 .8-.358.8-.8 0-.442-.358-.8-.8-.8h-.8c-.442 0-.8.358-.8.8 0 .442.358.8.8.8"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <use fill={fill} xlinkHref="#pro-file-detail" />
    </g>
  </svg>
);

export default SVG;
