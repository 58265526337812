import React from "react";
import { Link } from "react-router-dom";

import "./style.scss";
import { Button } from "../shared";
import { observer } from "mobx-react";

interface Props {
  data: any;
}

const Card: React.FC<Props> = (props: Props) => {
  const { data } = props;
  return (
    <div className="pro-selection-card">
      <img src={data.coverImageUrl} alt={data.name} />
      <div className="pro-selection-card--info">
        <h4>{data.name}</h4>
        <Link to={`/awards?id=${data.id}`}>
          <Button grid={10} type="ghost">
            参与评分
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default observer(Card);
