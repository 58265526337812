import React from "react";
import { IconHeading, AvatarCard } from "../shared";
import { CORE_MEMBER } from "../../constants/pro_constants";

const CoreMemberIntro: React.FC = props => {
  return (
    <>
      {props.coreMembers && (
        <>
          <div
            className="database-detail__block client__intro"
            id={CORE_MEMBER}
          >
            <IconHeading icon="pro-member" title="核心成员" />
            <div className="database-detail__block__content client__list core-member__list">
              {props.coreMembers.map((ele, i) => (
                <div key={i} className="core-member__item">
                  <AvatarCard theme="person" shape="circle" data={ele}>
                    <div className="avatar-card__desc text-limit--two">
                      {ele.summary && ele.summary}
                    </div>
                  </AvatarCard>
                </div>
              ))}
            </div>
          </div>
          <div className="database-detail__intro--bottom" />
        </>
      )}
    </>
  );
};

export default CoreMemberIntro;
