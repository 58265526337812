import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";
import ProRoute from "./routes";

import "./styles/app.scss";
import { ScrollToTop } from "./components/shared";
import { getWh, getQuery } from "./utils/tools";
import eruda from "eruda";

const setVh = () => {
  const set = () =>
    document.body.style.setProperty("--my-vh", `${getWh().height}px`);
  set();
  window.addEventListener("resize", () => {
    set();
  });
};

setVh();

const model = getQuery("model");

if (model === "eruda") {
  eruda.init();
}

const Pro = () => {
  render(
    <Router>
      <LastLocationProvider>
        <ScrollToTop />
        <Switch>
          <ProRoute />
        </Switch>
      </LastLocationProvider>
    </Router>,
    document.getElementById("pro")
  );
};

export default Pro;
