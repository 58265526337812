import React, { useEffect, useContext } from "react";
import { observer } from "mobx-react";

import "./message-detail.scss";
import messageStore from "../../stores/messageStore";
import lockIcon from "../../styles/images/inbox__lock-icon.png";

const PrivateArea: React.FC = () => {
  const { detail } = useContext(messageStore);

  useEffect(() => {
    // api
  }, []);

  return !detail.fullContent ? (
    <div className="message-private-area">
      <div className="message-private-area__locked">
        <img className="message-private-area__icon" src={lockIcon} alt="lock" />
        <p>尚无阅读权限</p>
      </div>
      <div
        className="message-private-area__btn"
        onClick={() => window.open(`/pricing`)}
      >
        成为PRO会员，畅读完整内容
      </div>
    </div>
  ) : (
    <></>
  );
};

export default observer(PrivateArea);
