import React, { useEffect, useContext } from "react";
import { observer } from "mobx-react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

import "./style.scss";
import { Sidebar } from "../shared";
import authStore from "../../stores/authStore";
import messageStore from "../../stores/messageStore";
import { PRO, REFER } from "../../constants/pro_constants";
import { labelToChinese } from "../../constants/label_to_chinese";
import noteImg from "../../styles/images/refer-notebook.png";
import ReferCard from "./ReferCard";
import Loading from "../shared/loading/Loading";

const Refer: React.FC = () => {
  const history = useHistory();
  const { fetchMemberInfo } = useContext(authStore);
  const { list, pagination, loading, fetchList, nextPage } = useContext(
    messageStore
  );

  const onScrollEvent = () => {
    if (!loading && pagination.hasNextPage) {
      const dom = document.getElementsByClassName(
        "refer-layout__content-wrapper"
      )[0];
      if (
        dom.scrollTop + dom.clientHeight > dom.scrollHeight * 0.9 &&
        list.length < pagination.total
      ) {
        nextPage("refer");
      }
    }
  };

  useEffect(() => {
    // revert();
    //!todo: 因为后端接口并发会导致卡死,等后端修复后去掉定时器;
    setTimeout(() => {
      fetchMemberInfo();
    }, 500);
    fetchList("refer"); //参考
    window.sessionStorage.removeItem("id");
    window.sessionStorage.removeItem("login");
  }, []);

  return (
    <div className="refer-layout">
      <Helmet>
        <title>
          {labelToChinese[REFER]}｜ {labelToChinese[PRO]}
        </title>
      </Helmet>
      <div className="refer-layout__sidebar">
        <Sidebar />
      </div>
      <div
        className="refer-layout__content-wrapper"
        id="refer-content"
        onScrollCapture={onScrollEvent}
      >
        <div className="refer-layout__content">
          <div className="refer__header">
            {labelToChinese[REFER]}
            <div
              onClick={() => {
                history.push(`/trend/insight`);
              }}
            >
              <img src={noteImg} alt="btn" />
              过往资料
            </div>
          </div>
          <div className="refer__content">
            <div className="refer__list">
              {list &&
                list.length > 0 &&
                list.map((item: any, index: number) => (
                  <ReferCard key={index} model={item} />
                ))}
              {loading && <Loading />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(Refer);
