import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  Tag,
  Collapses,
  EmptyImport,
  AuthWallModal,
  Collapse,
  IconHeading,
} from "../shared";
import globalAward from "../../styles/images/global-award.svg";
import DatabseDetailStore from "../../stores/databaseDetailStore";
import { observer } from "mobx-react";
import authStore from "../../stores/authStore";
import rank2020 from "../../styles/images/rank-2020.png";
import rank2021 from "../../styles/images/rank-2021.png";
import rank2022 from "../../styles/images/rank-2022.png";

const BusinessCaseHeader: React.FC = () => {
  const {
    data: { header },
  } = useContext(DatabseDetailStore);
  const { isTrialUser } = useContext(authStore);

  const [isShowAuthWallModal, setIsShowAuthWallModal] = useState(false);
  const isShowModal = isTrialUser && isShowAuthWallModal;

  const hasTechnologies = header.technologies.length > 0;
  const hasSolutions = header.solutions && header.solutions.length > 0;
  const hasKeyData = header.keyData && header.keyData.length > 0;

  const [isShowCollapse, setIsShowCollapse] = useState(false);
  const [isShowAll, setIsShowAll] = useState(false);
  const styles =
    isShowCollapse && !isShowAll
      ? { overflow: "hidden", maxHeight: "76px" }
      : {};
  useEffect(() => {
    const listDom = document.getElementsByClassName(
      "business-case__header-solutions__list"
    )[0];
    const categoryHeight = listDom && listDom.clientHeight;
    if (categoryHeight > 76 && header.solutions.length > 2) {
      setIsShowCollapse(true);
    }
  }, []);

  return (
    <div className="business-case-header">
      <h2>{header.name}</h2>
      <div className="business-case-header__tags">
        {header.fields &&
          header.fields.map(item => {
            return <Tag key={item}>{item}</Tag>;
          })}
      </div>
      <Collapse line="three" height={70} className="business-case__summary">
        {header.summary}
      </Collapse>
      {hasTechnologies && (
        <div className="business-case-header__keywords">
          核心技术：
          {header.technologies.map(item => {
            return <span key={item.id}>{item.name}</span>;
          })}
        </div>
      )}
      <div className="business-case__header-solutions">
        <div className="business-case__header-solutions__list">
          <span className="business-case__header-solutions__title">
            所用解决方案
          </span>
          {hasSolutions ? (
            <>
              <div
                className="business-case__header-solutions__list"
                style={styles}
              >
                {header.solutions.map(item => (
                  <div
                    key={item}
                    className="business-case__header-solutions__list-item"
                  >
                    {isTrialUser ? (
                      <a
                        onClick={() => {
                          setIsShowAuthWallModal(true);
                        }}
                      >
                        {item.title}
                      </a>
                    ) : (
                      <Link
                        to={`/database/solutions/${item.id}`}
                        target="_blank"
                      >
                        {item.title}
                      </Link>
                    )}
                    <div className="business-case__header-solutions__list-item-icon">
                      <img src={item.avatarURL} alt="" />
                      {item.producerName}
                    </div>
                  </div>
                ))}
              </div>
              {isShowCollapse && (
                <Collapses
                  openedText="收起"
                  closedText="展开"
                  isOpen={false}
                  onChange={() => setIsShowAll(!isShowAll)}
                />
              )}
            </>
          ) : (
            <EmptyImport />
          )}
        </div>

        <div className="pro-divider" />
        <div className="business-case__header-solutions__info">
          <span className="business-case__header-solutions__title">
            关键数据
          </span>
          <div className="business-case__header-solutions__info-items">
            {hasKeyData ? (
              header.keyData.map(item => {
                return (
                  <div
                    key={item.key}
                    className="business-case__header-solutions__info-item"
                  >
                    <span className="business-case__header-solutions__info-count">
                      {item.value}
                    </span>
                    <span className="business-case__header-solutions__info-item-key">
                      {item.key}
                    </span>
                  </div>
                );
              })
            ) : (
              <EmptyImport />
            )}
          </div>
        </div>
      </div>

      {/* {header.award2019List.map(item => {
        return (
          <div key={item} className="institution-header__awards">
            <img src={globalAward} alt="awards" />
            <span>三十大最佳 AI 应用案例</span>
          </div>
        );
      })} */}

      {((header.award2019List && header.award2019List.length > 0) ||
        (header.award2020List && header.award2020List.length > 0) ||
        (header.award2021List && header.award2021List.length > 0) ||
        (header.award2022List && header.award2022List.length > 0) ||
        (header.award2023List && header.award2023List.length > 0) ||
        (header.award2024List && header.award2024List.length > 0)) && (
        <div className="institution-header__awards">
          <IconHeading icon="pro-institution-honour" title="相关荣誉" />
          <div className="institution-header__awards__list">
            {header.award2022List &&
              header.award2022List.map(item => {
                return (
                  <div key={item} className="institution-header__awards__item">
                    <img src={rank2022} alt="awards" />
                    <span className="institution-header__awards--2022">
                      {item}
                    </span>
                  </div>
                );
              })}
            {header.award2021List &&
              header.award2021List.map(item => {
                return (
                  <div key={item} className="institution-header__awards__item">
                    <img src={rank2021} alt="awards" />
                    <span className="institution-header__awards--2021">
                      {item}
                    </span>
                  </div>
                );
              })}
            {header.award2020List &&
              header.award2020List.map(item => {
                return (
                  <div key={item} className="institution-header__awards__item">
                    <img src={rank2020} alt="awards" />
                    <span className="institution-header__awards--one">
                      {item}
                    </span>
                  </div>
                );
              })}
            {header.award2019List &&
              header.award2019List.map(item => {
                return (
                  <div key={item} className="institution-header__awards__item">
                    <img src={globalAward} alt="awards" />
                    <span className="institution-header__awards--two">
                      {item}
                    </span>
                  </div>
                );
              })}
          </div>
        </div>
      )}

      {isShowModal && (
        <AuthWallModal
          visible={isShowAuthWallModal}
          title="无法查看当前数据"
          description={` 您当前的用户类型为"试用用户"，仅支持查看基本信息。点击"成为会员"，获得全部数据访问权限，解锁更多高级功能。`}
          label="BusinessCase"
          onCancel={() => {
            setIsShowAuthWallModal(false);
          }}
        />
      )}
    </div>
  );
};

export default observer(BusinessCaseHeader);
