import { Modal } from "antd";
import React, { useContext, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { observer } from "mobx-react";

import { CHART } from "../../constants/pro_constants";
import researchDetailStore from "../../stores/researchDetailStore";
import { Button, Icon, IconHeading } from "../shared";
import authStore from "../../stores/authStore";
import closeIcon from "../../styles/images/pop-close.png";
import { trackResearchEvent } from "../../utils/ga_events";

interface Props {
  show?: boolean;
  close?(): void;
  contentTemplate?: React.ReactNode;
  footerTemplate?: React.ReactNode;
}

const scrollFn = (e: any): any => e.preventDefault();

const MobileModal = (props: Props) => {
  const { show, close, contentTemplate, footerTemplate } = props;
  const controlMove = (): void => {
    if (show) {
      document.addEventListener("touchmove", scrollFn, { passive: false });
    } else {
      document.removeEventListener("touchmove", scrollFn);
    }
  };

  useEffect(() => {
    controlMove();
  }, [show]);

  return (
    <>
      {show && (
        <div className="chart-mobile-modal--root">
          <div className="chart-mobile-modal">
            <div className="chart-mobile-modal--content">{contentTemplate}</div>
            <div className="chart-mobile-modal--footer" onClick={() => close()}>
              {footerTemplate}
            </div>
            <img
              className="chart-mobile-modal--close"
              src={closeIcon}
              onClick={() => close()}
            />
          </div>
          <div className="chart-mobile-modal--mask" onClick={() => close()} />
        </div>
      )}
    </>
  );
};

const Chart = () => {
  const {
    data: {
      tabs: { charts },
      header,
    },
    id,
    setForbiddenModal,
    setTrialExpiredModal,
    setVipExpiredModal,
  } = useContext(researchDetailStore);
  const {
    isTrialUser,
    isCurrentUserEmpty,
    isAlphaUserWithoutStartTrial,
    isAlphaUser,
    isTrialExpired,
    isExpiredVipUser,
    isExpiredInstitutionUser,
  } = useContext(authStore);
  const isForbidden =
    !isCurrentUserEmpty && (isAlphaUserWithoutStartTrial || !isAlphaUser);
  const isVipExpired = isExpiredVipUser || isExpiredInstitutionUser;

  const [showModal, setShowModal] = useState(false);
  const [showMobileModal, setShowMobileModal] = useState(false);
  const [modalHeader, setModalHeader] = useState("");
  const [modalImage, setModalImage] = useState("");
  const [modalFileUrl, setModalFileUrl] = useState("");
  const [downloadColor, setDownloadColor] = useState({});
  const history = useHistory();

  return (
    <>
      <div className="database-detail__intro--bottom" />
      <div className="research-detail__block" id={CHART}>
        <IconHeading icon="pro-chart" title="图表" />
        <div className="research-detail__tabs-charts research-detail__block__content">
          {charts.map((item, i) => {
            return (
              <div className="research-detail__tabs-chart" key={i}>
                <img src={item.image} alt="" />
                <a
                  className="research-detail__tabs-chart-text"
                  onClick={() => {
                    if (isCurrentUserEmpty) {
                      history.push("/login");
                      return;
                    }
                    if (isForbidden || isTrialExpired || isVipExpired) {
                      setForbiddenModal(isForbidden);
                      setTrialExpiredModal(isTrialExpired);
                      setVipExpiredModal(isVipExpired);
                      return;
                    }
                    setShowModal(true);
                    setModalHeader(item.name);
                    setModalImage(item.image);
                    setModalFileUrl(item.fileUrl);
                    trackResearchEvent(
                      "download",
                      "Research",
                      `${header.title}#${id}`,
                      header.categoryCn === "可视化" ? "1" : "0"
                    );
                  }}
                  onMouseEnter={() => {
                    setDownloadColor({ [item.name]: "#0062ff" });
                  }}
                  onMouseLeave={() => {
                    setDownloadColor({ [item.name]: "#C6CBD4" });
                  }}
                >
                  <span className="text-limit--two">{item.name}</span>
                  <Icon
                    name="pro-file-download"
                    color={downloadColor[item.name]}
                  />
                </a>
                <a
                  className="research-detail__tabs-chart-text--mobile"
                  onClick={() => {
                    if (isCurrentUserEmpty) {
                      history.push("/login");
                      return;
                    }
                    if (isForbidden || isTrialExpired || isVipExpired) {
                      setForbiddenModal(isForbidden);
                      setTrialExpiredModal(isTrialExpired);
                      setVipExpiredModal(isVipExpired);
                      return;
                    }
                    setShowMobileModal(true);
                    setModalHeader(item.name);
                    setModalImage(item.image);
                    setModalFileUrl(item.fileUrl);
                  }}
                  onMouseEnter={() => {
                    setDownloadColor({ [item.name]: "#0062ff" });
                  }}
                  onMouseLeave={() => {
                    setDownloadColor({ [item.name]: "#C6CBD4" });
                  }}
                >
                  <span className="text-limit--two">{item.name}</span>
                  <Icon
                    name="pro-file-download"
                    color={downloadColor[item.name]}
                  />
                </a>
              </div>
            );
          })}
        </div>
        <Modal
          className="research-detail__tabs-chart-modal"
          title={
            <div className="research-detail__tabs-chart-modal__header">
              {modalHeader}
            </div>
          }
          visible={showModal}
          onCancel={() => setShowModal(false)}
          cancelText="取消"
          style={{ top: 150 }}
          width={600}
          closeIcon={<Icon name="pro-close" size={16} color="#c1c7d0" />}
          footer={[
            <Button
              key="cancel"
              type="ghost"
              size="small"
              grid={4}
              onClick={() => setShowModal(false)}
            >
              取消
            </Button>,
            <a
              key="download"
              href={modalFileUrl}
              download
              target="_blank"
              rel="noopener"
            >
              <Button
                size="small"
                grid={4}
                type="primary"
                onClick={() => {
                  trackResearchEvent(
                    "download",
                    "Research",
                    `${header.title}#${id}`,
                    header.categoryCn === "可视化" ? "1" : "0"
                  );
                }}
              >
                立即下载
              </Button>
            </a>,
          ]}
        >
          <img
            className="research-detail__tabs-chart-modal__image"
            src={modalImage}
            alt={modalHeader}
          />
          {isTrialUser && (
            <span className="research-detail__auth-wall">
              {`您当前的用户类型为"试用用户"，仅支持图表预览。`}
              <Link to="/pricing">成为会员,</Link>
              {`，可下载高清无水印图表，获得全部数据访问权限，解锁更多高级功能。`}
            </span>
          )}
        </Modal>
        <MobileModal
          show={showMobileModal}
          close={() => setShowMobileModal(false)}
          contentTemplate={
            <>
              <h3>{modalHeader}</h3>
              <img
                className="research-detail__tabs-chart-modal__image"
                src={modalImage}
                alt={modalHeader}
              />
              {isTrialUser && (
                <span className="research-detail__auth-wall">
                  {`您当前的用户类型为"试用用户"，仅支持图表预览。`}
                  <Link to="/pricing">成为会员</Link>
                  {`，可下载高清无水印图表，获得全部数据访问权限，解锁更多高级功能。`}
                </span>
              )}
            </>
          }
          footerTemplate={
            <a
              className="chart-download-btn"
              key="download"
              href={modalFileUrl}
              download
              target="_blank"
              rel="noopener"
              onClick={() => {
                trackResearchEvent(
                  "download",
                  "Research",
                  `${header.title}#${id}`,
                  header.categoryCn === "可视化" ? "1" : "0"
                );
              }}
            >
              立即下载
            </a>
          }
        />
      </div>
    </>
  );
};

export default observer(Chart);
