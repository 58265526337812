/*
 * @Author: your name
 * @Date: 2020-08-27 10:05:26
 * @LastEditTime: 2020-12-04 15:04:57
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /Synced/frontend/sites/pro/constants/sidebar.ts
 */
import {
  DATABASE,
  HOME,
  TREND,
  INSTITUTION,
  SOLUTION,
  REFER,
  NEWS,
  // INSIGHT,
  DATASET,
  RESEARCH,
  // SUBSCRIBE,
  MESSAGE,
  COMUNICATION,
  EVENT,
  ZHIZHOU,
  SELECTIONS,
} from "./pro_constants";

export const sidebarList = [
  { icon: "pro-home", label: HOME, link: "/home" },
  {
    icon: "pro-database",
    label: DATABASE,
    subMenu: [
      { label: SOLUTION, link: "/database/solutions", isEnable: true },
      { label: INSTITUTION, link: "/database/institutions", isEnable: true },
      { label: DATASET, link: "/database/datasets", isEnable: true },
      { label: EVENT, link: "", isEnable: false },
    ],
  },
  { icon: "pro-research", label: RESEARCH, link: "/research" },
  {
    icon: "pro-trend",
    label: TREND,
    subMenu: [
      { label: NEWS, link: "/trend/news", isEnable: true },
      // { label: INSIGHT, link: "/trend/insight", isEnable: true },
      { label: REFER, link: "/trend/refer", isEnable: true },
    ],
  },
  // { icon: "pro-subscribe", label: SUBSCRIBE, link: "/subscribe" },
  { icon: "pro-subscribe", label: MESSAGE, link: "/message" },
  { icon: "pro-zhizhou", label: ZHIZHOU, link: "/zhizhou" },
  { icon: "pro-communication", label: COMUNICATION, link: "/communication" },
  {
    icon: "pro-selections",
    label: SELECTIONS,
    link: "/selections",
  },
];
