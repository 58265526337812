import React from "react";

const SVG = ({
  fill = "#fff",
  width = "100%",
  className = "",
  viewBox = "0 0 20 20",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h20v20H0z" />
      <path
        d="M7.5 4.167H5.833c-.92 0-1.666.746-1.666 1.666v10c0 .92.746 1.667 1.666 1.667h8.334c.92 0 1.666-.746 1.666-1.667v-10c0-.92-.746-1.666-1.666-1.666H12.5"
        stroke={fill}
        strokeWidth="1.667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 4.167c0-.92.746-1.667 1.667-1.667h1.666c.92 0 1.667.746 1.667 1.667h0c0 .92-.746 1.666-1.667 1.666H9.167c-.92 0-1.667-.746-1.667-1.666zM7.5 14.167v-3.334M10 14.167v-.834M12.5 14.167V12.5M10 14.167v-.834"
        stroke={fill}
        strokeWidth="1.667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SVG;
