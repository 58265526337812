import { createContext } from "react";
import { computed, observable, action } from "mobx";
import _ from "lodash";
import moment from "moment";

import {
  formatDatabaseData,
  formatDataBaseListData,
} from "../utils/formatDatabaseData";
import {
  INSTITUTION,
  UPLOADED_AT,
  YEARS,
  DATASET,
} from "../constants/pro_constants";
import {
  fetchKeywordResultData,
  fetchFiltersData,
  fetchDatabaseListData,
} from "../api";
import debounce from "../utils/debounce";
import { trackDatabaseEvent } from "../utils/ga_events";
import { labelToChinese } from "../constants/label_to_chinese";

class DatabaseStore {
  @observable type = "";
  @observable filters = {};
  @observable isShowAllFilters = false;
  @observable isShowAllFilterItems = false;
  @observable keywordResultData = {};
  @observable keyword = "";
  @observable keywordFilters = [];
  @observable selectedKeywordResult = {};
  @observable resultList = {
    data: [{}],
  };
  @observable pagination = {};
  @observable isCustomizeDate = false;
  @observable sortField = "";
  @observable tempSortField = "";
  @observable sortInfo = {};
  @observable isLoading = false;
  @observable isEnableSelectedInFilters = true;
  @observable isShowAuthWallModal = true;
  @observable timeZone = [];
  @observable isGetData = false;

  @action
  setIsShowAuthWallModal = status => {
    this.isShowAuthWallModal = status;
  };

  @action
  setIsEnableSelectedInFilters = status => {
    this.isEnableSelectedInFilters = status;
  };

  @action
  initializeType = type => {
    this.type = type;
  };

  @action
  setTimeZone = timeZone => {
    this.timeZone = timeZone;
  };

  @action
  resetFilters = () => {
    this.keyword = "";
    this.keywordResultData = {};
    this.keywordFilters = [];
    this.filters = {};
    this.selectedKeywordResult = {};
    this.tempSortField = this.sortField =
      this.type === DATASET ? UPLOADED_AT : "";
    this.timeZone = [];
  };

  @action
  initializeIsShowAllFilters = () => {
    this.isShowAllFilters = false;
  };

  @action
  initializeFilters = async () => {
    const result = await fetchFiltersData(this.type);
    this.filters = formatDatabaseData(this.type, result.filter).filters;
  };

  @action
  setSortOrderInfo = sorter => {
    if (sorter && sorter.column) {
      this.sortInfo = sorter;
      this.sortField = "";
    } else {
      this.sortField = this.tempSortField;
      this.sortInfo = {};
    }
  };

  upperCapitalLetter = str => {
    return str
      .toLowerCase()
      .replace(/( |^)[a-z]/g, element => element.toUpperCase());
  };

  statByGtag = page => {
    const type = this.upperCapitalLetter(this.type);

    trackDatabaseEvent("view_list", type, this.getConditionStr(), page);
  };

  getConditionStr = () => {
    const conditionObj = {};
    let conditions = "";

    this.selectedConditionItems.map(item => {
      if (conditionObj[labelToChinese[item.field]]) {
        conditionObj[labelToChinese[item.field]] += `&${item.name}`;
      } else {
        conditionObj[labelToChinese[item.field]] = item.name;
      }
    });

    Object.keys(conditionObj).map((key, i) => {
      conditions += `${key}#${conditionObj[key]}${
        Object.keys(conditionObj).length - 1 === i ? "" : "|"
      }`;
    });

    return conditions;
  };

  @action
  fetchDatabaseList = async (pagination?, sorter?) => {
    let params = "";

    this.isLoading = true;
    this.selectedConditionItems.forEach(item => {
      if (item.field === "field") {
        params += `technologies[]=${item.name}&`;
        return;
      }
      if (item.field === "uploadedAt" && item.name !== "自定义") {
        this.timeZone = [this.uploadBeginDate, this.uploadEndDate];
        return;
      }
      params += `${item.field}[]=${item.name}&`;
    });
    if (this.selectedKeywordResult[INSTITUTION]) {
      params += `${INSTITUTION}=${this.selectedKeywordResult[INSTITUTION]}&`;
    }
    if (pagination) {
      params += `size=${pagination.pageSize}&page=${pagination.current}&`;
    }
    if (sorter && sorter.column) {
      params += `sort=${sorter.field}&rule=${sorter.order}&`;
    }
    if (this.sortField) {
      params += `sort=${this.sortField}&`;
    }
    if (!_.isEmpty(this.timeZone)) {
      params += `beginAt=${this.timeZone[0]}&endAt=${this.timeZone[1]}&`;
    }
    const result = await fetchDatabaseListData(this.type, params);
    this.resultList = formatDataBaseListData(this.type, result);
    if (this.resultList.current) {
      this.statByGtag(this.resultList.current);
    }
    this.pagination = {
      current: this.resultList.current - 0,
      pageSize: this.resultList.pageSize,
      total: this.resultList.currentTotalCount,
      defaultPageSize: 20,
      hideOnSinglePage: true,
      position: ["bottomCenter"],
      showSizeChanger: false,
    };
    this.isLoading = false;
  };

  @action
  toggleFiltersActive = (field, activeName) => {
    trackDatabaseEvent(
      "filter",
      this.upperCapitalLetter(this.type),
      this.getConditionStr()
    );
    if (field === UPLOADED_AT) {
      this.setIsCustomizeDate(activeName);
      this.filters[field].map(item => {
        if (item.name === activeName) {
          item.active = !item.active;
        } else {
          item.active = false;
        }
      });
      this.filters[field].find(item => item.name === "不限").active =
        this.filters[field].find(item => item.name === "不限").active ||
        !this.filters[field].some(item => item.active === true);

      if (!this.filters[field].find(item => item.name === "自定义").active) {
        this.timeZone = [];
      }
    } else {
      if (activeName === "不限") {
        this.filters[field].find(item => item.name === "不限").active = true;
        this.filters[field].map(item => {
          if (item.name !== "不限") {
            item.active = false;
          }
        });
      } else {
        this.setFiltersActiveStatus(
          field,
          activeName,
          !this.filters[field].find(item => item.name === activeName).active
        );
      }
    }

    this.fetchDatabaseList();
  };

  @action
  setFiltersInactive = (field, activeName) => {
    this.setIsShowAuthWallModal(true);

    if (activeName === "不限") {
      this.filters[field].find(item => item.name === "不限").active = true;
      this.filters[field].map(item => {
        if (item.name !== "不限") {
          item.active = false;
        }
      });
      this.setIsShowAuthWallModal(false);
      this.fetchDatabaseList();
      return;
    }

    if (this.filters[field].find(item => item.name === activeName).active) {
      this.setFiltersActiveStatus(
        field,
        activeName,
        !this.filters[field].find(item => item.name === activeName).active
      );

      this.setIsShowAuthWallModal(false);
      this.fetchDatabaseList();
    }
  };

  setFiltersActiveStatus = (field, activeName, isActive) => {
    this.filters[field].find(
      item => item.name === activeName
    ).active = isActive;
    this.filters[field].find(
      item => item.name === "不限"
    ).active = !this.filters[field].some(item => item.active === true);
  };

  @action
  setIsShowAllFilters = () => {
    this.isShowAllFilters = !this.isShowAllFilters;
  };

  @action
  setIsCustomizeDate = name => {
    name === "自定义"
      ? (this.isCustomizeDate = true)
      : (this.isCustomizeDate = false);
  };

  @action
  removeConditionItem = (type, data?) => {
    if (type === "all") {
      this.clearConditionItem();
      return;
    }
    if (data.field === "field") {
      this.clearSelectedKeywordResult(data.field, data.name);
      return;
    }
    if (data.color === "blue") {
      this.setFiltersActiveStatus(data.field, data.name, false);
    } else {
      this.keywordFilters = this.keywordFilters.filter(
        ele => ele.name != data.name
      );
    }
    this.fetchDatabaseList();
  };

  clearConditionItem = () => {
    this.keyword = "";
    this.keywordFilters = [];
    this.selectedKeywordResult = {};
    Object.keys(this.filters).map(key => {
      this.filters[key].map(ele => {
        ele.active = ele.name == "不限";
      });
    });
    this.fetchDatabaseList();
  };

  @action
  setKeyword = value => {
    this.keyword = value;
  };

  @action
  handleKeyDownEvent = value => {
    if (value != "") {
      trackDatabaseEvent("search", this.upperCapitalLetter(this.type), value);
      this.keywordFilters.push({ name: value });
      this.fetchDatabaseList();
      this.keyword = "";
    }
  };

  @action
  fetchKeywordResultData = async () => {
    this.isGetData = false;
    debounce(async () => {
      let result = await fetchKeywordResultData(this.type, this.keyword);
      this.keywordResultData = result.recommends;
      this.isGetData = true;
    }, 200);
  };

  @action
  setSelectedKeywordResult = (type, name) => {
    this.keyword = "";
    if (type === INSTITUTION) {
      this.selectedKeywordResult = Object.assign(this.selectedKeywordResult, {
        [type]: name,
      });
    } else {
      if (this.selectedKeywordResult[type]) {
        this.selectedKeywordResult[type].push(name);
      } else {
        this.selectedKeywordResult[type] = [name];
      }
    }

    this.fetchDatabaseList();
  };

  @action
  clearSelectedKeywordResult = (type, name?) => {
    if (type === INSTITUTION) {
      delete this.selectedKeywordResult[type];
    } else {
      this.selectedKeywordResult[type].splice(
        this.selectedKeywordResult[type].findIndex(element => element === name),
        1
      );
    }
    this.fetchDatabaseList();
  };

  @action
  hideSearchPanel = () => {
    document.querySelector(".keyword-result-panel").classList.add("hide");
    document
      .querySelector(".keyword-result-panel__layer")
      .classList.add("hide");
  };

  @action
  showSearchPanel = () => {
    const searchResultDom = document.querySelector(".keyword-result-panel");
    const searchResultLayer = document.querySelector(
      ".keyword-result-panel__layer"
    );

    searchResultDom && searchResultDom.classList.remove("hide");
    searchResultLayer && searchResultLayer.classList.remove("hide");
  };

  @action
  setSortField = value => {
    this.tempSortField = this.sortField = value;
    this.sortInfo = {};
    this.fetchDatabaseList();
  };

  @computed
  get currentUploadAt() {
    return this.filters.uploadedAt.find(item => item.active).name;
  }

  @computed
  get uploadBeginDate() {
    if (this.currentUploadAt === "一月内") {
      return moment()
        .subtract(1, "months")
        .format("YYYY-MM-DD");
    }

    if (this.currentUploadAt === "三月内") {
      return moment()
        .subtract(3, "months")
        .format("YYYY-MM-DD");
    }

    return moment()
      .subtract(7, "d")
      .format("YYYY-MM-DD");
  }

  @computed
  get uploadEndDate() {
    return moment().format("YYYY-MM-DD");
  }

  @computed
  get isSelectedConditionsPanelShow() {
    return (
      !_.isEmpty(this.selectedConditionItems) ||
      !_.isEmpty(this.selectedKeywordResult)
    );
  }

  @computed
  get selectedConditionItems() {
    let arr = [];
    Object.keys(this.filters).map(key => {
      this.filters[key].map(ele => {
        if (ele.name != "不限" && ele.active) {
          arr.push({
            field: key,
            name: ele.name,
            color: "blue",
          });
        }
      });
    });
    if (this.selectedKeywordResult.field) {
      this.selectedKeywordResult.field.forEach(item => {
        arr.push({
          field: "field",
          name: item,
          color: "blue",
        });
      });
    }
    this.keywordFilters.map(ele => {
      arr.push({ name: ele.name, color: "orange", field: "keywords" });
    });
    return arr;
  }

  @computed
  get keywordResultInstitutionData() {
    return (
      this.keywordResultData.institutions &&
      this.keywordResultData.institutions.slice(0, 5)
    );
  }

  @computed
  get keywordResultRelatedData() {
    return (
      this.keywordResultData.related &&
      this.keywordResultData.related.slice(0, 5)
    );
  }

  @computed
  get isKeywordResultInstitutionDataEmpty() {
    return _.isEmpty(this.keywordResultData.institutions);
  }

  @computed
  get isKeywordResultRelatedDataEmpty() {
    return _.isEmpty(this.keywordResultData.related);
  }

  @computed
  get filterKeys() {
    return Object.keys(this.filters);
  }

  @computed
  get hidedFiltersKeys() {
    return this.type === INSTITUTION ? [YEARS] : [];
  }

  @computed
  get showedFiltersKeys() {
    const keys = this.filterKeys.filter(item => item !== YEARS);

    return keys;
  }

  @computed
  get hasExpandBtn() {
    const keys = this.hidedFiltersKeys.length > 0;

    return keys;
  }
}

export default createContext(new DatabaseStore());
