import React from "react";
import "./style.scss";
import { Icon } from "..";

interface Props {
  icon: string;
  title: string;
}

const IconHeading: React.FC<Props> = (props: Props) => {
  return (
    <div className="pro-icon-heading">
      <Icon name={props.icon} />
      <span className="pro-icon-heading__title">{props.title}</span>
    </div>
  );
};

export default IconHeading;
