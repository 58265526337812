import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Form, Input, Checkbox } from "antd";
import { observer } from "mobx-react";

import "./style.scss";
import { Button } from "..";
// import wechatImage from "../../../styles/images/social-wechat.svg";
// import githubImage from "../../../styles/images/social-github.svg";
// import weiboImage from "../../../styles/images/social-weibo.svg";
import authStore from "../../../stores/authStore";
import { PROTOCOL, TOP_DOMAIN } from "../../../api";
// import { trackLoginEvent } from "../../../utils/ga_events";
import { useHistory } from "react-router-dom";

interface Props {
  onRegisterSwitched?: () => void;
}

const Login: React.FC<Props> = () => {
  const { params } = useHistory().location;
  const { url } = params ? params : { url: "" };
  const { onLoginSubmit } = useContext(authStore);

  // const handleLogin = () => {
  //   let url = "";
  //   const mdetect = require("mdetect");
  //   trackLoginEvent("Wechat");
  //   if (mdetect.isWechat()) {
  //     url = `${window.location.origin}/auth/wechatservice`;

  //   } else {
  //     url = `https://open.weixin.qq.com/connect/qrconnect?appid=${window.appid}&scope=snsapi_login&redirect_uri=${PROTOCOL}//${WWWHost}/auth/wechat/callback&state=&login_type=jssdk&self_redirect=default`;
  //   }
  //   window.location.href = url;
  // };

  return (
    <div className="pro-login">
      <Form onFinish={values => onLoginSubmit(values, url)}>
        <h2>欢迎回来</h2>
        <h2 className="pro-login__header">登录账号继续使用</h2>
        <Form.Item
          hasFeedback
          rules={[{ required: true, message: "请输入正确的手机号或邮箱" }]}
          name="login_name"
        >
          <Input placeholder="手机号/邮箱" />
        </Form.Item>
        <Form.Item
          hasFeedback
          rules={[
            { required: true, message: "请输入正确的密码" },
            { min: 6, message: "密码最少是 6 位" },
          ]}
          name="password"
        >
          <Input.Password placeholder="密码" />
        </Form.Item>
        <Form.Item className="pro-login__submit">
          <Button isFullWidth size="large" htmlType="submit">
            登录
          </Button>
        </Form.Item>
        <div className="pro-login__info">
          <Form.Item
          // name="remember_me"
          // initialValue="false"
          // valuePropName="checked"
          >
            <Checkbox>记住密码</Checkbox>
          </Form.Item>
          <span>
            <a
              href={`${PROTOCOL}//pro.${TOP_DOMAIN}/forget-password`}
              className="pro-login__forget-password"
            >
              忘记密码
            </a>
            <Link to="/register" className="pro-login__register">
              创建用户
            </Link>
          </span>
        </div>
      </Form>
      {/* <div className="pro-auth__social-media">
        <div className="pro-auth--divider" />
        <div>
          <a onClick={handleLogin}>
            <img src={wechatImage} alt="wechat login" />
          </a>
          <a
            href="/auth/weibo"
            target="_blank"
            rel="noopener"
            onClick={() => trackLoginEvent("Weibo")}
          >
            <img src={weiboImage} alt="weibo login" />
          </a>
          <a
            href="/auth/github"
            target="_blank"
            rel="noopener"
            onClick={() => trackLoginEvent("Github")}
          >
            <img src={githubImage} alt="github login" />
          </a>
        </div>
      </div> */}
    </div>
  );
};

export default observer(Login);
