import React from "react";
import _ from "lodash";

const Institution = ({ data }) => {
  return (
    data && (
      <>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">机构名称:</div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.name) ? (
              data.name
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">
            机构常用别名:
          </div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.aliasName) ? (
              data.aliasName
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">
            机构注册名称:
          </div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.registrationName) ? (
              data.registrationName
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">机构阶段:</div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.scenarios) ? (
              data.scenarios.join("，")
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">机构类型:</div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.typeCns) ? (
              data.typeCns.join("，")
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">
            机构产业链角色:
          </div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.roles) ? (
              data.roles.join("，")
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">
            业务覆盖领域:
          </div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.aiFields) ? (
              data.aiFields.join("，")
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">
            业务覆盖场景:
          </div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.scenarios) ? (
              data.scenarios.join("，")
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">
            自身所处行业:
          </div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.industries) ? (
              data.industries.join("，")
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">
            业务覆盖行业:
          </div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.selfIndustries) ? (
              data.selfIndustries.join("，")
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">
            技术覆盖领域:
          </div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.techFields) ? (
              data.techFields.join("，")
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">
            优势性技术:
          </div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.technologies) ? (
              data.technologies
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">核心成员:</div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.teamMember) ? (
              data.teamMember
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">机构简介:</div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.summary) ? (
              data.summary
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">机构规模:</div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.companySize) ? (
              data.companySize
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">
            业务覆盖地区:
          </div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.region) ? (
              data.region
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">成立时间:</div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.established) ? (
              data.established
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">
            机构详细介绍:
          </div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.desc) ? (
              <div
                className="selection-rating-modules-form-text--rich"
                dangerouslySetInnerHTML={{ __html: data.desc || "" }}
              />
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">机构官网:</div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.website) ? (
              data.website
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">客户:</div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.clients) ? (
              data.clients
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">
            人工智能技术供应商:
          </div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.suppliers) ? (
              data.suppliers
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">
            信息技术合作伙伴:
          </div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.partners) ? (
              data.partners
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">荣誉资质:</div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.honors) ? (
              <div
                className="selection-rating-modules-form-text--rich"
                dangerouslySetInnerHTML={{ __html: data.honors || "" }}
              />
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">官方 Logo:</div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.logoUrl) ? (
              <div className="selection-rating-modules-form--img">
                <img src={data.logoUrl} alt="image" />
              </div>
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
      </>
    )
  );
};

export default Institution;
