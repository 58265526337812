import React from "react";

const SVG = ({
  fill = "#000000",
  width = "100%",
  className = "",
  viewBox = "0 0 22 22",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Group 21</title>
    <desc>Created with Sketch.</desc>
    <g id="price" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group-21">
        <rect id="Rectangle" x="0" y="0" width="22" height="22" />
        <polygon
          id="true"
          fill={fill}
          fillRule="nonzero"
          points="8.08 18.4 1 11.596 1.888 10.66 8.008 16.54 19.24 4 20.2 4.84"
        />
      </g>
    </g>
  </svg>
);

export default SVG;
