import React from "react";

const SVG = ({ width = "100%", className = "" }) => (
  <svg
    width={width}
    height={width}
    viewBox="0 0 30 30"
    version="1.1"
    className={`svg-icon ${className || ""}`}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>icon/pop warn</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <path
        d="M14.3346156,7.73209285 C14.8502864,6.75804795 15.692121,6.75389236 16.2099918,7.73209285 L16.2099918,7.73209285 L23.3346156,21.1897155 C23.8502864,22.1637604 23.3790718,22.9609042 22.2772144,22.9609042 L22.2772144,22.9609042 L8.26739299,22.9609042 C7.1649265,22.9609042 6.69212097,22.167916 7.20999181,21.1897155 L7.20999181,21.1897155 Z M15.2723037,18.7648042 L15.1557352,18.771536 C14.6585894,18.8293348 14.2723037,19.2522328 14.2723037,19.7648042 L14.2723037,19.7648042 L14.2790355,19.8813727 C14.3368343,20.3785185 14.7597323,20.7648042 15.2723037,20.7648042 L15.2723037,20.7648042 L15.3888722,20.7580724 C15.886018,20.7002736 16.2723037,20.2773756 16.2723037,19.7648042 L16.2723037,19.7648042 L16.2655719,19.6482357 C16.2077731,19.1510899 15.7848751,18.7648042 15.2723037,18.7648042 L15.2723037,18.7648042 Z M15.2723037,10.7648042 L15.1557352,10.771536 C14.6585894,10.8293348 14.2723037,11.2522328 14.2723037,11.7648042 L14.2723037,11.7648042 L14.2723037,16.7648042 C14.2723037,17.3168042 14.7203037,17.7648042 15.2723037,17.7648042 L15.2723037,17.7648042 L15.3888722,17.7580724 C15.886018,17.7002736 16.2723037,17.2773756 16.2723037,16.7648042 L16.2723037,16.7648042 L16.2723037,11.7648042 C16.2723037,11.2128042 15.8243037,10.7648042 15.2723037,10.7648042 L15.2723037,10.7648042 Z"
        id="NotifyWarn"
      />
    </defs>
    <g
      id="icon/pop-warn"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="icon">
        <rect id="Rectangle" x="0" y="0" width="30" height="30" />
        <mask id="NotifyWarn2" fill="white">
          <use xlinkHref="#NotifyWarn" />
        </mask>
        <g id="Combined-Shape" />
        <rect
          id="color"
          fill="#FFA600"
          mask="url(#NotifyWarn2)"
          x="0"
          y="0"
          width="30"
          height="30"
        />
      </g>
    </g>
  </svg>
);

export default SVG;
