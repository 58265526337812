import React from "react";
import _ from "lodash";

const InstitutionFinance = ({ data }) => {
  return (
    data && (
      <>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">
            机构注册资本:
          </div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.registeredCapital) ? (
              data.registeredCapital
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">
            机构融资轮次:
          </div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.round) ? (
              data.round
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">
            机构资产负债率:
          </div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.liabilities) ? (
              data.liabilities
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">
            机构资产净收益率:
          </div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.income) ? (
              data.income
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
      </>
    )
  );
};

export default InstitutionFinance;
