import React, { useContext } from "react";
import { observer } from "mobx-react";

import { IconHeading } from "../shared";
import { REPORT_INTRO } from "../../constants/pro_constants";
import researchDetailStore from "../../stores/researchDetailStore";

const ReportIntro = observer(() => {
  const {
    data: {
      tabs: { reportIntro },
    },
  } = useContext(researchDetailStore);

  return (
    <div
      className="research-detail__intro research-detail__block"
      id={REPORT_INTRO}
    >
      <IconHeading icon="pro-institution-intro" title="报告简介" />
      <div
        className="research-detail__block__content"
        dangerouslySetInnerHTML={{ __html: `${reportIntro}` }}
      />
    </div>
  );
});

export default ReportIntro;
