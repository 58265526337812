import React from "react";
import { Carousel } from "antd";
import { Helmet } from "react-helmet";

import "./style.scss";
import LandingItem from "./LandingItem";
import { LandingItems } from "../../constants/home";
import { Sidebar } from "../shared";
import Profile from "./Profile";
import carouselOne from "../../styles/images/pro-home-carousel-1.png";
import { PRO } from "../../constants/pro_constants";
import { labelToChinese } from "../../constants/label_to_chinese";

const Home = () => {
  return (
    <div className="pro-home-layout">
      <Helmet>
        <title>{labelToChinese[PRO]}</title>
      </Helmet>
      <div className="pro-home-layout__sidebar">
        <Sidebar />
      </div>
      <div className="pro-layout__content-wrapper">
        <div className="pro-home-layout__content">
          <Carousel dots={false}>
            <img
              className="pro-home__carousel"
              src={carouselOne}
              alt="home carousel"
            />
          </Carousel>
          <div className="pro-home__landings">
            <Profile />
            <div className="pro-home--divider" />
            <div className="pro-home__landing-items">
              {LandingItems.map((item, i) => {
                return <LandingItem {...item} key={i} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
