import React, { useContext } from "react";
import { IconHeading, FileCard } from "../shared";
import databaseDetailStore from "../../stores/databaseDetailStore";
import { observer } from "mobx-react";
import { RELATED_FILE } from "../../constants/pro_constants";

const RelatedFilesIntro = () => {
  const {
    data: {
      tabs: { relatedFile },
    },
  } = useContext(databaseDetailStore);

  return (
    <>
      <div className="database-detail__block" id={RELATED_FILE}>
        <IconHeading icon="pro-material" title="相关材料" />
        <div className="database-detail__block__content">
          <FileCard name={relatedFile.name} type={relatedFile.type} />
        </div>
      </div>
      <div className="database-detail__intro--bottom" />
    </>
  );
};

export default observer(RelatedFilesIntro);
