export const CHINA = "china";
export const ABROAD = "abroad";

export const PRO = "pro";

export const LOGIN = "login";
export const REGISTER = "register";
export const AUTHENTICATION = "authentication";

export const PAID_USER = 2;
export const TRIAL_USER = 3;
export const TRIAL_USER2 = 1;
export const OTHER_USER = 0;

export const DATABASE = "database";
export const HOME = "home";
export const TREND = "trend";
export const INSTITUTION = "institution";
export const SOLUTION = "solution";
export const DATASET = "dataset";
export const BUSINESS_CASE = "businessCase";
export const INVEST = "invest";
export const FINANCING = "financing";
export const REFER = "refer";
export const NEWS = "news";
export const INSIGHT = "insight";
export const RESEARCH = "research";
export const COMUNICATION = "communication";
export const SUBSCRIBE = "subscribe";
export const MESSAGE = "message";
export const EVENT = "event";
export const ZHIZHOU = "zhizhou";
export const PROFILE = "profile";
export const SELECTIONS = "selections";

/* Business case Tabs label on database detail page*/
export const CASE_DETAIL = "caseDetail";
export const PROJECT_BACKGROUND = "projectBackground";
export const CORE_INSTITUTION = "coreInstitution";
export const RELATED_FILE = "relatedFile";

/* Institution Tabs label on database detail page*/
export const INSTITUTION_INTRO = "institutionIntro";
export const PRODUCT_SOLUTIONS = "productSolutions";
export const CLIENTS = "clients";
export const CORE_MEMBER = "coreMember";
export const COOPERATION_INSTITUTION = "cooperationInstitution";
export const ASSETS_EVENT = "assetsEvent";

/* Solution Tabs label on database detail page*/
export const SOLUTION_DESC = "solutionDesc";
export const SOLUTION_FEATURE = "solutionFeature";
export const SOLUTION_TECHNOLOGY = "solutionTechnology";
export const SOLUTION_MATERIAL = "solutionMaterial";
export const SOLUTION_BUSINESS_CASE = "solutionBusinessCase";

/* Institution filter key on database page */
export const COLLECTIONS = "collections";
export const INDUSTRIES = "industries";
export const INDUSTRY_ROLES = "industryRoles";
export const DEVELOP_STAGE = "developStage";
export const COMPANY_SIZE = "companySize";
export const YEARS = "years";
export const REGION = "region";
export const AREAS = "areas";
export const TECHNOLOGIES = "technologies";
export const APPLY_FORMATS = "applyFormats";
export const KEYWORDS = "keywords";

/* Dataset filter key on database page */
export const SOURCES = "sources";
export const UPLOADED_AT = "uploadedAt";
export const DATA_COUNT = "numericalValues";
export const DATA_TYPES = "contentTypes";
export const DATA_AMOUNT = "amount";

/* Institution filter key on database page */
export const INSTITUION_BRIEF = "institutionBrief";
export const ROLES = "roles";
export const FOUNDEDAT = "foundedAt";

/* Research module */
export const OTHERS = "others";
export const FILE_TYPE = "categories";
export const REPORT_SIZES = "pageSizes";
export const RESEARCH_TYPE = "researchTypes";

/* Research Detail Tabs */
export const REPORT_INTRO = "reportIntro";
export const REPORT_CATALOG = "reportCatalog";
export const CHART = "chart";

/* table textwrite */
export const TRIGGER_DESC = "triggerDesc";
export const TRIGGER_ASC = "triggerAsc";
export const CACCEL_SORT = "cancelSort";

/* sales menus */
export const OVERVIEW = "overview";
export const FUNCTION = "function";
export const SERVICE = "service";
export const PRICE = "price";
export const SUCCEED_CASE = "succeedCase";

/* Profile */
export const IDENTITY = "category";
export const CITY = "city";
export const SCHOOL = "school";
export const MAJOR = "major";
export const DEGREE = "degree";
export const GRADUATE = "graduationYear";
export const COMPANY = "company";
export const POSITION = "position";
export const WORKEXPERIENCE = "workExperience";
export const FULLNAME = "fullName";
export const MOBILE = "mobile";
export const WECHAT = "wechat";
export const EMAIL = "email";
