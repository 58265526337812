import React, { useState, useContext } from "react";
import { Checkbox, Radio } from "antd";
import { observer } from "mobx-react";

import { labelToChinese } from "../../constants/label_to_chinese";
import { UPLOADED_AT, INDUSTRIES } from "../../constants/pro_constants";
import { Collapses } from "../shared";
import researchStore from "../../stores/researchStore";

interface Field {
  name: string;
  checked: boolean;
}

interface Props {
  type: string;
  fields: Field[];
  isShow: boolean;
  isShowCollapse: boolean;
}

const FilterItem: React.FC<Props> = (props: Props) => {
  const windowInnerWidth = window.innerWidth;
  const [isCollapse, setIsCollapse] = useState(true);
  const [size] = useState(windowInnerWidth >= 1920 ? "medium" : "small");
  const collapseClass =
    props.isShowCollapse && isCollapse
      ? "research-filter--collapse"
      : "research-filter--expand";

  const { updateResearchList } = useContext(researchStore);
  const isIndustriesClass =
    props.type === INDUSTRIES ? "filter--industries" : "";

  return (
    <>
      {props.isShow && (
        <>
          <div className={`research-filter ${isIndustriesClass}`}>
            <span className="research-filter__type">
              {labelToChinese[props.type]}
            </span>
            <div className={`research-filter-items ${collapseClass}`}>
              {props.fields.map((item, i) => {
                if (props.type === UPLOADED_AT) {
                  return (
                    <Radio
                      checked={item.checked}
                      onChange={e =>
                        updateResearchList(
                          e.target.checked,
                          props.type,
                          item.name
                        )
                      }
                      key={i}
                    >
                      {item.name}
                    </Radio>
                  );
                } else {
                  return (
                    <Checkbox
                      checked={item.checked}
                      onChange={e =>
                        updateResearchList(
                          e.target.checked,
                          props.type,
                          item.name
                        )
                      }
                      key={i}
                    >
                      {item.name}
                    </Checkbox>
                  );
                }
              })}
            </div>
          </div>
          {props.isShowCollapse && (
            <Collapses
              size={size}
              onChange={() => setIsCollapse(!isCollapse)}
              openedText="收起选项"
              closedText="更多选项"
            />
          )}
        </>
      )}
    </>
  );
};
export default observer(FilterItem);
