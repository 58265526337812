import React from "react";

const SVG = ({
  fill = "#FFF",
  width = "100%",
  className = "",
  viewBox = "0 0 12 12",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M11.66 10.606c.09 0 .177.036.24.102.064.065.1.154.1.246v.697a.344.344 0 0 1-.34.349H6.228a.335.335 0 0 1-.24-.102.353.353 0 0 1-.099-.247v-.697c0-.092.036-.18.1-.246a.335.335 0 0 1 .24-.102h5.431zM7.413.102a.333.333 0 0 1 .48 0l3.361 3.45a.355.355 0 0 1 0 .494l-7.314 7.51a.334.334 0 0 1-.271.1l-3.08-.288a.344.344 0 0 1-.308-.315L.001 7.89A.354.354 0 0 1 .1 7.612L7.413.102zm.24 1.726L1.397 8.252l.16 1.807 1.76.164 4.13-4.24-1.892-1.942.96-.986 1.892 1.942L9.573 3.8l-1.92-1.972z"
      fill={fill}
      fillRule="nonzero"
    />
  </svg>
);

export default SVG;
