import { action, computed, observable } from "mobx";
import { createContext } from "react";
import { fetchSelectionRateInfo, submitSelectionRateForm } from "../api";
import { formatSelectionRatingData } from "../utils/formatSelectionRatingDetailData";
class SelectionRatingDetailStore {
  @observable public ratingInfo = {};
  @observable public selectModuleId = "";
  @observable private ratingForm = [];
  @observable private taskId = "";
  @observable private rankId = "";

  @action
  setSelectModuleId = id => {
    this.selectModuleId = id;
  };

  resetSelectModuleId = () => {
    this.selectModuleId = "";
  };

  @action
  setTaskId = id => {
    this.taskId = id;
  };

  @action
  setRankId = id => {
    this.rankId = id;
  };

  @action
  getRatingInfo = async (id, rankId) => {
    this.setTaskId(id);
    this.setRankId(rankId);
    const result = await fetchSelectionRateInfo(id, rankId);
    if (result.status === "success") {
      this.ratingInfo = formatSelectionRatingData(result);
      this.ratingForm = this.ratingInfo.questionnaire.data;
    }
  };

  @action
  onSubmit = async (id, data) => {
    const result = await submitSelectionRateForm(
      id,
      this.getParams(this.getFormData(data))
    );
    if (result.status === "success") {
      window.location.href = `/selection-rating/${this.taskId}?rankId=${this.rankId}`;
    }
  };

  @action
  getParams = formData => {
    return this.ratingForm.map(item => {
      const index = formData.findIndex(
        element => element.order - 0 === item.order - 0
      );
      item.score = formData[index].score;
      item.comment = formData[index].comment;
      return item;
    });
  };

  @action
  getFormData = data => {
    let formData = [];
    Object.keys(data).forEach(element => {
      const order = this.getLastNumber(element);
      if (formData.some(item => item.order === order)) {
        Object.assign(
          formData[formData.findIndex(item => item.order === order)],
          { [this.getField(element, order)]: data[element] }
        );
      } else {
        formData.push({
          order: order,
          [this.getField(element, order)]: data[element],
        });
      }
    });
    return formData;
  };

  @action
  getLastNumber = str => {
    return str.match(/\d/g)[0];
  };

  @action
  getField = (str, order) => {
    return str.split(order)[0];
  };

  @computed
  get formInitValues() {
    let values = {};
    this.ratingForm.forEach(item => {
      values[`score${item.order}`] = item.score;
      values[`comment${item.order}`] = item.comment;
    });
    return values;
  }

  @computed
  get isRankRated() {
    return (
      this.ratingInfo.header &&
      this.ratingInfo.header.ratedFormsCount ===
        this.ratingInfo.header.ratedFormsTotalCount
    );
  }

  @computed
  get isFormFinished() {
    return (
      this.ratingInfo.questionnaire &&
      this.ratingInfo.questionnaire.status === "finished"
    );
  }
}

export default createContext(new SelectionRatingDetailStore());
