import React from "react";

const SVG = ({
  fill = "#0062FF",
  width = "100%",
  className = "",
  viewBox = "0 0 16 16",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FFF"
        d="M8 10.4c-3.2 0-5.6 2.4-5.6 2.4l.8 2.4h9.6l.8-2.4s-2.4-2.4-5.6-2.4"
      />
      <path
        fill={fill}
        d="M14.166 12.234c.214.215.289.532.193.819l-.8 2.4c-.11.327-.415.547-.759.547H3.2c-.345 0-.65-.22-.759-.547l-.8-2.4c-.096-.287-.02-.604.194-.819C1.942 12.126 4.508 9.6 8 9.6c3.491 0 6.058 2.526 6.166 2.634zM12.223 14.4l.45-1.35C11.937 12.45 10.149 11.2 8 11.2c-2.162 0-3.941 1.246-4.674 1.848l.45 1.352h8.447zM8 1.6C6.677 1.6 5.6 2.677 5.6 4S6.677 6.4 8 6.4s2.4-1.077 2.4-2.4S9.323 1.6 8 1.6zM8 8C5.794 8 4 6.206 4 4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4z"
      />
    </g>
  </svg>
);

export default SVG;
