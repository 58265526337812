import React, { useContext } from "react";
import moment from "moment";

import Icon from "../icon/Icon";
import EmptyImport from "../empty_import/EmptyImport";
import DatabseDetailStore from "../../../stores/databaseDetailStore";
import "./style.scss";

interface Props {
  data: any[];
}

const Newsflash: React.FC<Props> = (props: Props) => {
  const { isNewsflashContentEmpty } = useContext(DatabseDetailStore);
  return (
    <div className="pro-newsflash">
      <div className="pro-newsflash__title">
        <Icon name="pro-newsflash" />
        <span>最新动态</span>
      </div>
      <div className="pro-newsflash__main">
        {!isNewsflashContentEmpty && (
          <div className="pro-newsflsh__vertical-line" />
        )}
        {!isNewsflashContentEmpty ? (
          props.data.map((ele, i) => {
            return (
              <div className="pro-newsflash__item" key={i}>
                <a href={ele.source_url} target="_blank">
                  {ele.title}
                </a>
                <span>{moment(ele.published_at * 1000).fromNow()}</span>
              </div>
            );
          })
        ) : (
          <EmptyImport />
        )}
      </div>
    </div>
  );
};

export default Newsflash;
