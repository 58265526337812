import { createContext } from "react";
import { observable, action, computed } from "mobx";
import {
  formatResearchList,
  formatedResearchFilters,
} from "../utils/formatResearchData";
import {
  RESEARCH_TYPE,
  UPLOADED_AT,
  FILE_TYPE,
} from "../constants/pro_constants";
import { fetchResearchFilters, fetchResearchList } from "../api";
import moment from "moment";
import { trackResearchEvent } from "../utils/ga_events";
import { labelToChinese } from "../constants/label_to_chinese";

class ResearchStore {
  @observable researchList = [];
  @observable researchFilters = [];
  @observable researchTypeDisabled = false;
  @observable keywords = "";
  @observable totalCount = 0;
  @observable newChartCount = 0;
  @observable isLoading = false;
  @observable isShowAuthWallModal = false;
  @observable pagination = {
    current: 1,
    pageSize: 20,
    defaultPageSize: 20,
    total: 0,
    position: ["bottomCenter"],
    hideOnSinglePage: true,
    showSizeChanger: false,
  };

  @action
  initializeResearchList = async () => {
    this.isLoading = true;
    const params = this.getUrlParams;

    const result = await fetchResearchList(params);
    this.totalCount = result.totalCount;
    this.newChartCount = result.newChartCount;
    this.researchList = formatResearchList(result.data);
    this.pagination.current = Number(result.current);
    this.pagination.total = Number(result.currentTotalCount);
    this.isLoading = false;
    trackResearchEvent("view_list", "Research", {}, result.current);
  };

  @action
  handleSelectReport = checked => {
    checked ? this.checkReportTypeOnly() : this.checkAllResearchTypes();

    //TODO: Hidden report size fields for pro phase1 release
    // this.researchFilters.find(
    //   item => item.type === REPORT_SIZES
    // ).isShow = checked;
    this.researchTypeDisabled = !this.researchTypeDisabled;
    this.updateResearchList(true, FILE_TYPE, "不限");
  };

  @action
  checkReportTypeOnly = () => {
    this.researchFilters
      .find(item => item.type === RESEARCH_TYPE)
      .fields.map(field => {
        if (field.name === "深度报告" || field.name === "研究简报") {
          field.checked = true;
        } else {
          field.checked = false;
        }
      });
  };

  @action
  checkAllResearchTypes = () => {
    this.researchFilters
      .find(item => item.type === RESEARCH_TYPE)
      .fields.map(field => {
        field.checked = true;
      });
  };

  @action
  initializeResearchFilters = async () => {
    const result = await fetchResearchFilters();
    this.researchFilters = formatedResearchFilters(result.filter);
  };

  @action
  updateResearchList = async (checked, filterType, name) => {
    this.isLoading = true;
    if (filterType === RESEARCH_TYPE) {
      this.checkCurrentItem(filterType, name, checked);
    }

    if (filterType !== RESEARCH_TYPE && name !== "不限") {
      this.researchFilters.find(
        item => item.type === filterType
      ).fields[0].checked = false;

      this.checkCurrentItem(filterType, name, checked);
    }

    if (filterType === UPLOADED_AT) {
      this.checkCurrentItem(filterType, name, checked);
      this.uncheckOtherItems(filterType, name, checked);
    }

    if (filterType !== RESEARCH_TYPE && name === "不限" && checked) {
      this.checkCurrentItem(filterType, name, checked);
      this.uncheckOtherItems(filterType, name, checked);
    }
    trackResearchEvent("filter", "Research", this.conditions, "");

    const params = this.getUrlParams;
    const result = await fetchResearchList(params);
    this.researchList = formatResearchList(result.data);
    this.pagination.total = result.currentTotalCount;
    trackResearchEvent(
      "view_list",
      "Research",
      this.conditions,
      result.current
    );
    this.isLoading = false;
  };

  @action
  checkCurrentItem = (filterType: string, name: string, checked: boolean) => {
    this.researchFilters
      .find(item => item.type === filterType)
      .fields.find(field => field.name === name).checked = checked;
  };

  @action
  uncheckOtherItems = (filterType: string, name: string, checked: boolean) => {
    this.researchFilters
      .find(item => item.type === filterType)
      .fields.map(field => {
        if (field.name !== name) {
          field.checked = !checked;
        }
      });
  };

  @action
  updateResearchListByKeywords = async value => {
    this.isLoading = true;
    this.keywords = value;

    const params = this.getUrlParams;
    const result = await fetchResearchList(params);
    this.researchList = formatResearchList(result.data);
    this.pagination.total = result.currentTotalCount;
    this.isLoading = false;
    trackResearchEvent("search", "Research", value, result.current);
  };

  @action
  updateResearchListBySort = async (type?: string) => {
    this.isLoading = true;
    const params = `${this.getUrlParams}${type ? "sort=touchCount" : ""}`;
    const result = await fetchResearchList(params);
    this.researchList = formatResearchList(result.data);
    this.pagination.total = result.currentTotalCount;
    this.isLoading = false;
    trackResearchEvent(
      "view_list",
      "Research",
      this.conditions,
      result.current
    );
  };

  @action
  updatePagination = async pagination => {
    this.isLoading = true;
    this.pagination.current = pagination.current;

    const params = this.getUrlParams;
    const result = await fetchResearchList(params);
    this.researchList = formatResearchList(result.data);
    this.pagination.total = result.currentTotalCount;
    this.isLoading = false;
    trackResearchEvent(
      "view_list",
      "Research",
      this.conditions,
      result.current
    );
  };

  @action
  resetFilters = async () => {
    this.isLoading = true;
    const filtersExcludeReasearchType = this.researchFilters.filter(
      item => item.type !== RESEARCH_TYPE
    );

    filtersExcludeReasearchType.filter(filter =>
      filter.fields.map(item => {
        if (item.name === "不限") {
          item.checked = true;
        } else {
          item.checked = false;
        }
      })
    );

    const params = this.getUrlParams;
    const result = await fetchResearchList(params);
    this.researchList = formatResearchList(result.data);
    this.pagination.total = result.currentTotalCount;
    this.isLoading = false;
  };

  @action
  setIsShowAuthWallModal = value => {
    this.isShowAuthWallModal = value;
  };

  @action
  scrollToTop = () => {
    document.querySelector(".pro-layout__content-wrapper").scrollTo({ top: 0 });
  };

  @computed
  get conditions() {
    let conditionObj = {};
    let conditionStr = "";

    this.researchFilters.map(filter => {
      const type = labelToChinese[filter.type];
      filter.fields.map(field => {
        if (conditionObj[type] && field.checked) {
          conditionObj[type].push(field.name);
        } else if (!conditionObj[type] && field.checked) {
          conditionObj[type] = [field.name];
        }
      });
    });
    Object.keys(conditionObj).map((key, i) => {
      conditionStr += `${key}#${conditionObj[key].join("&")}${
        Object.keys(conditionObj).length - 1 === i ? "" : "|"
      }`;
    });

    return conditionStr;
  }

  @computed
  get currentUploadAt() {
    return this.researchFilters
      .find(filter => filter.type === UPLOADED_AT)
      .fields.find(item => item.checked).name;
  }

  @computed
  get getBeginAt() {
    if (this.currentUploadAt === "一月内") {
      return moment()
        .subtract(1, "months")
        .format("YYYY-MM-DD");
    }

    if (this.currentUploadAt === "半年内") {
      return moment()
        .subtract(6, "months")
        .format("YYYY-MM-DD");
    }

    return moment()
      .subtract(7, "d")
      .format("YYYY-MM-DD");
  }

  @computed
  get getEndAt() {
    return moment().format("YYYY-MM-DD");
  }

  @computed
  get getUrlParams() {
    let params = this.pagination.current
      ? `size=${this.pagination.pageSize}&page=${this.pagination.current}&`
      : "";

    params += this.keywords ? `keyword="${this.keywords}&` : "";

    this.researchFilters.map(item => {
      item.fields.map(field => {
        if (field.checked && field.name !== "不限") {
          if (item.type === UPLOADED_AT) {
            params += `beginAt=${this.getBeginAt}&endAt=${this.getEndAt}&`;
          } else {
            params += `${item.type}[]=${field.name}&`;
          }
        }
      });
    });

    return params;
  }

  @computed
  get hasResearchFilters() {
    return this.researchFilters.length > 0;
  }

  @computed
  get researchTypes() {
    return this.hasResearchFilters
      ? this.researchFilters.find(item => item.type === RESEARCH_TYPE).fields
      : [];
  }

  @computed
  get filters() {
    return this.researchFilters.filter(item => item.type !== RESEARCH_TYPE);
  }
}

export default createContext(new ResearchStore());
