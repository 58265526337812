import { createContext } from "react";
import { observable, action, computed } from "mobx";
import { message } from "antd";
import { trackLoginEvent } from "../utils/ga_events";
import {
  fetchMemberArticleApi,
  fetchMemberListApi,
  fetchReferenceListApi,
  fetchSearchListApi,
  fetchNewCodeVerify,
  PROTOCOL,
  WWWHost,
} from "../api/index";
import {
  formatDetail,
  formatList,
  formatSearchList,
} from "../utils/formatMemberInboxData";

class memberInboxStore {
  @observable public dialog = {
    params: {} as any,
    "new-buy": false,
    cost: false,
    "pay-error": false,
    "pay-success": false,
    integral: false,
    login: false,
    "invalid-code": false,
    "guide-signin": false,
    "exchange-error": false,
    "edit-info": false,
    "user-agreement": false,
  };
  @observable public xyText = "请阅读并勾选协议，否则无法进行操作";
  @observable public xy =
    "https://www.jiqizhixin.com/short_urls/d2e4df6c-709f-436a-b22c-15b4d9c1ebf3";
  @observable public baseXy =
    "https://www.jiqizhixin.com/short_urls/d2e4df6c-709f-436a-b22c-15b4d9c1ebf3";
  @observable public detail: any = null;
  @observable public detailLoading = true;
  @observable list: any = [];
  @observable loading = false;
  @observable public pagination = {
    total: 10,
    current: 1,
    pageSize: 10,
    hasNextPage: true,
  };
  @observable public params: any = {
    page: 1,
    per: 10,
  };
  @observable public inboxTabs: any = [
    {
      tab: "通讯",
      key: "comm",
      icon: "inbox-book",
      api: (params: any) => fetchMemberListApi(params),
      active: true,
    },
    {
      tab: "参考",
      key: "refer",
      icon: "inbox-battery",
      api: (params: any) => fetchReferenceListApi(params),
      active: false,
    },
  ];
  @observable searchList: any = [];
  @observable public searchLoading = false;
  @observable public searchPagination = {
    total: 10,
    current: 1,
    pageSize: 10,
    hasNextPage: true,
  };
  @observable public keyword = "";
  @observable public time = 0;
  @observable public isBack = false;
  // 是否折扣购买会员
  @observable public discount = true;
  // 续费包年 155.5
  @observable public xfOyId = "9e2fe18c-3814-4901-a14c-1cf5304eba11";
  // 原价包年 514.8
  @observable public oyId = "4b36cd61-60be-4328-b684-0314c4f99a7a";
  // 新用户三个月 38.9
  @observable public ntmId = "a69db391-c677-4417-8eb1-dc7c5e58dd64";
  // 新用户包年 155.5
  @observable public noyId = "7ee8fc24-bf6f-4d4f-9e8d-d3f6eafe0300";
  // 积分 50/2.99
  @observable public jfId = "dc2a39bd-a9db-4a2b-9ce2-0e56592eb808";
  // 标记是刚购买的新用户，不关闭页面不会再次提示，直到下次打开新页面
  @observable public purchase = "";

  @computed
  get activeTab() {
    return this.inboxTabs.find((item: any) => item.active);
  }

  @action
  setDialog = (name: string, value: boolean, params?: any) => {
    this.dialog.params = params || {};
    this.dialog[name] = value;
  };

  @action
  setIsBack = (value: boolean) => {
    this.isBack = value;
  };

  @action
  setDiscount = (value: boolean) => {
    this.discount = value;
  };

  @action
  setPurchase = (value: string) => {
    this.purchase = value;
  };

  @action
  fetchDetail = async (id: string) => {
    this.detailLoading = true;
    const res = await fetchMemberArticleApi(id);
    this.detail = formatDetail(res.content);
    this.detailLoading = false;
  };

  @action
  revert = () => {
    this.list = [];
    this.params = {
      page: 1,
      per: 10,
    };
  };

  @action
  revertSearch = () => {
    this.searchList = [];
    this.searchPagination = {
      total: 10,
      current: 1,
      pageSize: 10,
      hasNextPage: true,
    };
    this.keyword = "";
  };

  @action
  nextPage = () => {
    this.params.page += 1;
    this.fetchList();
  };

  @action
  fetchList = async (reset?: boolean) => {
    this.loading = true;
    this.revertSearch();
    reset && this.revert();
    if (
      !(this.params.page > 1 && this.pagination.current === this.params.page)
    ) {
      const res = await this.activeTab.api(this.params);
      this.list =
        this.params.page > 1 && this.pagination.current !== this.params.page
          ? [...this.list, ...formatList(res.contents)]
          : formatList(res.contents);
      this.pagination = {
        total: res.totalCount,
        current: res.currentPage,
        pageSize: this.params.per,
        hasNextPage: res.hasNextPage,
      };
    }
    this.loading = false;
  };

  // 搜索列表
  fetchSearchList = async (keyword?: any, page?: any, per?: any) => {
    this.searchLoading = true;
    const res = await fetchSearchListApi(keyword || "", page || 1, per || 10);
    this.searchPagination = {
      total: res.totalCount,
      current: res.currentPage,
      pageSize: per || 10,
      hasNextPage: res.hasNextPage,
    };
    this.searchList =
      page && page > 1
        ? [...this.searchList, ...formatSearchList(res.contents)]
        : formatSearchList(res.contents);
    this.searchLoading = false;
  };

  @action
  setKeyword = (keyword: any) => {
    this.keyword = keyword;
  };

  @action
  switchTab = (key: string, fetch?: boolean) => {
    // fetch: true，单纯重置数据，不请求接口
    this.revert();
    this.inboxTabs.forEach((item: any) => {
      item.active = false;
      if (item.key === key) {
        item.active = true;
      }
    });
    !fetch && this.fetchList(true);
  };

  @action public setCountdown = () => {
    let timer = null;
    this.time = 60;
    timer && clearInterval(timer);
    timer = setInterval(() => {
      if (this.time === 0) {
        clearInterval(timer);
      }
      this.time -= 1;
    }, 1000);
  };

  @action public onSendCode = async (data: any) => {
    if (data.mobile) {
      if (data._rucaptcha) {
        await fetchNewCodeVerify(data, response => {
          if (response.statusCode === 200) {
            this.setCountdown();
            message.success("已将验证码发送到您的手机，请查收！");
          } else {
            if (response.statusCode === 422) {
              const errorMsg = response.body.errors[0];
              message.error(errorMsg);
            } else {
              const errorMsg = response.body.errors.join(",");
              message.error(
                errorMsg === "手机号码已被注册。"
                  ? "该手机号已存在，请使用新手机号绑定"
                  : errorMsg
              );
            }
          }
        });
      } else {
        message.error("验证码不合法!");
      }
    } else {
      message.error("手机号不合法!");
    }
  };

  @action public wxLogin = (path?: any, cb?: any) => {
    let url = "";
    const mdetect = require("mdetect");
    trackLoginEvent("Wechat");
    const pathname = path ? path : `${location.pathname}${location.search}`;
    const origin = `${location.origin}${pathname}`;
    if (mdetect.isWechat()) {
      window.localStorage.setItem("login", "wxLogin");
      url = `${window.location.origin}/auth/wechatservice?origin=${origin}`;
      cb && cb();
    } else {
      url = `https://open.weixin.qq.com/connect/qrconnect?appid=${window.appid}&scope=snsapi_login&redirect_uri=${PROTOCOL}//${WWWHost}/auth/wechat/callback&state=&login_type=jssdk&self_redirect=default&origin=${origin}`;
    }
    window.location.href = url;
  };

  @action
  updateActiveThumbnail = index => {
    this.detail.reportCatalog.activeIndex = index;
  };
}

export default createContext(new memberInboxStore());
