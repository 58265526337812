import React from "react";

const SVG = ({
  fill = "#92929D",
  width = "100%",
  className = "",
  viewBox = "0 0 24 24",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill={fill}
        d="M12 1.501c1.418 0 2.793.278 4.086.825 1.25.528 2.373 1.286 3.338 2.25.964.965 1.722 2.088 2.25 3.338.547 1.293.825 2.668.825 4.086 0 1.418-.278 2.793-.825 4.086-.528 1.25-1.286 2.373-2.25 3.338-.965.964-2.088 1.721-3.338 2.25-1.293.547-2.668.825-4.086.825-1.418 0-2.793-.278-4.086-.825-1.25-.529-2.373-1.286-3.338-2.25-.964-.965-1.722-2.088-2.25-3.338C1.779 14.793 1.5 13.418 1.5 12c0-1.418.278-2.793.825-4.086.528-1.25 1.286-2.373 2.25-3.338.965-.964 2.088-1.722 3.338-2.25C9.207 1.779 10.582 1.5 12 1.5m0-1.199C5.54.302.302 5.539.302 12c0 6.46 5.237 11.698 11.698 11.698 6.46 0 11.698-5.237 11.698-11.698C23.698 5.54 18.461.302 12 .302z"
      />
      <path
        fill={fill}
        d="M10.613 16.42c-2.102.099-3.8-.955-3.8-2.357 0-1.404 1.698-2.53 3.8-2.627 2.103-.097 3.805.77 3.805 2.17 0 1.403-1.702 2.72-3.805 2.815m4.475-4.557c-.5-.096-.257-.365-.257-.365s.489-.805-.097-1.39c-.725-.725-2.487.092-2.487.092-.673.209-.494-.096-.4-.613 0-.61-.208-1.642-2.001-1.032-1.79.612-3.328 2.76-3.328 2.76-1.07 1.427-.928 2.53-.928 2.53.267 2.435 2.854 3.103 4.866 3.261 2.117.166 4.974-.73 5.84-2.57.867-1.843-.707-2.573-1.208-2.673"
      />
      <path
        fill={fill}
        d="M10.907 14.078c-.134.1-.299.086-.37-.034-.073-.117-.045-.304.09-.402.156-.117.32-.083.39.034.07.12.02.299-.11.402M9.66 15.139c-.394.047-.712-.18-.712-.511 0-.329.282-.673.677-.714.453-.043.748.218.748.548 0 .329-.32.633-.713.677m.534-2.778c-2.114.247-1.87 2.227-1.87 2.227s-.022.627.567.946c1.237.67 2.51.265 3.154-.566.643-.831.265-2.853-1.851-2.607m5.923-1.354c.17 0 .315-.126.34-.29.002-.012.004-.025.004-.037.258-2.321-1.902-1.921-1.902-1.921-.192 0-.345.155-.345.348 0 .19.153.346.345.346 1.552-.343 1.209 1.209 1.209 1.209 0 .192.157.345.349.345"
      />
      <path
        fill={fill}
        d="M15.865 6.956c-.747-.175-1.515-.024-1.73.017-.017.002-.032.017-.047.02-.008.002-.013.01-.013.01-.212.06-.367.256-.367.488 0 .276.224.504.504.504 0 0 .272-.037.456-.109.183-.073 1.73-.054 2.498 1.236.42.941.184 1.572.155 1.673 0 0-.1.244-.1.485 0 .279.224.453.502.453.232 0 .428-.032.485-.424h.002c.825-2.75-1.009-4.041-2.345-4.353"
      />
    </g>
  </svg>
);

export default SVG;
