import React from "react";

const SVG = ({
  fill = "#C6CBD4",
  width = "100%",
  className = "",
  viewBox = "0 0 16 16",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      fillRule="evenodd"
      d="M8 2.4c3.525 0 6.154 1.85 7.894 5.525.141.3.141.648 0 .948C14.154 12.55 11.527 14.4 8 14.4c-3.525 0-6.154-1.85-7.894-5.525-.141-.3-.141-.648 0-.948C1.846 4.25 4.473 2.4 8 2.4zM8 4C5.208 4 3.158 5.383 1.68 8.35l-.026.05.024.049c1.437 2.883 3.415 4.27 6.085 4.348L8 12.8c2.792 0 4.842-1.383 6.321-4.35l.024-.05-.023-.048c-1.437-2.884-3.415-4.272-6.085-4.349L8 4zm.007 1.257c1.764 0 3.194 1.43 3.194 3.194s-1.43 3.194-3.194 3.194c-.847 0-1.66-.336-2.258-.935-.6-.6-.935-1.412-.935-2.259 0-1.764 1.43-3.194 3.193-3.194zm0 1.6c-.88 0-1.593.714-1.593 1.594 0 .423.167.828.466 1.127.3.3.705.467 1.127.467.88 0 1.594-.714 1.594-1.594 0-.88-.713-1.594-1.594-1.594z"
    />
  </svg>
);

export default SVG;
