import React from "react";

const SVG = ({
  fill = "#C6CBD4",
  width = "100%",
  className = "",
  viewBox = "0 0 16 16",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      fillRule="evenodd"
      d="M7 0C3.14 0 0 3.14 0 7s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7m0 12.6c-3.088 0-5.6-2.512-5.6-5.6 0-3.088 2.512-5.6 5.6-5.6 3.088 0 5.6 2.512 5.6 5.6 0 3.088-2.512 5.6-5.6 5.6m0-4.9c.387 0 .7-.313.7-.7V4.2c0-.387-.313-.7-.7-.7-.387 0-.7.313-.7.7V7c0 .387.313.7.7.7m0 1.33c-.425 0-.77.345-.77.77 0 .425.345.77.77.77.425 0 .77-.345.77-.77 0-.425-.345-.77-.77-.77"
    />
  </svg>
);

export default SVG;
