import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { Input, Form, Rate } from "antd";

import "./style.scss";
import { Button } from "../shared";
import Icon from "@ant-design/icons";
import ArrowRightCircleSvg from "../../styles/images/arrow-right-circle.svg";
import SelectionRatingDetailStore from "../../stores/selectionRatingDetailStore";

const { TextArea } = Input;

const RATE_GRADES = [
  { grade: 1, desc: "非常不符合", color: "rgba(0, 98, 255, 0.2)" },
  { grade: 2, desc: "不符合", color: "rgba(0, 98, 255, 0.4)" },
  { grade: 3, desc: "基本符合", color: "rgba(0, 98, 255, 0.6)" },
  { grade: 4, desc: "比较符合", color: "rgba(0, 98, 255, 0.8)" },
  { grade: 5, desc: "非常符合", color: "rgba(0, 98, 255, 1)" },
];

const SelectionRatingForm: React.FC = () => {
  const [score, setScore] = useState({});
  const { id } = useParams();
  const [form] = Form.useForm();

  const {
    ratingInfo: { questionnaire },
    isFormFinished,
    formInitValues,
    resetSelectModuleId,
    setSelectModuleId,
    onSubmit,
  } = useContext(SelectionRatingDetailStore);
  const questtionCards = questionnaire && questionnaire.data;

  useEffect(() => {
    form.setFieldsValue(formInitValues);
  }, [formInitValues]);

  const fixModule = moduleId => {
    let scrollHeight = 0;
    let index = 0;
    resetSelectModuleId();
    setTimeout(() => {
      setSelectModuleId(moduleId);
      const modulesDom = document.getElementsByClassName(
        "selection-rating-modules"
      )[0];
      const selectedModuleDom = document.getElementById(moduleId);
      const selectedMobuleParentNode = selectedModuleDom.parentNode;
      const selectedModuleSiblingNodes = [].slice.call(
        selectedMobuleParentNode.childNodes
      );
      const parentNodeTop = selectedMobuleParentNode.offsetTop - 189;
      index = selectedModuleSiblingNodes.findIndex(
        node => node.id === moduleId
      );
      selectedModuleSiblingNodes.slice(0, index).forEach(preNode => {
        scrollHeight += preNode.offsetHeight;
      });
      modulesDom.scrollTo({
        top: scrollHeight + (parentNodeTop > 0 ? parentNodeTop : 0),
      });
    }, 0);
  };

  return (
    questionnaire && (
      <div className="selection-rating-form">
        <div className="selection-rating-form__wrapper">
          <div className="selection-rating-form__wrapper-content">
            <div className="selection-rating-form__tooltip">
              <Icon name="information" color="#0062ff" />
              <span>点击题目可直接查看相关数据</span>
            </div>
            <Form
              form={form}
              className="srf__question-cards"
              layout="vertical"
              requiredMark={false}
              scrollToFirstError
              onFinish={values => {
                onSubmit(id, values);
              }}
            >
              {questtionCards &&
                questtionCards.map((item, index) => {
                  return (
                    <Form.Item key={index} noStyle>
                      <Form.Item className="srf__question-card">
                        <div
                          className="srf__question-card__title"
                          onClick={() => fixModule(item.moduleId)}
                        >
                          <span className="srf__question-card__order">
                            {item.order}
                          </span>
                          <span>{item.title}</span>
                          <img
                            src={ArrowRightCircleSvg}
                            alt=""
                            className="srf__question-card__toggle"
                          />
                        </div>
                        <Form.Item className="srf__question-card__middle">
                          <Form.Item
                            className="srf__question-card__score"
                            name={`score${item.order}`}
                            label="请为该内容评分"
                            rules={[
                              { required: true, message: "请为该内容评分" },
                              {
                                type: "number",
                                min: 1,
                                message: "请为该内容评分",
                              },
                            ]}
                          >
                            <Rate
                              disabled={isFormFinished}
                              character={({ index }) => {
                                return customIcons[index + 1];
                              }}
                              style={{
                                color: RATE_GRADES[score[index]]
                                  ? RATE_GRADES[score[index]].color
                                  : "#0062ff",
                              }}
                              tooltips={RATE_GRADES.map(
                                element => `${element.grade}分`
                              )}
                              onChange={value => {
                                setScore(
                                  Object.assign({}, score, {
                                    [index]: value - 1,
                                  })
                                );
                              }}
                            />
                          </Form.Item>
                          {isFormFinished ? (
                            <p className="srf__question-card__rate-text">
                              {
                                <>
                                  <span className="srf__question-card__rate-text--desc">
                                    {
                                      RATE_GRADES[
                                        RATE_GRADES.findIndex(
                                          element =>
                                            element.grade === item.score
                                        )
                                      ].desc
                                    }
                                  </span>
                                  <span className="srf__question-card__rate-text--grade">
                                    {item.score} <span>分</span>
                                  </span>
                                </>
                              }
                            </p>
                          ) : (
                            <p className="srf__question-card__rate-text">
                              {score[index] >= 0 ? (
                                <>
                                  <span className="srf__question-card__rate-text--desc">
                                    {RATE_GRADES[score[index]].desc}
                                  </span>
                                  <span className="srf__question-card__rate-text--grade">
                                    {RATE_GRADES[score[index]].grade}{" "}
                                    <span>分</span>
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span className="srf__question-card__rate-text--desc zero">
                                    0
                                  </span>
                                  <span className="srf__question-card__rate-text--grade zero">
                                    0 <span>分</span>
                                  </span>
                                </>
                              )}
                            </p>
                          )}
                        </Form.Item>
                        <Form.Item
                          className="srf__question-card__comment"
                          name={`comment${item.order}`}
                          label="请简述评分理由"
                          rules={[
                            {
                              required: true,
                              message: "请简述评分理由，以便提交",
                            },
                          ]}
                        >
                          <TextArea
                            autoSize={{ minRows: 3, maxRows: 3 }}
                            disabled={isFormFinished}
                          />
                        </Form.Item>
                      </Form.Item>
                    </Form.Item>
                  );
                })}
              <Form.Item className="srf__submit" style={{ margin: 0 }}>
                <Button
                  isFullWidth
                  size="large"
                  disabled={isFormFinished}
                  htmlType="submit"
                >
                  {isFormFinished ? "已提交" : "提交"}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    )
  );
};
const RateStepOneSvg = () => (
  <svg width="3em" height="0.5em" fill="currentColor" viewBox="0 0 60 10">
    <rect height="12" width="62" y="-1" x="-1" id="rate-step1" />
  </svg>
);

const RateStepTwoSvg = () => (
  <svg width="3em" height="0.5em" fill="currentColor" viewBox="0 0 60 10">
    <rect height="12" width="62" y="-1" x="-1" id="rate-step2" />
  </svg>
);

const customIcons = {
  1: <Icon component={RateStepOneSvg} />,
  2: <Icon component={RateStepOneSvg} />,
  3: <Icon component={RateStepOneSvg} />,
  4: <Icon component={RateStepTwoSvg} />,
  5: <Icon component={RateStepTwoSvg} />,
};

export default observer(SelectionRatingForm);
