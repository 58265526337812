import React, { useContext } from "react";

import { IconHeading, FileCard } from "../shared";
import { SOLUTION_MATERIAL } from "../../constants/pro_constants";
import databaseDetailStore from "../../stores/databaseDetailStore";

const SolutionMateria = () => {
  const {
    data: {
      tabs: { relatedFile },
    },
  } = useContext(databaseDetailStore);

  return (
    <>
      <div className="database-detail__block" id={SOLUTION_MATERIAL}>
        <IconHeading icon="pro-material" title="相关材料" />
        <div className="database-detail__block__content">
          <FileCard name={relatedFile.name} type={relatedFile.type} />
        </div>
      </div>
      <div className="database-detail__intro--bottom" />
    </>
  );
};

export default SolutionMateria;
