import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react";
import { Carousel } from "antd";
import insightStore from "../../stores/insightStore";
import { trackInsightEvent } from "../../utils/ga_events";
import { useHistory } from "react-router-dom";

const InsightHeader: React.FC = () => {
  const history = useHistory();
  const {
    latestInsightList,
    initializeLatestInsight,
    islatestInsightListEmpty,
  } = useContext(insightStore);

  useEffect(() => {
    initializeLatestInsight();
  }, []);

  return (
    <div className="insight-header">
      <div className="common-tab">
        <div
          className={`common-tab__title ${location.pathname ===
            "/trend/insight" && "common-tab__title--active"}`}
          onClick={() => {
            history.push("/trend/insight");
          }}
        >
          洞察文章
        </div>
        <div
          className={`common-tab__title ${location.pathname === "/subscribe" &&
            "common-tab__title--active"}`}
          onClick={() => {
            history.push("/subscribe");
          }}
        >
          行业周报
        </div>
      </div>
      <div
        className={`insight-header__content ${
          latestInsightList.length > 0 ? "insight-header__content--data" : ""
        }`}
      >
        {!islatestInsightListEmpty && (
          <Carousel
            autoplay
            dots={false}
            autoplaySpeed={30000}
            slidesPerRow={2}
          >
            {latestInsightList.map((data, i) => {
              return (
                <a
                  className="insight-header__item"
                  key={i}
                  href={data.linkUrl}
                  target="_blank"
                  rel="noopener"
                  onClick={() =>
                    trackInsightEvent(
                      "view_item",
                      `${data.name}#${data.id}`,
                      ""
                    )
                  }
                >
                  <img src={data.avatarUrl} alt="" />
                  <div className="insight-header__brief">
                    <h3 className="text-limit--two">{data.name}</h3>
                    <span>
                      {data.type} · {data.time}
                    </span>
                  </div>
                </a>
              );
            })}
          </Carousel>
        )}
      </div>
    </div>
  );
};

export default observer(InsightHeader);
