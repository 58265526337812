import React from "react";

const SVG = ({ width = "100%", className = "" }) => (
  <svg
    width={width}
    height={width}
    viewBox="0 0 30 30"
    version="1.1"
    className={`svg-icon ${className || ""}`}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>icon/pop error</title>
    <desc>Created with Sketch.</desc>
    <defs>
      <path
        d="M13.7547264,7.58705098 C14.5380144,6.80373159 15.8049091,6.80490131 16.5870275,7.58705098 L16.5870275,7.58705098 L22.7547264,13.7549968 C23.5380144,14.5383162 23.5368447,15.8051891 22.7547264,16.5872493 L22.7547264,16.5872493 L16.5870275,22.7544896 C15.8037394,23.5377194 14.5368447,23.5365498 13.7547264,22.7544896 L13.7547264,22.7544896 L7.58702748,16.5872493 C6.80373945,15.8040195 6.80490912,14.5371465 7.58702748,13.7549968 L7.58702748,13.7549968 Z M15.1708769,18.1705349 L15.0543084,18.1772667 C14.5571626,18.2350655 14.1708769,18.6579635 14.1708769,19.1705349 L14.1708769,19.1705349 L14.1776087,19.2871034 C14.2354075,19.7842492 14.6583055,20.1705349 15.1708769,20.1705349 L15.1708769,20.1705349 L15.2874454,20.1638031 C15.7845912,20.1060043 16.1708769,19.6831063 16.1708769,19.1705349 L16.1708769,19.1705349 L16.1641451,19.0539664 C16.1063463,18.5568206 15.6834484,18.1705349 15.1708769,18.1705349 L15.1708769,18.1705349 Z M15.1708769,10.1705349 L15.0543084,10.1772667 C14.5571626,10.2350655 14.1708769,10.6579635 14.1708769,11.1705349 L14.1708769,11.1705349 L14.1708769,16.1705349 C14.1708769,16.7225349 14.6188769,17.1705349 15.1708769,17.1705349 L15.1708769,17.1705349 L15.2874454,17.1638031 C15.7845912,17.1060043 16.1708769,16.6831063 16.1708769,16.1705349 L16.1708769,16.1705349 L16.1708769,11.1705349 C16.1708769,10.6185349 15.7228769,10.1705349 15.1708769,10.1705349 L15.1708769,10.1705349 Z"
        id="NotifyError"
      />
    </defs>
    <g
      id="icon/pop-error"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="icon">
        <rect id="Rectangle" x="0" y="0" width="30" height="30" />
        <mask id="NotifyError2" fill="white">
          <use xlinkHref="#NotifyError" />
        </mask>
        <g id="Combined-Shape" />
        <rect
          id="color"
          fill="#E02020"
          mask="url(#NotifyError2)"
          x="0"
          y="0"
          width="30"
          height="30"
        />
      </g>
    </g>
  </svg>
);

export default SVG;
