import React from "react";
import { Link, useLocation } from "react-router-dom";

import { Header, Footer, Button } from "../shared";
import SuccessImage from "../../styles/images/auth-success.svg";

import "./style.scss";

const AuthSuccess = () => {
  const location = useLocation();
  const text = location.pathname.indexOf("login") > 0 ? "登录" : "注册";

  return (
    <div className="success-layout header-footer--verticality-layout">
      <Header />
      <div className="success-layout__mesasge">
        <img src={SuccessImage} alt="认证成功" />
        <p className="success-layout__success-text">恭喜你，{text}成功</p>
        <p className="success-layout__identity-text">
          您需要完成身份认证，才可浏览「机器之心 Pro」数据。
        </p>
        <Link
          to={"/identification/step1"}
          className="success-layout__forward-identity"
        >
          <Button type="primary" size="large" grid={16}>
            立即认证
          </Button>
        </Link>
      </div>
      <Footer />
    </div>
  );
};

export default AuthSuccess;
