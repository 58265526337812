import { createContext } from "react";
import { observable, action, computed } from "mobx";
import _ from "lodash";
import { message } from "antd";

import {
  fetchPersonalInfo,
  fetchCertificationInfo,
  fetchVipInfo,
  updateName,
  activateEmail,
  fetchPhoneVerifyCode,
  verfiyIdentidy,
  updatePhone,
  fetchVerifyIdentidyCode,
  updateEmail,
  updatePassword,
  cancelBind,
} from "../api";
import {
  formatPersonalInfo,
  formatCertificationMeta,
  formatCertificationUserMeta,
  formatVipInfo,
} from "../utils/formatProfileData";
import { PAID_USER } from "../constants/pro_constants";

class ProfileStore {
  @observable personalInfo = {};
  @observable certificationMeta = [];
  @observable certificationUserMeta = [];
  @observable vipInfo = {};
  @observable isUpdateName = false;
  @observable isShowModal = false;
  @observable isShowPasswordModal = false;
  @observable checkMethod = "";
  @observable checkMethodCount = 0;
  @observable isUpdatePhone = false;

  @action
  setIsUpdateName = status => {
    this.isUpdateName = status;
  };

  @action
  setIsShowModal = status => {
    this.isShowModal = status;
  };

  @action
  setIsShowPasswordModal = status => {
    this.isShowPasswordModal = status;
  };

  @action
  fetchPersonalInfo = async () => {
    const result = await fetchPersonalInfo();
    this.personalInfo = formatPersonalInfo(result);
  };

  @action
  fetchCertificationInfo = async () => {
    const result = await fetchCertificationInfo();
    this.certificationMeta = formatCertificationMeta(result);
    this.certificationUserMeta = formatCertificationUserMeta(result);
  };

  @action
  fetchVipInfo = async () => {
    const result = await fetchVipInfo();
    this.vipInfo = formatVipInfo(result);
  };

  @action
  onUpdateName = async name => {
    await updateName(name, response => {
      if (response.statusCode === 204) {
        this.fetchPersonalInfo();
        this.setIsUpdateName(false);
        message.info("昵称修改成功");
      } else {
        message.error(response.message);
      }
    });
  };

  @action
  onActivateEmail = async () => {
    await activateEmail(response => {
      if (response.statusCode === 200) {
        message.success("已发送验证地址到您的邮箱，请查收！");
      } else {
        message.error(response.message);
      }
    });
  };

  @action
  getPhoneVerifyCode = async mobile => {
    await fetchPhoneVerifyCode(mobile, response => {
      if (response.statusCode === 200) {
        message.success("已将验证码发送到您的手机，请查收！");
      } else {
        if (response.statusCode === 422) {
          message.error(response.body.errors[0]);
        } else {
          message.error(response.body.errors.join("，"));
        }
      }
    });
  };

  @action
  onVerfiyIdentidy = async type => {
    await verfiyIdentidy(type, response => {
      if (response.body.success === "ok") {
        this.checkMethod = "";
        this.isUpdatePhone = true;
      } else if (response.body.length >= 2) {
        this.checkMethod = "phone";
        this.isUpdatePhone = false;
        this.checkMethodCount = 2;
      } else if (response.body.length === 1) {
        this.checkMethod =
          response.body[0].type === "email" ? "email" : "phone";
        this.isUpdatePhone = false;
      }
    });
  };

  @action
  onUpdatePhone = async data => {
    await updatePhone(data, response => {
      if (response.statusCode === 200) {
        this.setIsShowModal(false);
        message.success("手机更改成功");
        this.fetchPersonalInfo();
      } else {
        message.error(response.body.errors);
      }
    });
  };

  @action
  getVerifyIdentidyCode = async (value, way) => {
    await fetchVerifyIdentidyCode(value, response => {
      if (response.statusCode === 200) {
        message.success(
          `已将验证码发送到您的${way === "phone" ? "手机" : "邮箱"}，请查收`
        );
      } else {
        message.error(response.body.errors.join("，"));
      }
    });
  };

  @action
  onUpdateEmail = async data => {
    await updateEmail(data, response => {
      if (response.statusCode === 200) {
        this.setIsShowModal(false);
        this.fetchPersonalInfo();
        message.success("邮箱更改成功");
      } else {
        message.error(response.body.errors);
      }
    });
  };

  @action
  onUpdatePassword = async data => {
    let params = {};
    if (!this.isPasswordEmpty) {
      params = {
        "user[password]": data.password,
        "user[origin_password]": data.oldPassword,
      };
    } else {
      params = {
        "user[password]": data.password,
      };
    }
    await updatePassword(params, response => {
      if (response.statusCode === 200) {
        this.setIsShowPasswordModal(false);
        message.success("密码更改成功");
        this.fetchPersonalInfo();
      } else {
        message.error(response.body.errors);
      }
    });
  };

  @action
  onCancelBind = async type => {
    await cancelBind(type, response => {
      if (response.statusCode === 200) {
        this.fetchPersonalInfo();
      } else {
        message.error(response.body.errors);
      }
    });
  };

  @computed
  get isPasswordEmpty() {
    return _.isEmpty(this.personalInfo.password);
  }

  @computed
  get isUpdatePassword() {
    return (
      !_.isEmpty(this.personalInfo.mobile) ||
      !_.isEmpty(this.personalInfo.email)
    );
  }

  @computed
  get isChooseVerifyWay() {
    return this.checkMethod !== "";
  }

  @computed
  get isCheckMethodCountEqual2() {
    return this.checkMethodCount === 2;
  }

  @computed
  get isPaidUser() {
    return this.vipInfo.vip === PAID_USER;
  }

  @computed
  get isVipInfoEmpty() {
    return _.isEmpty(this.vipInfo);
  }

  @computed
  get isMobileEmpty() {
    return _.isEmpty(this.personalInfo.mobile);
  }
}

export default createContext(new ProfileStore());
