import React from "react";

const SVG = ({
  fill = "#C6CBD4",
  width = "100%",
  className = "",
  viewBox = "0 0 16 16",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FFF"
        d="M0 0H1440V1019H0z"
        transform="translate(-842 -522)"
      />
      <path
        fill="#F4F4F6"
        d="M200 0H1440V1135H200z"
        transform="translate(-842 -522)"
      />
      <g transform="translate(-842 -522) translate(220 497)">
        <rect width="1200" height="66" fill="#FFF" rx="4" />
        <g fillRule="nonzero">
          <path
            fill={fill}
            d="M10.403 14.495H5.597c-1.355 0-2.458-1.1-2.458-2.451V4.44c0-.346.28-.625.625-.625s.625.28.625.625v7.603c0 .662.542 1.201 1.208 1.201h4.806c.666 0 1.208-.539 1.208-1.201V4.44c0-.346.28-.625.625-.625s.625.28.625.625v7.603c-.002 1.351-1.103 2.451-2.458 2.451zm3.614-9.482H1.983c-.346 0-.625-.28-.625-.625 0-.346.28-.625.625-.625h12.036c.345 0 .625.28.625.625s-.281.625-.627.625zM6.57 11.951c-.345 0-.625-.28-.625-.625V6.984c0-.345.28-.625.625-.625.346 0 .625.28.625.625v4.343c0 .345-.28.625-.625.625zm2.86 0c-.346 0-.625-.28-.625-.625V6.984c0-.345.28-.625.625-.625s.625.28.625.625v4.343c0 .345-.28.625-.625.625zm2.1-7.502c-.246 0-.477-.144-.577-.384l-.384-.92c-.097-.235-.325-.385-.58-.385H6.012c-.254 0-.482.151-.58.384l-.385.92c-.1.24-.333.385-.577.385-.08 0-.162-.016-.24-.048-.32-.133-.47-.499-.336-.818l.384-.92c.292-.7.974-1.153 1.733-1.153h3.977c.76 0 1.44.453 1.732 1.153l.385.92c.133.32-.018.685-.336.818-.077.032-.16.048-.24.048z"
            transform="translate(622 25)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
