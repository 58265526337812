import React from "react";

const SVG = ({
  fill = "#C6CBD4",
  width = "100%",
  className = "",
  viewBox = "0 0 16 16",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        id="pro-report"
        d="M9.6 7.2c0 .442-.358.8-.8.8H5.6c-.442 0-.8-.358-.8-.8 0-.442.358-.8.8-.8h3.2c.442 0 .8.358.8.8zm0-3.2c0 .442-.358.8-.8.8H5.6c-.442 0-.8-.358-.8-.8 0-.442.358-.8.8-.8h3.2c.442 0 .8.358.8.8zm4-.8h-.8v-.8c0-.44.36-.8.8-.8.44 0 .8.36.8.8 0 .44-.36.8-.8.8zm-2.4 10.4c0 .44-.36.8-.8.8-.44 0-.8-.36-.8-.8V12c0-.442-.358-.8-.8-.8H3.2v-8c0-.882.718-1.6 1.6-1.6h6.547c-.09.25-.147.518-.147.8v11.2zm-8 .8c-.882 0-1.6-.718-1.6-1.6H8v.8c0 .28.049.55.137.8H3.2zM13.6 0H4.8C3.035 0 1.6 1.435 1.6 3.2v8H.8c-.442 0-.8.358-.8.8v.8C0 14.565 1.435 16 3.2 16h7.2c1.324 0 2.4-1.077 2.4-2.4V4.8h.8c1.323 0 2.4-1.077 2.4-2.4S14.923 0 13.6 0z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <use fill={fill} xlinkHref="#pro-report" />
    </g>
  </svg>
);

export default SVG;
