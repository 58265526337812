import React from "react";

import "./style.scss";

interface Item {
  key: string;
  name: string;
  isHide: boolean;
}

interface Props {
  items: Item[];
  selectedKey: string;
  className?: string;
  onClick: (item: Item) => void;
}

const Menu: React.FC<Props> = (props: Props) => {
  const isShowedItems = props.items.filter(item => !item.isHide);

  return (
    <div className={`pro-menus ${props.className}`}>
      {isShowedItems.map((item, i) => {
        const selected = props.selectedKey == item.key ? "selected" : "";

        return (
          <div
            key={i}
            className={`pro-menu__item ${selected}`}
            onClick={() => {
              props.onClick(item);
            }}
          >
            {item.name}
          </div>
        );
      })}
    </div>
  );
};

export default Menu;
