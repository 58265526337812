import { createContext } from "react";
import { action, observable, computed } from "mobx";

import formatCommunicationGroups from "../utils/formatCommunicationData";
import { fetchCommunicationList } from "../api";
import { trackCommunicationEvent } from "../utils/ga_events";

class CommunicationStore {
  @observable groups = [];
  @observable totalCount = 0;
  @observable isShowModal = false;
  @observable currentGroupName = "";
  @observable currentGroupAvatar = "";
  @observable currentGroupSummary = "";
  @observable loading = false;
  @observable hasMore = false;
  @observable pagination = {
    current: 1,
    pageSize: 0,
  };

  @action
  initializeGroups = async () => {
    const result = await fetchCommunicationList();
    this.totalCount = result.totalCount;
    this.pagination.pageSize = Number(result.pageSize);
    this.groups = formatCommunicationGroups(result.data);
    trackCommunicationEvent("view_list", "", this.pagination.current);
  };

  @action
  updateIsShowModal = (
    isShow: boolean,
    title?: string,
    avatar?: string,
    summary?: string
  ) => {
    this.isShowModal = isShow;
    this.currentGroupName = title;
    this.currentGroupAvatar = avatar;
    this.currentGroupSummary = summary;
    if (this.isShowModal) {
      trackCommunicationEvent("join", title, "");
    }
  };

  @action
  fetchMoreData = async () => {
    this.loading = true;
    this.hasMore = true;

    if (this.totalCount !== 0 && this.isLoadedAll) {
      this.hasMore = false;
      this.loading = false;
      return;
    }

    const result = await fetchCommunicationList();
    this.groups = this.groups.concat(formatCommunicationGroups(result.data));
    this.loading = false;
    trackCommunicationEvent("view_list", "", this.pagination.current);
    this.pagination.current = this.pagination.current + 1;
  };

  @computed
  get isLoadedAll() {
    return this.pagination.pageSize > this.totalCount;
  }

  @computed
  get getUrlParams() {
    let params = this.pagination.current
      ? `size=${this.pagination.pageSize}&page=${this.pagination.current}&`
      : "";

    return params;
  }
}

export default createContext(new CommunicationStore());
