import React from "react";
import { Link } from "react-router-dom";

interface Props {
  linkTo: string;
  className?: string;
  children: React.ReactNode;
}

const LinkWrapper: React.FC<Props> = (props: Props) => {
  const isExternalLink = props.linkTo.includes("http");

  return (
    <>
      {isExternalLink ? (
        <a href={props.linkTo} className={props.className}>
          {props.children}
        </a>
      ) : (
        <Link to={props.linkTo} className={props.className}>
          {props.children}
        </Link>
      )}
    </>
  );
};

export default LinkWrapper;
