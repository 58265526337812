import React, { useContext } from "react";
import FilterItem from "./FilterItem";
import { Col } from "antd";
import { observer } from "mobx-react";
import researchStore from "../../stores/researchStore";
import { INDUSTRIES } from "../../constants/pro_constants";

const Filters: React.FC = () => {
  const { filters, resetFilters } = useContext(researchStore);

  return (
    <Col span={4} className="research-filters">
      <div className="research-filters__title">
        <span>筛选</span>
        <button className="research-filters__clear" onClick={resetFilters}>
          清空筛选
        </button>
      </div>
      <div>
        {filters.map((item, i) => {
          const isShowCollapse =
            item.type === INDUSTRIES && item.fields.length > 5;
          return (
            <FilterItem key={i} {...item} isShowCollapse={isShowCollapse} />
          );
        })}
      </div>
    </Col>
  );
};

export default observer(Filters);
