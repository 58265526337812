import React from "react";

const SVG = ({
  fill = "#00B67A",
  width = "100%",
  className = "",
  viewBox = "0 0 20 20",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd">
      <circle cx="10" cy="10" r="9" fill="#FFF" />
      <path
        fill={fill}
        d="M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0m0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8m2.293-10.707L9 10.586 7.707 9.293c-.391-.391-1.023-.391-1.414 0s-.391 1.023 0 1.414l2 2c.195.195.451.293.707.293.256 0 .512-.098.707-.293l4-4c.391-.391.391-1.023 0-1.414s-1.023-.391-1.414 0"
      />
    </g>
  </svg>
);

export default SVG;
