import React from "react";

const Contact = ({ data }) => {
  return (
    data && (
      <>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">姓名:</div>
          <div className="selection-rating-modules-form--value">
            {data.name}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">职位:</div>
          <div className="selection-rating-modules-form--value">
            {data.position}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">部门:</div>
          <div className="selection-rating-modules-form--value">
            {data.department}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">手机:</div>
          <div className="selection-rating-modules-form--value">
            {data.mobile}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">微信ID:</div>
          <div className="selection-rating-modules-form--value">
            {data.wechat}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">所在城市:</div>
          <div className="selection-rating-modules-form--value">
            {data.city}
          </div>
        </div>
      </>
    )
  );
};

export default Contact;
