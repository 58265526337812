import React, { useEffect, useContext } from "react";
import { observer } from "mobx-react";

import "./dialog.scss";
import { Dialog } from "../shared";
import codeIcon from "../../styles/images/invalid-code.png";

import memberInboxStore from "../../stores/memberInboxStore";

const InvalidCodeDialog = () => {
  const { dialog, setDialog } = useContext(memberInboxStore);
  const onCancel = () => {
    setDialog("invalid-code", false);
    window.sessionStorage.setItem("endCode", "end");
  };

  useEffect(() => {
    // api();
  }, []);

  return (
    <Dialog
      visible={dialog["invalid-code"]}
      style={{ padding: "48px 20px 38px", borderRadius: "10px" }}
      onCancel={onCancel}
      layerClose={false}
      grayClose
      closeStyle={{ color: "#bec2d7", top: "16px", right: "18px" }}
    >
      <div className="invalid-code-dialog">
        <img src={codeIcon} alt="codeIcon" />
        <p>哎呀，手慢了~</p>
      </div>
    </Dialog>
  );
};

export default observer(InvalidCodeDialog);
