/*
 * @Author: wh.q
 * @Date: 2020-09-16 10:27:42
 * @LastEditTime: 2020-09-16 16:11:56
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /Synced/frontend/sites/pro/stores/membershipStore.ts
 */
import { action, observable, computed } from "mobx";
import { createContext } from "react";
import crownStart from "../styles/images/crown-start.png";
import crownEnd from "../styles/images/crown-end.png";
import { fetchAlphaInfo, fetchMembership } from "../api";
import { message } from "antd";
import moment from "moment";

//todo: ruby端做过期跳转 /

interface StoreProps {
  days: number;
  title: string;
  loading: boolean;
  expireAt: string;
  description: string;
  descriptionEnd: string;
  available: boolean;
  getAlphaInfo(): void;
  getMembership(): void;
}

class MembershipStore implements StoreProps {
  @observable public loading = true;
  @observable public available = false;
  @observable public days = 90;
  @observable public expireAt = "";

  @action public getAlphaInfo = async () => {
    const { available, days, expireAt } = await fetchAlphaInfo();
    this.available = !available;
    this.days = days;
    this.expireAt = moment.unix(expireAt).format("YYYY.MM.DD");
    this.loading = false;
  };

  @action public getMembership = async () => {
    const result = await fetchMembership();
    message[result.status](result.message);
    result.status === "success" && (window.location.href = "/membership");
  };

  @computed
  get src() {
    return this.available ? crownEnd : crownStart;
  }

  @computed
  get title() {
    return this.available ? "领取成功" : "领取 Pro 会员";
  }

  @computed
  get description() {
    return this.available
      ? "您已成为机器之心Pro会员"
      : "您将获得机器之心Pro会员";
  }

  @computed
  get descriptionEnd() {
    return this.available ? `${this.expireAt}到期` : `会员有效期${this.days}天`;
  }
}

export default createContext(new MembershipStore());
