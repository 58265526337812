import React from "react";

const SVG = ({
  fill = "#C6CBD4",
  width = "100%",
  className = "",
  viewBox = "0 0 20 20",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>arrow right</title>
    <desc>Created with Sketch.</desc>
    <g id="研究" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="arrow-right">
        <rect
          id="Rectangle"
          fill="#000000"
          fillRule="nonzero"
          opacity="0"
          x="0"
          y="0"
          width="20"
          height="20"
        />
        <path
          d="M19.620957,10.1460156 C19.620957,4.91146484 15.3774414,0.668125 10.1429102,0.668125 C4.90851563,0.668125 0.665175781,4.91144531 0.665175781,10.1460156 C0.665175781,15.3805469 4.90849609,19.6239063 10.1429102,19.6239063 C15.3774609,19.6239063 19.620957,15.3805664 19.620957,10.1460547 L19.620957,10.1460156 Z M1.84976563,10.1460156 C1.84976563,5.56572266 5.56289063,1.85291016 10.1428906,1.85291016 C14.7229883,1.85291016 18.4360156,5.56576172 18.4360156,10.1460156 C18.4360156,14.7261133 14.7229492,18.4391016 10.1428906,18.4391016 C5.56285156,18.4391016 1.84976563,14.7260937 1.84976563,10.1460156 L1.84976563,10.1460156 Z M12.8296094,10.6663672 C12.9718164,10.5244922 13.088457,10.3299805 13.0561914,10.1460352 C13.0884766,9.96207031 12.9718164,9.76751953 12.8296094,9.62537109 L8.29394531,5.08970703 C8.06255859,4.85865234 7.68759766,4.85869141 7.45621094,5.08970703 C7.22482422,5.32109375 7.22480469,5.69623047 7.45621094,5.92763672 L11.6745898,10.1460352 L7.45621094,14.3642773 C7.22482422,14.595625 7.22482422,14.9707617 7.45621094,15.202168 C7.68755859,15.4333984 8.06251953,15.4333984 8.29394531,15.202168 L12.8296094,10.6664258 L12.8296094,10.6663672 Z"
          id="Shape"
          fill={fill}
        />
      </g>
    </g>
  </svg>
);

export default SVG;
