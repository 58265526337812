import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react";
import { useParams, useLocation } from "react-router-dom";

import "./style.scss";
import SelectionRatingHeader from "./SelectionRatingHeader";
import SelectionRatingModulesInfo from "./SelectionRatingModulesInfo";
import SelectionRatingForm from "./SelectionRatingForm";
import SelectionRatingDetailStore from "../../stores/selectionRatingDetailStore";

const SelectionRatingDetail: React.FC = () => {
  const { id } = useParams();
  const rankId = new URLSearchParams(useLocation().search).get("rankId");
  const { getRatingInfo } = useContext(SelectionRatingDetailStore);

  useEffect(() => {
    getRatingInfo(id, rankId);
  }, [id]);
  return (
    <div className="selection-rating-detail-layout">
      <div className="pro-layout__content-wrapper">
        <div className="selection-rating-detail-layout__container">
          <SelectionRatingHeader />
          <div className="selection-rating-detail-layout__content">
            <SelectionRatingModulesInfo />
            <SelectionRatingForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(SelectionRatingDetail);
