import React, { useContext, useEffect } from "react";
// import { Button } from "../shared";
import insightStore from "../../stores/insightStore";
import { observer } from "mobx-react";
import InfiniteScroll from "react-infinite-scroll-component";
import { trackInsightEvent } from "../../utils/ga_events";

const InsightList: React.FC = () => {
  const {
    insightCategories,
    insightList,
    initializeInsightCategories,
    initializeInsightList,
    handleCategoryRemoved,
    handleCategoryChanged,
    fetchMoreData,
    hasMore,
    loading,
  } = useContext(insightStore);

  useEffect(() => {
    initializeInsightCategories();
    initializeInsightList();
  }, []);

  return (
    <div className="insight-list">
      <p className="insight-list__title">最新洞察</p>
      <div className="insight-list__categories">
        <div className="insight-list__tags">
          {insightCategories.map((data, i) => {
            if (i === 0) {
              return (
                <span
                  className={`insight-list__tag-item ${
                    data.active ? "active" : ""
                  }`}
                  key={i}
                  onClick={() => handleCategoryChanged(data.name, "all")}
                >
                  {data.name}
                </span>
              );
            }
            return (
              <span
                className={`insight-list__tag-item ${
                  data.active ? "active" : ""
                }`}
                key={i}
                onClick={() => handleCategoryChanged(data.name)}
              >
                {data.name}
                {data.active && (
                  <span
                    className="insight-list__tag-item--remove"
                    onClick={event => {
                      event.stopPropagation();
                      event.cancelable = true;
                      handleCategoryRemoved(data.name);
                    }}
                  />
                )}
              </span>
            );
          })}
        </div>
        {/* <div className="insight-list__categories__other">
          <span>找不到感兴趣的内容？</span>
          <Button
            size="small"
            grid={4}
            type="primary"
            onClick={() => {
              window.open(
                "https://www.jiqizhixin.com/short_urls/46a839bc-a3e5-4dd1-9763-46bc7c0efadc"
              );
            }}
          >
            提交内容建议及线索
          </Button>
        </div> */}
      </div>
      {/* <div className="insight-list__content">
        {insightList.map((data, i) => {
          return <InsightItem key={i} data={data} />;
        })}
      </div> */}
      <InfiniteScroll
        className="insight-list__content"
        dataLength={insightList.length}
        next={fetchMoreData}
        scrollThreshold="200px"
        hasMore={hasMore}
        scrollableTarget="insight-content"
        loader={
          loading && (
            <div className="loader" key={0}>
              Loading
            </div>
          )
        }
      >
        {insightList.map((data, i) => {
          return <InsightItem key={i} data={data} />;
        })}
      </InfiniteScroll>
    </div>
  );
};

const InsightItem = ({ data }) => {
  return (
    <a
      className="insight-item"
      href={data.linkUrl}
      target="_blank"
      rel="noopener"
      onClick={() =>
        trackInsightEvent("view_item", `${data.name}#${data.id}`, "")
      }
    >
      <img className="insight-item__avatar" src={data.avatarUrl} alt="" />
      <div className="insight-item__info">
        <h3 className="insight-item__title text-limit--two">{data.name}</h3>
        <p className="insight-item__desc text-limit--two">{data.desc} </p>
        <span className="insight-item__category">{data.type}</span>
        <span className="insight-item__time">{data.time}</span>
      </div>
    </a>
  );
};

export default observer(InsightList);
