import React, { useContext } from "react";
import { Modal, Form, Input } from "antd";
import { observer } from "mobx-react";

import { Icon, Button } from "../shared";
import ProfileStore from "../../stores/ProfileStore";

const UpdatePassword = props => {
  const { onUpdatePassword } = useContext(ProfileStore);
  const title = props.isFisrtSettingPW ? "设置密码" : "更改密码";
  return (
    <Modal
      visible={props.visible}
      width={464}
      footer={null}
      closeIcon={<Icon name="pro-close" size={16} color="#c1c7d0" />}
      className="update-EP-moal"
      onCancel={() => props.onHide()}
    >
      <div className="update-EP-modal__content">
        <div className="update-EP-modal__title">{title}</div>
        <div className="update-EP-modal__body">
          <Form onFinish={values => onUpdatePassword(values)}>
            {!props.isFirstSettingPW && (
              <Form.Item
                name="oldPassword"
                rules={[
                  {
                    required: true,
                    message: "请输入当前密码",
                  },
                ]}
              >
                <Input.Password placeholder="当前密码" />
              </Form.Item>
            )}
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "请输入新密码",
                },
                {
                  pattern: /^.{6,32}$/,
                  message: "密码6-32位",
                },
              ]}
              hasFeedback
            >
              <Input.Password placeholder="新密码" />
            </Form.Item>

            <Form.Item
              name="confirmPassword"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "请输入确认密码!",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject("新密码和确认密码不一致");
                  },
                }),
              ]}
            >
              <Input.Password placeholder="确认新密码" />
            </Form.Item>
            <Form.Item className="update-EP-modal__action">
              <Button size="large" grid={33} htmlType="submit">
                保存
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default observer(UpdatePassword);
