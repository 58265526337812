import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { OVERVIEW } from "../../constants/pro_constants";
import { Button } from "../shared";
import Banner from "../../styles/images/banner.png";
import AuthStore from "../../stores/authStore";
import moment from "moment";

const SalesBanner = () => {
  const {
    currentUser,
    isVipUser,
    isAlphaUser,
    isTrialUser,
    expiredAt,
    isPaidUser,
    isAlphaUserWithoutStartTrial,
    isTrialExpired,
  } = useContext(AuthStore);

  const isPaidVip = isAlphaUser && isPaidUser;
  const isExpiredVipUser = isAlphaUser && !isVipUser && expiredAt < moment();
  const trialInfo = () => {
    if (!currentUser) {
      return { label: "开始试用", path: `/login` };
    }
    if (!isAlphaUser) {
      return { label: "开始试用", path: `/users/${currentUser.id}` };
    }
    if (isAlphaUserWithoutStartTrial) {
      return { label: "开始试用", path: `/identification/start` };
    }
    if (isAlphaUser && isTrialUser) {
      return { label: "开始试用", path: `/home` };
    }
    if (isTrialExpired) {
      return { label: "开始使用", path: `/pricing` };
    }
    if (isPaidVip) {
      return { label: "开始使用", path: `/home` };
    }
    if ((isAlphaUser && expiredAt === null) || isExpiredVipUser) {
      return { label: "开始使用", path: `/pricing` };
    }
  };
  return (
    <div className="sales-layout__fill-wrapper sales-banner" id={OVERVIEW}>
      <div className="sales-banner__info">
        <div className="sales-banner__title">专业的人工智能市场信息终端</div>
        <div className="sales-banner__desc">
          实践AI，信息先行。机器之心Pro为用户提供全面准确的人工智能市场信息，助力专业用户更好的把握人工智能发展趋势，更加高效的实现人工智能落地。
        </div>
        <Link className="sales-banner__forward" to={trialInfo().path}>
          <Button size={"xlarge"} grid={14}>
            立即前往
          </Button>
        </Link>
        <Link className="sales-banner__forward--mobile" to={trialInfo().path}>
          <Button size={"large"} grid={11}>
            立即前往
          </Button>
        </Link>
        <div className="sales-banner__img">
          <img src={Banner} alt="" />
        </div>
      </div>
    </div>
  );
};

export default observer(SalesBanner);
