import React from "react";
import DataCollectingSvg from "../../styles/images/data-collecting.svg";
import { Button } from "../shared";

interface Props {
  label: string;
}
const DataCollecting: React.FC<Props> = (props: Props) => {
  return (
    <div className="data-collecting">
      <img src={DataCollectingSvg} alt="" />
      <p>{props.label}数据收集中</p>
      <Button size="small" grid={4}>
        前往XXXX探索更多
      </Button>
    </div>
  );
};

DataCollecting.defaultProps = {
  label: "",
};

export default DataCollecting;
