import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";

import "./style.scss";
import { Header, Footer, Button } from "../shared";
import identityWorking from "../../styles/images/identity-working.svg";
import identityStudents from "../../styles/images/identity-students.svg";
import identificationStore from "../../stores/identificationStore";

const Identity: React.FC = () => {
  const {
    userIdentity,
    updateUserIdentity,
    initializeCareerInformation,
    initializeBasicInformation,
  } = useContext(identificationStore);

  useEffect(() => {
    initializeCareerInformation();
    initializeBasicInformation();
  }, []);

  return (
    <div className="pro-identification-layout header-footer--verticality-layout">
      <Header />
      <div className="pro-identification-content">
        <h3>请选择你的身份</h3>
        <span>我们会对不同身份提供更精准的信息服务</span>
        <div className="pro-identification-cards">
          <input
            type="radio"
            id="working"
            name="radio"
            defaultChecked={userIdentity === "working"}
          />
          <label htmlFor="working">
            <img
              onClick={() => updateUserIdentity("working")}
              src={identityWorking}
              alt="在职"
            />
          </label>
          <input
            type="radio"
            id="students"
            name="radio"
            defaultChecked={userIdentity === "studying"}
          />
          <label htmlFor="students">
            <img
              onClick={() => updateUserIdentity("studying")}
              src={identityStudents}
              alt="在读"
            />
          </label>
        </div>
        <p className="pro-identification-description">
          请放心选择，以后还可以在个人中心更改
        </p>
        <Link to="/identification/step2">
          <Button size="large" grid={18}>
            下一步
          </Button>
        </Link>
      </div>
      <Footer />
    </div>
  );
};

export default Identity;
