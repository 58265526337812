import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react";

import "./style.scss";
import { Icon } from "../shared";
import DrawerMeta from "./DrawerMeta";
import DrawerList from "./DrawerList";
import DrawerFooter from "./DrawerFooter";
import SubscribeStore from "../../stores/subscribeStore";

interface Props {
  visible: boolean;
  onClose: () => void;
}

const SubscribeDrawer: React.FC<Props> = (props: Props) => {
  const {
    initialSubscribeDetail,
    subscribeDetail,
    selectedId,
    isSubscribed,
    subscribedCount,
  } = useContext(SubscribeStore);

  useEffect(() => {
    initialSubscribeDetail();
  }, [selectedId, subscribedCount, isSubscribed]);

  return (
    <>
      {subscribeDetail && (
        <div
          className={`subscribe-drawer ${
            props.visible ? "" : "drawer--hidden"
          }`}
        >
          <div className="subscribe-drawer__content">
            <div
              className="subscribe-drawer__content-close"
              onClick={props.onClose}
            >
              <Icon name="pro-close" size={16} color="#c1c7d0" />
            </div>
            <div className="subscribe-drawer__content-info">
              <DrawerMeta />
              <div className="subscribe--divider" />
              <DrawerList />
            </div>
          </div>
          <DrawerFooter />
        </div>
      )}
    </>
  );
};

export default observer(SubscribeDrawer);
