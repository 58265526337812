//@ts-nocheck
import {
  INSTITUTION,
  SOLUTION,
  BUSINESS_CASE,
} from "../constants/pro_constants";
import moment from "moment";

const getAwards = (initData: any) => ({
  award2019List: [...new Set(initData.award2019_list)],
  award2020List: [...new Set(initData.award2020_list)],
  award2021List: [...new Set(initData.award2021_list)],
  award2022List: [...new Set(initData.award2022_list)],
  award2023List: [...new Set(initData.award2023_list)],
  award2024List: [...new Set(initData.award2024_list)],
});

const formatDetailData = (type, initData) => {
  if (type === INSTITUTION) {
    return {
      header: {
        avatar: initData.avatar_url,
        avatarLink: initData.website,
        name: initData.name,
        contactable: initData.assigned,
        ...getAwards(initData),
        aliasName: initData.alias_names,
        avatarDesc: initData.role_desc,
        fields: initData.fields,
        summary: initData.summary,
        website: initData.website,
        technologies: initData.technologies,
        registerName: initData.meta_card.registered_name,
        foundedAt: initData.meta_card.founded_at,
        officialWebsite: initData.meta_card.website,
        region: initData.meta_card.region,
      },
      tabs: {
        clients: initData.clients.map(item => ({
          id: item.id,
          name: item.name,
          avatarUrl: item.avatar_url,
          summary: item.summary,
          fields: item.fields,
        })),
        cooperatePartners: initData.partners.map(item => ({
          id: item.id,
          name: item.name,
          avatarUrl: item.avatar_url,
          fields: item.fields,
        })),
        coreMembers: initData.experts.map(item => ({
          id: item.id,
          name: item.name,
          avatarUrl: item.avatar_url,
          summary: item.summary,
        })),
        institutionIntro: initData.desc,
        productSolutions: initData.solutions.map(item => {
          return {
            id: item.id,
            name: item.name,
            businessCases: item.business_cases,
            fields: item.fields,
          };
        }),
      },
      newsflash: initData.flows,
      level: initData.completeness,
    };
  }

  if (type === SOLUTION) {
    return {
      header: {
        id: initData.id,
        name: initData.name,
        fields: initData.fields,
        summary: initData.summary,
        technologies: initData.technologies,
        ...getAwards(initData),
        producer: initData.producer,
      },
      tabs: {
        solutionIntro: initData.desc,
        solutionFeature: initData.feature,
        techArchitecture: initData.architecture,
        businessCases: initData.business_cases,
        relatedFile: {
          name: initData.related_file.filename,
          type: initData.related_file.content_type,
        },
      },
      newsflash: initData.flows,
      level: initData.completeness,
    };
  }

  if (type === BUSINESS_CASE) {
    return {
      level: initData.completeness,
      header: {
        name: initData.name,
        fields: initData.fields,
        summary: initData.summary,
        technologies: initData.technologies,
        solutions: initData.solutions.map(item => {
          return {
            id: item.id,
            title: item.name,
            avatarURL: item.producer.avatar_url,
            producerName: item.producer.name,
          };
        }),
        keyData: initData.key_data.map(ele => ({
          key: ele.key,
          value: ele.value,
        })),
        ...getAwards(initData),
      },
      tabs: {
        caseDetailIntro: initData.desc,
        projectBackground: initData.background,
        coreInstitution: initData.institutions,
        relatedFile: {
          name: initData.related_file.filename,
          type: initData.related_file.content_type,
        },
      },
      newsflash: initData.flows,
    };
  }
};

const formatAssetsData = initData => {
  return initData.map(item => {
    return {
      date: moment(item.date * 1000).format("YYYY.MM"),
      round: item.round,
      type: item.type,
      investee: item.name,
      investors: item.investors,
      amount: item.amount,
    };
  });
};

export { formatDetailData, formatAssetsData };
