import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

import "./style.scss";
import { Header, Footer, Button } from "../shared";
import identificationStore from "../../stores/identificationStore";
import AuthStore from "../../stores/authStore";
import { observer } from "mobx-react";

interface Props {
  header: string;
  icon: string;
}

const IdentificationSuccess: React.FC<Props> = (props: Props) => {
  const { onTrialStart } = useContext(identificationStore);
  const { isVipUser, isPaidUser } = useContext(AuthStore);
  const history = useHistory();

  return (
    <div className="pro-identification-layout header-footer--verticality-layout">
      <Header />
      <div className="pro-identification-success__content">
        <img src={props.icon} alt="" />
        <h4>{props.header}</h4>
        {!isVipUser && (
          <>
            <label>请确认是否立即开始试用「机器之心 Pro」， </label>
            <span>试用时间为七天，您可以稍后开始试用。</span>
          </>
        )}
        <div className="pro-identification-success__button-group">
          <a className="pro-identification-success__button--cancel" href="/">
            稍后再说
          </a>
          <a>
            <Button
              size="large"
              grid={10}
              onClick={() => {
                if (isPaidUser) {
                  history.push("/home");
                } else {
                  onTrialStart();
                }
              }}
            >
              {isVipUser ? "开始使用" : "立即试用"}
            </Button>
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default observer(IdentificationSuccess);
