import React, { useEffect, useContext, useState } from "react";
import { observer } from "mobx-react";
import { Helmet } from "react-helmet";

import "./style.scss";
import { Sidebar } from "../shared";
import authStore from "../../stores/authStore";
import messageStore from "../../stores/messageStore";
import { PRO, MESSAGE } from "../../constants/pro_constants";
import { labelToChinese } from "../../constants/label_to_chinese";
import btnImg from "../../styles/images/message-subscribe-btn.png";
import MessageCard from "./MessageCard";
import AssistantCodeModal from "../shared/modal/AssistantCodeModal";
import Loading from "../shared/loading/Loading";

const Message: React.FC = () => {
  const { fetchMemberInfo } = useContext(authStore);
  const { list, pagination, loading, fetchList, nextPage } = useContext(
    messageStore
  );

  const [isShowModal, setIsShowModal] = useState(false);

  const onScrollEvent = () => {
    if (!loading && pagination.hasNextPage) {
      const dom = document.getElementsByClassName(
        "message-layout__content-wrapper"
      )[0];
      if (
        dom.scrollTop + dom.clientHeight > dom.scrollHeight * 0.9 &&
        list.length < pagination.total
      ) {
        nextPage("comm");
      }
    }
  };

  useEffect(() => {
    // revert();
    //!todo: 因为后端接口并发会导致卡死,等后端修复后去掉定时器;
    setTimeout(() => {
      fetchMemberInfo();
    }, 500);
    fetchList("comm"); //通讯
    window.sessionStorage.removeItem("id");
    window.sessionStorage.removeItem("login");
  }, []);

  return (
    <div className="message-layout">
      <Helmet>
        <title>
          {labelToChinese[MESSAGE]}｜ {labelToChinese[PRO]}
        </title>
      </Helmet>
      <div className="message-layout__sidebar">
        <Sidebar />
      </div>
      <div
        className="message-layout__content-wrapper"
        id="message-content"
        onScrollCapture={onScrollEvent}
      >
        <div className="message-layout__content">
          <div className="message__header">
            会员通讯
            <img
              src={btnImg}
              alt="btn"
              onClick={() =>
                window.open(
                  "https://www.jiqizhixin.com/short_urls/46a839bc-a3e5-4dd1-9763-46bc7c0efadc"
                )
              }
            />
          </div>
          <div className="message__content">
            {/* className="member-inbox__expire" */}
            <div className="message__list">
              {list &&
                list.length > 0 &&
                list.map((item: any, index: number) => (
                  <MessageCard key={index} model={item} />
                ))}
              {loading && <Loading />}
            </div>
          </div>
        </div>
        {isShowModal && (
          <AssistantCodeModal
            visible={isShowModal}
            desc="添加小助手后，回复「开发票」"
            onCancel={() => {
              setIsShowModal(false);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default observer(Message);
