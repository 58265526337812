import React, { useContext, useEffect } from "react";

import "./style.scss";
import { Header, Footer, Button } from "../shared";
import { Form, Input } from "antd";
import identificationStore from "../../stores/identificationStore";

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

const BasicInformation: React.FC = () => {
  const {
    name,
    email,
    mobile,
    wechat,
    updateBasicInformation,
    initializeBasicInformation,
  } = useContext(identificationStore);

  useEffect(() => {
    initializeBasicInformation();
  }, [name, email, mobile, wechat]);

  return (
    <div className="pro-identification-layout header-footer--verticality-layout">
      <Header />
      <div className="pro-basic-information__content">
        <h3>个人信息</h3>
        <span>我们会将你的信息保密，仅用于为你提供更精准的信息服务</span>
        <Form
          className="pro-basic-information__form"
          onFinish={values => updateBasicInformation(values)}
          {...layout}
        >
          <Form.Item
            rules={[{ required: true, message: "请输入真实姓名" }]}
            label="姓名"
            name="name"
            initialValue={name}
          >
            <Input autoComplete="off" placeholder="请输入真实姓名" />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: "请输入手机号码" },
              {
                pattern: /^1[3456789]\d{9}$/,
                message: "请输入正确的手机号码",
              },
            ]}
            label="手机号码"
            name="mobile"
            initialValue={mobile}
          >
            <Input autoComplete="off" placeholder="请输入手机号码" />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: "请输入微信ID账号" }]}
            label="微信 ID"
            name="wechat"
            initialValue={wechat}
          >
            <Input autoComplete="off" placeholder="请输入微信ID账号" />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: "请输入邮箱地址" },
              {
                type: "email",
                message: "请输入正确的邮箱地址",
              },
            ]}
            label="邮箱"
            name="email"
            initialValue={email}
          >
            <Input autoComplete="off" placeholder="请输入邮箱地址" />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 4 }}>
            <Button size="large" grid={14} htmlType="submit">
              完成
            </Button>
          </Form.Item>
        </Form>
      </div>
      <Footer />
    </div>
  );
};

export default BasicInformation;
