import React from "react";
import SyncedLogo from "../../../styles/images/logo.png";
import { TOP_DOMAIN } from "../../../api";
import { FooterRelevantLinks } from "../../../constants/footer_relevant_links";
import Icon from "../icon/Icon";
import "./style.scss";

const Footer = () => {
  const SyncedWechatCodeUrl =
    "https://cdn.jiqizhixin.com/assets/application/weixinQR-2e2eb9e01d350c1ce6bd7cfe4cf8aa2209a1d72b50f1a6147a371e85548fcdf4.jpg";
  return (
    <div className="sales-layout__footer-wrapper">
      <div className="sales-layout__footer-content">
        <div className="sales-layout__footer-content__about-synced-wrapper">
          <img src={SyncedLogo} alt="Synced" />
          <div>
            <a href={`http://www.${TOP_DOMAIN}/about`} target="_blank">
              关于我们
            </a>
            <a href={`http://www.${TOP_DOMAIN}/terms`} target="_blank">
              服务条款
            </a>
          </div>
        </div>
        <div className="sales-layout__footer-content__relevant-wrapper">
          <h3>全球人工智能信息服务</h3>
          <span>友情链接</span>
          {FooterRelevantLinks.map(ele => (
            <a href={ele.href} target="_blank" key={ele.title}>
              {ele.title}
            </a>
          ))}
        </div>

        <div className="sales-layout-footer__social-media--top">
          <a>
            <Icon name="pro-footer-wechat" size={30} />
            <div className="sales-layout__footer-content__wechat-img">
              <img src={SyncedWechatCodeUrl} alt="" />
            </div>
          </a>
          <a href="http://weibo.com/synced" target="_blank">
            <Icon name="pro-footer-weibo" size={30} />
          </a>
          <a
            className="sales-layout-footer__social-media-rss"
            href="https://jiqizhixin.com/rss"
            target="_blank"
          >
            <Icon name="pro-footer-rss" size={30} />
          </a>
        </div>
        <div className="sales-layout__footer--divider" />
        <div className="sales-layout-footer__social-media--bottom">
          <a>
            <Icon name="pro-footer-wechat" size={30} />
            <div className="sales-layout__footer-content__wechat-img">
              <img src={SyncedWechatCodeUrl} alt="" />
            </div>
          </a>
          <a href="http://weibo.com/synced" target="_blank">
            <Icon name="pro-footer-weibo" size={30} />
          </a>
          <a
            className="sales-layout-footer__social-media-rss"
            href="https://jiqizhixin.com/rss"
            target="_blank"
          >
            <Icon name="pro-footer-rss" size={30} />
          </a>
        </div>
        <div className="sales-layout__footer-content__info-wrapper">
          ©{new Date().getFullYear()}{" "}
          机器之心（北京）科技有限公司&nbsp;&nbsp;&nbsp;&nbsp;
          <a href="https://beian.miit.gov.cn" target="_blank">
            京 ICP 备 14017335号-2
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
