import React, { useContext, useRef } from "react";
import { Link } from "react-router-dom";
import { Form, Input } from "antd";
import { observer } from "mobx-react";

import "./style.scss";
import { Button } from "..";
// import wechatImage from "../../../styles/images/social-wechat.svg";
// import githubImage from "../../../styles/images/social-github.svg";
// import weiboImage from "../../../styles/images/social-weibo.svg";
import authStore from "../../../stores/authStore";
// import { trackLoginEvent } from "../../../utils/ga_events";
// import { PROTOCOL, WWWHost } from "../../../api";

interface Props {
  onLoginSwitched?: () => void;
}

const Register: React.FC<Props> = (props: Props) => {
  const [form] = Form.useForm();
  const { onRegisterSubmit, updateCaptcha, onSendCode, time } = useContext(
    authStore
  );
  const captchaRef = useRef(null);

  // const handleLogin = () => {
  //   let url = "";
  //   const mdetect = require("mdetect");
  //   trackLoginEvent("Wechat");
  //   if (mdetect.isWechat()) {
  //     url = `${window.location.origin}/auth/wechatservice`;
  //   } else {
  //     url = `https://open.weixin.qq.com/connect/qrconnect?appid=${window.appid}&scope=snsapi_login&redirect_uri=${PROTOCOL}//${WWWHost}/auth/wechat/callback&state=&login_type=jssdk&self_redirect=default`;
  //   }
  //   window.location.href = url;
  // };

  return (
    <div className="pro-register">
      <Form form={form} onFinish={values => onRegisterSubmit(values)}>
        <h2>欢迎加入</h2>
        <h2 className="pro-register__header">注册账号继续使用</h2>
        <Form.Item
          hasFeedback
          rules={[
            { required: true, message: "请输入正确的手机号码" },
            {
              pattern: /^1[3456789]\d{9}$/,
              message: "请输入正确的手机号码",
            },
          ]}
          name="mobile"
        >
          <Input placeholder="手机号码" />
        </Form.Item>
        <div className="pro-register__code">
          <Form.Item
            hasFeedback
            rules={[
              { required: true, message: "请输入正确的验证码" },
              { min: 5, message: "验证码是 5 位" },
            ]}
            name="_rucaptcha"
          >
            <Input autoComplete="off" placeholder="图形验证码" />
          </Form.Item>
          <img
            className="pro-register__captcha"
            ref={captchaRef}
            src="/rucaptcha/"
            alt="图形验证码"
            onClick={e => updateCaptcha(captchaRef.current, e)}
          />
        </div>
        <div className="pro-register__code">
          <Form.Item
            hasFeedback
            rules={[{ required: true, message: "请输入正确的短信验证码" }]}
            name="phone_verify_code"
          >
            <Input autoComplete="off" placeholder="验证码" />
          </Form.Item>
          <div
            className={`send-code ${time > 0 && "send-code--time"}`}
            onClick={() => {
              if (time <= 0) {
                const values: any = form.getFieldsValue();
                const { mobile, _rucaptcha } = values;
                onSendCode({ mobile, _rucaptcha });
              }
            }}
          >
            {time > 0 ? `${time}s 后重新发送` : "发送验证码"}
          </div>
        </div>
        <Form.Item
          hasFeedback
          rules={[{ required: true, message: "请输入昵称" }]}
          name="name"
        >
          <Input placeholder="昵称" autoComplete="off" />
        </Form.Item>
        <Form.Item
          hasFeedback
          rules={[
            { required: true, message: "请输入正确的密码" },
            { min: 6, message: "密码最少是 6 位" },
          ]}
          name="password"
        >
          <Input.Password placeholder="密码" autoComplete="off" />
        </Form.Item>
        <Form.Item className="pro-register__submit">
          <Button isFullWidth size="large" htmlType="submit">
            注册
          </Button>
        </Form.Item>
      </Form>
      <span className="pro-register__info">
        已有账号，立刻
        {props.onLoginSwitched ? (
          <a onClick={props.onLoginSwitched}> 登录</a>
        ) : (
          <Link to="/login"> 登录</Link>
        )}
      </span>
      {/* <div className="pro-auth__social-media">
        <div className="pro-auth--divider" />
        <div>
          <a onClick={handleLogin}>
            <img src={wechatImage} alt="wechat login" />
          </a>
          <a
            href="/auth/weibo"
            target="_blank"
            rel="noopener"
            onClick={() => trackLoginEvent("Weibo")}
          >
            <img src={weiboImage} alt="weibo login" />
          </a>
          <a
            href="/auth/github"
            target="_blank"
            rel="noopener"
            onClick={() => trackLoginEvent("Github")}
          >
            <img src={githubImage} alt="github login" />
          </a>
        </div>
      </div> */}
    </div>
  );
};

export default observer(Register);
