import React from "react";

const BusinessCase = ({ data }) => {
  return (
    data && (
      <>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">案例名称:</div>
          <div className="selection-rating-modules-form--value">
            {data.name}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">
            案例涉及智能领域:
          </div>
          <div className="selection-rating-modules-form--value">
            {data.aiFields.join("，")}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">
            案例涉及智能技术:
          </div>
          <div className="selection-rating-modules-form--value">
            {data.techFields.join("，")}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">
            案例使用核心技术:
          </div>
          <div className="selection-rating-modules-form--value">
            {data.coreTech.length > 0 ? (
              data.coreTech.join("，")
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">案例简述:</div>
          <div className="selection-rating-modules-form--value">
            {data.summary}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">
            案例背景与痛点描述:
          </div>
          <div className="selection-rating-modules-form--value">
            {data.background}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">
            案例落地过程详解:
          </div>
          <div className="selection-rating-modules-form--value">
            <div
              className="selection-rating-modules-form-text--rich"
              dangerouslySetInnerHTML={{ __html: data.desc || "" }}
            />
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">
            案例公布时间:
          </div>
          <div className="selection-rating-modules-form--value">
            {data.publishAt}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">
            技术提供方:
          </div>
          <div className="selection-rating-modules-form--value">
            {data.provider}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">
            技术采用方:
          </div>
          <div className="selection-rating-modules-form--value">
            {data.adopter}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">
            案例适用行业:
          </div>
          <div className="selection-rating-modules-form--value">
            {data.industries.join("，")}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">
            案例适用业务场景:
          </div>
          <div className="selection-rating-modules-form--value">
            {data.scenarios.length > 0 ? (
              data.scenarios.join("，")
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">
            案例涉及的技术应用:
          </div>
          <div className="selection-rating-modules-form--value">
            {data.applyTasks ? (
              data.applyTasks
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">
            案例涉及设施:
          </div>
          <div className="selection-rating-modules-form--value">
            {data.solution}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">
            案例相关介绍链接:
          </div>
          <div className="selection-rating-modules-form--value">
            {data.website ? (
              data.website
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">案例成就:</div>
          <div className="selection-rating-modules-form--value">
            {data.achievement ? (
              data.achievement
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
      </>
    )
  );
};

export default BusinessCase;
