import React from "react";

const SVG = ({
  fill = "#0062FF",
  width = "100%",
  className = "",
  viewBox = "0 0 16 16",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      fillRule="evenodd"
      d="M15.2 3.2c.442 0 .8.36.8.8v11.2c0 .442-.358.8-.8.8H.8c-.442 0-.8-.358-.8-.8V4c0-.44.358-.8.8-.8h1.344l.88-2.654c.109-.324.411-.542.752-.545h8.408c.358-.018.686.204.8.545l.872 2.655H15.2zM1.6 4.8l2.164 4.8h8.505L14.4 4.8H1.6zm2.899-3.2L4 3.2h8l-.506-1.6H4.499zM1.6 14.4h12.8V8l-1 2.57c-.118.33-.418.553-.76.568H3.464c-.355 0-.674-.225-.8-.567L1.6 8.026V14.4zM8 7.2c.442 0 .8.359.8.8 0 .443-.358.8-.8.8-.442 0-.8-.357-.8-.8 0-.441.358-.8.8-.8z"
    />
  </svg>
);

export default SVG;
