import React, { useContext } from "react";
import { observer } from "mobx-react";

import "./style.scss";
import { Form, Input } from "antd";
import { Button } from "../shared";
import ForgetPasswordStore from "../../stores/forgetPasswordStore";
import arrowLeftImg from "../../styles/images/arrow-left-circle.png";

const ResetPassword: React.FC = () => {
  const { finish, last, isComplete, setIsComplete } = useContext(
    ForgetPasswordStore
  );

  const [form] = Form.useForm();

  const onFieldsChange = () => {
    const values: any = form.getFieldsValue();
    const { password1, password2 } = values;
    setIsComplete(
      !!password1 &&
        !!password2 &&
        form.getFieldsError().filter(({ errors }) => errors.length).length === 0
    );
  };

  return (
    <Form
      form={form}
      className="pro-forget-password-reset"
      onFieldsChange={onFieldsChange}
      onFinish={values => isComplete && finish(values)}
    >
      <div className="pro-forget-password-title--wrapper">
        <div className="pro-forget-password-title">
          <img src={arrowLeftImg} alt="arrow" onClick={last} />
          <p>输入新密码</p>
        </div>
      </div>
      <Form.Item
        hasFeedback
        rules={[
          { required: true, message: "密码不能为空" },
          { min: 6, message: "密码最少是 6 位" },
        ]}
        name="password1"
      >
        <Input.Password autoComplete="off" placeholder="输入新密码" />
      </Form.Item>
      <Form.Item
        hasFeedback
        dependencies={["password1"]}
        rules={[
          { required: true, message: "密码不能为空" },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue("password1") === value) {
                return Promise.resolve();
              }

              return Promise.reject("两次输入密码不一致");
            },
          }),
        ]}
        name="password2"
      >
        <Input.Password autoComplete="off" placeholder="确认密码" />
      </Form.Item>
      <Form.Item
        className={`pro-forget-password__send ${!isComplete &&
          "pro-forget-password__send--opacity"}`}
      >
        <Button isFullWidth size="large" htmlType="submit">
          完成
        </Button>
      </Form.Item>
    </Form>
  );
};

export default observer(ResetPassword);
