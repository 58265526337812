import React from "react";

const SVG = ({
  fill = "#92929D",
  width = "100%",
  className = "",
  viewBox = "0 0 16 16",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      fillRule="evenodd"
      d="M6.869 10.4H1.6c-.442 0-.8-.358-.8-.8 0-.442.358-.8.8-.8h5.269L5.834 7.766c-.312-.313-.312-.819 0-1.132.313-.312.819-.312 1.132 0l2.4 2.4c.312.313.312.819 0 1.132l-2.4 2.4c-.156.156-.361.234-.566.234-.205 0-.41-.078-.566-.234-.312-.313-.312-.819 0-1.132L6.87 10.4zM14.4 16H1.6c-.442 0-.8-.358-.8-.8v-1.6c0-.442.358-.8.8-.8.442 0 .8.358.8.8v.8h11.2V4.494L8 1.694l-5.6 2.8V5.6c0 .442-.358.8-.8.8-.442 0-.8-.358-.8-.8V4c0-.303.171-.58.442-.716l6.4-3.2c.225-.112.49-.112.716 0l6.4 3.2c.27.136.442.413.442.716v11.2c0 .442-.358.8-.8.8z"
    />
  </svg>
);

export default SVG;
