import React, { useEffect, useContext, useState } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";

import "./dialog.scss";
import { message } from "antd";
import { Dialog } from "../shared";
import agreeIcon from "../../styles/images/inbox-agree.png";
import disagreeIcon from "../../styles/images/inbox-disagree.png";
import tipsIcon from "../../styles/images/inbox-tips.png";
import memberInboxStore from "../../stores/memberInboxStore";
import { wxJsPay, debugFn, isWxLogin } from "../../utils/wx_tools";
import AuthStore from "../../stores/authStore";
import { fetchMemberInfoApi, unlockMemberApi } from "../../api/index";
import { buyInboxMemberEvent } from "../../utils/ga_events";
import checkOrigin from "./checkOrigin";
import { onAllImgLoaded } from "../../utils/tools";

interface Props {
  onSuccess?(): any;
}

// 非新用户购买弹窗
const CostDialog: React.FC<Props> = (props: Props) => {
  const { onSuccess = () => {} } = props;
  const { id } = useParams();
  const { fetchMemberInfo, isCurrentUserEmpty, memberInfo } = useContext(
    AuthStore
  );
  const {
    dialog,
    setDialog,
    xy,
    xyText,
    wxLogin,
    xfOyId,
    oyId,
    discount,
    setPurchase,
  } = useContext(memberInboxStore);
  const onCancel = () => {
    window.localStorage.removeItem("gangLogin");
    setDialog("cost", false);
  };
  const [info, setInfo] = useState<any>({});
  const [show, setShow] = useState<any>(false);
  const [agree, setAgree] = useState<any>(true);
  const purchase_source = checkOrigin();

  const fetchInfo = () => {
    // 折扣discount/全价购买
    return Promise.all([
      fetchMemberInfoApi(discount ? xfOyId : oyId).then((res: any) => {
        setInfo(res);
      }),
    ]);
  };

  useEffect(() => {
    setShow(false);
    if (dialog.cost) {
      fetchInfo().then(() => {
        onAllImgLoaded(document.querySelector(".cost-dialog")).then(() => {
          setShow(true);
        });
      });
    }
  }, [dialog.cost]);

  return (
    <Dialog
      style={{ padding: "0", borderRadius: "10px" }}
      visible={dialog.cost}
      onCancel={onCancel}
      layerClose={false}
      closeStyle={{ color: "rgba(55,65,81,0.2)", top: "20px", right: "12px" }}
      needAuthCb={
        memberInfo && isWxLogin()
          ? null
          : () => {
              wxLogin();
            }
      }
    >
      <div className={`cost-dialog ${show && "cost-dialog--show"}`}>
        <div className="cost-dialog__header">
          <img src={info.show_image_url} alt="" />
        </div>
        {info.price && (
          <div
            className="cost-dialog__btn"
            onClick={() => {
              if (isCurrentUserEmpty && !memberInfo) {
                onCancel();
                wxLogin();
              } else {
                if (agree) {
                  onCancel();
                  wxJsPay(discount ? xfOyId : oyId)
                    .then(async (data: any) => {
                      if (id) {
                        await unlockMemberApi({
                          id,
                        });
                      }
                      // 标记是刚购买过的会员
                      setPurchase("cost");
                      fetchMemberInfo();
                      onSuccess();
                      setDialog("pay-success", true);
                      buyInboxMemberEvent({
                        transaction_id: data.shortId,
                        value: data.final_price / 100,
                        item_id: data.proProductId,
                        item_name: data.proProductName,
                        price: data.final_price / 100,
                        purchase_source,
                        user_id: memberInfo.id,
                        user: memberInfo.username,
                      });
                    })
                    .catch((err: any) => {
                      setDialog("pay-error", true);
                      debugFn(err);
                    });
                } else {
                  message.open({
                    type: "error",
                    duration: 3,
                    className: "ant-toast",
                    content: xyText,
                  });
                }
              }
            }}
          >
            立即订阅
          </div>
        )}
        <div
          className="cost-tips"
          style={info.desc ? {} : { marginBottom: "20px" }}
        >
          <img
            src={agree ? agreeIcon : disagreeIcon}
            alt="agreeIcon"
            onClick={() => setAgree(!agree)}
          />
          <div>
            我已阅读并同意{" "}
            <a href={xy} target="_blank">
              《用户服务协议》
            </a>
          </div>
        </div>
        {info.desc && (
          <div className="cost-intro">
            <div className="cost-intro__title">
              <img src={tipsIcon} alt="agreeIcon" />
              权益详细说明
            </div>
            <div className="cost-intro__desc">
              {info.desc.split("\r\n").map((p: string, index: number) => (
                <p key={index}>{p}</p>
              ))}
            </div>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default observer(CostDialog);
