import React, { useContext } from "react";
import { observer } from "mobx-react";

import InstitutionIntro from "./InstitutionIntro";
import { Menu } from "../shared";
import AssetsEventIntro from "./AssetsEventIntro";
import ProductSolutionIntro from "./ProductSolutionIntro";
import ClientIntro from "./ClientIntro";
import CoreMemberIntro from "./CoreMemberIntro";
import CooperationInstitution from "./CooperationInstitution";
import DatabseDetailStore from "../../stores/databaseDetailStore";

const InstitutionTabs = () => {
  const {
    data,
    scrolledDomId,
    scrollTo,
    institutionTabs,
    isClientsTabContentEmpty,
    isCoreMemberTabContentEmpty,
    isCooperationInstitutionTabContentEmpty,
    isAssetsEventTabContentEmpty,
  } = useContext(DatabseDetailStore);

  return (
    <div>
      <Menu
        items={institutionTabs}
        selectedKey={scrolledDomId}
        onClick={item => scrollTo(item.key)}
      />
      <InstitutionIntro />
      <ProductSolutionIntro />
      {!isClientsTabContentEmpty && <ClientIntro {...data.tabs} />}
      {!isCoreMemberTabContentEmpty && <CoreMemberIntro {...data.tabs} />}
      {!isCooperationInstitutionTabContentEmpty && (
        <CooperationInstitution {...data.tabs} />
      )}
      {!isAssetsEventTabContentEmpty && <AssetsEventIntro />}
    </div>
  );
};

export default observer(InstitutionTabs);
