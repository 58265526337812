import React, { useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import { Button, Icon, Progress } from "../shared";
import { Select } from "antd";
import SelectionRatingDetailStore from "../../stores/selectionRatingDetailStore";

const SelectionRatingHeader: React.FC = () => {
  const history = useHistory();
  const rankId = new URLSearchParams(useLocation().search).get("rankId");

  const {
    ratingInfo: { header },
    isFormFinished,
    isRankRated,
  } = useContext(SelectionRatingDetailStore);

  return (
    header && (
      <div className="selection-rating-header">
        <div className="selection-rating-header__layout">
          <div className="selection-rating-header__info">
            <Link
              to={`/awards?id=${header.selection.id}`}
              className="selection-rating-header__back"
            >
              <Icon name="arrow-left-with-circle" size={20} />
            </Link>
            <p className="selection-rating-header__selction">
              {header.selection.name}
            </p>
            <p className="selection-rating-header__rank">
              {header.ranking.name}
            </p>
            <p className="selection-rating-header__institution">
              {header.institution.name}
            </p>
          </div>
          <div className="selection-rating-header__action">
            <div className="selection-rating-header__review-scored-report">
              <span>查看已评分的申报</span>
              <Select
                style={{ width: 200 }}
                placeholder="选择要查看的申报"
                onSelect={id => {
                  history.push(`/selection-rating/${id}?rankId=${rankId}`);
                }}
                options={header.ratedForms.map(form => ({
                  label: form.name,
                  value: form.id,
                }))}
              />
            </div>
            <div className="selection-rating-header__progress">
              <Progress
                numerator={header.ratedFormsCount}
                denominator={header.ratedFormsTotalCount}
              />
            </div>
            {!isRankRated && (
              <Link
                className="selection-rating-header__next-report"
                to={`/selection-rating/${header.nextNotRateFormId}?rankId=${header.ranking.id}`}
              >
                <Button
                  grid={6}
                  size="small"
                  type={isFormFinished ? "primary" : "transparent"}
                >
                  下一条
                </Button>
              </Link>
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default observer(SelectionRatingHeader);
