import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react";
import { Helmet } from "react-helmet";
import { useQuery } from "../../utils/useQuery";

import "./style.scss";
import { Sidebar } from "../shared";
import AwardItem from "./AwardItem";
import selectionRatingStore from "../../stores/selectionRatingStore";
import selectionBanner from "../../styles/images/selection-banner.jpeg";

const AwardList: React.FC = () => {
  const { awardData, awardList, isAllComplete, getAwardList } = useContext(
    selectionRatingStore
  );
  const id = useQuery().get("id");

  useEffect(() => {
    getAwardList(id);
  }, []);

  return (
    <div className="pro-layout-selections">
      <Helmet>
        <title>奖项评分</title>
      </Helmet>
      <div className="pro-layout-sidebar">
        <Sidebar />
      </div>
      <div className="pro-layout-wrapper">
        <div className="pro-layout-content">
          <h2>奖项评分</h2>
          <div className="pro-award-content">
            <img src={selectionBanner} alt="banner" />
            <div className="pro-award-list">
              <h3>{awardData.name}</h3>
              <p>{awardData.description}</p>
              {isAllComplete && (
                <a href="javascript:">
                  <img src={awardData.thanksImageUrl} alt="advertisement" />
                </a>
              )}
              {awardList.map((item, index) => (
                <AwardItem key={index} data={item} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(AwardList);
