import React from "react";
import { observer } from "mobx-react";

import "./style.scss";

interface Props {
  numerator: number;
  denominator: number;
}

const Progress: React.FC<Props> = (props: Props) => {
  const { numerator, denominator } = props;
  return (
    <div className="pro-progress">
      <p className="pro-progress-field">评选进度</p>
      <div className="pro-progress-pipe">
        <div
          className="pro-progress-line"
          style={{ width: (numerator / denominator) * 86 }}
        />
      </div>
      <p className="pro-progress-numerator">{numerator}</p>
      <p className="pro-progress-denominator">/{denominator}</p>
    </div>
  );
};

Progress.defaultProps = {
  numerator: 0,
  denominator: 100,
};

export default observer(Progress);
