import React from "react";

const SVG = ({
  fill = "#92929D",
  width = "100%",
  className = "",
  viewBox = "0 0 20 20",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>icon 4</title>
    <desc>Created with Sketch.</desc>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Component/icon/ic_communication" fill={fill}>
        <g
          id="Polaris-icon/Major/2-Tone/Chat"
          transform="translate(1.666667, 1.666667)"
        >
          <path
            d="M8.33333333,0 C3.73833333,0 0,3.73833333 0,8.33333333 C0,9.77333333 0.375,11.1833333 1.08666667,12.4366667 L0.0425,15.57 C-0.0566666667,15.8691667 0.0208333333,16.1991667 0.244166667,16.4225 C0.4025,16.5808333 0.615833333,16.6666667 0.833333333,16.6666667 C0.921666667,16.6666667 1.01,16.6525 1.09666667,16.6233333 L4.23,15.5791667 C5.48416667,16.2925 6.89416667,16.6666667 8.33333333,16.6666667 C12.9283333,16.6666667 16.6666667,12.9283333 16.6666667,8.33333333 C16.6666667,3.73833333 12.9283333,0 8.33333333,0 M8.33333333,15 C7.07666667,15 5.84583333,14.6416667 4.77583333,13.9641667 C4.64083333,13.8783333 4.48583333,13.835 4.33,13.835 C4.24083333,13.835 4.1525,13.8483333 4.06583333,13.8775 L2.15083333,14.5158333 L2.78916667,12.6 C2.86833333,12.3625 2.83666667,12.1016667 2.7025,11.8908333 C2.025,10.82 1.66666667,9.59083333 1.66666667,8.33333333 C1.66666667,4.6575 4.6575,1.66666667 8.33333333,1.66666667 C12.0091667,1.66666667 15,4.6575 15,8.33333333 C15,12.0091667 12.0091667,15 8.33333333,15 M8.33333333,7.5 C7.87333333,7.5 7.5,7.8725 7.5,8.33333333 C7.5,8.79416667 7.87333333,9.16666667 8.33333333,9.16666667 C8.79333333,9.16666667 9.16666667,8.79416667 9.16666667,8.33333333 C9.16666667,7.8725 8.79333333,7.5 8.33333333,7.5 M5,7.5 C4.54,7.5 4.16666667,7.8725 4.16666667,8.33333333 C4.16666667,8.79416667 4.54,9.16666667 5,9.16666667 C5.46,9.16666667 5.83333333,8.79416667 5.83333333,8.33333333 C5.83333333,7.8725 5.46,7.5 5,7.5 M11.6666667,7.5 C11.2066667,7.5 10.8333333,7.8725 10.8333333,8.33333333 C10.8333333,8.79416667 11.2066667,9.16666667 11.6666667,9.16666667 C12.1266667,9.16666667 12.5,8.79416667 12.5,8.33333333 C12.5,7.8725 12.1266667,7.5 11.6666667,7.5"
            id="Mask"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
