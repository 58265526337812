import React from "react";

const SVG = ({
  fill = "#0062FF",
  width = "100%",
  className = "",
  viewBox = "0 0 16 16",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd" transform="translate(-240 -1393)">
      <path fill="#F4F4F6" d="M0 0H1440V2012H0z" />
      <rect width="793" height="1574" x="220" y="439" fill="#FFF" rx="4" />
      <path
        fill={fill}
        d="M255 1407.4c.552 0 1 .358 1 .8 0 .442-.448.8-1 .8h-14c-.552 0-1-.358-1-.8 0-.442.448-.8 1-.8zm-8.68-14c.442 0 .8.358.8.8v11.2c0 .442-.358.8-.8.8-.442 0-.8-.358-.8-.8v-11.2c0-.442.358-.8.8-.8zm-3.2 7.2c.442 0 .8.358.8.8v4c0 .442-.358.8-.8.8-.442 0-.8-.358-.8-.8v-4c0-.442.358-.8.8-.8zm9.6-.8c.442 0 .8.358.8.8v4.8c0 .442-.358.8-.8.8-.442 0-.8-.358-.8-.8v-4.8c0-.442.358-.8.8-.8zm-3.2-3.2c.442 0 .8.358.8.8v8c0 .442-.358.8-.8.8-.442 0-.8-.358-.8-.8v-8c0-.442.358-.8.8-.8z"
      />
    </g>
  </svg>
);

export default SVG;
