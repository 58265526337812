import React from "react";

const SVG = ({
  fill = "#C6CBD4",
  width = "100%",
  className = "",
  viewBox = "0 0 20 20",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd" transform="translate(-260 -1314)">
      <path fill="#F4F4F6" d="M0 0H1440V2012H0z" />
      <rect width="793" height="1574" x="220" y="439" fill="#FFF" rx="4" />
      <g>
        <path
          fill={fill}
          d="M19.621 10.146c0-5.235-4.244-9.478-9.478-9.478S.665 4.911.665 10.146c0 5.235 4.243 9.478 9.478 9.478 5.234 0 9.478-4.243 9.478-9.478zm-17.771 0c0-4.58 3.713-8.293 8.293-8.293 4.58 0 8.293 3.713 8.293 8.293 0 4.58-3.713 8.293-8.293 8.293-4.58 0-8.293-3.713-8.293-8.293zm10.98.52c.142-.142.258-.336.226-.52.032-.184-.084-.378-.226-.52L8.294 5.09c-.231-.231-.606-.231-.838 0-.231.231-.231.606 0 .838l4.219 4.218-4.219 4.218c-.231.232-.231.607 0 .838.232.231.607.231.838 0l4.536-4.536z"
          transform="matrix(-1 0 0 1 280 1314)"
        />
      </g>
    </g>
  </svg>
);

export default SVG;
