import moment from "moment";
import "moment/locale/zh-cn";

const formatLatestInsight = initData => {
  return initData.map(data => ({
    id: data.id,
    name: data.title,
    avatarUrl: data.coverImageUrl,
    linkUrl: data.linkUrl,
    type: data.category,
    time: moment(Number(data.publishedAt) * 1000).fromNow(),
  }));
};

const formatInsight = initData => {
  return initData.map(data => ({
    id: data.id,
    name: data.title,
    desc: data.summary,
    avatarUrl: data.coverImageUrl,
    linkUrl: data.linkUrl,
    type: data.category,
    time: moment(Number(data.publishedAt) * 1000).fromNow(),
  }));
};

const fortmatInsightCategories = initData => {
  return [{ name: "全部", active: true }].concat(
    initData.map(ele => {
      return { name: ele.name, active: false, value: ele.value };
    })
  );
};

export { formatLatestInsight, fortmatInsightCategories, formatInsight };
