import React, { useContext, useState } from "react";
import { IconHeading, AvatarCard, Tag, AuthWallModal } from "../shared";
import databaseDetailStore from "../../stores/databaseDetailStore";
import { observer } from "mobx-react";
import { CORE_INSTITUTION } from "../../constants/pro_constants";
import authStore from "../../stores/authStore";

const CoreInstitutionIntro = () => {
  const {
    data: {
      tabs: { coreInstitution },
    },
    type,
  } = useContext(databaseDetailStore);
  const { isTrialUser } = useContext(authStore);
  const [isShowAuthWallModal, setIsShowAuthWallModal] = useState(false);

  const isShowModal = isTrialUser && isShowAuthWallModal;

  return (
    <>
      <div className="database-detail__block" id={CORE_INSTITUTION}>
        <IconHeading icon="pro-cooperation-institution" title="核心机构" />
        <div className="database-detail__block__content">
          {coreInstitution.map((item, i) => (
            <div key={i} className="client__avatar-card__item">
              <AvatarCard
                theme="company"
                data={item}
                onClick={() => {
                  if (isTrialUser) {
                    setIsShowAuthWallModal(true);
                  } else {
                    window.open(`/database/institutions/${item.id}`);
                  }
                }}
              >
                <div className="avatar-card__tags">
                  {item.fields.map((ele, i) => (
                    <Tag key={i}>{ele}</Tag>
                  ))}
                </div>
              </AvatarCard>
            </div>
          ))}
        </div>
        {isShowModal && (
          <AuthWallModal
            visible={isShowAuthWallModal}
            title="无法查看当前数据"
            description={` 您当前的用户类型为"试用用户"，仅支持查看基本信息。点击"成为会员"，获得全部数据访问权限，解锁更多高级功能。`}
            label={type}
            onCancel={() => {
              setIsShowAuthWallModal(false);
            }}
          />
        )}
      </div>
      <div className="database-detail__intro--bottom" />
    </>
  );
};

export default observer(CoreInstitutionIntro);
