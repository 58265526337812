import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";

import "./style.scss";
import { Header, Footer, Button } from "../shared";
import { Form, Input, Select } from "antd";
import IdentificationStore from "../../stores/identificationStore";
import { CHINA } from "../../constants/pro_constants";
import { labelToChinese } from "../../constants/label_to_chinese";
import { WorkSeniority, Degrees } from "../../constants/identification";

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

const tailLayout = {
  wrapperCol: { offset: 4, span: 20 },
};

const CareerInformation: React.FC = () => {
  const history = useHistory();
  const {
    position,
    major,
    degree,
    company,
    graduateSchool,
    graduationYear,
    workExperience,
    regions,
    selectedCountry,
    userIdentity,
    setSelectedCountry,
    updateCareerInormation,
    initializeCareerInformation,
  } = useContext(IdentificationStore);
  const isWorking = userIdentity === "working";

  const handleFormFinish = values => {
    updateCareerInormation(values);
    history.push("/identification/step3");
  };
  const [form] = Form.useForm();

  useEffect(() => {
    initializeCareerInformation();
  }, []);

  return (
    <div className="pro-identification-layout header-footer--verticality-layout">
      <Header />
      <div className="pro-career-information__content">
        <h3>{isWorking ? "在职状态" : "在读状态"}</h3>
        <span>填写你在职城市以及职业描述</span>
        <Form
          form={form}
          className="pro-career-information__form"
          onFinish={values => handleFormFinish(values)}
          {...layout}
          onFieldsChange={changedFields => {
            if (changedFields[0] && changedFields[0].name[0] === "country") {
              form.setFieldsValue({
                city: undefined,
              });
            }
          }}
        >
          <Form.Item label="所在地">
            <Form.Item
              style={{
                width: "calc(50% - 10px)",
                display: "inline-block",
                margin: "0 10px 0 0",
              }}
              rules={[{ required: true, message: "选择" }]}
              name="country"
              initialValue={labelToChinese[CHINA]}
            >
              <Select
                placeholder="请选择"
                onChange={value => {
                  setSelectedCountry(value);
                }}
              >
                {Object.keys(regions).map((country, i) => (
                  <Select.Option value={country} key={i}>
                    {labelToChinese[country]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {selectedCountry === CHINA ? (
              <Form.Item
                style={{
                  width: "calc(50% - 10px)",
                  display: "inline-block",
                  margin: "0 0 0 10px",
                }}
                rules={[{ required: true, message: "选择" }]}
                name="city"
              >
                <Select placeholder="请选择城市">
                  {regions[selectedCountry].map(city => (
                    <Select.Option value={city.value} key={city.value}>
                      {city.value}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            ) : (
              <Form.Item
                style={{
                  width: "calc(50% - 10px)",
                  display: "inline-block",
                  margin: "0 0 0 10px",
                }}
                rules={[{ required: true, message: "请填写" }]}
                name="city"
              >
                <Input autoComplete="off" placeholder="请输入海外城市" />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item
            hasFeedback
            rules={[{ required: true, message: "请输入毕业学校" }]}
            label="毕业学校"
            name="graduateSchool"
            initialValue={graduateSchool}
          >
            <Input autoComplete="off" placeholder="请输入毕业学校" />
          </Form.Item>
          <Form.Item
            hasFeedback
            rules={[{ required: true, message: "请输入专业" }]}
            label="专业"
            name="major"
            initialValue={major}
          >
            <Input autoComplete="off" placeholder="请输入专业" />
          </Form.Item>
          <Form.Item
            hasFeedback
            rules={[{ required: true, message: "请选择学位" }]}
            label="学位"
            name="degree"
            initialValue={degree}
          >
            <Select placeholder="请选择">
              {Degrees.map((item, i) => (
                <Select.Option value={item.value} key={i}>
                  {item.value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            hasFeedback
            rules={[{ required: true, message: "请输入毕业年份" }]}
            label="毕业年份"
            name="graduationYear"
            initialValue={graduationYear}
          >
            <Input autoComplete="off" placeholder="请输入毕业年份" />
          </Form.Item>
          {isWorking && (
            <>
              <Form.Item
                hasFeedback
                rules={[{ required: true, message: "请输入公司" }]}
                label="公司"
                name="company"
                initialValue={company}
              >
                <Input placeholder="请输入公司全称" />
              </Form.Item>
              <Form.Item
                hasFeedback
                rules={[{ required: true, message: "请输入职务" }]}
                label="职务"
                name="position"
                initialValue={position}
              >
                <Input autoComplete="off" placeholder="请输入公司职务" />
              </Form.Item>
              <Form.Item
                label="工作年限"
                name="workExperience"
                initialValue={workExperience}
              >
                <Select placeholder="请选择">
                  {WorkSeniority.map((item, i) => (
                    <Select.Option value={item.value} key={i}>
                      {item.value}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          )}
          <Form.Item {...tailLayout}>
            <Button size="large" grid={14} htmlType="submit">
              下一步
            </Button>

            <Button
              type="transparent"
              size="large"
              grid={14}
              onClick={() => {
                history.push("/identification/step1");
              }}
            >
              上一步
            </Button>
          </Form.Item>
        </Form>
      </div>
      <Footer />
    </div>
  );
};

export default observer(CareerInformation);
