import React, {
  useContext,
  useState,
  useLayoutEffect,
  useRef,
  useEffect,
} from "react";
import { observer } from "mobx-react";
import { Input, Tooltip, DatePicker, Skeleton } from "antd";

import { Icon, Collapses } from "../shared";
import databaseStore from "../../stores/databaseStore";
import KeywordResultPanel from "./KeywordResultPanel";
import DatabaseStore from "../../stores/databaseStore";
import authStore from "../../stores/authStore";
import { labelToChinese } from "../../constants/label_to_chinese";
import {
  TECHNOLOGIES,
  DATASET,
  INSTITUTION,
  SOLUTION,
} from "../../constants/pro_constants";

interface Props {
  type: string;
}

const FilterSearchPanel: React.FC<Props> = ({ type }: Props) => {
  const [placeholder, setPlaceholder] = useState("");
  const {
    keyword,
    filterKeys,
    showedFiltersKeys,
    hidedFiltersKeys,
    isShowAllFilters,
    hasExpandBtn,
    fetchKeywordResultData,
    setIsShowAllFilters,
    initializeIsShowAllFilters,
    showSearchPanel,
    setKeyword,
    handleKeyDownEvent,
  } = useContext(DatabaseStore);
  const hasFilterKeys = filterKeys.length > 0;

  useEffect(() => {
    initializeIsShowAllFilters();
    if (type === INSTITUTION) {
      setPlaceholder("请输入关键词，添加检索条件筛选相关机构，如“智能企服”");
    }
    if (type === SOLUTION) {
      setPlaceholder("请输入关键词，添加检索条件筛选相关方案，如“智能零售”");
    }
    if (type === DATASET) {
      setPlaceholder("请输入关键词，添加检索条件筛选相关数据包");
    }
  }, [type]);

  return (
    <>
      <div className="databse-filter-panel__input">
        <Input
          placeholder={placeholder}
          type="text"
          value={keyword}
          onChange={event => {
            setKeyword(event.target.value);
            if (type != DATASET) {
              fetchKeywordResultData();
              showSearchPanel();
            }
          }}
          onPressEnter={event => {
            handleKeyDownEvent(event.target.value);
          }}
          prefix={<Icon name="pro-search" size={18} />}
        />
        <KeywordResultPanel />
      </div>
      <div className="pro-divider" />
      <div className="database-filter-panel">
        {hasFilterKeys ? (
          <>
            {showedFiltersKeys.map(key => {
              return <Category key={`${type}${key}`} field={key} />;
            })}
            {isShowAllFilters &&
              hasExpandBtn &&
              hidedFiltersKeys.map(key => {
                return <Category key={`${type}${key}`} field={key} />;
              })}
          </>
        ) : (
          <Skeleton
            active
            paragraph={{ rows: 3, width: ["25%", "50%", "40%"] }}
          />
        )}
        {hasExpandBtn && (
          <>
            <div className="pro-divider" />
            <div className="database-filter-panel__collapses">
              <Collapses
                openedText="收起"
                closedText="展开"
                isOpen={isShowAllFilters}
                onChange={() => setIsShowAllFilters()}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

const Category = observer(({ field }) => {
  const {
    filters,
    toggleFiltersActive,
    selectedConditionItems,
    isCustomizeDate,
    setIsEnableSelectedInFilters,
    setFiltersInactive,
    fetchDatabaseList,
    setTimeZone,
  } = useContext(databaseStore);
  const { isTrialUser } = useContext(authStore);

  const [isShowCollapse, setIsShowCollapse] = useState(false);
  const [isShowAll, setIsShowAll] = useState(false);
  const { RangePicker } = DatePicker;
  const styles =
    isShowCollapse && !isShowAll
      ? { overflow: "hidden", maxHeight: "35px" }
      : {};
  const fieldRef = useRef(null);

  useLayoutEffect(() => {
    const categoryHeight = fieldRef.current.clientHeight;
    if (categoryHeight > 40) {
      setIsShowCollapse(true);
    }
  }, []);

  return (
    <div className="database-filter-panel__category-block">
      <div className="database-filter-category__name">
        <span
          className={`database-filter-category__name-item ${
            field === TECHNOLOGIES ? "highlight" : ""
          }`}
        >
          {labelToChinese[field]}
        </span>
      </div>
      <div
        className={`database-filter-category__items ${field}`}
        style={styles}
        ref={fieldRef}
      >
        {filters[field] &&
          filters[field].map((item, i) => {
            const activeClass = item.active ? "active" : "";
            return (
              <React.Fragment key={i}>
                <Tooltip title={item.tips} placement="bottom" key={i}>
                  <span
                    key={i}
                    className={`database-filter-category__text ${activeClass}`}
                    onClick={() => {
                      if (isTrialUser && selectedConditionItems.length === 3) {
                        setIsEnableSelectedInFilters(false);
                        setFiltersInactive(field, item.name);
                      } else {
                        setIsEnableSelectedInFilters(true);
                        toggleFiltersActive(field, item.name);
                      }
                    }}
                  >
                    {item.name}
                  </span>
                </Tooltip>
                {isCustomizeDate && item.name === "自定义" && (
                  <RangePicker
                    format="YYYY-MM-DD"
                    onChange={(time, timeString) => {
                      if (timeString[0] !== "") {
                        setTimeZone(timeString);
                        fetchDatabaseList();
                      }
                    }}
                  />
                )}
              </React.Fragment>
            );
          })}
      </div>
      {isShowCollapse && (
        <div className="database-filter-panel__category-collapses">
          <Collapses
            openedText="收起"
            closedText="展开"
            isOpen={false}
            onChange={() => setIsShowAll(!isShowAll)}
          />
        </div>
      )}
    </div>
  );
});

export default observer(FilterSearchPanel);
