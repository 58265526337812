/*
 * @Author: wh.q
 * @Date: 2020-12-04 14:10:41
 * @LastEditTime: 2020-12-09 14:12:38
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /Synced/frontend/sites/pro/stores/selectionRatingStore.ts
 */

import { observable, action, computed } from "mobx";
import { createContext } from "react";
import { fetchRankings, fetchAwardList } from "../api";

interface StoreProps {
  rankings: any;
  awardData: any;
  awardList: any;
  isAllComplete: boolean;
  getRankings(): void;
  getAwardList(id: string): void;
}

class SelectionRatingStore implements StoreProps {
  @observable public rankings = [];
  @observable public awardList = [];
  @observable public awardData = {};

  @action public getRankings = async () => {
    const result = await fetchRankings();
    this.rankings = result.data;
  };

  @action public getAwardList = async id => {
    const result = await fetchAwardList(id);
    this.awardData = result.ranking;
    this.awardList = result.data;
  };

  @computed
  get isAllComplete() {
    return (
      this.awardList.length > 0 &&
      this.awardList.every(item => item.finished === item.total)
    );
  }
}

export default createContext(new SelectionRatingStore());
