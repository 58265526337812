const Degrees = [
  { key: "学士", value: "学士" },
  { key: "硕士", value: "硕士" },
  { key: "博士", value: "博士" },
  { key: "其他", value: "其他" },
];

const WorkSeniority = [
  { key: "一年内", value: "一年内" },
  { key: "1-3年", value: "1-3年" },
  { key: "3-5年", value: "3-5年" },
  { key: "5年以上", value: "5年以上" },
];

const Cities = [
  {
    key: "北京",
    value: "北京",
  },
  {
    key: "天津",
    value: "天津",
  },
  {
    key: "河北",
    value: "河北",
  },
  {
    key: "山西",
    value: "山西",
  },
  {
    key: "内蒙古",
    value: "内蒙古",
  },
  {
    key: "辽宁",
    value: "辽宁",
  },
  {
    key: "吉林",
    value: "吉林",
  },
  {
    key: "黑龙江",
    value: "黑龙江",
  },
  {
    key: "上海",
    value: "上海",
  },
  {
    key: "江苏",
    value: "江苏",
  },
  {
    key: "浙江",
    value: "浙江",
  },
  {
    key: "安徽",
    value: "安徽",
  },
  {
    key: "福建",
    value: "福建",
  },
  {
    key: "江西",
    value: "江西",
  },
  {
    key: "山东",
    value: "山东",
  },
  {
    key: "河南",
    value: "河南",
  },
  {
    key: "湖北",
    value: "湖北",
  },
  {
    key: "湖南",
    value: "湖南",
  },
  {
    key: "广东",
    value: "广东",
  },
  {
    key: "广西",
    value: "广西",
  },
  {
    key: "海南",
    value: "海南",
  },
  {
    key: "重庆",
    value: "重庆",
  },
  {
    key: "四川",
    value: "四川",
  },
  {
    key: "贵州",
    value: "贵州",
  },
  {
    key: "云南",
    value: "云南",
  },
  {
    key: "西藏",
    value: "西藏",
  },
  {
    key: "陕西",
    value: "陕西",
  },
  {
    key: "甘肃",
    value: "甘肃",
  },
  {
    key: "青海",
    value: "青海",
  },
  {
    key: "宁夏",
    value: "宁夏",
  },
  {
    key: "新疆",
    value: "新疆",
  },
];

export { Cities, WorkSeniority, Degrees };
