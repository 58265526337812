import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { IconHeading, Tabs } from "../shared";
import databaseDetailStore from "../../stores/databaseDetailStore";
import { observer } from "mobx-react";
import { ASSETS_EVENT } from "../../constants/pro_constants";

const { Tab } = Tabs;

const AssetsEventIntro = () => {
  const { assetsData } = useContext(databaseDetailStore);

  return (
    <>
      {assetsData.length > 0 && (
        <>
          <div
            className="database-detail__block assets-event__intro"
            id={ASSETS_EVENT}
          >
            <IconHeading icon="pro-behavior" title="资本事件" />
            <div className="database-detail__block__content">
              <Tabs activeTab={0} hasDivider>
                <Tab label="全部">
                  {assetsData.map(item => {
                    return <AssetsCard key={item.date} {...item} />;
                  })}
                </Tab>
                <Tab label="融资">
                  {assetsData.map(item => {
                    if (item.type === "融资") {
                      return <AssetsCard key={item.date} {...item} />;
                    }
                  })}
                </Tab>
                <Tab label="投资">
                  {assetsData.map(item => {
                    if (item.type === "投资") {
                      return <AssetsCard key={item.date} {...item} />;
                    }
                  })}
                </Tab>
              </Tabs>
            </div>
          </div>
          <div className="database-detail__intro--bottom" />
        </>
      )}
    </>
  );
};

const AssetsCard = props => {
  return (
    <div className="assets-event__card">
      <div className="assets-event__title">
        <span className="assets-event__title-type title--bold">
          {props.type}
        </span>
        <span>{props.date}</span>
      </div>
      <div className="assets-event__timeline" />
      <div className="assets-event__detail">
        <span className="assets-event__detail-round title--bold">
          {props.round}
        </span>
        {props.type === "投资" ? (
          <InvestInfo {...props} />
        ) : (
          <FinancingInfo {...props} />
        )}
      </div>
    </div>
  );
};

const FinancingInfo = props => {
  return (
    <div className="assets-event__detail-title">
      <div className="event__label-keywords">
        <span>{props.type}金额</span>
        <span>投资方</span>
      </div>
      <div className="event__label-value">
        <span>{props.amount}</span>
        {props.investors.map((item, index) => {
          return (
            <span key={index}>
              <Link
                to={`/database/institutions/${item.uuid}`}
                className="assets__investor"
                target="_blank"
              >
                {item.name}
              </Link>
            </span>
          );
        })}
      </div>
    </div>
  );
};

const InvestInfo = props => {
  return (
    <div className="assets-event__detail-title">
      <div className="event__label-keywords">
        <span>被投机构</span>
        <span>投资方</span>
        <span>投资金额</span>
      </div>
      <div className="event__label-value">
        <span>{props.investee}</span>
        {props.investors.map((item, index) => {
          return (
            <span key={index}>
              <Link to="" className="assets__investor" target="_blank">
                {item.name}
              </Link>
            </span>
          );
        })}
        <span>{props.amount}</span>
      </div>
    </div>
  );
};

export default observer(AssetsEventIntro);
