import React from "react";
import { Link } from "react-router-dom";
import { trackHitTheWallEvent } from "../../../utils/ga_events";
import Button from "../button/Button";
import "./style.scss";

const AuthWallPanel = ({ label }) => {
  return (
    <div className="auth-wall-panel">
      <span>您当前的用户类型为{"试用用户"}，仅支持查看部分数据。</span>
      <span>
        点击
        <Link to="/pricing">
          <Button
            size="medium"
            grid={6}
            onClick={() => {
              trackHitTheWallEvent(
                "become_vip",
                label.replace(/^\S/, s => s.toUpperCase())
              );
            }}
          >
            成为会员
          </Button>
        </Link>
        获得全部数据访问权限，解锁更多高级功能。
      </span>
    </div>
  );
};

export default AuthWallPanel;
