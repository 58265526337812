import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";

import { Header, Footer } from "../shared";
import SalesBanner from "./SalesBanner";
import SalesFunction from "./SalesFunction";
import SalesService from "./SalesService";
import SalesCase from "./SalesCase";
import SalesPrice from "./SalesPrice";
import { PRO } from "../../constants/pro_constants";
import { labelToChinese } from "../../constants/label_to_chinese";
import { useHistory } from "react-router-dom";

import "./style.scss";
import authStore from "../../stores/authStore";
import { forceLogout } from "../../utils/tools";
import { useLastLocation } from "react-router-last-location";

const Sales = () => {
  const history = useHistory();
  const {
    isTrialExpired,
    currentUser,
    isTrialUser,
    isPaidVipUser,
    isPaidInstitutionUser,
  } = useContext(authStore);
  const lastLocation = useLastLocation();
  const { search } = window.location;
  const direct = search ? search.substr(1) !== "false" : true;
  !lastLocation &&
    direct &&
    currentUser &&
    (isTrialUser || isPaidVipUser || isPaidInstitutionUser) &&
    history.push("/home");

  useEffect(() => {
    // 已登录未实名认证
    window.currentUser && !window.currentUser.mobile_verified && forceLogout();
  }, []);

  return (
    <div className="sales-layout">
      <Helmet>
        <title>{labelToChinese[PRO]}</title>
      </Helmet>

      <Header hasBanner={isTrialExpired} />
      <div id="sales-info">
        <SalesBanner />
        <SalesFunction />
        <SalesService />
        <SalesPrice />
        <SalesCase />
        <Footer />
      </div>
    </div>
  );
};

export default Sales;
