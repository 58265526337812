import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { Helmet } from "react-helmet";

import { Header, Footer, Button } from "../shared";
import { forceLogout } from "../../utils/tools";
import UserComingSoon from "../../styles/images/user-coming-soon.svg";
import AuthStore from "../../stores/authStore";
import { PRO } from "../../constants/pro_constants";
import { labelToChinese } from "../../constants/label_to_chinese";
import TrialAuthImg from "../../styles/images/trial-auth.svg";
import "./style.scss";

const User = () => {
  const { currentUser, isAlphaUser } = useContext(AuthStore);

  useEffect(() => {
    // 已登录未实名认证
    window.currentUser &&
      !window.currentUser.mobile_verified &&
      forceLogout(true);
  }, []);

  return (
    <div className="sales-layout">
      <Helmet>
        <title>申请试用 | {labelToChinese[PRO]}</title>
      </Helmet>
      <Header />
      <div id="sales-info">
        {isAlphaUser ? (
          <div className="user-content">
            <img src={UserComingSoon} alt="" />
            <p className="user-content__hint">
              机器之心Pro 正在升级中，暂时无法访问，请您过几天再回来看看！
            </p>
            <p className="user-content__hint">
              升级后的新版将为您带来全新的体验与更多功能，敬请期待！
            </p>
            <p className="user-content__hint">
              急需使用？请发送邮件至
              <span>pro@jiqizhixin.com</span>
            </p>
            {!currentUser && (
              <p className="user-content_login">
                已有账号，立刻 <Link to={"/login"}>登录</Link>
              </p>
            )}
          </div>
        ) : (
          <div className="trial-auth-content">
            <img src={TrialAuthImg} alt="" />
            <p>您需要完成身份认证，才可浏览「机器之心 Pro」数据。</p>
            <Link to={"/identification/step1"}>
              <Button grid={16} size="large">
                立即认证
              </Button>
            </Link>
          </div>
        )}
        <Footer />
      </div>
    </div>
  );
};

export default observer(User);
