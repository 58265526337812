import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";

import { Table, Modal, Skeleton } from "antd";
import researchStore from "../../stores/researchStore";
import isNew from "../../styles/images/pro-new.svg";
import TableOperation from "./TableOperation";
import { Icon, Button, AuthWallPanel } from "../shared";
import authStore from "../../stores/authStore";
import { trackResearchEvent } from "../../utils/ga_events";
import { ResearchCategory } from "../../constants/research";

const ListPanel = () => {
  const [isShowModal, setIsShowModal] = useState(false);
  const [modalHeader, setModalHeader] = useState("");
  const [modalImage, setModalImage] = useState("");
  const [reserachId, setReserachId] = useState("");
  const [reserachCategory, setReserachCategory] = useState("");

  const {
    researchList,
    pagination,
    isLoading,
    updatePagination,
    initializeResearchList,
    scrollToTop,
  } = useContext(researchStore);

  const { isTrialUser } = useContext(authStore);

  useEffect(() => {
    initializeResearchList();
  }, []);

  const researchListHeader = [
    {
      title: "标题",
      dataIndex: "title",
      key: "title",
      ellipsis: true,
      width: 482,
      render: (text, data) =>
        !isLoading ? (
          <>
            <div className="research-list-title-cell">
              {data.category === "可视化" && (
                <span
                  onClick={() => {
                    setIsShowModal(true);
                    setModalHeader(data.title);
                    setModalImage(data.fileUrl);
                    setReserachId(data.id);
                    setReserachCategory(data.category);
                    trackResearchEvent(
                      "view_info",
                      "Research",
                      `${data.title}#${data.id}`,
                      ResearchCategory[data.category]
                    );
                  }}
                  className="research-list-title text-limit--one"
                >
                  {data.title}
                </span>
              )}
              {data.category === "收录" && (
                <a
                  className="research-list-title text-limit--one"
                  href={data.externalUrl}
                  target="_blank"
                  rel="noopener"
                  onClick={() => {
                    trackResearchEvent(
                      "view_info",
                      "Research",
                      `${data.title}#${data.id}`,
                      ResearchCategory[data.category]
                    );
                  }}
                >
                  {data.title}
                </a>
              )}
              {data.category === "报告" && data.status !== "征集中" && (
                <a
                  className="research-list-title text-limit--one"
                  href={`/research/${data.id}`}
                  target="_blank"
                  rel="noopener"
                  onClick={() => {
                    trackResearchEvent(
                      "view_info",
                      "Research",
                      `${data.title}#${data.id}`,
                      ResearchCategory[data.category]
                    );
                  }}
                >
                  {data.title}
                </a>
              )}

              {data.isNew && <img src={isNew} />}
              {data.status && (
                <span
                  className={`research-list-title-status ${
                    data.status === "已发布" ? "status--published" : ""
                  } ${data.status === "征集中" ? "status--collecting" : ""}`}
                >
                  {data.status}
                </span>
              )}
            </div>
          </>
        ) : (
          <Skeleton paragraph={{ rows: 1, width: "80%" }} active />
        ),
    },
    {
      title: "内容类型",
      dataIndex: "researchTypes",
      key: "researchTypes",
      width: 120,
      render: data =>
        !isLoading ? (
          data
        ) : (
          <Skeleton paragraph={{ rows: 1, width: "100%" }} active />
        ),
    },
    {
      title: "作者",
      dataIndex: "author",
      key: "author",
      width: 131,
      render: data =>
        !isLoading ? (
          data
        ) : (
          <Skeleton paragraph={{ rows: 1, width: "100%" }} active />
        ),
    },
    {
      title: "发布日期",
      dataIndex: "uploadedAt",
      key: "uploadedAt",
      width: 111,
      render: data =>
        !isLoading ? (
          data
        ) : (
          <Skeleton paragraph={{ rows: 1, width: "100%" }} active />
        ),
    },
    {
      title: "操作",
      dataIndex: "operation",
      key: "operation",
      ellipsis: true,
      width: 116,
      fixed: "right",
      render: (text, data) =>
        !isLoading ? (
          <TableOperation
            id={data.id}
            key={data.id}
            title={data.title}
            lead={data.lead}
            category={data.category}
            status={data.status}
            fileUrl={data.fileUrl}
            researchTypes={data.researchTypes}
            externalUrl={data.externalUrl}
            onClick={() => {
              setIsShowModal(true);
              setModalHeader(data.title);
              setModalImage(data.fileUrl);
              setReserachId(data.id);
              setReserachCategory(data.category);
              if (ResearchCategory[data.category] === 1) {
                trackResearchEvent(
                  "download",
                  "Research",
                  `${data.title}#${data.id}`,
                  ResearchCategory[data.category]
                );
              } else {
                trackResearchEvent(
                  "view_info",
                  "Research",
                  `${data.title}#${data.id}`,
                  ResearchCategory[data.category]
                );
              }
            }}
          />
        ) : (
          <Skeleton paragraph={{ rows: 1, width: "100%" }} active />
        ),
    },
  ];

  return (
    <div className="research-list__table-container">
      <Table
        columns={researchListHeader}
        dataSource={researchList}
        pagination={JSON.parse(JSON.stringify(pagination))}
        scroll={{ x: 620 }}
        onChange={pagination => {
          updatePagination(pagination);
          scrollToTop();
        }}
      />
      {isTrialUser && <AuthWallPanel label="research" />}
      <Modal
        className="research-detail__tabs-chart-modal"
        title={
          <div className="research-detail__tabs-chart-modal__header">
            {modalHeader}
          </div>
        }
        visible={isShowModal}
        onCancel={() => setIsShowModal(false)}
        cancelText="取消"
        style={{ top: 150 }}
        width={600}
        closeIcon={<Icon name="pro-close" size={16} color="#c1c7d0" />}
        footer={[
          <Button
            size="small"
            grid={4}
            key="cancel"
            type="ghost"
            onClick={() => setIsShowModal(false)}
          >
            取消
          </Button>,
          <a
            key="download"
            href={modalImage}
            download
            onClick={() => {
              trackResearchEvent(
                "download",
                "Research",
                `${modalHeader}#${reserachId}`,
                reserachCategory === "可视化" ? "1" : "0"
              );
            }}
          >
            <Button type="primary" size="small" grid={4}>
              立即下载
            </Button>
          </a>,
        ]}
      >
        <img
          className="research-detail__tabs-chart-modal__image"
          src={modalImage}
          alt={modalHeader}
        />
        {isTrialUser && (
          <span className="research-detail__auth-wall">
            {`您当前的用户类型为"试用用户"，仅支持图表预览。`}
            <Link to="/pricing">成为会员</Link>
            {`，可下载高清无水印图表，获得全部数据访问权限，解锁更多高级功能。`}
          </span>
        )}
      </Modal>
    </div>
  );
};

export default observer(ListPanel);
