import React from "react";
import { observer } from "mobx-react";

import "./style.scss";

const Loading: React.FC = () => {
  return (
    <div className="pro-loading">
      <div className="pro-loading__dot1" />
      <div className="pro-loading__dot2" />
      <div className="pro-loading__dot3" />
      <div className="pro-loading__dot4" />
    </div>
  );
};

export default observer(Loading);
