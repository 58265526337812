import React, { useState, useContext } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import Menu from "../menu/Menu";
import Button from "../button/Button";
import { SalesMenus } from "../../../constants/sales_menu";
import { OVERVIEW } from "../../../constants/pro_constants";
import SyncedLogo from "../../../styles/images/logo-synced-black.png";
import ProImg from "../../../styles/images/pro.svg";
import AuthStore from "../../../stores/authStore";
import ForgetPasswordStore from "../../../stores/forgetPasswordStore";
import { PROTOCOL, TOP_DOMAIN } from "../../../api";
import moment from "moment";

import "./style.scss";
import { Icon } from "..";

interface Props {
  isShowMenu?: Boolean;
  hasBanner?: Boolean;
}

const Header: React.FC<Props> = (props: Props) => {
  const [scrolledDomId, setScrolledDomId] = useState(OVERVIEW);
  const [isShowBanner, setIsShowBanner] = useState(props.hasBanner);
  const [isShowProfilePopover, setIsShowProfilePopover] = useState(false);
  const [logoutColor, setLogoutColor] = useState("#92929D");

  const {
    currentUser,
    isVipUser,
    isAlphaUser,
    isTrialUser,
    expiredAt,
    isPaidUser,
    isAlphaUserWithoutStartTrial,
    isTrialExpired,
    onLogout,
  } = useContext(AuthStore);
  const { isSignin } = useContext(ForgetPasswordStore);

  const handleScroll = item => {
    setScrolledDomId(item.key);
    const scrollDom = document.querySelector(`#${item.key}`);
    window.scrollTo({ top: scrollDom.offsetTop - 130, behavior: "smooth" });
  };

  const setClassName = (type: string, className: string): void => {
    const map = {
      logout: setLogoutColor,
    };
    setTimeout(() => {
      map[type](className);
    }, 100);
  };

  const isPaidVip = isAlphaUser && isPaidUser;
  const isExpiredVipUser = isAlphaUser && !isVipUser && expiredAt < moment();
  const trialInfo = () => {
    if (!currentUser) {
      return { label: "开始试用", path: `/register` };
    }
    if (!isAlphaUser && !isPaidUser) {
      return { label: "开始试用", path: `/users/${currentUser.id}` };
    }
    if (!isAlphaUser && isPaidUser) {
      return { label: "开始使用", path: `/users/${currentUser.id}` };
    }
    if (isAlphaUserWithoutStartTrial) {
      return { label: "开始试用", path: `/identification/start` };
    }
    if (isAlphaUser && isTrialUser) {
      return { label: "开始试用", path: `/home` };
    }
    if (isTrialExpired) {
      return { label: "成为会员", path: `/pricing` };
    }
    if (isPaidVip) {
      return { label: "开始使用", path: `/home` };
    }
    if ((isAlphaUser && expiredAt === null) || isExpiredVipUser) {
      return { label: "开始使用", path: `/pricing` };
    }
  };

  return (
    <div className="sales-layout__header-wrapper">
      {isShowBanner && (
        <div className="sales-layout__banner-wrapper">
          <div className="sales-layout__banner">
            <a href="/pricing">
              <span>当前您的试用期已过 </span>
              <Button size="xsmall" grid={3} shape="round">
                <span className="sales-layout__banner-btn">成为会员</span>
              </Button>
              <span> 即可继续访问机器之心Pro</span>
            </a>
            <div
              className="sales-layout__banner-close"
              onClick={() => setIsShowBanner(false)}
            >
              <Icon name="close-grey" size={12} />
            </div>
          </div>
        </div>
      )}
      <div className="sales-layout__header-content">
        <Link to="/">
          <img src={SyncedLogo} alt="Synced" />
          <img src={ProImg} />
        </Link>
        {props.isShowMenu && (
          <Menu
            items={SalesMenus}
            selectedKey={scrolledDomId}
            className="sales-menus"
            onClick={item => handleScroll(item)}
          />
        )}
        {!isSignin && (
          <div className="sales-layout__header-content__action">
            {!currentUser ? (
              <Link to="/login" className="sales-layout__header-content__login">
                登录
              </Link>
            ) : (
              <div
                className="sales-layout__header-content__user"
                onClick={() => setIsShowProfilePopover(!isShowProfilePopover)}
              >
                <img
                  src={currentUser.avatar_url}
                  className={`${
                    isShowProfilePopover ? "sales-layout__avatar--active" : ""
                  }`}
                  alt=""
                />
                {isShowProfilePopover && (
                  <ul className="pro-sidebar__user-center">
                    <li
                      className="pro-sidebar__logout"
                      onMouseEnter={() => setClassName("logout", "#eb2835")}
                      onMouseLeave={() => setClassName("logout", "#92929D")}
                      onClick={() => {
                        setClassName("logout", "#92929D");
                        setIsShowProfilePopover(false);
                        onLogout();
                      }}
                    >
                      <Icon name="pro-logout" color={logoutColor} />
                      <a>
                        <span>退出登录</span>
                      </a>
                    </li>
                  </ul>
                )}
              </div>
            )}
            <a
              href={`${PROTOCOL}//pro.${TOP_DOMAIN}${trialInfo().path}`}
              className="sales-layout__header-content__try"
            >
              <Button size="medium" grid={6}>
                {trialInfo().label}
              </Button>
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

Header.defaultProps = {
  isShowMenu: false,
  hasBanner: false,
};

export default observer(Header);
