import React from "react";
import { observer } from "mobx-react";

import "./style.scss";
import src from "../../styles/images/crown-start.png";
import { Button } from "../shared";
import { PROTOCOL, TOP_DOMAIN } from "../../api";

const ExchangeSuccess: React.FC = () => {
  return (
    <div className="pro-coupon-code-success">
      <img className="pro-coupon-code-crown" src={src} />
      <p className="pro-coupon-code-title">兑换成功</p>
      <p className="pro-coupon-code-description">
        <p>您的权益立即生效，</p>
        <p>机器之心将持续为您提供专业、优质的服务。</p>
      </p>
      <a href={`${PROTOCOL}//pro.${TOP_DOMAIN}/home`}>
        <Button size="large" grid={12}>
          开始体验 Pro
        </Button>
      </a>
    </div>
  );
};

export default observer(ExchangeSuccess);
