import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Icon } from "../shared";
import _ from "lodash";
import { observer } from "mobx-react";
import { Skeleton } from "antd";
import researchDetailStore from "../../stores/researchDetailStore";
import authStore from "../../stores/authStore";
import { trackResearchEvent } from "../../utils/ga_events";
import { Modal, Form, Input, Tooltip, Checkbox } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import iconPopSuccess from "../../styles/images/icon-pop-success@2x.png";
import { infoInResearchDetail } from "../../api/index";

const title = "无法获取报告";
const description = `您当前的用户类型为"试用用户"，仅支持查看部分内容。点击"成为会员"，获得全部数据访问权限，解锁更多高级功能。`;

const DetailHeader = () => {
  const history = useHistory();
  const {
    data,
    isDatasetEmpty,
    isChartEmpty,
    id,
    setAuthWallModal,
    setForbiddenModal,
    setTrialExpiredModal,
    setVipExpiredModal,
  } = useContext(researchDetailStore);
  const { header } = data;
  const {
    isCurrentUserEmpty,
    isPaidVipUser,
    isPaidInstitutionUser,
    isAlphaUser,
    isAlphaUserWithoutStartTrial,
    isTrialExpired,
    isExpiredVipUser,
    isExpiredInstitutionUser,
  } = useContext(authStore);
  const isVipUser = isAlphaUser && (isPaidVipUser || isPaidInstitutionUser);
  const isForbidden =
    !isCurrentUserEmpty && (isAlphaUserWithoutStartTrial || !isAlphaUser);
  const isVipExpired = isExpiredVipUser || isExpiredInstitutionUser;
  const isReviewDocument =
    header &&
    ((header.charged && isVipUser) || (!header.charged && isAlphaUser));
  const [isShowModal, setIsShowModal] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [step, setStep] = useState(0);
  const [email, setEmail] = useState("");
  const [form] = Form.useForm();

  // const needLeads = true;

  const onFinish = async (values: any) => {
    setEmail(values.email);
    // eslint-disable-next-line no-unused-vars
    const { xy, ...obtain } = values;
    await infoInResearchDetail({
      obtain,
      id,
    });
    setStep(1);
  };
  const onFieldsChange = () => {
    const res: any = form.getFieldsValue();
    const error: any = form.getFieldsError();
    const isComplete =
      Object.values(res).every((value: any) => value) &&
      error
        .map((item: any) => item.errors)
        .every((item: any) => item.length === 0);

    setIsComplete(isComplete);
  };

  return (
    <div className="research-detail__header">
      {!_.isEmpty(data) ? (
        <>
          <div className="research-detail__header-cover">
            <img src={header.cover} alt="" />
          </div>
          <div className="research-detail__header-desc">
            <div>
              <h3>{header.title}</h3>
              <div className="research-detail__header-desc-label">
                <span className="research-detail__header-label-name">
                  {header.author}
                </span>
                <span className="research-divider" />
                <span>{header.uploadedAt}</span>
              </div>
              <div className="research-detail__header-desc--divider " />
              <div className="research-detail__header-fields">
                <div className="research-detail__header-fields-col">
                  <div className="research-detail__header-field">
                    <span className="research-detail__header-fields-title">
                      所属行业：
                    </span>
                    <span className="text-limit--one research-detail__header-fields-value">
                      {header.industry}
                    </span>
                  </div>
                  <div className="research-detail__header-field">
                    <span className="research-detail__header-fields-title">
                      报告页数：
                    </span>
                    <span className="research-detail__header-fields-value">
                      {`${header.pageSize}页`}
                    </span>
                  </div>
                </div>
                <div className="research-detail__header-fields-col">
                  {!isChartEmpty && (
                    <div className="research-detail__header-field">
                      <span className="research-detail__header-fields-title">
                        包含图表：
                      </span>
                      <span className="research-detail__header-fields-value">
                        {header.chartSize}
                      </span>
                    </div>
                  )}
                  {!isDatasetEmpty && (
                    <div className="research-detail__header-field">
                      <span className="research-detail__header-fields-title">
                        包含数据：
                      </span>
                      <span className="research-detail__header-fields-value">
                        {header.datasetSize}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="research-detail__header-action">
              {header.lead ? (
                <Button
                  extraClass={`research-detail__header-get-btn--one`}
                  grid={9}
                  onClick={() => {
                    console.log("need");
                    setIsShowModal(true);
                  }}
                >
                  立即获取
                </Button>
              ) : !isReviewDocument ? (
                <a>
                  <Button
                    extraClass={`research-detail__header-get-btn--${
                      isCurrentUserEmpty ? "two" : "one"
                    }`}
                    grid={9}
                    onClick={() => {
                      if (isCurrentUserEmpty) {
                        history.push("/login");
                        return;
                      }
                      if (isForbidden || isTrialExpired || isVipExpired) {
                        setForbiddenModal(isForbidden);
                        setTrialExpiredModal(isTrialExpired);
                        setVipExpiredModal(isVipExpired);
                        return;
                      }
                      setAuthWallModal(true, title, description);
                    }}
                  >
                    立即获取
                  </Button>
                </a>
              ) : (
                <a href={header.fileUrl} download>
                  <Button
                    extraClass={`research-detail__header-get-btn--${
                      header.charged && !isVipUser ? "two" : "one"
                    }`}
                    grid={9}
                    onClick={() => {
                      trackResearchEvent(
                        "download",
                        "Research",
                        `${header.title}#${id}`,
                        header.categoryCn === "可视化" ? "1" : "0"
                      );
                    }}
                  >
                    立即获取
                  </Button>
                </a>
              )}

              {header.charged && !isVipUser && (
                <a
                  href={header.paymentUrl}
                  className="research-detail__header-action__buy"
                  target="_blank"
                >
                  <Button
                    extraClass="research-detail__header-buy-btn"
                    grid={6}
                    type="yellow"
                    onClick={() => {
                      trackResearchEvent(
                        "buy",
                        "Research",
                        `${header.title}#${id}`,
                        header.categoryCn === "可视化" ? "1" : "0"
                      );
                    }}
                  >
                    <Icon name="pro-buy" size={14} />
                    立即购买
                  </Button>
                </a>
              )}
            </div>
          </div>
          <Modal
            className={`leads-modal ${step !== 0 && "leads-modal--pb"}`}
            visible={isShowModal}
            destroyOnClose
            onCancel={() => {
              setIsShowModal(false);
            }}
            centered
            footer={null}
            closeIcon={<Icon name="pro-close" size={16} color="#c1c7d0" />}
            title={
              step === 0 ? (
                <h3 className="leads-modal__header">免费获取完整报告</h3>
              ) : (
                <img
                  className="leads-modal__success-icon"
                  src={iconPopSuccess}
                  alt="success"
                />
              )
            }
          >
            {step === 0 ? (
              <Form
                name="basic"
                className="leads-modal__form"
                onFinish={onFinish}
                onFieldsChange={onFieldsChange}
                autoComplete="off"
                form={form}
                requiredMark={false}
                initialValues={{ xy: true }}
              >
                <Form.Item
                  label="姓名"
                  colon={false}
                  name="name"
                  rules={[{ required: true, message: "请填写此项" }]}
                >
                  <Input placeholder="请输入姓名" />
                </Form.Item>
                <Form.Item
                  label="手机号码"
                  colon={false}
                  name="mobile"
                  rules={[
                    { required: true, message: "请填写此项" },
                    {
                      pattern: /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
                      message: "请输入符合格式的内容",
                    },
                  ]}
                >
                  <Input placeholder="请输入大陆手机号码" />
                </Form.Item>
                <Form.Item
                  label={
                    <div className="leads-modal__form-label">
                      <p>邮箱</p>
                      <Tooltip title="*报告将通过邮件送达，请务必填写真实邮箱地址">
                        <InfoCircleOutlined style={{ color: "#92929d" }} />
                      </Tooltip>
                    </div>
                  }
                  colon={false}
                  name="email"
                  rules={[
                    { required: true, message: "请填写此项" },
                    {
                      pattern: /^[A-Za-z0-9]+([_\\.][A-Za-z0-9]+)*@([A-Za-z0-9\\-]+\.)+[A-Za-z]{2,6}$/,
                      message: "请输入符合格式的内容",
                    },
                  ]}
                >
                  <Input placeholder="请输入邮箱" />
                </Form.Item>
                <Form.Item
                  label="就职机构"
                  colon={false}
                  name="company"
                  rules={[{ required: true, message: "请填写此项" }]}
                >
                  <Input placeholder="请输入就职机构" />
                </Form.Item>
                <Form.Item
                  label="所属部门"
                  colon={false}
                  name="department"
                  rules={[{ required: true, message: "请填写此项" }]}
                >
                  <Input placeholder="请输入所属部门" />
                </Form.Item>
                <Form.Item
                  label="职位"
                  colon={false}
                  name="position"
                  rules={[{ required: true, message: "请填写此项" }]}
                >
                  <Input placeholder="请输入职位" />
                </Form.Item>

                <Button
                  extraClass={`leads-modal__form-btn ${!isComplete &&
                    "leads-modal__form-btn--opacity"}`}
                  grid={10}
                  htmlType={isComplete ? "submit" : "button"}
                >
                  提交信息，获取报告
                </Button>

                <Form.Item
                  name="xy"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error("请先阅读并勾选同意《用户隐私政策》")
                            ),
                    },
                  ]}
                  className="leads-modal__form-item"
                >
                  <Checkbox className="leads-modal__form-checkbox">
                    点击提交即代表您已阅读并同意
                    <a
                      href="https://www.jiqizhixin.com/short_urls/f9e4dcd4-e5a1-456f-b487-178a4aba8d82"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      《用户隐私政策》
                    </a>
                    ，允许该报告的联合研究方就研究和交流等目的，以合作邀约、学术交流等形式与您进行联系。
                  </Checkbox>
                </Form.Item>
              </Form>
            ) : (
              <div className="leads-modal__success">
                <p>报告链接已发送至您的邮箱</p>
                <p>
                  <span>「{email}」</span>,请注意查收！
                </p>
                <Button
                  extraClass={`leads-modal__success-btn`}
                  grid={10}
                  type="white"
                  onClick={() => {
                    setIsShowModal(false);
                    if (isCurrentUserEmpty) {
                      window.sessionStorage.setItem("path", header.fileUrl);
                      history.push("/login");
                    } else {
                      window.location.href = header.fileUrl;
                    }
                  }}
                >
                  立即领取报告
                </Button>
              </div>
            )}
          </Modal>
        </>
      ) : (
        <Skeleton
          active
          paragraph={{ rows: 3, width: ["25%", "80%", "60%"] }}
        />
      )}
    </div>
  );
};

export default observer(DetailHeader);
