import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Keywords, IconHeading, EmptyImport, AuthWallModal } from "../shared";
import databaseDetailStore from "../../stores/databaseDetailStore";
import { observer } from "mobx-react";
import { PRODUCT_SOLUTIONS } from "../../constants/pro_constants";
import authStore from "../../stores/authStore";

const ProductSolutionIntro = () => {
  const [isShowAuthWallModal, setIsShowAuthWallModal] = useState(false);

  const {
    data: {
      tabs: { productSolutions },
    },
    type,
    isProductSolutionsTabContentEmpty,
  } = useContext(databaseDetailStore);
  const { isTrialUser } = useContext(authStore);

  const hasBusinessCase = item => item.businessCases.length > 0;
  const isShowModal = isTrialUser && isShowAuthWallModal;

  return (
    <>
      <div
        className="database-detail__intro database-detail__block"
        id={PRODUCT_SOLUTIONS}
      >
        <IconHeading icon="pro-package" title="产品与服务" />
        {isProductSolutionsTabContentEmpty ? (
          <div className="database-detail__block__content">
            <EmptyImport />
          </div>
        ) : (
          <div className="database-detail__block__content">
            {productSolutions.map(item => (
              <div className="product-solution__item" key={item.name}>
                <div className="product-solution__title">
                  {isTrialUser ? (
                    <a
                      onClick={() => {
                        setIsShowAuthWallModal(true);
                      }}
                    >
                      {item.name}
                    </a>
                  ) : (
                    <Link to={`/database/solutions/${item.id}`} target="_blank">
                      {item.name}
                    </Link>
                  )}
                </div>
                <Keywords list={item.fields} />
                {hasBusinessCase(item) && (
                  <div className="product-solution__case">
                    <span className="product-solution__case-text">案例</span>
                    {item.businessCases.map((item, i) => {
                      return isTrialUser ? (
                        <a
                          key={i}
                          className="product-solution__case-title"
                          onClick={() => {
                            setIsShowAuthWallModal(true);
                          }}
                        >
                          {item.name}
                        </a>
                      ) : (
                        <Link
                          key={i}
                          className="product-solution__case-title"
                          to={`/database/business-cases/${item.id}`}
                          target="_blank"
                        >
                          {item.name}
                        </Link>
                      );
                    })}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}

        {isShowModal && (
          <AuthWallModal
            visible={isShowAuthWallModal}
            title="无法查看当前数据"
            description={` 您当前的用户类型为"试用用户"，仅支持查看基本信息。点击"成为会员"，获得全部数据访问权限，解锁更多高级功能。`}
            label={type}
            onCancel={() => {
              setIsShowAuthWallModal(false);
            }}
          />
        )}
      </div>
      <div className="database-detail__intro--bottom" />
    </>
  );
};

export default observer(ProductSolutionIntro);
