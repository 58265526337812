import React from "react";

const SVG = ({
  fill = "#92929D",
  width = "100%",
  className = "",
  viewBox = "0 0 20 20",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      fillRule="evenodd"
      d="M11.414 10l6.293-6.293c.391-.391.391-1.023 0-1.414-.39-.391-1.023-.391-1.414 0L10 8.586 3.707 2.293c-.39-.391-1.023-.391-1.414 0-.39.391-.39 1.023 0 1.414L8.586 10l-6.293 6.293c-.39.391-.39 1.023 0 1.414.195.195.451.293.707.293.256 0 .512-.098.707-.293L10 11.414l6.293 6.293c.195.195.451.293.707.293.256 0 .512-.098.707-.293.391-.391.391-1.023 0-1.414L11.414 10z"
    />
  </svg>
);

export default SVG;
