import React from "react";
import Icon from "../icon/Icon";
import "./style.scss";

interface Props {
  color?: "blue" | "orange";
  size?: string;
  isHasBorder?: boolean;
  isClose?: boolean;
  children: React.ReactNode;
  onRemove?: () => void;
}

const COLORS = {
  blue: "#0062ff",
  orange: "#ff8d00",
};

const Tag: React.FC<Props> = (props: Props) => {
  return (
    <span
      className={`tag tag--${props.color} size--${props.size} ${
        props.isHasBorder ? "tag--border" : ""
      }`}
    >
      {props.children}
      {props.isClose && (
        <span className="tag__close" onClick={() => props.onRemove()}>
          <Icon name="pro-close" size={11} color={COLORS[props.color]} />
        </span>
      )}
    </span>
  );
};

Tag.defaultProps = {
  color: "blue",
  size: "small",
  isHasBorder: false,
  isClose: false,
};

export default Tag;
