import React, { useContext, useEffect } from "react";
// import { Tooltip } from "antd";
import { observer } from "mobx-react";
// import { Icon } from "../shared";
import SubscribeStore from "../../stores/subscribeStore";
import { useHistory } from "react-router-dom";

const SubscribeList = props => {
  const history = useHistory();
  const {
    subscribedList,
    unSubscribedList,
    isSubscribedListEmpty,
    isUnsubscribedListEmpty,
    subscribedListCount,
    fetchSubscribe,
    isDrawerVisible,
  } = useContext(SubscribeStore);

  useEffect(() => {
    fetchSubscribe();
  }, []);

  return (
    <>
      <div
        className="subscribe-header"
        style={isDrawerVisible ? { maxWidth: "775px" } : {}}
      >
        <div className="common-tab">
          <div
            className={`common-tab__title ${location.pathname ===
              "/trend/insight" && "common-tab__title--active"}`}
            onClick={() => {
              history.push("/trend/insight");
            }}
          >
            洞察文章
          </div>
          <div
            className={`common-tab__title ${location.pathname ===
              "/subscribe" && "common-tab__title--active"}`}
            onClick={() => {
              history.push("/subscribe");
            }}
          >
            行业周报
          </div>
        </div>
        <a
          className={`subscribe-header__banner ${isDrawerVisible &&
            "subscribe-header__banner--active"}`}
          href="https://www.jiqizhixin.com/short_urls/46a839bc-a3e5-4dd1-9763-46bc7c0efadc"
          target="_blank"
        />
      </div>

      {!isSubscribedListEmpty && (
        <div
          className="subscribe-list"
          style={isDrawerVisible ? { width: "775px" } : {}}
        >
          <div className="subscribe-list__title">
            <span className="subscribe-list__title__text">正在订阅</span>
            <span className="subscribe-list__title__count">
              (<span>{subscribedListCount}</span>/3)
            </span>
            {/* <Tooltip placement="bottom" title={"权益相关文案这里是"}>
              <span>
                <Icon name={"information"} />
              </span>
            </Tooltip> */}
          </div>
          <div className="subscribe-list__content">
            {subscribedList.map(item => {
              return (
                <div onClick={() => props.onItemClick(item)} key={item.id}>
                  <SubscribeItem data={item} />
                </div>
              );
            })}
          </div>
        </div>
      )}

      {!isUnsubscribedListEmpty && (
        <div
          className="subscribe-list unsubscribe"
          style={isDrawerVisible ? { width: "775px" } : {}}
        >
          <div className="subscribe-list__title">
            <span className="subscribe-list__title__text">可订阅</span>
          </div>
          <div className="subscribe-list__content">
            {unSubscribedList.map(item => {
              return (
                <div onClick={() => props.onItemClick(item)} key={item.id}>
                  <SubscribeItem data={item} />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

const SubscribeItem = observer(({ data }) => {
  const { selectedId } = useContext(SubscribeStore);

  return (
    <div
      className={` subscribe-item ${selectedId === data.id ? "active" : ""}`}
    >
      <div className="subscribe-item__firstline">
        <div className="subscribe-item__name">{data.title}</div>
        <div className="subscribe-item__type">{data.type}</div>
        <div className="subscribe-item__account">
          更新到 <span>{data.count}</span> 期
        </div>
      </div>
      <div className="subscribe-item__technologies">
        <span>核心技术：</span>
        {data.technologies.map((technology, i) => (
          <span key={i}>{technology}</span>
        ))}
      </div>
      <div className="subscribe-item__keywords">
        <span>关键字：</span>
        {data.keywords.map((keyword, i) => (
          <span key={i}>{keyword}</span>
        ))}
      </div>
    </div>
  );
});

export default observer(SubscribeList);
