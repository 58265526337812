import { observable, action } from "mobx";
import { createContext } from "react";
import { Notify } from "../components/shared";
import { getCodeByAccount, validateCode, resetPassword } from "../api";

// hooks里 state更改对象需要使用新的(slice)
interface StoreProps {
  step: number;
  codes: any;
  time: number;
  isError: boolean;
  login_name: string;
  next(): void;
  last(): void;
  validate(): void;
  updateParams(values: any): any;
  setPaste(inputs: any, e: any): any;
  setCode(index: number, v: any, cb?: any): any;
  onSend(values: any, next: boolean, fn: any): void;
  setFocus(index: number, inputs: any, e: any): void;
}

class ForgetPasswordStore implements StoreProps {
  @observable public step = 0;
  @observable public time = 60;
  @observable public codes = [];
  @observable public login_name = "";
  @observable public isError = false;
  @observable public isComplete = false;
  @observable public isSignin =
    window.sessionStorage.getItem("login") &&
    window.sessionStorage.getItem("login") === "signin";

  @action public next = () => {
    this.step += 1;
  };

  @action public last = () => {
    this.step -= 1;
  };

  @action public setIsComplete = complete => {
    this.isComplete = complete;
  };

  @action public updateParams = values => {
    const { login_name, _rucaptcha = "" } = values;
    this.login_name = login_name || this.login_name;
    const params = { login_name: this.login_name };
    _rucaptcha && Object.assign(params, { _rucaptcha });
    return params;
  };

  @action public onSend = async (values, next = true, fn) => {
    this.setIsComplete(false);
    await getCodeByAccount(this.updateParams(values), response => {
      if (response.body.success) {
        next && this.next();
        !next && this.setCountdown();
        fn && fn(false);
        Notify.success({
          message: "系统通知",
          description: "验证码发送成功!",
        });
      } else {
        Notify.error({
          message: "系统通知",
          description: response.body.errors,
        });
      }
    });
    this.setIsComplete(true);
  };

  @action public setFocus = (index, inputs, e) => {
    if (e.keyCode === 8) {
      if (index - 1 > -1 && e.currentTarget.value === "") {
        inputs[index - 1].current.focus();
      }
    }
    // TODO: 左右键快捷跳转存在光标位置问题
    // else if (e.keyCode === 37) {
    //   if (index - 1 > -1) {
    //     inputs[index - 1].current.focus();
    //   }
    // } else if (e.keyCode === 39) {
    //   if (index + 1 < 6) {
    //     inputs[index + 1].current.focus();
    //   }
    // }
    else {
      if (index + 1 < 6) {
        setTimeout(() => {
          if (inputs[index].current.state.value) {
            inputs[index + 1].current.focus();
          } else {
            this.setFocus(index, inputs, e);
          }
        }, 0);
      }
    }
  };

  @action public validate = async () => {
    const code = this.codes.filter(item => item !== " " && item !== "");
    const length = this.codes.filter(item => item).length;
    this.isError = length === 6 && code.length !== 6;
    if (!this.isError && code.length === 6) {
      await validateCode(
        { login_name: this.login_name, code: code.join("") },
        response => {
          if (response.body.success) {
            this.next();
          } else {
            this.isError = true;
          }
        }
      );
    }
  };

  @action public setPaste = (inputs, e) => {
    this.codes = e.clipboardData
      .getData("Text")
      .split("")
      .slice(0, 6);
    inputs[5].current.focus();
    this.validate();
  };

  @action public setCode = async (index, v, cb?) => {
    this.codes[index] = v;
    this.validate();
    cb && cb();
  };

  @action public setCountdown = () => {
    let timer = null;
    this.time = 60;
    timer && clearInterval(timer);
    timer = setInterval(() => {
      if (this.time === 0) {
        clearInterval(timer);
      }
      this.time -= 1;
    }, 1000);
  };

  @action public finish = async values => {
    const { password1, password2 } = values;
    if (password1 === password2) {
      await resetPassword(
        { login_name: this.login_name, password: password1 },
        response => {
          if (response.body.success) {
            const loginType = window.sessionStorage.getItem("login");
            const inboxId = window.sessionStorage.getItem("id");
            if (loginType && loginType === "signin") {
              // 找回密码后，需要从哪里触发的回到哪里
              window.location.replace(inboxId ? `/inbox/${inboxId}` : `/inbox`);
            } else {
              window.location.replace(`/?false`);
            }
            window.sessionStorage.removeItem("login");
          } else {
            Notify.error({
              message: "系统通知",
              description: response.body.errors,
            });
          }
        }
      );
    }
  };
}

export default createContext(new ForgetPasswordStore());
