import React, { useState, useContext, useEffect } from "react";
import { observer } from "mobx-react";

import "./style.scss";
import { Header, Footer, PricingCard, Button, Icon } from "../shared";
import blueLevel1 from "../../styles/images/blue-bg-level1.png";
import blueLevel2 from "../../styles/images/blue-bg-level2.png";
import blueLevel3 from "../../styles/images/blue-bg-level3.png";
import PaymentModal from "./PaymentModal";
import PricingRights from "./PricingRights";
import { Link } from "react-router-dom";
import authStore from "../../stores/authStore";
import ContactModal from "./ContactModal";
import ContactSuccessModal from "./ContactSuccessModal";
import paymentStore from "../../stores/paymentStore";
import { useQuery } from "../../utils/useQuery";

const Pricing = () => {
  const couponCode = useQuery().get("couponCode");
  const isShowMoal = useQuery().get("isShowMoal");
  const [isShowPaymentModal, setIsShowPaymentModal] = useState(
    isShowMoal === "1"
  );
  const [isShowContactModal, setIsShowContactModal] = useState(false);
  const [isShowContactSuccessModal, setIsShowContactSuccessModal] = useState(
    true
  );

  const {
    currentUser,
    isAlphaUser,
    trialDays,
    isPaidInstitutionUser,
    isPaidVipUser,
    isExpiredVipUser,
    isExpiredInstitutionUser,
    isInTrial,
    isAlphaUserWithoutStartTrial,
    isTrialExpired,
    isPaidUser,
  } = useContext(authStore);
  const {
    contactSuccess,
    personProduct,
    initContactSuccess,
    fetchProductsInfo,
    showUpTips,
  } = useContext(paymentStore);

  useEffect(() => {
    fetchProductsInfo(couponCode);
    setIsShowContactSuccessModal(true);
    if (isShowContactSuccessModal || isShowContactModal) {
      initContactSuccess();
    }
  }, [isShowContactModal]);

  const isDisableTrial =
    isTrialExpired ||
    isPaidVipUser ||
    isPaidInstitutionUser ||
    isExpiredVipUser ||
    isExpiredInstitutionUser ||
    (isPaidUser && !isAlphaUser);

  const TrialLink = () => {
    if (!currentUser) {
      return "/login";
    }

    if (
      isTrialExpired ||
      isPaidVipUser ||
      isPaidInstitutionUser ||
      isExpiredVipUser ||
      isExpiredInstitutionUser
    ) {
      return "/pricing";
    }

    if (isInTrial) {
      return "/home";
    }

    if (currentUser && !isAlphaUser) {
      return `/users/${currentUser && currentUser.id}`;
    }

    if (isAlphaUserWithoutStartTrial) {
      return "/identification/start";
    }
  };

  const trialButtonText = () => {
    if (currentUser) {
      if (isDisableTrial) {
        return "不可试用";
      } else {
        return isAlphaUserWithoutStartTrial || (currentUser && !isAlphaUser)
          ? "开始试用"
          : "继续体验";
      }
    } else {
      return "开始试用";
    }
  };

  return (
    <div className="pro-pricing-layout header-footer--verticality-layout">
      <Header />
      {couponCode && (
        <div className="pro-pricing__discount">
          正在享受优惠码：{couponCode} 折扣
        </div>
      )}
      <div className="pro-pricing">
        <h1>用户定价</h1>
        <p className="pro-pricing__desc">
          以根据您或企业规模与业务需求，购买不同的产品套餐
        </p>
        <div className="pro-pricing__cards">
          <PricingCard header="试用会员" headerImage={blueLevel1}>
            <span className="pro-pricing__card-trial-desc">
              新注册用户可免费试用<label>7</label>天
            </span>
            {isInTrial && (
              <span className="pro-pricing__card-trial-reminder">
                试用期还剩余 {trialDays} 天
              </span>
            )}
            <div
              className="pro-pricing__trial-button"
              style={{ cursor: `${isDisableTrial}` ? "not-allowed" : "" }}
            >
              <Link to={TrialLink} disabled={isDisableTrial}>
                <Button
                  size="xlarge"
                  grid={14}
                  type="transparent"
                  shape="round"
                  disabled={isDisableTrial}
                >
                  <span className="pricing-button__bold">
                    {trialButtonText()}
                  </span>
                </Button>
              </Link>
            </div>
          </PricingCard>
          <PricingCard header="Pro会员" headerImage={blueLevel2}>
            {couponCode ? (
              <>
                <div className="pro-pricing__card-personal-price-discount">
                  <span className="pro-pricing__card-personal-price-discount__price">
                    ￥{personProduct.reducedPrice}
                  </span>
                  <label className="pro-pricing__cards-unit">元 /年</label>
                </div>
                <div className="pro-pricing__card-personal-price-cost">
                  原价{personProduct.price} 元/年
                </div>
              </>
            ) : (
              <div className="pro-pricing__card-personal-price-text">
                <span className="pro-pricing__card-personal-price">
                  {personProduct.price}
                </span>
                <label className="pro-pricing__cards-unit">元 /年</label>
              </div>
            )}
            <ul>
              <li>
                <Icon name="notify-success" size={26} />
                <span>专业市场数据</span>
              </li>
              <li>
                <Icon name="notify-success" size={26} />
                <span>千余研究报告</span>
              </li>
              <li>
                <Icon name="notify-success" size={26} />
                <span>实时产业资讯</span>
              </li>
              <li>
                <Icon name="notify-success" size={26} />
                <span>高端职业交流</span>
              </li>
            </ul>
            {showUpTips && (
              <p className="pro-up-tips-top">于2022年5月9日晚11点55分涨价至</p>
            )}
            {showUpTips && (
              <p className="pro-up-tips-bottom">
                <span>￥1,999</span>
                <span>元 /年</span>
              </p>
            )}
            {isPaidVipUser && (
              <span className="pro-pricing__vip-text">您当前是个人会员</span>
            )}
            {currentUser && isExpiredVipUser && (
              <span className="pro-pricing__vip--expired">
                您的会员身份已过期
              </span>
            )}
            <div className="pro-pricing__personal-button">
              {currentUser ? (
                isPaidVipUser ? (
                  <Link to={isAlphaUser ? "/home" : `/users/${currentUser.id}`}>
                    <Button size="xlarge" grid={14} shape="round">
                      <span className="pricing-button__bold">开始使用</span>
                    </Button>
                  </Link>
                ) : (
                  <Button
                    size="xlarge"
                    grid={14}
                    shape="round"
                    disabled={isPaidInstitutionUser}
                    onClick={() => setIsShowPaymentModal(true)}
                  >
                    <span className="pricing-button__bold">
                      立即成为Pro会员
                    </span>
                  </Button>
                )
              ) : (
                <Link to="/login">
                  <Button size="xlarge" grid={14} shape="round">
                    <span className="pricing-button__bold">
                      立即成为Pro会员
                    </span>
                  </Button>
                </Link>
              )}
            </div>
          </PricingCard>
          <PricingCard header="机构会员" headerImage={blueLevel3}>
            <span className="pro-pricing__card-institution-desc">了解更多</span>
            <span className="pro-pricing__cards-level3-desc">
              深度定制，系统服务
            </span>
            {currentUser && isPaidInstitutionUser && (
              <span className="pro-pricing__vip-text">您当前是机构会员</span>
            )}
            {currentUser && isExpiredInstitutionUser && (
              <span className="pro-pricing__vip--expired">
                您的会员身份已过期
              </span>
            )}
            {currentUser ? (
              isPaidInstitutionUser ? (
                <Link to="/home">
                  <Button
                    size="xlarge"
                    grid={14}
                    type="transparent"
                    shape="round"
                  >
                    <span className="pricing-button__bold">开始使用</span>
                  </Button>
                </Link>
              ) : (
                <Button
                  size="xlarge"
                  grid={14}
                  type="transparent"
                  shape="round"
                  onClick={() => setIsShowContactModal(true)}
                >
                  <span className="pricing-button__bold">立即咨询</span>
                </Button>
              )
            ) : (
              <Link to="/login">
                <Button
                  size="xlarge"
                  grid={14}
                  type="transparent"
                  shape="round"
                >
                  <span className="pricing-button__bold">立即咨询</span>
                </Button>
              </Link>
            )}
          </PricingCard>
        </div>
        <div className="pro-pricing__coupon">
          <Link to="/coupon-code" className="pro-pricing__coupon__link">
            {">>>   "}优惠码兑换{"   <<<"}
          </Link>
        </div>
        <PricingRights
          setIsShowPaymentModal={setIsShowPaymentModal}
          setIsShowContactModal={setIsShowContactModal}
        />
        {isShowPaymentModal && (
          <PaymentModal
            visible={isShowPaymentModal}
            onCancel={() => setIsShowPaymentModal(false)}
          />
        )}
        {isShowContactModal && (
          <ContactModal
            visible={isShowContactModal}
            onCancel={() => setIsShowContactModal(false)}
          />
        )}
        {isShowContactSuccessModal && contactSuccess && (
          <ContactSuccessModal
            visible={isShowContactSuccessModal && contactSuccess}
            onCancel={() => setIsShowContactSuccessModal(false)}
          />
        )}
        <Footer />
      </div>
    </div>
  );
};

export default observer(Pricing);
