import React from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";

import "./style.scss";
import { Button, Progress } from "../shared";

interface Props {
  data: any;
  rankId?: string;
}

const AwardItem: React.FC<Props> = (props: Props) => {
  const { data } = props;

  const id = data.finished === data.total ? data.finishedId : data.writingId;
  const text = data.finished === data.total ? "查看评分" : "开始评分";

  return (
    <div className="pro-award-item">
      <p className="pro-award-item-title text-limit--two">{data.name}</p>
      <Progress numerator={data.finished} denominator={data.total} />
      <Link to={`/selection-rating/${id}?rankId=${data.id}`}>
        <Button grid={4} type="ghost">
          {text}
        </Button>
      </Link>
    </div>
  );
};

export default observer(AwardItem);
