import {
  IDENTITY,
  CITY,
  SCHOOL,
  MAJOR,
  DEGREE,
  GRADUATE,
  COMPANY,
  POSITION,
  WORKEXPERIENCE,
  FULLNAME,
  MOBILE,
  WECHAT,
  EMAIL,
} from "../constants/pro_constants";

const certificationMeta = [
  IDENTITY,
  CITY,
  SCHOOL,
  MAJOR,
  DEGREE,
  GRADUATE,
  COMPANY,
  POSITION,
  WORKEXPERIENCE,
];

const certificationUserMeta = [FULLNAME, MOBILE, WECHAT, EMAIL];
import moment from "moment";

const formatPersonalInfo = initData => ({
  name: initData.name,
  avatarUrl: initData.avatarUrl,
  mobile: initData.mobile,
  email: initData.email,
  password: initData.password,
  bindData: initData.bind,
  emailVerified: initData.emailVerified,
});

const formatCertificationMeta = initData => {
  const { category } = initData;
  const certificationInfo =
    category === "studying"
      ? certificationMeta.slice(0, certificationMeta.length - 3)
      : certificationMeta;
  return certificationInfo.map(item => ({
    name: item,
    value: initData[item] || "-",
  }));
};

const formatCertificationUserMeta = initData =>
  certificationUserMeta.map(item => ({
    name: item,
    value: initData[item] || "-",
  }));

const formatVipInfo = initData => ({
  name: initData.name,
  vip: initData.vip,
  expiredAt: moment.unix(initData.expiredAt).format("YYYY年MM月DD日"),
  identity: initData.identity,
  trialDays: initData.trialDays,
  orders: initData.orders.map(item => ({
    id: item.shortId,
    payTime: moment(item.updatedAt).format("YYYY/MM/DD HH:mm"),
    productName: { productName: item.productName, couponCode: item.couponCode },
    price: item.finalPrice / 100,
    payWay: item.payWay,
    payStatus: item.status,
  })),
});

export {
  formatPersonalInfo,
  formatVipInfo,
  formatCertificationMeta,
  formatCertificationUserMeta,
};
