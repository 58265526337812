// import React from "react";
import "./style.scss";
import Icon from "../icon/Icon";
import { notification } from "antd";
import React from "react";

type IconType = "success" | "info" | "error" | "warning";
type NotificationPlacement =
  | "topLeft"
  | "topRight"
  | "bottomLeft"
  | "bottomRight";

interface OptionProps {
  top?: number;
  bottom?: number;
  duration?: number;
  prefixCls?: string;
  placement?: NotificationPlacement;
  getContainer?: () => HTMLElement;
  closeIcon?: React.ReactNode;
  rtl?: boolean;
}

interface ArgsProps {
  message: React.ReactNode;
  description?: React.ReactNode;
  btn?: React.ReactNode;
  key?: string;
  onClose?: () => void;
  duration?: number | null;
  icon?: React.ReactNode;
  placement?: NotificationPlacement;
  style?: React.CSSProperties;
  prefixCls?: string;
  className?: string;
  readonly type?: IconType;
  onClick?: () => void;
  top?: number;
  bottom?: number;
  getContainer?: () => HTMLElement;
  closeIcon?: React.ReactNode;
}

interface NotificationInstance {
  success(args: ArgsProps): void;
  error(args: ArgsProps): void;
  info(args: ArgsProps): void;
  warning(args: ArgsProps): void;
  open(args: ArgsProps): void;
}
interface NotificationApi extends NotificationInstance {
  warn(args: ArgsProps): void;
  close(key: string): void;
  config(options: OptionProps): void;
  destroy(): void;
}

const closeIcon: React.ReactNode = (
  <Icon name="pro-close" size={16} color="#c1c7d0" />
);
const successIcon: React.ReactNode = <Icon name="notify-success" size={30} />;
const errorIcon: React.ReactNode = <Icon name="notify-error" size={30} />;
const warnIcon: React.ReactNode = <Icon name="notify-warn" size={30} />;
const infoIcon: React.ReactNode = "";

const Notify: NotificationApi = {
  success: props =>
    notification.success({ ...props, icon: successIcon, closeIcon }),
  error: props => notification.error({ ...props, icon: errorIcon, closeIcon }),
  info: props => notification.info({ ...props, icon: infoIcon, closeIcon }),
  warning: props => notification.warning(props),
  open: props => notification.open(props),
  warn: props => notification.warn({ ...props, icon: warnIcon, closeIcon }),
  close: props => notification.close(props),
  config: props => notification.config(props),
  destroy: () => notification.destroy(),
};

export default Notify;
