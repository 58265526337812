import { message } from "antd";
import { action, observable, computed } from "mobx";
import { createContext } from "react";
import {
  fetchPhoneVerifyCode,
  fetchNewCodeVerify,
  login,
  register,
  logout,
  fetchUserInfoApi,
  mobileVerified,
} from "../api";
import {
  OTHER_USER,
  PAID_USER,
  TRIAL_USER,
  TRIAL_USER2,
} from "../constants/pro_constants";
import moment from "moment";
import { trackLoginEvent, trackSignupEvent } from "../utils/ga_events";
import { formatMemberInfo } from "../utils/formatMemberInboxData";
import _ from "lodash";

class AuthStore {
  @observable public currentUser = window.currentUser;
  @observable public memberInfo: any = null;
  @observable public memberLoading = true;
  @observable public time = 0;

  @action public judgeRole = (response, mess, url) => {
    const path = window.sessionStorage.getItem("path");
    const isNotVip = response.body.user.vip === OTHER_USER;
    const isAlphaUser = response.body.user.isAlpha;

    if (response.statusCode === 200 && !isAlphaUser) {
      message.success(mess);
      const href = path ? path : `/login/success`;
      window.location.replace(url ? url : href);
      window.sessionStorage.removeItem("path");
      return;
    }

    if (response.statusCode === 200 && !isNotVip) {
      message.success(mess);
      const href = path ? path : `/home`;
      window.location.replace(url ? url : href);
      window.sessionStorage.removeItem("path");
      return;
    }

    if (response.statusCode === 200 && isNotVip) {
      message.success(mess);
      const href = path ? path : `/`;
      window.location.replace(url ? url : href);
      window.sessionStorage.removeItem("path");
      return;
    }

    if (response.statusCode === 200 && isAlphaUser && isNotVip) {
      message.success(mess);
      const href = path ? path : `/`;
      window.location.replace(url ? url : href);
      window.sessionStorage.removeItem("path");
      return;
    }
  };

  @action
  public onLoginSubmit = async (data, url, judge?: boolean) => {
    const path = window.sessionStorage.getItem("path");
    await login(data, async response => {
      if (response.statusCode === 401) {
        message.error(response.body || "登录失败");
        return;
      }
      // const isNotVip = response.body.user.vip === OTHER_USER;
      // const isAlphaUser = response.body.user.isAlpha;

      trackLoginEvent("Password");
      if (judge) {
        if (response.statusCode === 200) {
          await this.fetchMemberInfo();
          message.success("登录成功");
          if (response.body.user && !!response.body.user.mobile_verified) {
            // 已实名认证，原有逻辑
            if (
              this.memberInfo &&
              this.memberInfo.email &&
              this.memberInfo.mobile
            ) {
              window.location.replace(url ? url : path);
            } else {
              window.location.replace(`/bind-info`);
            }
            window.sessionStorage.removeItem("path");
          } else {
            // 未认证，跳转至实名认证
            window.location.replace(`/authentication`);
          }
          return;
        }
      } else {
        if (response.body.user && !!response.body.user.mobile_verified) {
          // 已实名认证，原有逻辑
          this.judgeRole(response, "登录成功", url);
        } else {
          // 未认证，跳转至实名认证
          if (response.statusCode === 200) {
            message.success("登录成功");
            window.location.replace("/authentication");
          }
        }
      }
    });
  };

  @action
  public onAuthSubmit = async (data, url) => {
    const params = {
      mobile_verify: {
        mobile: data.mobile,
        phone_verify_code: data.phone_verify_code,
      },
      _rucaptcha: data._rucaptcha,
    };
    await mobileVerified(params, response => {
      if (response.statusCode === 200) {
        // const isNotVip = response.body.user.vip === OTHER_USER;
        // const isAlphaUser = response.body.user.isAlpha;
        this.judgeRole(response, "实名认证成功", url);
      } else if (response.statusCode === 401) {
        message.error(response.body || "实名认证失败");
      } else {
        message.error(response.body.errors);
      }
    });
  };

  @action public setCountdown = () => {
    let timer = null;
    this.time = 60;
    timer && clearInterval(timer);
    timer = setInterval(() => {
      if (this.time === 0) {
        clearInterval(timer);
      }
      this.time -= 1;
    }, 1000);
  };

  @action public onSendCode = async data => {
    await fetchPhoneVerifyCode(data, response => {
      if (response.statusCode === 200) {
        this.setCountdown();
        message.success("已将验证码发送到您的手机，请查收！");
      } else {
        if (response.statusCode === 422) {
          const errorMsg = response.body.errors[0];
          message.error(errorMsg);
        } else {
          const errorMsg = response.body.errors.join(",");
          message.error(
            errorMsg === "手机号码已被注册。"
              ? "该手机号已存在，请使用新手机号绑定"
              : errorMsg
          );
        }
      }
    });
  };

  @action public onAuthSendCode = async (data: any) => {
    if (data.mobile) {
      if (data._rucaptcha) {
        await fetchNewCodeVerify(data, response => {
          if (response.statusCode === 200) {
            this.setCountdown();
            message.success("已将验证码发送到您的手机，请查收！");
          } else {
            if (response.statusCode === 422) {
              const errorMsg = response.body.errors[0];
              message.error(errorMsg);
            } else {
              const errorMsg = response.body.errors.join(",");
              message.error(
                errorMsg === "手机号码已被注册。"
                  ? "该手机号已存在，请使用新手机号绑定"
                  : errorMsg
              );
            }
          }
        });
      } else {
        message.error("验证码不合法!");
      }
    } else {
      message.error("手机号不合法!");
    }
  };

  @action
  public onRegisterSubmit = async data => {
    // const path = window.sessionStorage.getItem("path");
    const pramas = {
      user: {
        mobile: data.mobile,
        phone_verify_code: data.phone_verify_code,
        name: data.name,
        password: data.password,
      },
      _rucaptcha: data._rucaptcha,
    };
    await register(pramas, response => {
      if (response.statusCode === 200) {
        message.success("注册成功");
        trackSignupEvent("Password");
        // window.location.href = path ? path : `/register/success`;
        // 未认证，跳转至实名认证
        window.location.href = `/authentication`;
        window.sessionStorage.removeItem("path");
        return;
      } else {
        message.error(response.body.errors);
        return;
      }
    });
  };

  @action
  public updateCaptcha = async (dom, event) => {
    dom.src = event.target.src;
  };

  @action
  public onLogout = async () => {
    await logout(response => {
      if (response.statusCode === 200) {
        window.location.href = "/";
        window.sessionStorage.removeItem("path");
      } else {
        message.error(response.body.errors);
      }
    });
  };

  @action
  public updateAndLogout = async () => {
    if (!!this.memberInfo && !this.memberInfo.mobile) {
      logout(() => {
        this.memberInfo = null;
      });
    }
  };

  @action
  public fetchMemberInfo = async (out?: boolean) => {
    this.memberLoading = true;
    try {
      const res = await fetchUserInfoApi();
      this.memberInfo = formatMemberInfo(res.member);
    } catch (e) {
      this.memberInfo = null;
    }
    this.memberLoading = false;
    out && this.updateAndLogout();
  };

  // 手动修改用户信息
  @action
  public changeMemberInfo = async (values: any) => {
    this.memberInfo.name = values.name;
    this.memberInfo.bio =
      !!values.company && !!values.position
        ? values.company + "·" + values.position
        : (!!values.company ? values.company : "") +
          (!!values.position ? values.position : "");
  };

  // 是否收件箱新会员用户
  @computed
  get isNewMember() {
    return this.memberInfo ? this.memberInfo.newMember : false;
  }
  // 是否窗口期(会员过期后30天内)
  @computed
  get windowExpire() {
    return this.memberInfo ? this.memberInfo.discountedRenewal : false;
  }

  @computed
  get isPaidUser() {
    return this.currentUser && this.currentUser.vip === PAID_USER;
  }

  @computed
  get isVipUser() {
    return this.currentUser && this.currentUser.vip !== OTHER_USER;
  }

  @computed
  get isAlphaUser() {
    return this.currentUser && this.currentUser.isAlpha;
  }

  @computed
  get isTrialUser() {
    return (
      this.currentUser &&
      (this.currentUser.vip === TRIAL_USER ||
        this.currentUser.vip === TRIAL_USER2)
    );
  }

  @computed
  get institutional() {
    return this.currentUser && this.currentUser.institutional;
  }

  @computed
  get trialDays() {
    return this.currentUser && this.currentUser.trialDays;
  }

  @computed
  get expiredAt() {
    return this.currentUser && this.currentUser.expiredAt;
  }

  @computed
  get isExpired() {
    return (
      this.currentUser &&
      this.expiredAt &&
      moment(moment.unix(this.expiredAt).format("YYYY-MM-DD")).isSameOrBefore(
        moment()
      )
    );
  }

  @computed
  get isAlphaUserWithoutStartTrial() {
    return (
      this.currentUser &&
      this.isAlphaUser &&
      this.currentUser.vip === OTHER_USER &&
      this.trialDays === null
    );
  }

  @computed
  get isPaidInstitutionUser() {
    return (
      this.isAlphaUser &&
      this.isPaidUser &&
      this.institutional &&
      !this.isExpired
    );
  }

  @computed
  get isPaidVipUser() {
    return (
      this.currentUser &&
      this.isPaidUser &&
      !this.institutional &&
      !this.isExpired
    );
  }

  @computed
  get isExpiredVipUser() {
    return (
      this.isAlphaUser &&
      this.isPaidUser &&
      !this.institutional &&
      this.isExpired
    );
  }

  @computed
  get isExpiredInstitutionUser() {
    return (
      this.isAlphaUser &&
      this.isPaidUser &&
      this.institutional &&
      this.isExpired
    );
  }

  @computed
  get isInTrial() {
    return this.currentUser && this.isAlphaUser && this.isTrialUser;
  }

  @computed
  get isTrialExpired() {
    return (
      this.currentUser &&
      this.isAlphaUser &&
      !this.isVipUser &&
      this.trialDays <= 0
    );
  }

  @computed
  get isInsideUser() {
    return this.currentUser && this.currentUser.whitelist;
  }

  @computed
  get userTags() {
    return this.currentUser && this.currentUser.tagList;
  }

  @computed
  get isCurrentUserEmpty() {
    return _.isEmpty(this.currentUser);
  }

  @computed
  get isIOS() {
    return !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  }
}

export default createContext(new AuthStore());
