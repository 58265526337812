import React from "react";
import _ from "lodash";

const SolutionTech = ({ data }) => {
  return (
    data && (
      <>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">
            涉及智能技术:
          </div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.techFields) && data.techFields.join("，")}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">核心技术:</div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.coreTech) ? (
              data.coreTech
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">技术架构:</div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.architecture) ? (
              data.architecture
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">
            方案是否提供云端服务:
          </div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.cloudServices) ? (
              data.cloudServices
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">
            方案是否支持大规模数据处理:
          </div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.largeScale) ? (
              <div
                className="selection-rating-modules-form-text--rich"
                dangerouslySetInnerHTML={{ __html: data.largeScale || "" }}
              />
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">
            产品/方案可扩展性:
          </div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.scalability) ? (
              data.scalability
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
        <div className="selection-rating-modules-form--block">
          <div className="selection-rating-modules-form--field">
            产品/方案兼容性:
          </div>
          <div className="selection-rating-modules-form--value">
            {!_.isEmpty(data.compatibility) ? (
              <div
                className="selection-rating-modules-form-text--rich"
                dangerouslySetInnerHTML={{ __html: data.compatibility || "" }}
              />
            ) : (
              <span className="selection-no-data">无</span>
            )}
          </div>
        </div>
      </>
    )
  );
};

export default SolutionTech;
