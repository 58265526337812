import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react";

import { Menu } from "../shared";
import researchDetailStore from "../../stores/researchDetailStore";
import ReportIntro from "./ReportIntro";
import ReportCatalog from "./ReportCatalog";
import Chart from "./Chart";
import Dataset from "./Dataset";

const DetailTabs = () => {
  const {
    scrolledDomId,
    scrollTo,
    initializeScrolledDomId,
    researchDetailTabs,
    isReportIntroTabContentEmpty,
    isReportCatalogTabContentEmpty,
    isDatasetTabContentEmpty,
    isChartTabContentEmpty,
  } = useContext(researchDetailStore);

  useEffect(() => {
    initializeScrolledDomId();
  }, []);

  return (
    <div className="research-detail-tabs">
      <Menu
        items={researchDetailTabs}
        selectedKey={scrolledDomId}
        onClick={item => scrollTo(item.key)}
      />
      {!isReportIntroTabContentEmpty && <ReportIntro />}
      {!isReportCatalogTabContentEmpty && <ReportCatalog />}
      {!isChartTabContentEmpty && <Chart />}
      {!isDatasetTabContentEmpty && <Dataset />}
    </div>
  );
};

export default observer(DetailTabs);
