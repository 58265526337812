import React, { useContext, useState } from "react";
import ListHeader from "./ListHeader";
import ListPanel from "./ListPanel";
import Filters from "./Filters";
import { Col, Row } from "antd";
import researchStore from "../../stores/researchStore";
import { observer } from "mobx-react";
import { AuthWallModal } from "../shared";

const ResultList = () => {
  const {
    updateResearchListBySort,
    isShowAuthWallModal,
    setIsShowAuthWallModal,
  } = useContext(researchStore);

  const [activeTab, setActiveTab] = useState("latest");

  return (
    <div className="research-list__table-container">
      <ListHeader />
      <Row className="research-list__-filters-table">
        <Col span={20} className="research-list__table">
          <div className="research-list__table-header">
            {/* TODO: Hidden temporary for pro phase1 release */}
            {/* <span>
              共 {totalCount} 篇
              {keywords && (
                <span className="research-list__table-header-keywords">{`「${keywords}」`}</span>
              )}
              {keywords && "相关"}
              研究
            </span> */}
            <div className="research-list__sort-group">
              <span>排序方式：</span>
              <button
                className={`${activeTab === "latest" ? "active" : ""}`}
                onClick={() => {
                  updateResearchListBySort(), setActiveTab("latest");
                }}
              >
                最新
              </button>
              {/* TODO: Hidden temporary for pro phase1 release */}
              {/* <button
                className={`${activeTab === "hot" ? "active" : ""}`}
                onClick={() => {
                  updateResearchListBySort("hot"), setActiveTab("hot");
                }}
              >
                热门
              </button> */}
            </div>
          </div>
          <ListPanel />
          <AuthWallModal
            title="无法获取报告"
            description={`您当前的用户类型为"试用用户"，仅支持查看部分内容。点击"成为会员"，获得全部数据访问权限，解锁更多高级功能。`}
            visible={isShowAuthWallModal}
            label="research"
            onCancel={() => setIsShowAuthWallModal(false)}
          />
        </Col>
        <Filters />
      </Row>
    </div>
  );
};

export default observer(ResultList);
