import { createContext } from "react";
import { observable, action } from "mobx";
import {
  fetchPcMemberArticleApi,
  fetchPcMemberListApi,
  fetchPcReferenceListApi,
} from "../api/index";
import { formatDetail, formatList } from "../utils/formatMemberInboxData";

class memberInboxStore {
  @observable public detail: any = null;
  @observable public detailLoading = true;
  @observable public activeIndex = 0;
  @observable list: any = [];
  @observable loading = false;
  @observable public pagination = {
    total: 10,
    current: 1,
    pageSize: 10,
    hasNextPage: true,
  };
  @observable public params: any = {
    page: 1,
    per: 10,
  };

  @action
  fetchDetail = async (id: string) => {
    this.detailLoading = true;
    const res = await fetchPcMemberArticleApi(id);
    this.detail = formatDetail(res.content);
    this.detailLoading = false;
  };

  //去掉字符串两端的空格
  @action
  trim = str => {
    return str.replace(/(^\s*)|(\s*$)/g, "");
  };
  //去除换行
  @action
  ClearBr = key => {
    key = key.replace(/<\/?.+?>/g, "");
    key = key.replace(/[\r\n]/g, "");
    return key;
  };

  @action
  isInViewPort = (element: any) => {
    const viewWidth = window.innerWidth || document.documentElement.clientWidth;
    const viewHeight =
      window.innerHeight || document.documentElement.clientHeight;
    const { top, right, bottom, left } = element.getBoundingClientRect();

    return top >= 0 && left >= 0 && right <= viewWidth && bottom <= viewHeight;
  };

  @action
  scrollChoose = (type: string) => {
    const container: any = document.querySelector(
      `.${type}-detail-layout__content-wrapper`
    );
    if (container) {
      let activeArr = [];
      const nearItem: any = {
        y: Infinity,
      };
      const items = Array.from(
        document.querySelectorAll(`.${type}-detail-body h3`)
      ).filter((item: any) => !!this.ClearBr(this.trim(item.innerText)));
      items.forEach((it: any, index: number) => {
        if (this.isInViewPort(it)) {
          activeArr.push(index);
        }
        const res = Math.abs(container.scrollTop - it.offsetTop);

        if (res < nearItem.y) {
          nearItem.y = res;
          nearItem.index = index;
        }
      });
      let activeIndex = 0;
      if (activeArr.length > 0) {
        activeIndex = activeArr[0];
      } else {
        activeIndex = nearItem.index;
      }
      this.activeIndex = activeIndex;
    }
  };

  @action
  revert = () => {
    this.list = [];
    this.params = {
      page: 1,
      per: 20,
    };
  };

  @action
  nextPage = (key: string) => {
    this.params.page += 1;
    this.fetchList(key, true);
  };

  @action
  fetchList = async (key: string, next?: boolean) => {
    this.loading = true;
    !next && this.revert();
    if (
      !(this.params.page > 1 && this.pagination.current === this.params.page)
    ) {
      const res =
        key === "comm"
          ? await fetchPcMemberListApi(this.params)
          : await fetchPcReferenceListApi(this.params);
      this.list =
        this.params.page > 1 && this.pagination.current !== this.params.page
          ? [...this.list, ...formatList(res.contents)]
          : formatList(res.contents);
      this.pagination = {
        total: res.totalCount,
        current: res.currentPage,
        pageSize: this.params.per,
        hasNextPage: res.hasNextPage,
      };
    }
    this.loading = false;
  };
}

export default createContext(new memberInboxStore());
