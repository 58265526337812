import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react";

import "./style.scss";
import { Header, Footer } from "../shared";
import ValidateAccount from "./ValidateAccount";
import ValidateCode from "./ValidateCode";
import ResetPassword from "./ResetPassword";
import ForgetPasswordStore from "../../stores/forgetPasswordStore";

const ForgetPassword: React.FC = () => {
  const { step, isSignin } = useContext(ForgetPasswordStore);

  useEffect(() => {}, [step]);

  const components = [
    <ValidateAccount key={0} />,
    <ValidateCode key={1} />,
    <ResetPassword key={2} />,
  ];

  return (
    <div
      className={`pro-forget-password-layout ${isSignin &&
        "pro-forget-password-layout--bottom0"}`}
    >
      <Header />
      <div className="pro-forget-password-content">{components[step]}</div>
      {!isSignin && <Footer />}
    </div>
  );
};

export default observer(ForgetPassword);
