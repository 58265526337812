import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { observer } from "mobx-react";

import { PRO } from "../../constants/pro_constants";
import { labelToChinese } from "../../constants/label_to_chinese";
import researchDetailStore from "../../stores/researchDetailStore";
import authStore from "../../stores/authStore";
import { useQuery } from "../../utils/useQuery";

const BasePage = () => {
  const query = useQuery();
  const history = useHistory();

  const id = query.get("document");
  const { isCurrentUserEmpty } = useContext(authStore);
  const { data, initializeId, initializeData } = useContext(
    researchDetailStore
  );

  const { header = {} } = data;

  useEffect(() => {
    if (id) {
      initializeId(id);
      initializeData();
    }
  }, []);

  useEffect(() => {
    if (header.fileUrl) {
      if (isCurrentUserEmpty) {
        window.sessionStorage.setItem("path", header.fileUrl);
        history.push("/login");
      } else {
        window.location.href = header.fileUrl;
      }
    }
  }, [header]);

  return (
    <div className="base-page-layout">
      <Helmet>
        <title>{labelToChinese[PRO]}</title>
      </Helmet>
    </div>
  );
};

export default observer(BasePage);
