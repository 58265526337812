import React, { useContext } from "react";

import "./style.scss";
import { Button } from "../shared";
import communicationStore from "../../stores/communicationStore";
import { observer } from "mobx-react";

interface Props {
  title: string;
  description: string;
  image: string;
  groupAvatar: string;
  summary: string;
}

const GroupCard: React.FC<Props> = (props: Props) => {
  const { updateIsShowModal } = useContext(communicationStore);

  return (
    <div className="pro-communication__group-card">
      <img src={props.image} alt={props.title} />
      <div className="pro-communication__group-card-info">
        <h4>{props.title}</h4>
        <span>{props.description}</span>
        <div className="pro-communication__group-card-button">
          <Button
            grid={10}
            type="ghost"
            onClick={() =>
              updateIsShowModal(
                true,
                props.title,
                props.groupAvatar,
                props.summary
              )
            }
          >
            立即加入
          </Button>
        </div>
      </div>
    </div>
  );
};

export default observer(GroupCard);
