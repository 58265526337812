import React from "react";
import { observer } from "mobx-react";

import "./style.scss";
import SelectionRatingModulesForm from "./SelectionRatingModulesForm";

interface Props {
  title: string;
  forms: any;
}

const SelectionRatingModulesItem: React.FC<Props> = ({ title, forms }) => {
  return (
    <div className="selection-rating-modules-item">
      <h3>{title}</h3>
      <div className="selection-rating-modules-item-content">
        {forms.map((item, index) => (
          <SelectionRatingModulesForm key={index} form={item} />
        ))}
      </div>
    </div>
  );
};

SelectionRatingModulesItem.defaultProps = {
  title: "",
};

export default observer(SelectionRatingModulesItem);
