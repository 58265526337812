import React from "react";

const SVG = ({
  fill = "#282828",
  width = "100%",
  className = "",
  viewBox = "0 0 30 30",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>RSS</title>
    <desc>Created with Sketch.</desc>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="RSS" fillRule="nonzero">
        <rect
          id="Rectangle"
          fill="#000000"
          opacity="0"
          x="0"
          y="0"
          width="30"
          height="30"
        />
        <path
          d="M7.98046875,24.0410156 C6.62109375,24.0410156 5.52246094,22.9394531 5.52246094,21.5830078 C5.52246094,20.2265625 6.62402344,19.125 7.98046875,19.125 C9.33984375,19.125 10.4384766,20.2265625 10.4384766,21.5830078 C10.4384766,22.9394531 9.33984375,24.0410156 7.98046875,24.0410156 Z M17.3056641,23.484375 L14.1123047,23.484375 C14.2705078,14.4960938 5.75976562,15.4482422 5.75976562,15.4482422 L5.75976562,12.46875 C17.6484375,12.46875 17.3056641,23.484375 17.3056641,23.484375 Z M23.7216797,23.484375 L20.3232422,23.484375 C20.2705078,7.78125 5.75976562,9.24023438 5.75976562,9.24023438 L5.75976562,6.09082031 C25.0048828,6.09082031 23.7216797,23.484375 23.7216797,23.484375 Z"
          id="Shape"
          fill={fill}
          opacity="0.800000012"
        />
      </g>
    </g>
  </svg>
);

export default SVG;
