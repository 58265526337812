import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";

import { IconHeading, Icon, AuthWallModal } from "../shared";
import authStore from "../../stores/authStore";

const SolutionsBusinessCaseItem = item => {
  console.log("item", JSON.stringify(item));
  const [isExpand, setIsExpand] = useState(false);
  const [isShowAuthWallModal, setIsShowAuthWallModal] = useState(false);
  const { isTrialUser } = useContext(authStore);

  const isShowModal = isTrialUser && isShowAuthWallModal;

  return (
    <div className="solution-business-cases__item">
      <div className="solution-business-cases__title">
        {isTrialUser ? (
          <a
            className="text-limit--one"
            onClick={() => {
              setIsShowAuthWallModal(true);
            }}
          >
            {item.name}
          </a>
        ) : (
          <Link
            to={`/database/business-cases/${item.id}`}
            className="text-limit--one"
            target="_blank"
          >
            {item.name}
          </Link>
        )}
        <div className="solution-business-cases__title__key-data">
          {item.key_data.map((ele, i) => {
            return (
              <span
                className="solution-business-cases__title__key-data--item"
                key={i}
              >
                <span className="solution-business-cases__title__key-data--label">
                  {ele.key}：
                </span>
                <span className="solution-business-cases__title__key-data--value">
                  {ele.value}
                </span>
              </span>
            );
          })}
        </div>
      </div>
      <p className="solution-business-cases__summary">{item.summary}</p>
      <div className="mobile-solution-business-cases__title__key-data">
        {item.keyData &&
          item.keyData.map((ele, i) => {
            return (
              <span
                className="mobile-solution-business-cases__title__key-data--item"
                key={i}
              >
                <span className="mobile-solution-business-cases__title__key-data--label">
                  {ele.key}：
                </span>
                <span className="mobile-solution-business-cases__title__key-data--value">
                  {ele.value}
                </span>
              </span>
            );
          })}
      </div>
      {item.technologies.length > 0 && (
        <span className="solution-business-cases__technologies">
          核心技术：
          {item.technologies.map((ele, i) => (
            <span key={i}>{ele.name}</span>
          ))}
        </span>
      )}

      {((item.insititutions && item.insititutions.length > 0) ||
        item.background ||
        item.desc) &&
        !isExpand && (
          <div
            className="solution-business-cases__expand"
            onClick={() => {
              setIsExpand(true);
            }}
          >
            显示更多 <Icon name="pro-arrow-down" size={16} color="#6b8aba" />
          </div>
        )}

      {isExpand && (
        <>
          {item.insititutions.length > 0 && (
            <div className="solution-business-cases__cooperate-institution solution-business-cases__block">
              <IconHeading
                icon="pro-cooperation-institution"
                title="合作机构"
              />
              <div className="solution-business-cases__block-content solution-business-cases__cooperate-institution-content">
                {item.insititutions.map((ele, i) => {
                  return isTrialUser ? (
                    <a
                      className="solution-business-cases__cooperate-institution__item"
                      key={i}
                      onClick={() => {
                        setIsShowAuthWallModal(true);
                      }}
                    >
                      <div className="solution-business-cases__cooperate-institution__item--avatar">
                        <img src={ele.avatar_url} alt="" />
                      </div>
                      <span>{ele.name}</span>
                    </a>
                  ) : (
                    <Link
                      to={`/database/institutions/${ele.id}`}
                      className="solution-business-cases__cooperate-institution__item"
                      key={i}
                      target="_blank"
                    >
                      <div className="solution-business-cases__cooperate-institution__item--avatar">
                        <img src={ele.avatar_url} alt="" />
                      </div>
                      <span>{ele.name}</span>
                    </Link>
                  );
                })}
              </div>
            </div>
          )}
          {item.background && (
            <div className="solution-business-cases__background solution-business-cases__block">
              <IconHeading icon="pro-first-order" title="项目背景与客户痛点" />
              <div
                className="solution-business-cases__block-content"
                dangerouslySetInnerHTML={{ __html: `${item.background}` }}
              />
            </div>
          )}
          {item.desc && (
            <div className="solution-business-cases__detail-intro solution-business-cases__block">
              <IconHeading icon="pro-text-yes" title="案例详解" />
              <div
                className="solution-business-cases__block-content"
                dangerouslySetInnerHTML={{ __html: `${item.desc}` }}
              />
            </div>
          )}
        </>
      )}

      {isShowModal && (
        <AuthWallModal
          visible={isShowAuthWallModal}
          title="无法查看当前数据"
          description={` 您当前的用户类型为"试用用户"，仅支持查看基本信息。点击"成为会员"，获得全部数据访问权限，解锁更多高级功能。`}
          label={item.type}
          onCancel={() => {
            setIsShowAuthWallModal(false);
          }}
        />
      )}
    </div>
  );
};

export default SolutionsBusinessCaseItem;
