import moment from "moment";

const formatNewsTabs = initData => {
  return initData.map(ele => ele);
};

const formatNewsList = initData => {
  return initData.map(item => {
    return {
      id: item.id,
      title: item.title,
      types: item.dynamicTypes,
      sourceUrl: item.source_url,
      time: moment(item.publishedAt * 1000).format("YYYY-MM-DD"),
      institutions: item.institutions.map(institution => ({
        avatarUrl: institution.avatarUrl,
        name: institution.name,
        id: institution.id,
      })),
    };
  });
};

export { formatNewsTabs, formatNewsList };
