import React from "react";

const SVG = ({
  fill = "#92929D",
  width = "100%",
  className = "",
  viewBox = "0 0 20 20",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FFF"
        d="M5.833 12.5c.92 0 1.667.746 1.667 1.667 0 .92-.746 1.666-1.667 1.666-.92 0-1.666-.746-1.666-1.666 0-.92.746-1.667 1.666-1.667zm5-5.833c.92 0 1.667.746 1.667 1.666 0 .92-.746 1.667-1.667 1.667-.92 0-1.666-.746-1.666-1.667 0-.92.746-1.666 1.666-1.666zm-5-5.834C6.753.833 7.5 1.58 7.5 2.5s-.746 1.667-1.667 1.667c-.92 0-1.666-.747-1.666-1.667S4.913.833 5.833.833z"
        transform="translate(1.667 1.667)"
      />
      <path
        fill={fill}
        d="M.833 3.333h2.654C3.832 4.301 4.748 5 5.833 5c1.085 0 2.001-.7 2.346-1.667h7.654c.461 0 .834-.372.834-.833 0-.46-.373-.833-.834-.833H8.18C7.834.699 6.92 0 5.833 0c-1.085 0-2 .7-2.346 1.667H.833c-.46 0-.833.372-.833.833 0 .46.372.833.833.833m15 10H8.18c-.345-.967-1.26-1.666-2.346-1.666-1.085 0-2 .699-2.346 1.666H.833c-.46 0-.833.373-.833.834 0 .46.372.833.833.833h2.654c.345.967 1.261 1.667 2.346 1.667 1.085 0 2.001-.7 2.346-1.667h7.654c.461 0 .834-.373.834-.833 0-.461-.373-.834-.834-.834m0-5.833H13.18c-.345-.968-1.26-1.667-2.346-1.667-1.085 0-2 .7-2.345 1.667H.833c-.46 0-.833.372-.833.833 0 .461.372.834.833.834h7.655c.345.967 1.26 1.666 2.345 1.666 1.085 0 2.001-.699 2.346-1.666h2.654c.461 0 .834-.373.834-.834 0-.46-.373-.833-.834-.833m-10-5.833c.46 0 .834.373.834.833 0 .46-.374.833-.834.833C5.373 3.333 5 2.96 5 2.5c0-.46.373-.833.833-.833m0 13.333c-.46 0-.833-.373-.833-.833 0-.46.373-.834.833-.834.46 0 .834.374.834.834 0 .46-.374.833-.834.833m5-5.833c-.46 0-.833-.374-.833-.834 0-.46.373-.833.833-.833.46 0 .834.373.834.833 0 .46-.374.834-.834.834"
        transform="translate(1.667 1.667)"
      />
    </g>
  </svg>
);

export default SVG;
