import React, { useContext } from "react";
import { observer } from "mobx-react";

import "./style.scss";
import { Input } from "antd";
import { Button } from "../shared";
import authStore from "../../stores/authStore";
import couponCodeStore from "../../stores/couponCodeStore";

const ExchangeCoupon: React.FC = () => {
  const { disabled, changeCode, validateCode } = useContext(couponCodeStore);
  const { currentUser } = useContext(authStore);
  return (
    <div className="pro-coupon-code-exchange">
      <h2>请在下方输入优惠码</h2>
      <p>
        优惠码相关的权益将会运用在{" "}
        <span>
          {currentUser.name} ({currentUser.email})
        </span>
        账号中。
      </p>
      <div>
        <Input
          autoComplete="off"
          placeholder="如有优惠码，请在此键入"
          onChange={e => changeCode(e.target.value)}
        />
      </div>
      <Button disabled={disabled} size="large" grid={10} onClick={validateCode}>
        确认兑换
      </Button>
    </div>
  );
};

export default observer(ExchangeCoupon);
