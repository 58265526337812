import React, { useContext, useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { Helmet } from "react-helmet";

import "./style.scss";
import { Sidebar } from "../shared";
import FilterSearchPanel from "./FilterSearchPanel";
import databaseStore from "../../stores/databaseStore";
import SelectedConditionsPanel from "./SelectedConditionsPanel";
import ResultListPanel from "./ResultListPanel";
import { PRO } from "../../constants/pro_constants";
import { labelToChinese } from "../../constants/label_to_chinese";

interface Props {
  type: "institution" | "solution" | "dataset";
}

const Database: React.FC<Props> = (props: Props) => {
  const contentRef = useRef();

  const {
    initializeType,
    initializeFilters,
    fetchDatabaseList,
    isSelectedConditionsPanelShow,
    resetFilters,
  } = useContext(databaseStore);

  useEffect(() => {
    initializeType(props.type);
    initializeFilters();
    resetFilters();
    fetchDatabaseList();
  }, [props.type]);

  return (
    <div className="detail-layout">
      <Helmet>
        <title>
          {labelToChinese[props.type]} ｜ {labelToChinese[PRO]}
        </title>
      </Helmet>

      <div className="detail-layout__sidebar">
        <Sidebar />
      </div>
      <div className="pro-layout__content-wrapper">
        <div className="database-layout__content" ref={contentRef}>
          <FilterSearchPanel type={props.type} />
          {isSelectedConditionsPanelShow && (
            <SelectedConditionsPanel type={props.type} parentRef={contentRef} />
          )}
          <ResultListPanel />
        </div>
      </div>
    </div>
  );
};

export default observer(Database);
