import { createContext } from "react";
import { observable, action, computed } from "mobx";
import _ from "lodash";
import {
  formatLatestInsight,
  fortmatInsightCategories,
  formatInsight,
} from "../utils/formatInsightData";
import {
  fetchLatestInsight,
  fetchInsightList,
  fetchInsightCategories,
} from "../api";
import { trackInsightEvent } from "../utils/ga_events";

class InsightStore {
  @observable latestInsightList = [];
  @observable insightCategories = [];
  @observable insightList = [];
  @observable filterParams = "";
  @observable loading = false;
  @observable hasMore = true;
  @observable totalCount = 0;
  @observable pagination = {
    current: 1,
    pageSize: 20,
  };

  @action
  initializeLatestInsight = async () => {
    const result = await fetchLatestInsight();
    this.latestInsightList = formatLatestInsight(result.data);
  };

  @action
  initializeInsightCategories = async () => {
    const result = await fetchInsightCategories();
    this.insightCategories = fortmatInsightCategories(result.categories);
  };

  @action
  initializeInsightList = async () => {
    const result = await fetchInsightList(this.filterParams, this.getUrlParams);
    this.insightList = formatInsight(result.data);
    trackInsightEvent("view_list", this.condition, this.pagination.current);
    this.pagination.current = this.pagination.current + 1;
  };

  @action
  updateInsightList = async () => {
    const result = await fetchInsightList(this.filterParams);
    this.insightList = formatInsight(result.data);
    trackInsightEvent("view_list", this.condition, this.pagination.current);
    this.pagination.current = this.pagination.current + 1;
    this.hasMore = true;
  };

  @action
  fetchMoreData = async () => {
    this.loading = true;

    const result = await fetchInsightList(this.filterParams, this.getUrlParams);
    this.insightList = this.insightList.concat(formatInsight(result.data));
    trackInsightEvent("view_list", this.condition, this.pagination.current);
    this.totalCount = result.totalCount;
    this.loading = false;

    if (this.totalCount !== 0 && this.isLoadedAll) {
      this.hasMore = false;
      this.loading = false;
    } else {
      this.pagination.current = this.pagination.current + 1;
    }
  };

  @action
  setTypeActive = (data, type?) => {
    this.pagination.current = 1;

    if (type === "all") {
      this.insightCategories = this.insightCategories.map((item, i) => {
        item.active = i === 0;
        return item;
      });
    } else {
      this.insightCategories = this.insightCategories.map(item => {
        if (item.name == data) {
          item.active = true;
        }
        return item;
      });
      this.insightCategories[0].active = !this.insightCategories.some(
        (item, i) => i != 0 && item.active === true
      );
    }
  };

  @action
  cancelTypeActive = data => {
    this.insightCategories = this.insightCategories.map(item => {
      if (item.name == data) {
        item.active = false;
      }
      return item;
    });
    this.insightCategories[0].active = !this.insightCategories.some(
      (item, i) => i != 0 && item.active === true
    );
  };

  @action
  handleCategoryChanged = async (name: string, type?: string) => {
    this.setTypeActive(name, type);
    this.updateFilterParams();
    this.updateInsightList();
  };

  @action
  handleCategoryRemoved = async (name: string) => {
    this.cancelTypeActive(name);
    this.updateFilterParams();
    this.updateInsightList();
  };

  @action
  updateFilterParams = () => {
    let params = "";
    this.insightCategories.map(item => {
      if (item.active && item.name !== "全部") {
        params += `categories[]=${item.value}&`;
      }
    });

    this.filterParams = params;
  };

  @computed
  get condition() {
    let conditions = "";
    this.insightCategories.map((item, i) => {
      if (item.active) {
        conditions += `${item.name}${
          i === this.insightCategories.length - 1 ? "" : "|"
        }`;
      }
    });
    return conditions;
  }

  @computed
  get getUrlParams() {
    let params = this.pagination.current
      ? `size=${this.pagination.pageSize}&page=${this.pagination.current}&`
      : "";

    return params;
  }

  @computed
  get isLoadedAll() {
    return this.pagination.pageSize * this.pagination.current > this.totalCount;
  }

  @computed
  get islatestInsightListEmpty() {
    return _.isEmpty(this.latestInsightList);
  }
}

export default createContext(new InsightStore());
