import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { Header, Footer, Button } from "../shared";
import SuccessImage from "../../styles/images/payment-success.svg";
import AuthStore from "../../stores/authStore";
import "./style.scss";

const PaymentSuccess = () => {
  const { isAlphaUser, currentUser } = useContext(AuthStore);

  return (
    <div className="payment-success-layout header-footer--verticality-layout">
      <Header />
      <div className="payment-success-layout__mesasge">
        <img src={SuccessImage} alt="恭喜你，支付成功" />
        <h3 className="payment-success-layout__title">支付成功</h3>
        <p className="payment-success-layout__text">
          您已成为机器之心 PRO 会员
        </p>
        <p className="payment-success-layout__text">
          我们将持续为您提供更专业、更优质的服务
        </p>
        <Link
          to={isAlphaUser ? "/home" : `/users/${currentUser.id}`}
          className="payment-success-layout__forward"
        >
          <Button type="primary" size="large" grid={10}>
            进入机器之心PRO
          </Button>
        </Link>
        <Link to="/profile" className="payment-success-layout__bill">
          {`前往「个人中心-会员身份」开具发票 >`}
        </Link>
      </div>
      <Footer />
    </div>
  );
};

export default PaymentSuccess;
