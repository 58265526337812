import React, { useEffect, useContext } from "react";
import { observer } from "mobx-react";

import "./dialog.scss";
import { Dialog } from "../shared";
import errorIcon from "../../styles/images/inbox-pay-error.png";
import memberInboxStore from "../../stores/memberInboxStore";

const PayErrorDialog = () => {
  const { dialog, setDialog } = useContext(memberInboxStore);
  const onCancel = () => setDialog("pay-error", false);

  useEffect(() => {
    // api();
  }, []);

  return (
    <Dialog
      visible={dialog["pay-error"]}
      style={{ padding: "24px 14px", borderRadius: "10px" }}
      onCancel={onCancel}
      layerClose={false}
      closeStyle={{ color: "rgba(55,65,81,0.2)", top: "24px", right: "14px" }}
    >
      <div className="pay-error-dialog">
        <img src={errorIcon} alt="errorIcon" />
        <p>支付失败</p>
        <div
          onClick={() => {
            // 后续 retry dialog name 非cost弹窗 必传!;
            const name = dialog.params.name || "cost";
            setDialog(name, true);
            onCancel();
          }}
        >
          重新支付
        </div>
      </div>
    </Dialog>
  );
};

export default observer(PayErrorDialog);
