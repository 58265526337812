import React from "react";

const SVG = ({
  fill = "#92929D",
  width = "100%",
  className = "",
  viewBox = "0 0 20 20",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <path
                        fill="#FFF"
                        d="M8.333 0.833L5.833 5.833 0.833 6.667 4.167 10 3.333 15.833 8.333 13.333 13.333 15.833 12.5 10 15.833 6.667 10.833 5.833z"
                        transform="translate(-23 -571) translate(0 30) translate(0 537) translate(23 2) translate(0 1) translate(.667 .667)"
                      />
                      <path
                        fill={fill}
                        d="M2.567 7.223L4.756 9.41c.185.186.273.447.235.707l-.61 4.268 3.646-1.8c.235-.116.512-.115.745.003l3.51 1.778-.607-4.249c-.038-.26.05-.521.236-.707l2.203-2.203-3.53-.629c-.262-.046-.486-.215-.603-.455L8.323 2.717l-1.745 3.49c-.12.237-.345.405-.608.448l-3.403.568zm.766 9.444c-.172 0-.344-.054-.49-.159-.25-.181-.38-.486-.335-.793l.774-5.42L.244 7.256c-.221-.22-.3-.546-.204-.843.095-.298.348-.517.656-.568l4.581-.764 2.31-4.62c.143-.284.455-.471.75-.46.318.001.606.183.745.469l2.212 4.543 4.685.834c.306.054.556.274.649.57.093.297.014.62-.206.84l-3.039 3.038.775 5.42c.043.308-.087.615-.34.796-.252.181-.586.206-.862.066l-4.565-2.312-4.689 2.315c-.116.058-.243.087-.369.087z"
                        transform="translate(-23 -571) translate(0 30) translate(0 537) translate(23 2) translate(0 1) translate(.667 .667)"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
