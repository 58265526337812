import moment from "moment";

const formatOrderData = initData => ({
  orderId: initData.orderId,
  price: initData.price / 100,
  expiredAt: moment
    .unix(initData.expiredAt)
    .utcOffset(480)
    .format("YYYY年MM月DD日"),
  productId: initData.productId,
  productName: initData.productName,
  username: initData.userName,
  userEmail: initData.userEmail,
});

const formatPersonProductData = initData => ({
  id: initData.id,
  name: initData.name,
  price: initData.price / 100,
  duration: initData.duration,
  reducedPrice: initData.finalPrice / 100,
});

export { formatOrderData, formatPersonProductData };
