import React, { useContext, useRef } from "react";
import { observer } from "mobx-react";

import "./style.scss";
import { Form, Input, Row, Col } from "antd";
import { Button } from "../shared";
import ForgetPasswordStore from "../../stores/forgetPasswordStore";
import authStore from "../../stores/authStore";
import arrowLeftImg from "../../styles/images/arrow-left-circle.png";

const ValidateAccount: React.FC = () => {
  const captchaRef = useRef(null);
  const [form] = Form.useForm();

  const { updateCaptcha } = useContext(authStore);
  const { isSignin, onSend, isComplete, setIsComplete } = useContext(
    ForgetPasswordStore
  );

  const onFieldsChange = () => {
    const values: any = form.getFieldsValue();
    const { login_name, _rucaptcha } = values;
    setIsComplete(
      !!login_name &&
        !!_rucaptcha &&
        form.getFieldsError().filter(({ errors }) => errors.length).length === 0
    );
  };

  return (
    <div className="pro-forget-password-account">
      <Form
        form={form}
        onFieldsChange={onFieldsChange}
        onFinish={values => isComplete && onSend(values, true, () => {})}
      >
        <div className="pro-forget-password-title--wrapper">
          <div className="pro-forget-password-title">
            <img src={arrowLeftImg} alt="arrow" />
            <p>验证账号，重置密码</p>
          </div>
        </div>
        <Form.Item
          hasFeedback
          rules={[
            { required: true, message: "请输入正确的账户名" },
            {
              pattern: /(^1[0-9]{10}$)|(^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$)/,
              message: "账户名不正确",
            },
          ]}
          name="login_name"
        >
          <Input autoComplete="off" placeholder="输入手机号码或工作邮箱" />
        </Form.Item>
        <Row gutter={24}>
          <Col span={16}>
            <Form.Item
              hasFeedback
              rules={[
                { required: true, message: "请输入正确的验证码" },
                { min: 5, message: "验证码是 5 位" },
              ]}
              name="_rucaptcha"
            >
              <Input autoComplete="off" placeholder="图形验证码" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <img
              className="pro-forget-password__captcha"
              ref={captchaRef}
              src="/rucaptcha/"
              alt="图形验证码"
              onClick={e => updateCaptcha(captchaRef.current, e)}
            />
          </Col>
        </Row>
        <Form.Item
          className={`pro-forget-password__send ${!isComplete &&
            "pro-forget-password__send--opacity"}`}
        >
          <Button isFullWidth size="large" htmlType="submit">
            发送验证码
          </Button>
        </Form.Item>
      </Form>
      {!isSignin && (
        <div className="pro-forget-password-info">
          <p>如无法通过手机或邮箱验证</p>
          <p>请发送邮件至 support@jiqizhixin.com</p>
        </div>
      )}
    </div>
  );
};

export default observer(ValidateAccount);
