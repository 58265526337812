import React from "react";
import { Modal } from "antd";
import { Link } from "react-router-dom";

import "./style.scss";
import { Icon, Button } from "..";
import bluePanel from "../../../styles/images/auth-modal-blue.png";
import crownIcon from "../../../styles/images/pro-crown.svg";
import { trackHitTheWallEvent } from "../../../utils/ga_events";

interface Props {
  visible: boolean;
  title: string;
  description: string;
  label: string;
  onCancel: () => void;
}

const AuthWallModal: React.FC<Props> = (props: Props) => {
  return (
    <Modal
      className="auth-wall-modal"
      style={{ top: 200 }}
      width={430}
      visible={props.visible}
      footer={null}
      closeIcon={<Icon name="pro-close" size={16} color="#c1c7d0" />}
      onCancel={props.onCancel}
    >
      <img className="auth-wall-modal__bg--blue" src={bluePanel} alt="" />
      <div className="auth-wall-modal__header">
        <img src={crownIcon} alt="" />
        <h2>Pro 会员专属</h2>
      </div>
      <div className="auth-wall-modal__content">
        <h3>{props.title}</h3>
        <p>{props.description}</p>
        <div className="auth-wall-modal__button-group">
          <Button
            type="grey"
            size="large"
            grid={10}
            onClick={() => {
              props.onCancel();
              trackHitTheWallEvent(
                "go_on_trial",
                props.label.replace(/^\S/, s => s.toUpperCase())
              );
            }}
          >
            继续试用
          </Button>
          <Link to="/pricing">
            <Button
              size="large"
              grid={10}
              onClick={() => {
                trackHitTheWallEvent(
                  "become_vip",
                  props.label.replace(/^\S/, s => s.toUpperCase())
                );
              }}
            >
              成为会员
            </Button>
          </Link>
        </div>
      </div>
    </Modal>
  );
};

export default AuthWallModal;
