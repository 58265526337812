import React from "react";
import { Helmet } from "react-helmet";
import { observer } from "mobx-react";

import "./style.scss";
import { Sidebar } from "../shared";
import InsightHeader from "./InsightHeader";
import InsightList from "./InsightList";
import { PRO, INSIGHT } from "../../constants/pro_constants";
import { labelToChinese } from "../../constants/label_to_chinese";

const Insight = () => {
  return (
    <div className="insight-layout">
      <Helmet>
        <title>
          {labelToChinese[INSIGHT]}｜ {labelToChinese[PRO]}
        </title>
      </Helmet>

      <div className="insight-layout__sidebar">
        <Sidebar />
      </div>
      <div className="pro-layout__content-wrapper" id="insight-content">
        <div className="insight-layout__content">
          <InsightHeader />
          <InsightList />
        </div>
      </div>
    </div>
  );
};

export default observer(Insight);
