import React, { useState, useContext, useEffect } from "react";
import { Upload, Input, message } from "antd";

import { Button, Icon, Notify } from "../shared";
import { observer } from "mobx-react";
import ProfileStore from "../../stores/ProfileStore";
import { PROTOCOL, WWWHost } from "../../api";
import uploadIcon from "../../styles/images/upload-icon.svg";
import UpdateEmailAndPhoneModal from "./UpdateEmailAndPhoneModal";
import UpdatePassword from "./UpdatePassword";
import encryptMobile from "../../utils/encryptMobile";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const bindData = [
  {
    title: "微信",
    icon: "bind-wechat-icon",
  },
  {
    title: "微博",
    icon: "bind-webo-icon",
  },
  {
    title: "Github",
    icon: "bind-github-icon",
  },
];

const ACTIVECOLORS = {
  "bind-wechat-icon": "#0BB906",
  "bind-webo-icon": "#D71F06",
  "bind-github-icon": "#121212",
};

const csrfToken = document.querySelector("meta[name=csrf-token]").content;

const PersonalInformation = () => {
  const [imageUrl, setImageUrl] = useState("");
  const [name, setName] = useState("");
  const [type, setType] = useState("phone");
  const [isFirstSettingPW, setIsFirstSettingPW] = useState(false);

  const {
    personalInfo,
    isUpdateName,
    isMobileEmpty,
    isShowModal,
    isShowPasswordModal,
    isUpdatePassword,
    isPasswordEmpty,
    fetchPersonalInfo,
    onUpdateName,
    setIsUpdateName,
    onActivateEmail,
    onVerfiyIdentidy,
    setIsShowModal,
    setIsShowPasswordModal,
    onCancelBind,
  } = useContext(ProfileStore);
  const isEmailUnverified = personalInfo.email && !personalInfo.emailVerified;

  bindData.map((element, index) => {
    element["name"] =
      personalInfo.bindData && personalInfo.bindData[index].name;
    element["value"] =
      personalInfo.bindData && personalInfo.bindData[index].value;
    if (element["value"]) {
      element["color"] = ACTIVECOLORS[element.icon];
    } else {
      delete element["color"];
    }
    return element;
  });

  const handleChange = info => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      Notify.success({
        message: "系统通知",
        description: "头像上传成功",
      });
      getBase64(info.file.originFileObj, url => {
        setImageUrl(url);
      });
    }
  };

  const onBindWechat = () => {
    const url = `https://open.weixin.qq.com/connect/qrconnect?appid=${window.appid}&scope=snsapi_login&redirect_uri=${window.location.origin}/auth/wechat/callback&state=&login_type=jssdk&self_redirect=default`;
    window.location.href = url;
  };

  useEffect(() => {
    fetchPersonalInfo();
  }, []);

  return (
    <section className="personal-info">
      <div className="personal-info__content">
        <h3 className="personal-info__title">基本信息</h3>
        <div className="personal-info__basic">
          <Upload
            name="user[avatar]"
            method="put"
            listType="picture"
            headers={{
              "X-CSRF-Token": csrfToken,
            }}
            accept="image/*"
            withCredentials
            className="personal-info__basic__uploader"
            showUploadList={false}
            action={`${PROTOCOL}//${WWWHost}/settings/profile`}
            onChange={handleChange}
          >
            {imageUrl ? (
              <img
                src={imageUrl}
                alt="avatar"
                className="avatar-img-size avatar-img-fit--auto"
                style={{ width: "100%" }}
              />
            ) : (
              <img
                src={personalInfo.avatarUrl}
                className="avatar-img-size avatar-img-fit--auto"
                alt=""
              />
            )}
            <img
              src={uploadIcon}
              alt="上传"
              className="personal-info__basic__upload-icon"
            />
          </Upload>
          <p className="personal-info__basic__name">
            {personalInfo.name}
            <Icon name="pro-icon" size={33} color="#0062ff" />
          </p>
          <div className="personal-info__basic__item">
            <span className="personal-info__basic__key">昵称</span>
            {isUpdateName ? (
              <Input
                className="personal-info__basic__value input"
                defaultValue={personalInfo.name}
                onChange={event => {
                  setName(event.target.value);
                }}
              />
            ) : (
              <span className="personal-info__basic__value">
                {personalInfo.name}
              </span>
            )}
            <div className="personal-info__basic__action">
              {isUpdateName ? (
                <>
                  <Button
                    grid={5}
                    size="xsmall"
                    type="text"
                    onClick={() => setIsUpdateName(false)}
                  >
                    取消
                  </Button>
                  <Button
                    grid={5}
                    size="xsmall"
                    type="second-primary"
                    onClick={() => onUpdateName(name)}
                  >
                    保存
                  </Button>
                </>
              ) : (
                <Button
                  grid={5}
                  size="xsmall"
                  type="link"
                  onClick={() => setIsUpdateName(true)}
                >
                  修改
                </Button>
              )}
            </div>
          </div>
          <div className="personal-info__basic__item">
            <span className="personal-info__basic__key">手机</span>
            <span className="personal-info__basic__value">
              {!isMobileEmpty ? encryptMobile(personalInfo.mobile) : "-"}
            </span>
            <div className="personal-info__basic__action">
              <Button
                grid={5}
                size="xsmall"
                type="link"
                onClick={() => {
                  onVerfiyIdentidy("mobile");
                  setIsShowModal(true);
                  setType("phone");
                }}
              >
                {isMobileEmpty ? "绑定" : "修改"}
              </Button>
            </div>
          </div>
          <div className="personal-info__basic__item">
            <span className="personal-info__basic__key">邮箱</span>
            <span className="personal-info__basic__value">
              {personalInfo.email ? personalInfo.email : "-"}
              {isEmailUnverified && (
                <span className="personal-info__basic__unverified">未激活</span>
              )}
            </span>
            <div className="personal-info__basic__action">
              {personalInfo.email ? (
                <>
                  <Button
                    grid={5}
                    size="xsmall"
                    type="link"
                    onClick={() => {
                      setType("email");
                      onVerfiyIdentidy("email");
                      setIsShowModal(true);
                    }}
                  >
                    修改
                  </Button>
                  {isEmailUnverified && (
                    <Button
                      grid={5}
                      size="xsmall"
                      type="second-primary"
                      onClick={() => onActivateEmail()}
                    >
                      激活
                    </Button>
                  )}
                </>
              ) : (
                <Button
                  grid={5}
                  size="xsmall"
                  type="link"
                  onClick={() => {
                    setType("email");
                    onVerfiyIdentidy("email");
                    setIsShowModal(true);
                  }}
                >
                  绑定
                </Button>
              )}
            </div>
          </div>
          <div className="personal-info__basic__item">
            <span className="personal-info__basic__key">账户密码</span>
            <span className="personal-info__basic__value">
              {personalInfo.password}
            </span>
            <div className="personal-info__basic__action">
              <Button
                grid={5}
                size="xsmall"
                type="link"
                onClick={() => {
                  if (isUpdatePassword) {
                    setIsFirstSettingPW(isPasswordEmpty);
                    setIsShowPasswordModal(true);
                  } else {
                    message.info("您需先绑定手机号或邮箱才可设置密码");
                  }
                }}
              >
                {isPasswordEmpty ? "设置" : "修改"}
              </Button>
            </div>
          </div>
        </div>
        <h3 className="personal-info__title">绑定设置</h3>
        <div className="personal-info__bind">
          {bindData.map((item, index) => (
            <div className="personal-info__basic__item" key={index}>
              <span className="personal-info__basic__key">{item.title}</span>
              <Icon name={item.icon} size={24} color={item.color} />
              <div className="personal-info__basic__action">
                {item.value ? (
                  <Button
                    grid={5}
                    size="xsmall"
                    type="link"
                    onClick={() => onCancelBind(item.name)}
                  >
                    取消绑定
                  </Button>
                ) : (
                  <>
                    {item.title === "微信" && (
                      <Button
                        grid={5}
                        size="xsmall"
                        type="link"
                        onClick={() => onBindWechat()}
                      >
                        绑定
                      </Button>
                    )}
                    {item.title !== "微信" && (
                      <a
                        href={`/auth/${
                          item.title === "微博" ? "weibo" : "github"
                        }`}
                      >
                        <Button grid={5} size="xsmall" type="link">
                          绑定
                        </Button>
                      </a>
                    )}
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      {isShowModal && (
        <UpdateEmailAndPhoneModal
          visible={isShowModal}
          onHide={() => setIsShowModal(false)}
          type={type}
        />
      )}
      {isShowPasswordModal && (
        <UpdatePassword
          visible={isShowPasswordModal}
          isFirstSettingPW={isFirstSettingPW}
          onHide={() => {
            setIsShowPasswordModal(false);
          }}
        />
      )}
    </section>
  );
};

export default observer(PersonalInformation);
