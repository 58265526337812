import React, { useContext } from "react";
import { Modal } from "antd";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";

import "./style.scss";
import { Icon, Button } from "..";
import bluePanel from "../../../styles/images/auth-modal-blue.png";
import crownIcon from "../../../styles/images/pro-crown.svg";
import authStore from "../../../stores/authStore";

interface Props {
  visible: boolean;
  title: string;
  userType: "forbidden" | "trial-expired" | "vip-expired";
  description?: React.ReactNode;
  onCancel: () => void;
}

const PrivilegeModal: React.FC<Props> = (props: Props) => {
  const { isAlphaUser, isPaidUser, currentUser } = useContext(authStore);
  const isPaidWithoutAlphaUser = !isAlphaUser && isPaidUser;

  return (
    <Modal
      className="privilege-modal"
      style={{ top: 200 }}
      width={430}
      visible={props.visible}
      footer={null}
      closeIcon={<Icon name="pro-close" size={16} color="#c1c7d0" />}
      onCancel={props.onCancel}
    >
      <img className="privilege-modal__bg--blue" src={bluePanel} alt="" />
      <div className="privilege-modal__header">
        <img src={crownIcon} alt="" />
        <h2>Pro 会员专属</h2>
      </div>
      <div className="privilege-modal__content">
        <h3>{props.title}</h3>
        {props.userType === "forbidden" &&
          (isPaidWithoutAlphaUser ? (
            <p>完成专业认证即可查看此报告。</p>
          ) : (
            <p>
              机器之心Pro为用户提供全面准确的人工智能市场信息，助力专业用户更好的把握人工智能发展趋势，更加高效的实现人工智能落地。
            </p>
          ))}
        {props.userType === "trial-expired" && (
          <>
            <p>点击「成为会员」</p>
            <p>获得全部数据访问权限，解锁更多高级功能</p>
          </>
        )}
        {props.userType === "vip-expired" && (
          <>
            <p>点击「会员续费」</p>
            <p>获得全部数据访问权限，解锁更多高级功能</p>
          </>
        )}
        <div className="privilege-modal__button-group">
          {props.userType === "forbidden" && (
            <Link
              to={
                isPaidWithoutAlphaUser
                  ? "/identification/step1"
                  : `/users/${currentUser.id}`
              }
            >
              <Button size="large" grid={10}>
                {isPaidWithoutAlphaUser ? "进行认证" : "前往认证"}
              </Button>
            </Link>
          )}
          {props.userType === "trial-expired" && (
            <Link to="/pricing">
              <Button size="large" grid={10}>
                成为会员
              </Button>
            </Link>
          )}
          {props.userType === "vip-expired" && (
            <Link to="/pricing">
              <Button size="large" grid={10}>
                会员续费
              </Button>
            </Link>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default observer(PrivilegeModal);
