import React from "react";

const SVG = ({
  fill = "#92929D",
  width = "100%",
  className = "",
  viewBox = "0 0 24 24",
}) => (
  <svg
    width={width}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill={fill}
        d="M12 1.501c1.418 0 2.793.278 4.086.825 1.25.528 2.373 1.286 3.338 2.25.964.965 1.722 2.088 2.25 3.338.547 1.293.825 2.668.825 4.086 0 1.418-.278 2.793-.825 4.086-.528 1.25-1.286 2.373-2.25 3.338-.965.964-2.088 1.721-3.338 2.25-1.293.547-2.668.825-4.086.825-1.418 0-2.793-.278-4.086-.825-1.25-.529-2.373-1.286-3.338-2.25-.964-.965-1.722-2.088-2.25-3.338C1.779 14.793 1.5 13.418 1.5 12c0-1.418.278-2.793.825-4.086.528-1.25 1.286-2.373 2.25-3.338.965-.964 2.088-1.722 3.338-2.25C9.207 1.779 10.582 1.5 12 1.5m0-1.199C5.54.302.302 5.539.302 12c0 6.46 5.237 11.698 11.698 11.698 6.46 0 11.698-5.237 11.698-11.698C23.698 5.54 18.461.302 12 .302z"
      />
      <path
        fill={fill}
        d="M10.552 17.841c0-.188-.006-.69-.01-1.353-2.025.48-2.452-1.067-2.452-1.067-.331-.918-.808-1.163-.808-1.163-.662-.493.049-.484.049-.484.73.056 1.116.82 1.116.82.649 1.216 1.704.865 2.118.661.066-.514.255-.865.463-1.064-1.617-.2-3.317-.883-3.317-3.932 0-.869.284-1.579.75-2.135-.075-.202-.325-1.01.071-2.106 0 0 .611-.214 2.002.816.594-.177 1.207-.267 1.822-.268.619.003 1.242.092 1.823.268 1.39-1.03 2-.816 2-.816.398 1.096.148 1.904.073 2.106.467.556.748 1.266.748 2.135 0 3.057-1.702 3.729-3.324 3.926.262.246.494.732.494 1.474 0 1.063-.008 1.922-.008 2.182 0 .213-3.61.21-3.61 0z"
      />
    </g>
  </svg>
);

export default SVG;
